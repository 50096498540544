/* Keys
------------------------------ */

ul.keys {
  margin: 15px -10px -10px;
  overflow: hidden;
}
  ul.keys li {
    float: left;
    width: 33.3%;
    margin: 10px 0;
    padding: 0;
    list-style: none;
  }
    .keys li span {
      display: block;
      position: relative;
      margin: 0 10px;
      border: 1px solid $c-key-brd;
      border-radius: 3px;
      padding: 1em 20px;
      overflow: hidden;
      background-color: $c-key-bg;
      transition: background .2s ease;
    }
    .keys li:hover span {
      background-color: $c-key-h-bg;
    }
      .keys li a {
        display: block;
        margin: -1em 20px -1em -20px;
        padding: 1em 0 1em 20px;
        overflow: hidden;
        color: $c-key-txt;
        font-weight: bold;
        text-decoration: none;
        text-shadow: 0 1px 0 rgba(255, 255, 255, .75);
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .keys li a:hover {
        color: $c-key-h-txt !important;
      }

      .keys li a.a-delete {
        position: absolute;
        top: 50%;
        right: 20px;
        margin: -7px 0 0;
        padding: 0;
      }
