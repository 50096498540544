// @media queries

@mixin mobile {
  @media only screen and (max-width: 480px) {
    @content;
  }
}
@mixin smallscreen {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin ipad {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}
@mixin mobile-and-ipad {
  @media only screen and (max-width: 480px),
         only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}
@mixin retina {
  @media only screen and (min-resolution: 144dpi),
         only screen and (min-resolution: 1.5dppx) {
    @content;
  }
}
