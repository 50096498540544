/* Upgrade Notice
------------------------------ */

#specialPage .upgrade {
  margin: 0 0 40px !important;
  border-radius: 5px;
  padding: 1em;
  background-color: $c-upgrade-bg;
  color: $c-upgrade-txt;
  text-align: center;
}
  #specialPage .upgrade span.sep {
    color: $c-upgrade-meta;
  }
  #specialPage .upgrade a {
    color: $c-upgrade-l;
  }
  #specialPage .upgrade a:hover {
    border: 0;
    color: $c-upgrade-h;
  }


p.upgrade-notice {
  @extend .icon-attention;

  margin: 0 0 30px;
  border-radius: 5px;
  padding: 20px;
  background-color: $c-upgrade-bg;
  color: $c-upgrade-txt;
}
  p.upgrade-notice a {
    color: $c-upgrade-l;
    text-decoration: underline;
  }

p.upgrade-notice.compact {
  display: inline-block;
  margin: .5em 0 0;
  padding-top: 1em;
  padding-bottom: 1em;
}
