/* Editor
------------------------------ */

.cv--editor {
  position: relative;
  height: 400px;
  border: 0;
  overflow: hidden;
  background-color: $c-editor-bg;
  color: $c-editor-txt;
  font: small/1.5 $f-sansserif;
}


// Notification about editing the latest revision

.editor-notification {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zi-editor-notification;
  text-align: center;

  p {
    display: inline-block;
    margin: 0;
    border-radius: 0 0 3px 3px;
    padding: 1em 1.5em;
    background-color: $c-editor-notify-bg;
    color: $c-editor-notify-txt;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .2);
    font-family: $f-sansserif;
  }
    var {
      font-size: .9em;
      white-space: nowrap;
    }
    .sep {
      margin: 0 .25em;
      color: $c-editor-notify-sep;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
}


// Ace Editor

.ace_info,
.ace_warning,
.ace_error {
  background: none !important;
}
.ace_scroller,
.ace_content,
.ace_gutter {
  top: 0 !important;
  bottom: 0 !important;
  height: auto !important;
}
.ace_gutter .ace_layer {
  height: auto !important;
}


// Fallback & Simple Editor

#edit-me,
#edit-textarea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: .75em;
  -webkit-overflow-scrolling: touch;
  font: 12px Monaco, Menlo, 'Ubuntu Mono', 'Droid Sans Mono', Consolas, monospace;
  tab-size: 4;
}
#edit-me.ace_editor {
  padding: 0;
}


// Toolbar

.editor-toolbar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zi-editor-toolbar;
  padding: 12px;
  background-color: $c-editor-tb-bg;
  font-family: $f-sansserif;

  .button {
    margin: 0;
  }
}
  .editor_commit-message {
    box-sizing: border-box;
    width: 50%;
    height: 3.75em;
    margin: 0 .75em 0 0;
    border: 1px solid #AAA;
    padding: .6em .75em;
    font: 1em $f-sansserif;
    resize: none;

    &:disabled {
      border-color: #DDD;
    }
  }


@include mobile-and-ipad {
  .cv--editor {
    text-size-adjust: none;
  }
  .cv--editor,
  #edit-textarea {
    background-color: $c-editor-mob-bg;
    color: $c-editor-mob-txt;
  }
  .editor-notification p {
    border-radius: 0;
  }
}
/*
@include mobile {
  .editor_commit-message {
    width: 100%;
    margin: 0 0 1em;
  }
}
*/
