/* Pagination
------------------------------ */

.paginator {
  margin: 30px 0 1em;
  border-top: 1px solid $c-paginator-brd;
  padding: 2em 0 0;

  @media print {
    display: none;
  }

  &.attached {
    margin-top: 0;
    border-top: 0;
  }
}
  .paginator .status {
    margin: .5em .5em 1.25em;
    color: $c-paginator-txt;
    font-size: .9em;
  }
  .paginator a.page,
  .paginator .current {
    border-width: 1px;
    border-style: solid;
    padding: 2px 6px;
  }
  .paginator a.page {
    border-color: $c-paginator-l-brd;
    background-color: $c-paginator-l-bg;
    text-decoration: none;
  }
  .paginator a.page:hover {
    border-color: $c-paginator-h-brd;
    background-color: $c-paginator-h-bg;
    color: $c-paginator-h-txt;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  }
  .paginator .current {
    border-color: $c-paginator-cur-brd;
    background-color: $c-paginator-cur-bg;
    color: $c-paginator-cur-txt;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
  }
  .paginator .break,
  .paginator a.link,
  .paginator .disabled {
    margin: 0 .5em;
  }
  .paginator .break {
    background-color: transparent;
    color: $c-paginator-dis-txt;
  }
  .paginator a.link {
    font-weight: bold;
  }
  .paginator .disabled {
    color: $c-paginator-dis-txt;
  }
