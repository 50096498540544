/* Invoices
------------------------------ */

@media print {
  .scr_only {
    display: none !important;
  }
}


/* Invoices list */

.invoices-list {
  // Equal height for columns
  margin-bottom: -99999px;
  margin-left: -20px;
  border-left: 1px dashed $c-form-boxed-brd;
  padding-bottom: 99999px;
  padding-left: 20px;

  li {
    margin: 0 0 .6em;
    padding-bottom: 1px;
    overflow: hidden;
    color: $c-light;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }
    .invoice-date {
      display: inline-block;
      width: 7.5ex;
      margin-right: .5em;
      border: 2px solid $c-invoice-date-brd;
      border-radius: 2px;
      padding: .4em .6em .5em;
      color: $c-invoice-date-txt;
      font-weight: bold;
      font-size: .85em;
      text-transform: uppercase;
      white-space: nowrap;
    }
    .discount {
      margin-right: .25em;
      color: $c-invoice-discount;
      text-decoration: line-through;
    }
    a {
      border-bottom: 1px solid $c-invoice-l-brd;
      text-decoration: none;
    }
    .sep {
      margin: 0 .25em;
      color: inherit;
    }
    .meta {
      color: inherit;
      font-size: .9em;
    }
  .payment-in-advance {
    .invoice-date {
      border-color: $c-invoice-date-adv-brd;
      background-color: $c-invoice-date-adv-bg;
      color: $c-invoice-date-adv-txt;
    }
    a {
      font-weight: bold;
    }
  }
}


/* Invoice */

table.invoice {
  margin: 2em 0;
  border-radius: 5px;
  background-color: $c-invoice-bg;
}
  table.invoice th,
  table.invoice td {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $c-invoice-brd;
    padding: .75em 1.5em;
    color: $c-invoice-txt;
    line-height: 2;

    @media print {
      border-color: #DDD;
      padding: .75em 3em .75em 0;
      text-align: left;
    }
  }
  table.invoice th {
    border: 0;
    color: $c-invoice-meta;
    text-align: left;
  }
    table.invoice td.summary,
    table.invoice kbd,
    table.invoice span.discount {
      color: $c-invoice-meta;
    }
    table.invoice td.summary {
      width: 65%;
      text-align: right;
    }
    table.invoice span.discount {
      margin-right: .5em;
      text-decoration: line-through;

      @media print {
        color: #CCC !important;
      }
    }
    table.invoice span.amount-paid {
      border-radius: 2px;
      padding: .25em .5em;
      background-color: $c-invoice-txt;
      color: $c-invoice-bg;

      @media print {
        font-weight: bold;
      }
    }


/* Preview */

#invoice-preview {
  border: 1px solid $c-invoice-preview-brd;
  border-radius: 5px;
  padding: 1em;
}
  #invoice-preview p,
  #invoice-preview pre {
    margin: 0 !important;
    font: .92em/1.4 $f-monospace-fb !important;
    white-space: pre-wrap;

    .fonts-loaded & {
      font-family: $f-monospace !important;
    }
    strong {
      font-weight: normal;
    }
  }
