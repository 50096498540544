/* Shared files
------------------------------ */

table.ls-shared {
  margin: 1.5em 0 0;
}
  table.ls-shared th,
  table.ls-shared td {
    border-bottom: 1px solid $c-tbl-brd;
    padding: .25em .5em;
    vertical-align: middle;
  }

  table.ls-shared thead th,
  table.ls-shared thead td {
    border-bottom: 2px solid $c-tbl-head-brd;
    color: $c-tbl-head-txt;
    font-size: .85em;
  }

  table.ls-shared tbody th,
  table.ls-shared tbody td {
    padding-top: .75em;
    padding-bottom: .75em;
    overflow: hidden;
    white-space: nowrap;
  }
  table.ls-shared tbody td {
    width: 16%;
    color: $c-tbl-txt;
  }
  table.ls-shared thead th.access,
  table.ls-shared tbody td.access {
    width: 5%;
    padding-right: 3em;
    text-align: center;
  }
  table.ls-shared tbody td.action {
    width: 12px;
    text-align: right;
  }
    table.ls-shared tbody td.action a.a-delete {
      vertical-align: -.25em;
    }
