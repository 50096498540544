/* Blank Slate
------------------------------ */

.message + .blank-slate {
  margin-top: -1.5em;
}
.toolbar + .blank-slate {
  margin-top: 0;
  border-top: 1px solid $c-toolbar-brd;
}

.boxed--compare > .blank-slate {
  margin-top: 0;
  margin-bottom: 0;
}

.blank-slate {
  margin: -30px 0;
  padding: 60px 0;
  background: url('../images/bg-leaves-blank@2x.png') no-repeat center 0;
  background-size: 730px 320px;
}
.blank-slate .wrapper {
  width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

  .maincol .blank-slate {
    margin: -30px 0 30px;
    border-bottom: 1px solid $c-activity-brd;
    padding: 30px 0;
  }
  .maincol .blank-slate .wrapper {
    width: auto;
  }
  .maincol .blank-slate .message {
    margin-bottom: 0;
  }

  .maincol .blank-slate.last {
    min-height: 260px;
    margin-bottom: 0;
    border-bottom: 0;
  }


  /* Messages */

  .blank-slate .message {
    padding: 1.5em 2em 1em !important;
    font-size: 1.075em;

    h2 {
      margin: 0 0 .5em !important;
      font-size: 1.2em !important;
    }
    p {
      margin: 0 0 .95em !important;
    }
    .form-submit {
      margin: 1em 0 .9em;
      font-size: .92em;

      a.button {
        vertical-align: middle;
      }
      a.link-action {
        border-bottom: 1px dashed $c-blank-msg-action-brd;
      }
      a.link-action:hover {
        border-bottom-style: solid;
      }
    }
    &.notice {
      background: rgba(#FF9, .33);

      h2 {
        color: $c-blank-msg-title;
      }
    }
  }
  .blank-slate h2 {
    margin: 1.5em 0 .5em;
    color: $c-blank-title;
    font-weight: bold;
    font-size: 1.3em;
  }
  .blank-slate p {
    margin: 0 0 1em;
  }
  .blank-slate a {
    text-decoration: underline;
  }


  /* Support options @ Dashboard */

  .blank-slate #support-options {
    padding: 0 30px;
  }
    .blank-slate #support-options p {
      margin: 0 0 1.75em;
      color: $c-blank-txt;
    }
    .blank-slate #support-options h2 {
      margin-bottom: 1em;
    }
    .blank-slate #support-options .link-wildbit {
      display: inline-block;
      width: 60px;
      height: 17px;
      margin: 0 .25em 3px;
      vertical-align: middle;
      background: url('../images/logo-wildbit@2x.png') no-repeat;
      background-size: 60px 17px;
      text-indent: -9999em;
    }
  .blank-slate ul#contactus {
    padding: 0 30px;
  }
  .blank-slate ul#contactus li {
    margin-bottom: .5em;
  }
  .blank-slate ul#contactus li .meta {
    color: $c-blank-meta;
  }
