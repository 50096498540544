/* Sidebar
------------------------------ */

#sidebar {
  h2 {
    margin: -1px 0 1em;
    border: 0;
    color: $c-dark;

    &.icon-pending::before {
      margin-right: .4em;
      vertical-align: baseline;
    }
  }
  hr {
    height: 0;
    margin: 30px -30px 30px -29px;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $c-sidebar-brd;
    background-color: transparent;
  }
  p.blank {
    margin: 1.25em 0 !important;

    &:last-child {
      margin: 1em 0 .25em !important;
    }
  }
  p.action {
    margin: 0 0 -2px -2px !important;
    overflow: hidden;
  }
  p.action.loading {
    &::before {
      @extend %icon-loading;
      @extend %icon-loading--medium;

      content: '';
    }
  }
  p.action.loading a.button {
    visibility: hidden;
  }
}


/* Progress Bar */

.progressBar {
  .border {
    border: 1px solid $c-progressBar-brd;
    border-radius: 3px;
    padding: 2px;
    overflow: hidden;
    background-color: #F5F5F5;
    background-image: linear-gradient(to bottom, #FFF, #FFF 75%, #F9F9F9);
  }
  .background {
    width: 0;
    height: 20px;
    border-radius: 2px 0 0 2px;
    background-color: mix(#399ACA, #FFF, 75%);
  }
  &.danger .background {
    background-color: #F27461;
 }
}

/* Data Import */

#progress-bar.invisible {
  position: absolute;
  top: -500px;
  left: 0;
}


/* Tips */

.block-tips {
  margin: .5em .5em 1em;
  font-size: .9em;
  line-height: 1.55;
}


/* New default block */

.sidebar-block {
  position: relative;
  margin: 0 -30px 0 -29px;
  border-bottom: 1px solid $c-sidebar-brd;
  padding: 30px;
  overflow: hidden;

  &:first-child {
    margin-top: -30px;
  }
}
.sidebar-block--first {
  margin-top: -30px;
}
.sidebar-block--last {
  border-bottom: 0;
}


/* Sidebar Blocks (DEPRECATED. Use .sidebar-section) */

.sidebar_section,
.whats-new,
.did-you-know,
.repo-info,
.repo-subscription,
.repo-team,
.trial-account {
  position: relative;
  margin: 0 -30px 30px -29px;
  border-bottom: 1px solid $c-sidebar-brd;
  padding: 0 30px 30px;
  overflow: hidden;
}
  .did-you-know p {
    color: $c-sidebar-txt;
  }


  /* Repository Info */

  .repo-info {
  }
    .repo-info p {
      margin: 1.2em 0 0;
      font-size: .9em;
    }
      .repo-info p a {
        text-decoration: underline;
      }

    .repo-url {
      position: relative;
      margin: .25em 0 0;
      border: 1px solid $c-repo-url-brd;
      border-radius: 3px;
      padding: .2em .4em;
      box-shadow: 0 1px 2px rgba(#000, .05);
    }
      .repo-url input {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        background-color: transparent;
        font: normal .92em/1.5 $f-monospace-fb;
        text-shadow: 0 1px 0 #FFF;
        outline: none;

        .fonts-loaded & {
          font-family: $f-monospace;
        }
      }


    // HTTPS vs SSH selector

    .repo-url-selector {
      overflow: hidden;
    }
      $type-label-width: 4.25em;

      .repo-url-type {
        position: relative;
        float: left;
        box-sizing: border-box;
        width: $type-label-width;
        border: 1px solid $c-repo-url-type-brd;
        border-radius: 3px 0 0 3px;
        padding: .2em 0;
        background-color: $c-repo-url-type-bg;
        color: $c-repo-url-type-txt;
        box-shadow: 0 1px 2px rgba(#000, .05);
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: $c-repo-url-type-h-bg;
        }
      }
        .repo-url-type-label {
          font-weight: bold;
          font-size: .8em;
          -webkit-font-smoothing: antialiased;
          line-height: normal;
          text-shadow: 0 1px 0 rgba(#FFF, .2);

          &::after {
            content: '';

            display: inline-block;
            width: 0;
            height: 0;
            margin-right: -.25em;
            margin-left: .33em;
            border-top:   3px solid $c-repo-url-type-txt;
            border-right: 3px solid transparent;
            border-left:  3px solid transparent;
            vertical-align: .25em;
          }
        }
        .repo-url-type select {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }

      .repo-url--pretyped {
        margin-left: $type-label-width;
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }


  /* Repo Subscription */

  .repo-subscription {
  }
    .repo-subscription p {
      color: $c-sidebar-txt;
    }
    .repo-subscription a.button {
      margin: .5em 0 0 !important;
    }


  /* Repo Team */

  .repo-team {
    border-bottom-width: 0;

    a.button {
      margin: .25em 0 -2px -2px !important;
    }
    ul {
      margin: 1.25em 0 !important;
    }
      li {
        position: relative;
        margin: 0 0 .75em;
        padding: 0 0 .75em 45px;
        list-style: none;
      }
        ul a {
          text-decoration: none;
        }
          .userpic {
            display: block;
            position: absolute;
            top: .2em;
            left: 0;
          }
          .username {
            color: $c-base;
            font-weight: bold;
          }
          .last-ci {
            display: block;
            color: $c-mute;
            font-size: .85em;
          }
    ul.repo-team-compact {
      li {
        margin-bottom: .25em;
        padding-left: 26px;
      }
      li.repo-team-expand {
        padding-left: 0;
        font-size: .9em;
      }
        .userpic {
          top: 0;
        }
        .username {
          font-weight: normal;
        }
    }
  }
  .repo-team--cr {
    border-bottom-width: 1px;

    ul {
      margin-bottom: 0 !important;

      li:last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Trial Account */

  .trial-account {
    margin-top: -30px;
    padding-top: 30px;
  }
    .trial-account p {
      color: $c-sidebar-txt;
    }


  /* What's new? */

  .whats-new,
  .did-you-know {
    a.link-hide {
      position: absolute;
      top: -29px;
      right: 0;
      width:  21px;
      height: 21px;
      border-width: 0 0 1px 1px;
      border-style: solid;
      border-color: $c-sidebar-brd;
      border-radius: 0 0 0 3px;
      background-color: #FFF;
      text-indent: -9999em;

      &::before {
        content: '';

        position: absolute;
        top:  (21px - 7px) / 2;
        left: (21px - 7px) / 2;
        width:  7px;
        height: 7px;
        background: url('../images/b-hide-sidebar.svg') no-repeat;
        background-size: 7px 16px;
      }
      &:hover {
        background-color: #F6F6F6;

        &::before {
          background-position: 0 -7px;
        }
      }
    }
  }

  .whats-new {
    overflow: visible;
  }
    .whats-new .news-title {
      margin: 0 0 .5em;
      color: $c-news-bar-txt;
      font-size: .85em;
    }
      .whats-new .news-title a {
        color: $c-news-bar-txt;
        text-decoration: underline;
      }
      .whats-new .news-title a:hover {
        text-decoration: none;
      }

    .whats-new .notifier {
      margin-bottom: 0;
    }


  /* Did you know? */

  .did-you-know {
    overflow: visible;
  }
    .did-you-know span.key {
      display: inline-block;
      margin: 0 .25em;
      border: 1px solid lighten($c-sidebar-txt, 30%);
      border-radius: 2px;
      padding: 0 .35em;
      color: $c-sidebar-txt;
      line-height: 1.3;
    }
