/* Manual deployment
------------------------------ */

/* Environment name */

strong.environment-name {
  color: $c-dark;

  .color-badge {
    margin-right: .1em;
    margin-left:  .25em;
  }
}
.form-field strong.environment-name {
  line-height: 1.9;
}



/* Review & Deploy */

table.environment-changes {
  margin: 2em 0 .5em;
  border-radius: 5px;
  background-color: $c-dpl-review-bg;
}
  table.environment-changes th,
  table.environment-changes td {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $c-dpl-review-brd;
    padding: .75em 1.5em;
    color: $c-dpl-review-txt;
    line-height: 2;
  }
  table.environment-changes th {
    border: 0;
    padding: .9em 1.75em;
    color: $c-dpl-review-thead-txt;
    font-size: .85em;
    text-align: left;
  }
  table.environment-changes td.server-type {
    width: 5%;
    padding-right: 0;
    text-align: right;
  }
    table.environment-changes td.server-type span {
      font-weight: bold;
      font-size: .85em;
    }
  table.environment-changes td.server-name {
    padding-left: .75em;
  }
  table.environment-changes .revision,
  table.environment-changes .total-commits {
    width: 18%;
  }
    table.environment-changes td.revision a {
      border-bottom: 1px dashed $c-dpl-review-l-brd;
      color: $c-dpl-review-l;
      text-decoration: none;
    }
    table.environment-changes td.revision a:hover {
      border: 0;
    }

  table.environment-changes .revision-range {
    width: 36%;

    a {
      color: #333;
      text-decoration: none;

      u {
        border-bottom: 1px solid #BBB;
        text-decoration: none;
      }
    }
    a:hover {
      u {
        border-bottom-color: #808080;
      }
    }
    a.i-compare::before {
      margin-right: .25em;
      vertical-align: -.2em;
    }
  }

  table.environment-changes td.action {
    padding: 1.5em;
    line-height: 1;
    text-align: center;
  }


/* SSH overview */

.ssh-overview {
  margin: 40px 0 0;
}
  .ssh-overview h3 {
    margin: 0 0 .75em;
    padding: 0;
    color: $c-base;
    font-size: 1.1em;
  }
  .ssh-overview code pre {
    position: relative;
    width: 610px; /* TODO: this is a nasty hack */
    max-height: 20em;
    border-radius: 5px;
    padding: 1.25em 15px;
    overflow: auto;
    background-color: $c-term-bg;
    color: $c-term-txt;
    font-size: 11px;
    line-height: 1.5;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .75);
  }
