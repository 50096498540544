/* Toolbar
------------------------------ */

$tb-margin: 10px;
$tb-margin-extra: 5px;

.toolbar {
  @extend .clearfix;

  min-height: 26px; // eq. to .button height
  margin: -15px 0 15px;
  color: $c-toolbar-txt;

  a {
    text-decoration: underline;
  }
  a:hover {
    color: $c-toolbar-txt-h;
  }

  // TODO: where is it used?
  .loading {
    @extend %icon-loading;

    margin: 0 $tb-margin;
  }
  .button {
    margin-right: $tb-margin;
  }
}
.toolbar--underline {
  margin: -15px -30px 30px;
  border-bottom: 1px solid $c-toolbar-brd;
  padding: 0 30px 15px 20px;
}
.toolbar--detached {
  border-bottom: 1px solid $c-toolbar-brd;
  padding-bottom: 15px;
}
.toolbar--compare {
  padding: 10px 20px;
  text-align: center;
}

  // Separator

  .toolbar-sep {
    display: inline-block;
    width: 1px;
    height: 22px;
    margin: 2px ($tb-margin + $tb-margin-extra) 2px (2px + $tb-margin-extra);
    vertical-align: middle;
    background: $c-toolbar-sep;
  }
  .toolbar-sep--invisible {
    margin-right: $tb-margin;
    margin-left:  $tb-margin;
    background: transparent;
  }

  // Text or link

  .toolbar-text {
    display: inline-block;
    margin-top: .2em;
    vertical-align: middle;
  }

  // Filename on browse page

  .toolbar-title {
    display: inline-block;
    margin: 0 $tb-margin .1em 0;
    vertical-align: middle;
    overflow: visible;
    color: $c-toolbar-txt;
    font-weight: bold;
    font-size: 1.385em;
    line-height: normal;

    .color-badge--env {
      margin-right: .15em;
      vertical-align: -.05em;
    }
    .color-badge--team {
      margin-right: .15em;
      vertical-align: baseline;
    }
    .plain {
      margin-left: .25em;
      color: $c-toolbar-plain;
      font-weight: 200;
    }
    .a-settings {
      margin-left: .25em;
      vertical-align: -.15em;
    }
    .userpic {
      position: relative;
      top: 5px;
      margin-right: .15em;
      vertical-align: baseline;
    }
  }

    .editable-name {
      display: inline-block;
      margin: 0 -.33em;
      border-radius: 2px;
      padding: 0 .33em .1em;

      &:hover {
        background-color: $c-cs-msg-h-bg;
        cursor: text;
      }
    }
    .editable-name_form {
      display: inline-block;
      vertical-align: top;
      font-weight: normal;
      font-size: .722em;

      fieldset {
        display: inline-block;
        position: static;
        margin: 0;
        border: 0;
        padding: 0;
        overflow: visible;
        background-color: transparent;
      }
        input,
        .sep,
        .link-action {
          vertical-align: middle;
        }
        .sep {
          margin: 0 .33em;
        }
        input[type='submit'] {
          appearance: button;
        }
        .icon-loading {
          margin-left: 1em;
          vertical-align: middle;
        }
    }
        .editable-name_input {
          margin: -1px 0 -1px -6px;
          border: 1px solid $c-toolbar-editable-brd;
          border-radius: 2px;
          padding: 0 .275em .1em;
          color: $c-toolbar-txt;
          font: bold 1.385em/1 $f-sansserif;

          &::placeholder {
            padding-top: .15em;
            font-weight: normal;
          }
          &:focus {
            border-color: $c-toolbar-editable-f-brd;
            outline: none;
          }
          &.editable-name_input--changed {
            border-color: $c-toolbar-editable-chg-brd;
            background-color: $c-toolbar-editable-chg-bg;
          }
          &.editable-name_input--error {
            border-color: $c-toolbar-editable-e-brd;
            background-color: $c-toolbar-editable-e-bg;
            color: $c-red-dark;
            box-shadow: 0 0 2px 0 $c-toolbar-editable-e-brd;
          }
        }
        .editable-name_error {
          position: absolute;
          z-index: $zi-editable-name-error;
          margin: 1px 0 0 -6px;
          border-radius: 0 0 2px 2px;
          border-top: 1px solid $c-toolbar-errormsg-brd;
          padding: .6em .75em;
          background-color: $c-toolbar-errormsg-bg;
          color: $c-toolbar-errormsg-txt;
          box-shadow: 0 1px 2px rgba(#000, .25);
        }

    a.toolbar-title-link {
      color: $c-toolbar-txt;
      text-decoration: none;

      u {
        display: inline-block;
        height: 1.25em;
        border-bottom: 1px solid mix(#FFF, $c-toolbar-txt, 75%);
        text-decoration: none;
      }
      &:hover {
        color: $c-toolbar-txt-h;

        u {
          border-bottom-color: mix(#FFF, $c-toolbar-txt-h, 50%);
        }
      }
    }


  // Swap references

  .swap-refs {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 2px 0 -6px;
    border-radius: 3px;
    vertical-align: middle;
    overflow: hidden;
    background: url('../images/i-swap.svg') no-repeat 50% 50%;
    opacity: .5;
    text-indent: -9999em;

    &:hover {
      opacity: 1;
    }
  }


  // Content on the right side

  .toolbar-aside {
    float: right;

    form {
      display: inline-block;
      vertical-align: middle;
    }
      .select-cover {
        vertical-align: middle;
      }
    p {
      margin: .2em 0;
    }
  }


/* 3rd Level: Horizontal Tabs  */

.tabs {
  display: inline-block;
  margin: 0;
  vertical-align: middle;

  > li {
    display: inline-block;
    margin-right: .75em;
    list-style: none;
  }
}
  .tab {
    display: inline-block;
    border-radius: 2px;
    padding: .1em .6em .2em;
    color: $c-tab-txt;
    text-decoration: none;

    u {
      display: inline-block;
      height: 1.25em;
      border-bottom: 1px solid $c-tab-brd;
      text-decoration: none;
    }
    .count {
      margin-left: .25em;
      color: #BBB;
      font-size: .85em;
    }
  }
  .tab[href^='#'] {
    u {
      border-bottom-style: dashed;
    }
  }
  a.tab:hover {
    color: $c-tab-h-txt;
    text-decoration: none;

    u {
      border-bottom: 1px solid $c-tab-h-brd;
    }
  }

  .tab--current {
    background-color: $c-tab-cur-bg;
    color: #FFF !important;
    box-shadow: 0 1px 1px rgba(#FFF, .3), inset 0 1px 1px rgba($c-tab-cur-sh, .15);
    font-weight: bold;

    &:hover {
      background-color: $c-tab-h-cur-bg;
    }
    u {
      border-bottom: 0 !important;
    }
  }
  .tab--history {
    .tab {
      @extend .icon-history;
    }
    .tab:hover::before {
      background-position: 0 -14px;
    }
    .tab--current::before,
    .tab--current:hover::before {
      background-position: 0 -28px;
    }
  }
  .tab--locked {
    .tab {
      @extend .icon-locked;

      &::before {
        margin-right: .5em;
        vertical-align: -1px;
        background-position: 0 -24px;
      }
      &:hover::before {
        background-position: 0 -36px;
      }
    }
  }



/* Page Actions
------------------------------ */

.page-actions {
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid $c-toolbar-brd;
  padding-bottom: 15px;
  overflow: hidden;

  .mute a {
    color: inherit;
    text-decoration: underline;
  }
  &.alternate {
    margin-bottom: 0;
    border-bottom: 0;
  }
}
  .page-filters {
    float: right;
    padding: .65em 0 0;
    color: $c-toolbar-meta;
  }
  .page-actions-status {
    float: right;
    padding: .65em 0 0;
    color: $c-toolbar-meta;

    .sep {
      margin: 0 .25em 0 .5em;
      color: $c-toolbar-sep;
      font-size: .9em;
    }
  }
  .deployments-counter {
    display: inline-block;
    border-radius: 3px;
    padding: .15em .5em;
    background-color: $c-deploy-count-bg;
    color: $c-deploy-count-txt;
    font-size: .9em;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .4);
  }
