/* Events
------------------------------ */

$cr-event-icon: 16px;


.cr-events {
  margin: -20px 0 -30px;
}
  .cr-event {
    @extend .clearfix;

    position: relative;
    margin: 0 -30px 5px;
    border-top: 1px solid mix($c-content-bg, $c-cmt-brd, 50%);
    padding: 1.25em 30px;

    &:first-child {
      margin-top: -5px;
      border-top: 0;
    }
    a:link,
    a:visited,
    a:link:hover,
    a:visited:hover {
      text-decoration: underline;
    }
    a:hover {
      color: $c-dark;
    }
  }
    .cr-event_time {
      float: left;
      width: ($cr-timeline - 40px);
      margin-top: .33em;
      color: #AAA;
      font-size: .92em;
      white-space: nowrap;
    }
      .cr-event_date {
        float: left;
      }
      .cr-event_timestamp {
        float: right;
      }

    .cr-event_entry {
      position: relative;
      margin-left: $cr-timeline;
      line-height: 1.385; // 18px

      p {
        margin: .25em 0;
        line-height: inherit;

        strong {
          color: inherit;
        }
      }
    }

  .cr-event--approved,
  .cr-event--merged {
    border-top-color: #E5ECDC;
    background-color: $c-cmt-approval-bg !important;
    color: $c-form-status-ok-txt;
  }
  .cr-event--cancelled {
    border-top-color: #ECECEC;
    background-color: #F9F9F9;
    color: $c-mute;
  }
  .cr-event--commit {
    var {
      font-size: .85em;
    }
    a.rev-comment-extended {
      color: mix(#FFF, $c-link, 20%);
      font-size: .9em;
      text-decoration: none;
    }
    a.rev-comment-extended:hover {
      color: $c-link;
      text-decoration: none;
    }
  }
    .cr-push {
      &:first-of-type {
        margin-top: .66em;
      }
      td {
        border-bottom: 0;
        padding: .33em .5em .33em 0;
      }
    }
      .cr-push_rev {
        width: 1%;
      }
      .cr-push_dsc {
      }


  /* Code review status icons */

  .cr-event_icon {
    position: absolute;
    top: .1em;
    left: -((40px - $cr-event-icon) / 2 + $cr-event-icon);
    box-sizing: border-box;
    width:  $cr-event-icon;
    height: $cr-event-icon;
  }
  .cr-event_icon--approved,
  .cr-event_icon--cancelled {
    @extend .status-label;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -4px 0 0 -5px;
    }
  }
  .cr-event_icon--approved {
    @extend .status-label--ok;
  }
  .cr-event_icon--cancelled {
    @extend .status-label--disabled-error;
  }

  .cr-event_icon--reopened {
    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      width:  6px;
      height: 6px;
      margin: -3px 0 0 -3px;
      border-radius: 50%;
      background-color: #46A45C;
      background-image: none;
    }
  }

  .cr-event_icon--assigned,
  .cr-event_icon--unassigned,
  .cr-event_icon--committed,
  .cr-event_icon--merged,
  .cr-event_icon--issue-created,
  .cr-event_icon--issue-completed {
    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      width:  16px;
      height: 16px;
      margin: -8px 0 0 -8px;
      background-image: url('../images/i-cr-statuses.png');
      background-repeat: no-repeat;
      background-size: 96px 16px;

      @include retina {
        background-image: url('../images/i-cr-statuses@2x.png');
      }
    }
  }
  .cr-event_icon--assigned {
    &::before {
      background-position: 0 0;
    }
  }
  .cr-event_icon--unassigned {
    &::before {
      background-position: -16px 0;
    }
  }
  .cr-event_icon--committed {
    &::before {
      background-position: -32px 0;
    }
  }
  .cr-event_icon--merged {
    &::before {
      background-position: -48px 0;
    }
  }
  .cr-event_icon--issue-created {
    &::before {
      background-position: -80px 0;
    }
  }
  .cr-event_icon--issue-completed {
    &::before {
      background-position: -64px 0;
    }
  }
