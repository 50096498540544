/* Announces
------------------------------ */

.announce {
  position: relative;
  border-bottom: 1px solid $c-announce-brd;
  padding: 1em 30px;
  overflow: hidden;
  background-color: $c-announce-bg;
  color: $c-announce-txt;

  &.icon-warning::before {
    float: left;
  }

  a {
    color: $c-announce-l;
    text-decoration: underline;
  }
  a:hover {
    color: $c-announce-h;
  }
  a:active {
    color: $c-announce-a;
  }

  p {
    width: 90%;
    margin: 0;
    line-height: normal;
  }
  p.hide-announce {
    position: absolute;
    top: 1.15em;
    right: 30px;
    width: auto;
    font-size: .9em;
  }
}


/* Global Notification
------------------------------ */

.global-notification {
  position: relative;
  padding: .9em 45px 1.1em;
  background-color: $c-notify-global-bg;
  color: $c-notify-global-txt;
  box-shadow: 0 2px 5px rgba(#000, .33);
  font-weight: bold;
  line-height: 1.3;
  text-align: center;

  p {
    margin: .6em 0;
  }
  a {
    color: $c-notify-global-l;
    text-decoration: underline;
  }
}
.global-notification--expired-trial {
  border-bottom: 1px solid $c-expired-trial-brd;
  padding-left: (15px * 2 + 73px);
  background: $c-expired-trial-bg url('../images/logo-b-big.png') no-repeat 15px .33em;
  background-size: 73px 107px;
  color: $c-expired-trial-txt;
  box-shadow: none;
  font-weight: normal;
  text-align: left;
  text-shadow: 0 1px 1px rgba(#000, .15);

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: .75em;
    background-image: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, .13));
  }

  a,
  strong {
    color: $c-expired-trial-l;
  }
  a[rel='help'] {
    color: inherit;
  }
}
