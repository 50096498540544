/* Forms Extras
------------------------------ */

textarea {
  vertical-align: middle;
}

.http {
  margin-left: -2.9em;
  color: $c-form-boxed-meta;
}
.fullwidth {
  box-sizing: border-box;
  width: 100%;
}
.threefourths {
  box-sizing: border-box;
  width: 75%;
}
.halfwidth {
  box-sizing: border-box;
  width: 50%;
}


// Needed for compatibility with landing

.fullsize {
  @extend .fullwidth;
}


/* Terminal-like textarea */

.terminal {
  border: 0;
  border-radius: 3px;
  padding: .75em 1em !important;
  background-color: $c-term-bg;
  color: $c-term-txt;
  font: .92em/1.6 $f-monospace-fb !important;

  .fonts-loaded & {
    font-family: $f-monospace !important;
  }
}
.terminal:focus {
  background-color: $c-term-bg-f;
  outline: none;
}
.terminal::placeholder {
    color: $c-term-blank;
}


/* Checkbox with multiline label */

.multiline-checkbox {
  display: block;
  padding-left: 1.8em;
}
  .multiline-checkbox input {
    margin-left: -1.7em !important;
  }


/* Status */

.forms p.status,
.boxed p.status {
  margin: 1.5em 0 .75em;
  padding-left: 15px;
  background: url('../images/i-status.svg') no-repeat;
  background-size: 11px 200px;
  font-weight: bold;
  font-size: .85em;

  a,
  a:hover {
    color: inherit;
    text-decoration: underline;
  }
}
p.status.disabled {
  background-position: 0 -46px;
  color: $c-form-status-error-txt;
}
p.status.enabled {
  background-position: 0 2px;
  color: $c-form-status-ok-txt;
}


/* Profile Photo */

.forms img.photo-preview {
  border: 1px solid darken($c-form-boxed-brd, 5%);
  padding: 3px;
  vertical-align: middle;
  background-color: $c-form-boxed-bg;
}
.forms span.photo-label {
  margin-left: 1em;
  color: $c-form-boxed-meta !important;
}
.forms a.link-gravatar {
  display: inline-block;
  width: 70px;
  height: 14px;
  margin: 0 2px;
  vertical-align: -.25em;
  background: url('../images/logo-gravatar.png') no-repeat;
  background-size: 70px 14px;
  text-indent: -9999em;
}

/* Password Strength */

.password-strength,
.password-confirm {
  min-height: 1.5em;
  margin: .4em 0 1em !important;
  color: $c-form-boxed-meta;
  font-size: .923em;
  line-height: 1.5;
}
  .password-strength.align {
    clear: both;
    float: left;
    margin: -1em 0 1.25em !important;
  }
  .password-strength.loading {
    position: relative;
    width: 11px;
    overflow: hidden;
    text-indent: -9999em;

    &::before {
      @extend %icon-loading;

      content: '';

      position: absolute;
      top: .3em;
      left: 0;
    }
  }


  // In-place check with score

  .password-strength strong {
    margin-right: .5em;
    border-radius: 2px;
    padding: .2em .5em;
    color: $c-psw-strength-txt;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .33);
  }
  .password-strength strong.s-short {
    background-color: $c-psw-strength-short;
  }
  .password-strength strong.s-bad {
    background-color: $c-psw-strength-bad;
  }
  .password-strength strong.s-good {
    background-color: $c-psw-strength-good;
  }
  .password-strength strong.s-strong {
    background-color: $c-psw-strength-strong;
  }


  // Ajax-based password checker used when Strong Password is enabled

  .psw-label {
    margin-right: .25em;
    font-weight: bold;

    #specialPage #page .form-row & {
      display: block;
      margin-right: 0;
    }
  }
  .psw-label::before {
    content: '';

    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: .4em;
    background: url('../images/i-status.svg') no-repeat;
    background-size: 11px 200px;
  }

  .psw-label-weak {
    color: $c-form-status-error-txt;
  }
  .psw-label-weak::before {
    background-position: 0 -48px;
  }

  .psw-label-weak.psw-label-recommendation {
    color: $c-form-status-warn-txt;
  }
  .psw-label-weak.psw-label-recommendation::before {
    content: none;
  }

  .psw-label-strong {
    color: $c-form-status-ok-txt;
  }
  .psw-label-strong::before {
    background-position: 0 0;
  }


/* Checkbox toggle (See also: .subscription at _users.scss)
------------------------------ */

.checkbox-toggle {
  display: inline-block;
  position: relative;
  width:  20px;
  height: 20px;
  margin-top: -.15em;
  margin-right: .33em;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  overflow: hidden;
  background-color: #FFF;
  text-align: center;

  input[type='checkbox'] {
    margin: 0;
    vertical-align: -2px;
  }
  &:hover {
    border-color: #CCC;
  }
  &:focus {
    outline: none;
  }
  &.is-checked {
    border-color: $c-green-medium;
    background-color: rgba($c-green-light, .5);
  }
  &.is-loading {
    border-color: #E0E0E0;
    background-color: #FFF;

    &::before {
      @extend %icon-loading;

      content: '';

      position: absolute;
      top:  5px;
      left: 5px;
    }
    input[type='checkbox'] {
      opacity: 0;
    }
  }
}



/* Credit Cards
------------------------------ */


/* Credit card icon */

.icon-cc {
  $icon-cc-width:  16px;
  $icon-cc-height: 11px;

  display: inline-block;
  width:  $icon-cc-width;
  height: $icon-cc-height;
  margin-right: .5em;
  margin-left: .4em;
  border-radius: 2px;
  vertical-align: -.1em;
  background: url('../images/i-cc-small.png') no-repeat;
  background-size: $icon-cc-width ($icon-cc-height * 3);
  line-height: normal;

  @include retina {
    background-image: url('../images/i-cc.png');
    background-size: ($icon-cc-width * 2) ($icon-cc-height * 3);
  }

  &.cc-visa {
    background-position: 0 0;
  }
  &.cc-mastercard {
    background-position: 0 (-$icon-cc-height);
  }
  &.cc-amex {
    background-position: 0 (-$icon-cc-height * 2);
  }
}



/* Credit card type */

$cc-width:  32px;
$cc-height: 21px;
$cc-margin: 5px;

.cc-num-container {
  margin-right: (($cc-width + $cc-margin) * 3) + $cc-margin;
}
.cc-type {
  float: right;
  width: ($cc-width + $cc-margin) * 3;
  margin: .33em 0;
  padding: 0 !important;
  overflow: hidden;

  li {
    float: left;
    width:  $cc-width;
    height: $cc-height;
    margin: 0 0 0 $cc-margin;
    padding: 0 !important;
    overflow: hidden;
    background-image: url('../images/i-cc.png');
    background-repeat: no-repeat;
    background-size: ($cc-width * 2) ($cc-height * 3);
    text-indent: -999em;
    list-style: none;
  }
  .cc-visa {
    background-position: -$cc-width 0;

    &.current {
      background-position: 0 0;
    }
  }
  .cc-mastercard {
    background-position: -$cc-width (-$cc-height * 1);

    &.current {
      background-position: 0 (-$cc-height * 1);
    }
  }
  .cc-amex {
    background-position: -$cc-width (-$cc-height * 2);

    &.current {
      background-position: 0 (-$cc-height * 2);
    }
  }
}

@include retina {
  $cc-width:  30px;
  $cc-height: 19px;

  .cc-num-container {
    margin-right: (($cc-width + $cc-margin) * 3) + $cc-margin;
  }
  .cc-type {
    width: ($cc-width + $cc-margin) * 3;

    li {
      width:  $cc-width;
      height: $cc-height;
      background-image: url('../images/i-cc@2x.png');
      background-size: ($cc-width * 2) ($cc-height * 3);
    }
    .cc-visa {
      background-position: -$cc-width 0;

      &.current {
        background-position: 0 0;
      }
    }
    .cc-mastercard {
      background-position: -$cc-width (-$cc-height * 1);

      &.current {
        background-position: 0 (-$cc-height * 1);
      }
    }
    .cc-amex {
      background-position: -$cc-width (-$cc-height * 2);

      &.current {
        background-position: 0 (-$cc-height * 2);
      }
    }
  }
}


/* In-form validation */

.field-ok,
.field-error {
  position: relative;
  padding-right: 30px !important;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    right: 7px;
    margin: 0;
  }
}
.field-ok {
  &::before {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background: url('../images/i-checkmark-circle.svg') no-repeat;
    background-size: 16px 16px;
  }
}
.field-error {
  @extend .icon-warning;
  @extend .icon-warning--red;

  border: 1px solid $c-form-boxed-error-brd;
  box-shadow: 0 0 3px rgba($c-form-boxed-error-txt, .4);

  &::before {
    margin-top: -7px;
  }
}


/* Disabling Firefox field validation */

input[type='url']:invalid {
  box-shadow: none;
}



/* URLs validation */

.url-preview {
  position: relative;
  height: 1.5em + (.75em * 2);
  border: 1px solid $c-url-brd;
  border-radius: 3px;
  overflow: hidden;
  color: $c-url-meta;
  font: .92em/1.5em $f-monospace-fb;
  white-space: nowrap;

  .fonts-loaded & {
    font-family: $f-monospace;
  }
}
  .url-preview-wrap {
    position: absolute;
    right: 0;
    box-sizing: border-box;
    min-width: 100%;
    padding: .75em;
  }
    .url-input {
      margin: 0 .3em;
      border-radius: 1px;
      padding: .1em .4em;
      background-color: $c-url-bg;
      color: $c-url-txt;
      font-weight: normal;
    }

.form-error .url-preview {
  border: 1px dashed $c-url-error-brd;
  background-color: $c-url-error-bg;
  color:  $c-url-error-meta;
  cursor: pointer;
}
