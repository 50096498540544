$scroll-size:  6px;
$scroll-space: 0;

// Rail

.ps-container .ps-scrollbar-x-rail,
.ps-container .ps-scrollbar-y-rail {
  position: absolute;
  z-index: $zi-scrollbar-rail;
  background-color: $c-scrollrail-bg;
  transition: background-color .15s linear;
}
.ps-container .ps-scrollbar-x-rail {
  bottom: $scroll-space;
  height: $scroll-size;
}
.ps-container .ps-scrollbar-y-rail {
  right: $scroll-space;
  width: $scroll-size;
}
.ps-container .ps-scrollbar-x-rail:hover,
.ps-container .ps-scrollbar-y-rail:hover {
  background-color: $c-scrollrail-h-bg;
}

// X rail is disabled by default
/* stylelint-disable no-duplicate-selectors */
.ps-container .ps-scrollbar-x-rail {
  display: none;
}
.ps-container.scrollable-x .ps-scrollbar-x-rail {
  display: block;
}
/* stylelint-enable */


// Bar

.ps-container .ps-scrollbar-x,
.ps-container .ps-scrollbar-y {
  position: absolute;
  z-index: $zi-scrollbar-bar;
  border-radius: $scroll-size/2;
  background-color: $c-scrollbar-bg;
  cursor: pointer;
  transition: background-color .15s linear;
}
.ps-container .ps-scrollbar-x {
  bottom: 0;
  height: $scroll-size;
}
.ps-container .ps-scrollbar-y {
  right: 0;
  width: $scroll-size;
}
.ps-container .ps-scrollbar-x-rail:hover .ps-scrollbar-x,
.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y {
  background-color: $c-scrollbar-h-bg;
}
.ps-container .ps-scrollbar-x-rail.in-scrolling .ps-scrollbar-x,
.ps-container .ps-scrollbar-y-rail.in-scrolling .ps-scrollbar-y {
  background-color: $c-scrollbar-a-bg;
}


// Help sidebar

#help {
  .ps-container .ps-scrollbar-x-rail,
  .ps-container .ps-scrollbar-y-rail {
    background-color: transparent;
  }
  .ps-container .ps-scrollbar-x-rail:hover,
  .ps-container .ps-scrollbar-y-rail:hover {
    background-color: transparent;
  }
  .ps-container .ps-scrollbar-x,
  .ps-container .ps-scrollbar-y {
    background-color: rgba(#CCC, .4);
  }
  .ps-container .ps-scrollbar-x-rail:hover .ps-scrollbar-x,
  .ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y {
    background-color: rgba(#CCC, .6);
  }
  .ps-container .ps-scrollbar-x-rail.in-scrolling .ps-scrollbar-x,
  .ps-container .ps-scrollbar-y-rail.in-scrolling .ps-scrollbar-y {
    background-color: rgba(#CCC, .8);
  }
}
