/* Upgrade or Downgrade */

// TODO: Most likely these pages aren't used anymore
// Related pages:
// application/raw-html/adm-hq-downgrade.html
// application/raw-html/adm-hq-upgrade-promo.html
// application/raw-html/adm-hq-upgrade.html

.change-plan {
  margin: .6em 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
  .change-plan .from,
  .change-plan .to {
    width: 28%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: center !important;
  }
    .change-plan h2 {
      margin: .5em 0 0 !important;
      font-weight: bold;
      font-size: 1.3em !important;
    }
    .change-plan p {
      margin: 0;
      font-size: .9em;
      line-height: 1.6;
    }
    .change-plan p strong {
      padding: 0 .125em;
      color: $c-dark;
      font-size: 1.35em;
      line-height: 1.3;
    }

  .change-plan .from {
    border-color: $c-plan-change-from-brd;
    background-color: $c-plan-change-from-bg;
  }
    .change-plan .from h2 {
      color: $c-plan-change-from-title !important;
    }
    .change-plan .from p {
      color: $c-plan-change-from-txt;
    }
    .change-plan .from p strong {
      color: $c-plan-change-from-dark;
    }

  .change-plan .to {
    border-color: $c-plan-change-to-brd;
  }
    .change-plan .to h2 {
      color: $c-plan-change-to-title !important;
    }
    .change-plan .to p {
      color: $c-plan-change-to-txt;
    }
    .change-plan .to p strong {
      color: $c-plan-change-to-dark;
    }

.change-plan.plan-upgrade {
  background-image: url('../images/arrow-upgrade@2x.png');
  background-size: 26px 25px;
}
  .change-plan.plan-upgrade .from {
    float: left;
    margin-left: 10%;
  }
  .change-plan.plan-upgrade .to {
    float: right;
    margin-right: 10%;
  }

.change-plan.plan-downgrade {
  background-image: url('../images/arrow-downgrade@2x.png');
  background-size: 26px 25px;
}
  .change-plan.plan-downgrade .from {
    float: right;
    margin-right: 10%;
  }
  .change-plan.plan-downgrade .to {
    float: left;
    margin-left: 10%;
  }
