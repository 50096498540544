/* Wizards
------------------------------ */

/* Progress */

.wizard-progress {
  position: relative;
  margin: -40px -40px 40px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid $c-wizard-brd;
  padding: 20px 0 40px;
  overflow: hidden;
  background-color: $c-wizard-bg;

  &::before {
    content: '';

    position: absolute;
    top: 34px;
    right: 0;
    bottom: auto;
    left: 0;
    height: 2px;
    background: $c-wizard-brd;
  }
  ol {
    width: 720px;
    margin: 0;
    padding: 0 !important;
  }
    li {
      position: relative;
      z-index: $zi-wizard-progress-li;
      float: left;
      height: 23px;
      margin: 0;
      padding: 0;
      list-style: none;

      &::before,
      &::after {
        content: '';

        position: absolute;
        top: 14px;
        bottom: auto;
        height: 2px;
        background: $c-wizard-brd;
      }
      &::before {
        right: 50%;
        left: 0;
      }
      &::after {
        right: 0;
        left: 50%;
      }
      &.complete::before,
      &.complete::after,
      &.current::before {
        background: $c-wizard-brd-alt;
      }
    }
    .steps2 li {
      width: 50%;
    }
    .steps3 li {
      width: 33%;
    }
    .steps4 li {
      width: 25%;
    }
    .steps5 li {
      width: 20%;
    }
      span {
        display: block;
        padding: 5px 10px;
        color: $c-wizard-txt;
        font-size: .85em;
        text-align: center;
        text-shadow: 0 1px 0 #FFF;
      }
      .step-num {
        position: relative;
        z-index: $zi-wizard-progress-num;
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        border: 2px solid $c-wizard-txt;
        border-radius: 15px;
        padding: 4px 0 0;
        background-color: $c-wizard-step-bg;
        box-shadow: inset 0 0 0 1px #FFF;
        font-weight: normal;
        font-size: 1em;
      }
      .current span,
      .complete span {
        color: $c-wizard-cur-txt;
        font-weight: bold;
      }
      .current .step-num,
      .complete .step-num {
        border-color: $c-wizard-cur-txt;
        background-color: $c-wizard-cur-step-bg;
      }
}
