/* Integration Profile
------------------------------ */

/* Header (Logo & Buttons) */

.int-profile-header {
  position: relative;
  margin: 20px 0 0;
  overflow: hidden;
}

  /* Card */

  .int-profile-header .int-card {
    width: 20%;
    min-width: 150px;
    margin-left: 0;
  }
    .int-profile-header .int-card img {
      min-height: 150px;
    }
    .int-profile-header .int-card .logo {
      border-color: $c-int-card-brd-alt;
      transform-style: flat;
    }
      .int-profile-header .int-card .logo-container {
        top: 50%;
      }


  /* Status and Actions */

  .int-profile-header .int-actions {
    position: absolute;
    top: 30px;
    right: 0;
    bottom: 30px;
    left: 20%;
    border: 1px solid $c-form-boxed-brd;
    border-left-width: 0;
    overflow: hidden;
    background-color: $c-form-boxed-bg-alt;
  }
    .int-profile-header .int-actions .int-actions-alpha {
      display: table;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      overflow: hidden;
    }
      .int-profile-header .int-actions .int-actions-beta {
        display: table-cell;
        vertical-align: middle;
      }
      .int-profile-header .int-actions p.status {
        margin: .25em 0 1em 1px;
        font-size: 1em;
        line-height: 1.2;
      }
      .int-profile-header .int-actions a.button {
        vertical-align: middle;
      }
      .int-profile-header .int-actions .sep {
        margin: 0 .8em;
        color: $c-form-boxed-blank;
      }
      .int-profile-header .int-actions a.int-help-link {
        color: $c-int-help-l-alt;
        font-weight: bold;
        text-decoration: underline;
      }


/* Details (Screenshots & Features) */

.int-details {
  position: relative;
  margin: -10px 0 30px;
  overflow: hidden;
  color: $c-text;
}

  .int-feature {
    position: relative;
    margin-left: 6.66%;
    border-left: 1px solid $c-int-brd;
    padding-top: 30px;
    padding-left: 13.34%;
    overflow: hidden;
  }
    .int-feature .int-feature-wrap {
      margin-left: 19px;
    }
    .int-feature .int-pointer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $zi-int-pointer;
      width: 13.34%;
      height: 30px;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $c-int-brd;
      padding-top: .85em;
    }

  .int-feature.last {
    border-left: 0;
  }
    .int-feature.last .int-feature-wrap {
      margin-left: 20px;
    }
    .int-feature.last .int-pointer {
      border-left-width: 1px;
    }


    /* Description */

    .int-details h2 {
      position: relative;
      z-index: $zi-int-desc;
      margin: 0 0 .75em;
      padding: 0;
      overflow: visible;
      color: $c-int-title;
      font-size: 1.1em;
    }
      .int-details h2 .int-command {
        float: left;
        width: 149px;
        margin-top: .3em;
        margin-left: -169px;
      }
        .int-details h2 code {
          float: right;
          background-color: $c-content-bg;
          color: $c-int-meta;
          line-height: 1.2;
          text-align: right;
          white-space: nowrap;
        }
        .int-details h2 i.mode-auto {
          float: right;
          background-color: $c-content-bg;
        }
        .int-details h2 i.int-marker {
          position: relative;
          float: right;
          width: 1.25em;
          height: 1em;
          background-color: $c-content-bg;

          &::before {
            content: '';

            position: absolute;
            top: 50%;
            left: 0;
            width:  5px;
            height: 5px;
            margin-top: -2px;
            border-radius: 50%;
            background-color: #E0E0E0;
          }
        }

    .int-details p {
      margin: .5em 0 .75em;
    }


    /* Examples */

    .int-details pre code.source {
      margin: 1.5em 0 !important;
      padding: 1em 1.25em;
      overflow-x: auto;
      background-color: $c-term-bg;
      color: $c-term-txt;
      line-height: 1.8;
      white-space: pre;
    }
      .int-details pre code.source .mute {
        color: $c-term-mute;
      }


    /* Screenshots */

    .int-screenshot {
      width: 25%;
      min-width: 170px;
      margin: 1.25em 0;
      overflow: hidden;
    }
      .int-screenshot a {
        display: block;
        position: relative;
        min-height: 29px;
        margin: 0 20px 0 0;
        border: 1px solid $c-int-card-brd-alt;
        padding: 4px;
      }
      .int-screenshot a:hover {
        border-color: darken($c-int-card-brd-alt, 5%);
      }
        .int-screenshot a img {
          display: block;
          width: 100%;
          max-width: 341px;
          height: auto;
        }
        .int-screenshot a .int-ss-zoom {
          position: absolute;
          top: 4px;
          right: 4px;
          bottom: 4px;
          left: 4px;
          background-color: rgba($c-int-ss-cover, .25);
        }
        .int-screenshot a:hover .int-ss-zoom {
          background-color: rgba($c-int-ss-cover, .1);
        }

          .int-screenshot a .int-ss-zoom i {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../images/i-zoom.svg') no-repeat 100% 100%;
            background-size: 30px 30px;
          }


/* List of all webhooks */

.tb-webhooks {
  td {
    padding-top: 1em;
    padding-bottom: 1em;
    vertical-align: top;
  }
  tr.is-disabled {
    background-color: #FCFCFC;
    color: #AAA;

    .tb-webhooks_title {
      a {
        color: inherit;
      }
    }
  }
}
  .tb-webhooks_status {
    width: 3%;
  }
  .tb-webhooks_title {
    width: 25%;

    a {
      text-decoration: underline;
    }
  }
  .tb-webhooks_url {
    width: 30%;
  }
  .tb-webhooks_triggers {
    width: 30%;

    ul {
      font-size: .92em;

      li {
        display: inline;
        margin-right: .5em;

        &::after {
          content: ', ';
        }
      }
      li:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
  .tb-webhooks_action {
    text-align: right;
    white-space: nowrap;

    .a-settings,
    .a-delete {
      margin-left: .5em;
      vertical-align: middle;
    }
  }


/* Webhooks activity */

.tb-wh-activity {
  table-layout: fixed;

  tr.is-failed {
    background-color: rgba($c-pink-light, .5);
    color: $c-red;
  }
}
  .tb-wh-activity_response {
    width: 20%;

    a {
      border-bottom-color: inherit;
    }
  }
  .tb-wh-activity_http {
    width: 20%;
  }
  .tb-wh-activity_triggers {
    width: 20%;
  }
  .tb-wh-activity_time {
    width: 20%;
  }
  .tb-wh-activity_retry {
    text-align: right;
  }

  .tb-wh-activity_expanded {
    background-color: #FAFAFA;

    h4 {
      margin: .33em 0;
      font-size: 1em;
    }
    p {
      margin: .75em 0 1.75em;
    }
    .source {
      box-sizing: border-box;
      border: 1px solid #DDD;
      padding: 1.25em;
      overflow-wrap: break-word;
      background-color: #FFF;
      word-break: break-all;
      word-wrap: break-word;
    }
  }


/* Webhook triggers */

.wh-triggers {
  margin: .75em 0;
  padding-left: 0 !important;

  li {
    margin-bottom: .5em;
    color: $c-text;

    .sep {
      margin: 0 .33em;
      color: $c-mute;
    }
    input[type='checkbox'] {
      vertical-align: -.1em;
    }
  }
}
