// Color variables

// bg  - background
// brd - border
// txt - text
// dsb - disabled
// e   - empty
// l   - link
// v   - visited
// h   - hover
// a   - active
// f   - focus
// bdg - badge
// fb  - fallback


// SPECIFIC COLORS
// ---------------------------------

$c-lightest:                 #CCC;
$c-light:                    #AAA;
$c-mute:                     #999;
$c-text:                     #666;
$c-dark:                     #333;
$c-base:                     #000;

$c-brown:                    #413927;
$c-brown-dark:               #2F2816;

$c-pink-lightest:            #FFF8F4;
$c-pink-light:               #FFF0E9;
$c-pink:                     #F1CCCC;
$c-pink-medium:              #DDB7B7;
$c-red:                      #B00;
$c-red-dark:                 #900;

$c-yellow-lightest:          #FDFDE4;
$c-yellow-light:             #FFFDC3;
$c-yellow-bright:            #FCF28D;
$c-yellow-medium:            #FFDF40;
$c-yellow:                   #FFCC0D;

$c-olive-lightest:           #F8FAF5;
$c-olive-light:              #BACCA3;
$c-olive:                    #94A67D;
$c-olive-dark:               #4F5648;

$c-green-light:              #E3F5CE;
$c-green-medium:             #D0E7CC;
$c-green-darkish:            #269960;
$c-green-dark:               #4E6633;

$c-bottle-green:             #388C70;
$c-bottle-green-dark:        #208050;

$c-blue-portage:             #87A7E5;
$c-blue-lightest:            #F8F8FF;
$c-blue-lavender:            #C3CAFF;

$c-purple-darkest:           #334;
$c-purple-dark:              #667;
$c-purple-medium:            #89889C;
$c-purple:                   #ECEBFF;
$c-purple-light:             #F1F1FE;

$c-error:                    $c-red;
$c-warning:                  #C60;
$c-success:                  #0F7C14;



// ABSTRACT COLORS
// ---------------------------------

// File: _base.scss

$c-page-bg:                  $c-brown;
$c-page-txt:                 #FFFFE5;
$c-content-bg:               #FFF;

$c-link:                     #36C;

$c-h2:                       $c-dark;
$c-h4:                       #000;

$c-source-bg:                #F5F5FC;
$c-source-txt:               #556;

$c-tbl-head-brd:             #DDD;
$c-tbl-head-txt:             #AAA;
$c-tbl-action-brd:           $c-tbl-head-brd;
$c-tbl-action-bg:            #F6F6F6;
$c-tbl-action-f-bg:          $c-purple-light;
$c-tbl-brd:                  #F3F3F3;
$c-tbl-bg:                   #FCFCFC;
$c-tbl-txt:                  #777;
$c-tbl-meta:                 #AAA;
$c-tbl-meta-alt:             #BBB;
$c-tbl-dsb:                  #B5B5B5;
$c-tbl-empty-text:           #AAA;

$c-collapse-bg:              $c-blue-portage;
$c-collapse-h-bg:            #909090;

$c-highload-bg:              #FCFFEF;
$c-highload-brd:             #ECE8CA;
$c-highload-txt:             #553;

$c-notice-txt:               #C74D00;
$c-exclusive-bg:             #E1F1FA;
$c-exclusive-txt:            #39C;
$c-mark-bg:                  $c-yellow-lightest;
$c-mark-txt:                 #606000;
$c-mark-purple-bg:           $c-blue-portage;
$c-mark-purple-txt:          #FFF;
$c-mark-grey-bg:             #EEE;
$c-mark-grey-txt:            #444;

$c-link-system-txt:          #B70C1B;
$c-link-system-h-txt:        #C00;
$c-link-option-brd:          $c-lightest;
$c-link-option-txt:          $c-text;

$c-tag-bg:                   #99A;
$c-tag-txt:                  $c-blue-lightest;
$c-tag-more-txt:             #889;

$c-comment-txt:              #888;

$c-textbox-grey-bg:          #F6F6F6;
$c-textbox-grey-txt:         #777;
$c-textbox-purple-bg:        $c-purple;
$c-textbox-purple-txt:       $c-purple-medium;

$c-innertabs-brd:            mix(#FFF, $c-olive-light, 66%);
$c-innertabs-bg:             mix(#FFF, $c-olive-lightest, 50%);
$c-innertabs-txt:            darken($c-olive, 10%);
$c-innertabs-a-brd:          mix(#FFF, $c-olive-light, 50%);
$c-innertabs-a-bg:           #FFF;
$c-innertabs-a-txt:          #000;

// User labels

$c-user-owner-bg:            #690;
$c-user-admin-bg:            #930;
$c-user-invited-bg:          #069;

// Repository labels

$c-label-brd:                #EEE;
$c-label-txt:                #FFF;

$c-env-label-bg:             #FFF;
$c-env-label-brd:            #D9D9D9;

$c-label-pink:               #FDA7CC;
$c-label-red:                #FF5454;
$c-label-red-orange:         #F73;
$c-label-orange:             #FFA93A;
$c-label-yellow:             #F7D737;
$c-label-yellow-green:       #57D200;
$c-label-aqua-green:         #73E5AC;
$c-label-green:              #8ABF86;
$c-label-green-blue:         #7FBFBF;
$c-label-sky-blue:           #66E6FF;
$c-label-light-blue:         #99D5FF;
$c-label-blue:               #85AAF2;
$c-label-orchid:             #E493FD;
$c-label-violet:             #BF87BE;
$c-label-brown:              #BF9985;
$c-label-black:              #222;
$c-label-grey:               #AAA;
$c-label-white:              #FFF;
$c-label-white-txt:          #444;


// File: _hud.scss

$c-hud-bg:                   #FFF;
$c-hud-brd:                  #CFCFCF;
$c-hud-inside-brd:           #EEE;
$c-hud-txt:                  #666;
$c-hud-mute:                 $c-mute;
$c-hud-dark:                 $c-dark;

$c-hud-warning-brd:          $c-pink;
$c-hud-warning-bg:           $c-pink-lightest;

$c-hud-alt-bg:               #EEE;
$c-hud-alt-txt:              $c-text;
$c-hud-dsb-txt:              #AAA;

$c-hud-cancel-txt:           $c-error;
$c-hud-cancel-brd:           lighten($c-error, 48%);

$c-hud-url-bg:               $c-blue-lavender;
$c-hud-url-txt:              darken($c-blue-lavender, 70%);
$c-hud-url-f-bg:             $c-yellow-light;
$c-hud-url-f-txt:            #000;

$c-hud-search-bg:            #EEE;
$c-hud-search-brd:           #D9D9D9;
$c-hud-search-txt:           $c-text;
$c-hud-search-h-brd:         #C5C5C5;
$c-hud-search-f-brd:         rgba(#528FCC, .4);
$c-hud-search-f-bsh:         rgba(#528FCC, .15);

$c-hud-tab-brd:              lighten($c-hud-brd, 7.5%);
$c-hud-tab-txt:              #888;
$c-hud-tab-meta:             mix($c-hud-bg, $c-hud-tab-txt, 50%);
$c-hud-tab-h-txt:            $c-link;
$c-hud-tab-h-meta:           mix($c-hud-bg, $c-hud-tab-h-txt, 50%);
$c-hud-tab-cur-brd:          $c-hud-brd;
$c-hud-tab-cur-txt:          #000;
$c-hud-tab-cur-meta:         mix($c-hud-bg, $c-hud-tab-txt, 50%);
$c-hud-tab-dsb-txt:          #CCC;

$c-hud-ref-brd:              #EEE;
$c-hud-ref-txt:              #888;
$c-hud-ref-meta:             #BBB;
$c-hud-ref-h-txt:            #7EA0E3;
$c-hud-ref-h-meta:           $c-link;
$c-hud-ref-dsb-txt:          #CCC;

$c-hud-ref-more-bg:          rgba(#808080, .1);


// File: _perfect-scrollbar.scss

$c-scrollrail-bg:            rgba(#808080, .07);
$c-scrollrail-h-bg:          rgba(#808080, .15);

$c-scrollbar-bg:             rgba(#808080, .4);
$c-scrollbar-h-bg:           rgba(#808080, .6);
$c-scrollbar-a-bg:           rgba(#808080, .8);


// File: _layout.scss

$c-footer-txt:               #AAA;
$c-footer-meta:              #CCC;
$c-footer-l:                 #AAA;
$c-footer-h:                 #666;

$c-sidebar-mob-bg:           #F6F6F6;
$c-sidebar-mob-brd:          #DEDEDE;


// File: _header.scss

// 1st Level

$c-metanav-txt:              mix($c-page-bg, $c-page-txt, 45%);
$c-metanav-brd:              mix($c-page-bg, $c-page-txt, 70%);
$c-metanav-h-txt:            mix($c-page-bg, $c-page-txt, 25%);
$c-metanav-h-brd:            mix($c-page-bg, $c-page-txt, 50%);
$c-metanav-dsb-txt:          mix($c-page-bg, $c-page-txt, 66%);

$c-metanav-cur-bg:           mix($c-page-bg, $c-page-txt, 33%);
$c-metanav-cur-txt:          $c-page-bg;
$c-metanav-cur-h-bg:         $c-page-txt;
$c-metanav-cur-h-txt:        $c-page-bg;
$c-metanav-cur-count-txt:    mix($c-metanav-cur-bg, $c-page-bg, 30%);
$c-metanav-cur-userpic-brd:  rgba(#000, .33);

$c-metanav-help-bg:          mix($c-page-bg, $c-yellow, 80%);
$c-metanav-help-txt:         $c-yellow;
$c-metanav-help-h-bg:        $c-yellow;
$c-metanav-help-h-txt:       $c-page-bg;

$c-metanav-trial-bg:         $c-green-darkish;
$c-metanav-trial-txt:        mix($c-metanav-trial-bg, #FFF, 10%);
$c-metanav-trial-h-bg:       lighten($c-metanav-trial-bg, 5%);
$c-metanav-trial-h-txt:      #FFF;

// 2nd Level

$c-hbar-bg:                  $c-green-light;
$c-hbar-bg-alt:              $c-green-medium;
$c-hbar-brd:                 $c-olive-light;
$c-hbar-txt:                 $c-green-dark;
$c-hbar-meta:                mix($c-hbar-bg, $c-hbar-txt, 50%);

$c-mainnav-txt:              $c-hbar-txt;
$c-mainnav-brd:              mix($c-hbar-bg, $c-mainnav-txt, 66%);
$c-mainnav-count-txt:        mix($c-hbar-bg, $c-mainnav-txt, 33%);

$c-count-alt-brd:            mix($c-brown, $c-yellow-bright, 15%);
$c-count-alt-bg:             mix(#FFF, $c-yellow-bright, 25%);
$c-count-alt-txt:            mix($c-brown, $c-yellow-bright, 60%);

$c-mainnav-h-txt:            darken($c-mainnav-txt, 10%);
$c-mainnav-h-brd:            mix($c-hbar-bg, $c-mainnav-txt, 33%);

$c-mainnav-cur-bg:           $c-olive;
$c-mainnav-cur-bg-h:         #ADC292;
$c-mainnav-cur-sh:           $c-olive-dark;
$c-mainnav-cur-txt:          #FFF;
$c-mainnav-cur-count-txt:    $c-green-light;

$c-search-filter-bg:         mix($c-hbar-bg, $c-hbar-brd, 25%);
$c-search-field-brd:         mix($c-hbar-bg, $c-hbar-brd, 25%);
$c-search-mob-brd:           $c-hbar-brd;

// Dropdowns

$c-dd-metanav-h-bg:          mix($c-page-bg, $c-metanav-cur-h-bg, 85%);
$c-dd-mainnav-h-bg:          mix($c-hbar-bg, $c-mainnav-cur-bg, 60%);
$c-dd-bg:                    $c-olive-lightest;
$c-dd-bg-h:                  darken($c-dd-bg, 4%);
$c-dd-brd:                   $c-olive-light;
$c-dd-txt:                   $c-brown-dark;
$c-dd-meta:                  $c-mute;
$c-dd-l:                     $c-green-dark;
$c-dd-h:                     $c-brown-dark;
$c-dd-label-brd:             mix($c-dd-bg, $c-dd-brd, 50%);
$c-dd-list-brd:              mix($c-dd-bg, $c-green-dark, 75%);
$c-dd-list-brd-h:            mix($c-dd-bg, $c-brown-dark, 50%);

$c-qs-bg:                    mix($c-dd-bg, #FFF, 50%);
$c-qs-brd:                   darken($c-dd-bg, 7%);


// File: _toolbar.scss

$c-toolbar-brd:              #EEE;
$c-toolbar-sep:              #E5E5E5;
$c-toolbar-txt:              $c-dark;
$c-toolbar-txt-h:            $c-base;
$c-toolbar-plain:            $c-text;
$c-toolbar-meta:             $c-mute;

$c-toolbar-editable-brd:     #D0D0D0;
$c-toolbar-editable-f-brd:   rgba(#528FCC, .5);
$c-toolbar-editable-e-brd:   $c-pink;
$c-toolbar-editable-e-bg:    $c-pink-lightest;
$c-toolbar-editable-chg-brd: mix(desaturate($c-yellow-medium, 50%), #FFF, 60%);
$c-toolbar-editable-chg-bg:  mix($c-yellow-lightest, #FFF, 50%);
$c-toolbar-errormsg-bg:      mix($c-pink, #FFF, 75%);
$c-toolbar-errormsg-brd:     mix($c-pink, #000, 95%);
$c-toolbar-errormsg-txt:     mix($c-red, #000, 80%);

$c-deploy-count-bg:          $c-yellow-medium;
$c-deploy-count-txt:         $c-brown;

$c-tab-txt:                  darken($c-olive, 10%);
$c-tab-brd:                  mix($c-green-light, $c-green-dark, 66%);
$c-tab-h-txt:                darken($c-green-dark, 10%);
$c-tab-h-brd:                mix($c-green-light, $c-green-dark, 33%);
$c-tab-cur-bg:               mix(#FFF, $c-olive, 20%);
$c-tab-cur-sh:               $c-olive-dark;
$c-tab-h-cur-bg:             mix(#FFF, $c-olive, 33%);


// File: _pagination.scss

$c-paginator-brd:            #EEE;
$c-paginator-txt:            #666;
$c-paginator-dis-txt:        mix($c-content-bg, $c-paginator-txt, 55%);

$c-paginator-l-brd:          #DDD;
$c-paginator-l-bg:           #FFF;

$c-paginator-h-brd:          $c-link;
$c-paginator-h-bg:           $c-paginator-h-brd;
$c-paginator-h-txt:          #FFF;

$c-paginator-cur-bg:         #BBB;
$c-paginator-cur-brd:        #999;
$c-paginator-cur-txt:        #FFF;


// File: _announcements.scss

$c-announce-bg:              mix($c-page-bg, #FFF, 90%);
$c-announce-brd:             mix($c-page-bg, #FFF, 75%);
$c-announce-txt:             mix($c-page-bg, $c-page-txt, 33%);
$c-announce-l:               mix($c-page-bg, $c-page-txt, 20%);
$c-announce-h:               $c-page-txt;
$c-announce-a:               #FFF;

$c-notify-global-bg:         $c-yellow-bright;
$c-notify-global-txt:        $c-brown;
$c-notify-global-l:          $c-red-dark;

$c-expired-trial-bg:         $c-green-darkish;
$c-expired-trial-brd:        mix($c-page-bg, #FFF, 75%);
$c-expired-trial-txt:        mix($c-expired-trial-bg, #FFF, 20%);
$c-expired-trial-l:          #FFF;


// File: _forms.scss

$c-form-meta:                $c-mute;
$c-form-desc-txt:            #888;
$c-form-link-action:         #E00;

$c-form-disabled-txt:        $c-mute;

$c-form-error-bg:            $c-pink-light;
$c-form-error-txt:           $c-red-dark;
$c-form-error-link-action:   #000;

$c-validation-error-txt:     $c-red-dark;
$c-validation-ok-txt:        $c-success;


// File: _forms.notifications.scss

$c-notify-brd:               #FC6;
$c-notify-bg:                #FFC;
$c-notify-l:                 #933;
$c-notify-v:                 #755;
$c-notify-h:                 #A00;
$c-notify-title:             $c-red-dark;
$c-notify-hr:                mix($c-notify-bg, $c-notify-brd, 50%);

$c-notify-error-brd:         #300;
$c-notify-error-bg:          #A30;
$c-notify-error-txt:         #FFF;
$c-notify-error-title:       #FFC;

$c-bounce-brd:               #E9E9BA;
$c-bounce-bg:                #FFC;
$c-bounce-title:             #500;
$c-bounce-ls:                #A00;
$c-bounce-l:                 #333;
$c-bounce-hide-brd:          #B3B38F;
$c-bounce-hide-txt:          #8D8D88;
$c-bounce-meta-bg:           #FFDE00;
$c-bounce-meta-txt:          #988719;
$c-bounce-meta-h:            #333;

$c-notifier-bg:              #EFF5EE;
$c-notifier-txt:             mix($c-notifier-bg, $c-base, 40%);
$c-notifier-hide-txt:        mix($c-notifier-bg, $c-base, 70%);
$c-notifier-l:               $c-base;

$c-notifier-upgrade-bg:      $c-purple-light;
$c-notifier-upgrade-txt:     mix($c-purple-light, $c-base, 40%);
$c-notifier-upgrade-hide:    mix($c-purple-light, $c-base, 70%);
$c-notifier-upgrade-str:     saturate(mix($c-purple-light, $c-base, 25%), 10%);
$c-notifier-upgrade-mark-bg: #FCFCFF;

$c-notifier-news-bg:         $c-purple-darkest;
$c-notifier-news-txt:        #99A;
$c-notifier-news-title:      #FFF;

$c-notifier-message-brd:     $c-yellow;
$c-notifier-message-bg:      $c-yellow-light;
$c-notifier-message-title:   $c-base;


// File: _forms.buttons.scss

$c-button-bg:                #E9E9E9;
$c-button-bg-alt:            #FFF;
$c-button-txt:               #444;

$c-button-h-brd:             rgba(#528FCC, .66);
$c-button-h-bsh:             rgba(#528FCC, .2);
$c-button-h-txt:             #222;

$c-button-a-brd:             rgba(#528FCC, .85);
$c-button-a-bg:              #EEE;
$c-button-a-bg-alt:          #FFF;
$c-button-a-txt:             #555;
$c-button-a-bsh:             rgba(#528FCC, .3);

$c-button-dsb-brd:           #DDD;
$c-button-dsb-bg:            #F5F5F5;
$c-button-dsb-txt:           #AAA;

// Help button

$c-button-help-bg:           #98ADD9;
$c-button-help-txt:          #FFF;
$c-button-help-h-bg:         #7A96CC;

// Options

$c-options-action:           #333;


// File: _forms.new.scss
// File: _forms.boxes.scss
// File: _forms.extras.scss

$c-form-boxed-brd:           #E6E6E6;
$c-form-boxed-bg:            #FFF;
$c-form-boxed-bg-alt:        #FCFCFC;
$c-form-boxed-txt:           $c-text;
$c-form-boxed-h:             $c-base;
$c-form-boxed-title:         $c-base;
$c-form-boxed-blank:         #AAA;
$c-form-boxed-label:         $c-mute;
$c-form-boxed-meta:          $c-mute;
$c-form-boxed-mute:          #555;
$c-form-boxed-dark:          $c-dark;

$c-compare-box-brd:          mix(#FFF, $c-olive-light, 77%);
$c-compare-head-bg:          mix(#FFF, $c-olive-lightest, 50%);

$c-term-bg:                  #222;
$c-term-bg-f:                darken($c-term-bg, 3%);
$c-term-txt:                 #FFF;
$c-term-mute:                mix($c-term-bg, $c-term-txt, 33%);
$c-term-blank:               mix($c-term-bg, $c-term-txt, 60%);

$c-form-boxed-code-bg:       #EEE;
$c-form-boxed-code-txt:      #333;
$c-form-boxed-mark-bg:       #EEE;
$c-form-boxed-mark-txt:      #000;

$c-form-status-error-txt:    $c-error;
$c-form-status-warn-txt:     $c-warning;
$c-form-status-ok-txt:       $c-success;

// Error box

$c-form-boxed-error-brd:     $c-pink-medium;
$c-form-boxed-error-bg:      $c-pink-light;
$c-form-boxed-error-txt:     $c-red-dark;

// Warning box

$c-form-boxed-warn-brd:      $c-pink;
$c-form-boxed-warn-bg:       $c-pink-lightest;
$c-form-boxed-warn-txt:      #827070;
$c-form-boxed-warn-title:    #262121;
$c-form-boxed-warn-l:        #403636;

// Buttons

$c-form-buttons-brd:         #D7E7DB;
$c-form-buttons-bg:          #EFF5EE;
$c-form-buttons-txt:         mix($c-form-buttons-bg, $c-text, 50%);

// Password Strength

$c-psw-strength-txt:         #FFF;
$c-psw-strength-short:       $c-error;
$c-psw-strength-bad:         $c-error;
$c-psw-strength-good:        $c-warning;
$c-psw-strength-strong:      $c-success;

// URL Preview

$c-url-brd:                  #DDD;
$c-url-bg:                   mix($c-yellow, #FFF, 50%);
$c-url-txt:                  #000;
$c-url-meta:                 #888;

$c-url-error-brd:            mix($c-yellow-light, #000, 80%);
$c-url-error-bg:             lighten($c-yellow-light, 5%);
$c-url-error-meta:           mix($c-url-error-bg, $c-base, 60%);

// Form Subsection (introduced in invite user screen)

$c-subsection-brd:           #E0ECDD;
$c-subsection-bg:            #F9FBF8;
$c-subsection-text:          #798676;
$c-subsection-icon-bg:       #AEB8AC;


// File: _forms.upgrade-notice.scss

$c-upgrade-bg:               $c-purple;
$c-upgrade-txt:              $c-purple-dark;
$c-upgrade-meta:             mix($c-upgrade-bg, $c-upgrade-txt, 40%);
$c-upgrade-l:                $c-base;
$c-upgrade-h:                $c-upgrade-l;


// File: _forms.messages.scss

$c-msg-notice-brd:           #DFDE9C;
$c-msg-notice-bg:            #FEFFE0;
$c-msg-notice-txt:           #716C33;
$c-msg-notice-title:         #353500;
$c-msg-notice-l:             $c-base;
$c-msg-notice-meta:          #CC9;

$c-msg-error-brd:            #D69999;
$c-msg-error-bg:             #FAF2F2;
$c-msg-error-txt:            #C26666;
$c-msg-error-title:          #A11;
$c-msg-error-l:              $c-red-dark;


// File: _keys.scss

$c-key-brd:                  $c-form-boxed-brd;
$c-key-bg:                   $c-form-boxed-bg-alt;
$c-key-txt:                  $c-form-boxed-title;
$c-key-h-bg:                 darken($c-key-bg, 2%);
$c-key-h-txt:                $c-key-txt;


// File: _activity.scss

$c-activity-brd:             #EEE;
$c-activity-l:               $c-base;
$c-activity-title:           $c-dark;
$c-activity-txt:             $c-text;
$c-activity-mute:            $c-mute;
$c-activity-blank:           $c-light;
$c-activity-time:            #BBB;

$c-activity-meta-txt:        #A5A5A5;
$c-activity-meta-l:          #4A4A4A;

$c-rss-txt:                  #902E00;

$c-activity-rev-bg:          $c-blue-lightest;
$c-activity-rev-l-brd:       #EBEBF2;
$c-activity-rev-h-brd:       $c-mute;
$c-activity-rev-meta:        mix($c-activity-rev-bg, $c-base, 25%);
$c-activity-rev-meta-h:      $c-base;
$c-activity-rev-empty:       mix($c-activity-rev-bg, $c-base, 75%);

$c-activity-env-bg:          #FAF5FF;
$c-activity-env-l-brd:       #DAD3E2;
$c-activity-env-h-brd:       #BBB1C5;
$c-activity-env-meta:        #938A99;

$c-activity-pending-txt:     #958B9E;
$c-activity-pending-meta:    #C4BCCC;
$c-activity-pending-bdg-bg:  #BBB1C5;
$c-activity-pending-bdg-txt: $c-activity-env-bg;

$c-activity-failed-bg:       $c-pink-lightest;
$c-activity-failed-txt:      $c-red-dark;
$c-activity-failed-title:    $c-activity-failed-txt;
$c-activity-failed-meta:     #B3867D;
$c-activity-failed-bdg-bg:   #D88;
$c-activity-failed-bdg-txt:  $c-activity-failed-bg;

$c-activity-cancel-bg:       #F6F6F6;
$c-activity-cancel-txt:      $c-mute;
$c-activity-cancel-meta:     $c-light;
$c-activity-cancel-bdg-bg:   #BBB;
$c-activity-cancel-bdg-txt:  $c-activity-cancel-bg;

$c-activity-ext-bg:          $c-link;
$c-activity-ext-txt:         #FFF;
$c-activity-ext-h-bg:        $c-dark;

$c-activity-comment-txt:     #C6C6CC;
$c-activity-comment-h-txt:   #A6A6AB;

// Integrations

$c-label-int-lh-bg:          #D5D5DD;
$c-label-int-lh-txt:         #3D5866;
$c-label-int-hv-bg:          #F5DAC4;
$c-label-int-hv-txt:         #CC6D1F;
$c-label-int-fb-bg:          #DDD3C9;
$c-label-int-fb-txt:         #72593E;
$c-label-int-zd-bg:          #DEE6CF;
$c-label-int-zd-txt:         #6B990F;
$c-label-int-sf-bg:          mix(#F48436, #FFF, 40%);
$c-label-int-sf-txt:         mix(#573923, $c-label-int-sf-bg, 75%);
$c-label-int-jr-bg:          #CEE8F5;
$c-label-int-jr-txt:         mix(#036, $c-label-int-jr-bg, 60%);
$c-label-int-dd-bg:          mix(#F26F21, #FFF, 30%);
$c-label-int-dd-txt:         mix(#F26F21, #000, 85%);
$c-label-int-as-bg:          mix(#278BCF, #FFF, 55%);
$c-label-int-as-txt:         #FFF;
$c-label-int-gr-bg:          mix(#26C6C7, #FFF, 55%);
$c-label-int-gr-txt:         #FFF;
$c-label-int-pt-bg:          mix(#5283B0, #FFF, 75%);
$c-label-int-pt-txt:         #FFF;


// File: _serp.scss

$c-serp-hl-bg:               $c-yellow-bright;

$c-serp-comment-txt:         #99A;
$c-serp-comment-h-brd:       #BBC;
$c-serp-comment-hl-bg:       $c-yellow-light;


// File: _code.scss

// Toolbar

$c-ct-bg:                    #F5F5F5;
$c-ct-bg-alt:                #F3F3F3;
$c-ct-brd:                   #E5E5E5;
$c-ct-txt:                   $c-text;
$c-ct-h:                     $c-dark;
$c-ct-current-bg:            #EEE;
$c-ct-current-bg-alt:        #F0F0F0;
$c-ct-current-txt:           #888;
$c-ct-dsb-txt:               $c-light;

$c-ct-path-txt:              #8E8E8E;
$c-ct-path-h:                $c-link;
$c-ct-path-current:          #000;
$c-ct-path-sep:              mix($c-ct-bg, $c-ct-path-txt, 50%);

$c-ct-rev-brd:               #D8D8D8;
$c-ct-rev-bg:                #FFF;
$c-ct-rev-txt:               $c-dark;
$c-ct-rev-h-bg:              $c-olive;
$c-ct-rev-h-txt:             #FFF;

// Code viewer

$c-cv-brd:                   lighten($c-ct-brd, 3%);
$c-cv-brd-h:                 #DDD;
$c-cv-bg:                    #FCFCFC;
$c-cv-txt:                   #111;
$c-cv-meta:                  #888;
$c-cv-ln-txt:                #B5B5B5;
$c-cv-ln-h:                  #444;
$c-cv-hover:                 $c-yellow-light;
$c-cv-hl:                    #D3F5FF;

// Docs viewer

$c-docs-txt:                 #444;
$c-docs-l:                   $c-link;
$c-docs-v:                   mix($c-docs-txt, $c-docs-l, 50%);
$c-docs-h:                   darken($c-green-darkish, 4%);
$c-docs-a:                   $c-base;
$c-docs-h1:                  $c-base;
$c-docs-h2:                  $c-base;
$c-docs-h3:                  darken($c-green-darkish, 4%);
$c-docs-h4:                  $c-base;
$c-docs-anchor-l:            #DDD;
$c-docs-anchor-h:            #444;

// Blame

$c-blame-meta:               #A5A5A5;
$c-blame-meta-h:             #707070;
$c-blame-sep:                #D5D5D5;
$c-blame-tooltip-bg:         $c-olive;
$c-blame-tooltip-txt:        #FFF;

// Diff

$c-cv-ins-bg:                #D9F5CB;
$c-cv-ins-txt:               #252;
$c-cv-ins-bg-alt:            #B0E595;
$c-cv-ins-txt-alt:           #030;
$c-cv-del-bg:                #FFDFD9;
$c-cv-del-txt:               #522;
$c-cv-del-bg-alt:            #FFB7B0;
$c-cv-del-txt-alt:           #300;

// Comments

$c-loc-cmt-bg:               mix($c-cv-bg, $c-cv-hl, 66%);
$c-loc-cmt-meta:             mix($c-cv-hl, #000, 75%);
$c-loc-cmt-btn:              darken(#64A8F2, 5%);
$c-loc-cmt-btn-h:            $c-link;

$c-loc-issue-bg:             #F1F4FC;

$c-loc-cmt-idc-brd:          #D9D9D9;
$c-loc-cmt-idc-bg:           #F9F9F9;
$c-loc-cmt-idc-h-bg:         #C9C9C9;

$c-loc-blame-bg:             mix($c-cv-bg, $c-loc-cmt-bg, 33%);
$c-loc-blame-txt:            mix($c-cv-hl, #000, 70%);
$c-loc-blame-alt:            mix($c-cv-hl, #000, 60%);

$c-cmt-form-bg:              #F9F9F9;
$c-cmt-form-brd:             #E3E3E3;

$c-cmt-form-error-bg:        $c-pink-light;
$c-cmt-form-error-brd:       mix($c-pink-light, $c-pink-medium, 70%);
$c-cmt-form-error-txt:       #933;

$c-cmt-suggest-bg:           #F8FAF5;
$c-cmt-suggest-brd:          #E3E5E1;
$c-cmt-suggest-txt:          $c-base;
$c-cmt-suggest-meta:         mix($c-cmt-suggest-bg, $c-cmt-suggest-txt, 70%);
$c-cmt-suggest-a-bg:         #EDF2E6;
$c-cmt-suggest-h-bg:         #FFF;

$c-md-block-bg:              #FFF;
$c-md-block-brd:             mix($c-md-block-bg, #B3D0D8, 75%);
$c-md-block-brd-alt:         mix($c-md-block-bg, #B3D0D8, 60%);
$c-md-block-txt:             mix(#D3F5FF, #000, 50%);


// File: _changeset.scss

$c-cs-msg-txt:               $c-dark;
$c-cs-msg-e-txt:             $c-mute;
$c-cs-msg-h-bg:              #FFF9B2;

$c-cs-meta-brd:              #F3F3F3;
$c-cs-meta-title:            #AAA;
$c-cs-meta-txt:              #444;

$c-cs-pref-loading:          #BBB;
$c-cs-pref-enabled:          $c-green-darkish;
$c-cs-pref-disabled:         $c-mute;

$c-cmt-subscribe:            $c-green-darkish;
$c-cmt-unsubscribe:          $c-error;

$c-cmt-brd:                  #E3ECF0;
$c-cmt-bg:                   mix($c-content-bg, $c-loc-cmt-bg, 75%);
$c-cmt-meta:                 $c-loc-cmt-meta;
$c-cmt-ref:                  mix($c-cv-hl, #000, 70%);
$c-cmt-ref-h:                #000;
$c-cmt-preview-bg:           $c-yellow-light;

$c-cmt-approval-bg:          #F5FCED;
$c-cmt-approval-meta:        #A0BF9F;


// File: _branches.scss

$c-branch-txt:               $c-dark;
$c-branch-base-brd:          mix(#FFF, $c-blue-portage, 75%);
$c-branch-base-bg:           mix(#FFF, $c-blue-portage, 90%);
$c-branch-merged-txt:        #AAA;
$c-branch-behind-bg:         #CCC;
$c-branch-behind-txt:        #999;
$c-branch-ahead-bg:          mix(#FFF, $c-blue-portage, 25%);
$c-branch-ahead-txt:         $c-blue-portage;
$c-branch-locked-txt:        #804040;


// File: _browse.scss

$c-bdg-locked-bg:            #F7E4E4;
$c-bdg-locked-txt:           #995C5C;

$c-browse-sort-brd:          #CCC;
$c-browse-sort-h-brd:        #AAA;
$c-browse-sort-h-txt:        #888;

$c-browse-create-bg:         #F9F9F9;


// File: _integration.overview.scss

$c-button-guide-bg:          $c-green-darkish;
$c-button-guide-txt:         #FFF;
$c-int-help-l:               $c-green-darkish;
$c-int-help-l-alt:           #388C4D;
$c-int-help-h:               #222;

$c-int-page-brd:             #DFE6E0;
$c-int-page-bg:              $c-olive-lightest;
$c-int-page-txt:             #636462;

// Card: Front side

$c-int-card-brd:             #D3D5D0;
$c-int-card-brd-alt:         #D5D5D5;
$c-int-card-bg:              #FFF;
$c-int-card-h-bg:            #F9F9F9;

// Card: Back side

$c-int-card-alt-brd:         #292929;
$c-int-card-alt-bg:          $c-int-card-alt-brd;
$c-int-card-alt-h-brd:       #1B1B1B;
$c-int-card-alt-h-bg:        $c-int-card-alt-h-brd;
$c-int-card-alt-txt:         #BBB;
$c-int-card-alt-title:       #444;

// Card: Activated

$c-int-card-act-brd:         #BAD99C;
$c-int-card-act-bg:          #F5FFEB;
$c-int-card-act-bg-alt:      #E6FAD2;
$c-int-card-act-h-bg:        #EEFFDE;
$c-int-card-act-h-bg-alt:    #DAF7BF;
$c-int-card-act-title:       #48731D;

$c-int-card-act-bdg-bg:      #86B359;
$c-int-card-act-bdg-txt:     #FFF;
$c-int-card-act-alt-bdg-bg:  #595959;
$c-int-card-act-alt-bdg-txt: #000;


// File: _integration.profile.scss

$c-int-brd:                  #E0E0E0;
$c-int-title:                $c-base;
$c-int-meta:                 #999;

$c-int-ss-cover:             #888;


// File: _integration.wizard.scss

$c-int-help-img-brd:         #BBB;

$c-int-oauth-meta:           #AAA;
$c-int-oauth-l:              #388C4D;
$c-int-oauth-h:              #333;


// File: _repositories.scss

$c-repos-list-brd:           #EEE;
$c-repos-list-l:             #000;
$c-repos-list-txt:           #888;

$c-repos-list-dsb-l:         $c-red-dark;
$c-repos-list-dsb-txt:       #CC8080;


// File: _incidents.scss

$c-incident-brd:             #E9E9E9;
$c-incident-title:           $c-warning;
$c-incident-current-txt:     $c-warning;

$c-incident-notice-brd:      #DEDF89;
$c-incident-notice-bg:       #FFD;

$c-incident-resolutn-brd:    #D5D9E0;
$c-incident-resolutn-bg:     $c-blue-lightest;


// File: _plans.scss

$c-plans-page-brd:           #DDEDDF;
$c-plans-page-bg-alt:        rgba(#F5FFF5, .85);

$c-plan-title:               $c-bottle-green-dark;
$c-plan-l:                   $c-bottle-green-dark;

$c-plan-incl-bg:             #ECF7EB;
$c-plan-incl-txt:            mix($c-plan-incl-bg, $c-bottle-green-dark, 20%);
$c-plan-incl-dark:           $c-bottle-green-dark;

$c-plan-exceed-bg:           $c-pink-light;
$c-plan-exceed-txt:          mix($c-plan-exceed-bg, $c-red-dark, 40%);
$c-plan-exceed-dark:         mix($c-plan-exceed-bg, $c-red-dark, 20%);

$c-plan-bus-title:           #000;
$c-plan-title-cutoff:        #9C9C9C;
$c-plan-list-cutoff:         #939393;
$c-plan-dsb-txt:             mix($c-content-bg, $c-red-dark, 25%);

$c-plan-form-brd:            #EBEBBC;
$c-plan-form-bg:             #FFFFE5;
$c-plan-form-corner:         #CFCFA0;
$c-plan-form-title:          #000;
$c-plan-form-meta:           mix($c-plan-form-bg, $c-base, 40%);

// Plan in lineup

$c-plan-item-brd:            #D5EDDD;
$c-plan-item-bg:             #F5FFF5;
$c-plan-item-txt:            #3D996B;
$c-plan-item-title:          rgba(#53A67C, .6);
$c-plan-item-del:            mix(#3D996B, #F5FFF5, 50%);
$c-plan-item-btn-bg:         #53A67C;
$c-plan-item-btn-txt:        #FFF;
$c-plan-item-btn-corner:     $c-bottle-green;
$c-plan-item-current-bg:     $c-plan-item-brd;
$c-plan-item-current-txt:    rgba($c-bottle-green-dark, .6);

$c-plan-item-h-brd:          darken($c-plan-item-brd, 10%);
$c-plan-item-h-title:        rgba(#53A67C, .75);
$c-plan-item-h-current-bg:   darken($c-plan-item-brd, 5%);
$c-plan-item-h-current-txt:  rgba($c-bottle-green-dark, .85);

// Current plan in lineup

$c-plan-current-bg:          $c-bottle-green;
$c-plan-current-txt:         #FFF;
$c-plan-current-title:       rgba(#FFF, .4);
$c-plan-current-del:         mix(#FFF, $c-bottle-green, 50%);
$c-plan-current-bdg-bg:      rgba(#FFF, .2);
$c-plan-current-bdg-txt:     $c-yellow-light;


// File: _change-plan.scss

$c-plan-change-from-brd:     #C7D9CC;
$c-plan-change-from-bg:      #F6FAF5;
$c-plan-change-from-title:   #695;
$c-plan-change-from-txt:     #9A9;
$c-plan-change-from-dark:    #584;

$c-plan-change-to-brd:       #EEE;
$c-plan-change-to-title:     #444;
$c-plan-change-to-txt:       #888;
$c-plan-change-to-dark:      #444;


// File: _invoice.scss

$c-invoice-date-brd:         $c-form-boxed-brd;
$c-invoice-date-txt:         #C0C0C0;
$c-invoice-date-adv-brd:     darken(#E6F0F8, 5%);
$c-invoice-date-adv-bg:      mix(#E6F0F8, #FFF, 40%);
$c-invoice-date-adv-txt:     darken(#E6F0F8, 13%);

$c-invoice-discount:         mix($c-content-bg, $c-link, 60%);
$c-invoice-l-brd:            rgba($c-link, .33);

$c-invoice-brd:              #DBDAED;
$c-invoice-bg:               $c-purple;
$c-invoice-txt:              #434253;
$c-invoice-meta:             #9796A9;

$c-invoice-preview-brd:      #DDD;


// File: _repository-status.scss

$c-repo-status-hl-bg:        #FFFFC9;
$c-repo-status-hl-txt:       #606000;

$c-repo-status-actions-brd:  #EEE;

$c-repo-status-dsb-brd:      #810;
$c-repo-status-dsb-bg:       #C44F1D;
$c-repo-status-dsb-bg-alt:   #B53F0C;
$c-repo-status-dsb-txt:      #FFF;


// File: _getstarted.scss

$c-platform-mac-bg:          #ECEFFB;
$c-platform-mac-txt:         #71778D;
$c-platform-win-bg:          #DEF8FF;
$c-platform-win-txt:         #1FA6CC;
$c-platform-nix-bg:          #EEE;
$c-platform-nix-txt:         #666;

$c-coupon-bg:                #EEE;
$c-coupon-txt:               $c-text;
$c-coupon-l:                 $c-base;
$c-coupon-l-brd:             #888;
$c-coupon-code-bg:           #CCC;
$c-coupon-code-txt:          #333;

$c-help-block-brd:           mix(#FFF, $c-yellow-medium, 25%);
$c-help-block-bg:            mix(#FFF, $c-yellow-bright, 25%);
$c-help-block-mark-bg:       $c-yellow;
$c-help-block-mark-txt:      mix($c-help-block-mark-bg, $c-base, 33%);
$c-help-block-l:             mix($c-help-block-mark-bg, $c-base, 33%);
$c-help-block-h:             $c-base;


// File: _blank.scss

$c-blank-bg:                 #FFC;
$c-blank-title:              $c-purple-darkest;
$c-blank-txt:                $c-purple-dark;
$c-blank-meta:               #889;

$c-blank-msg-title:          #733;
$c-blank-msg-action-brd:     #808066;


// File: _sidebar.scss

$c-sidebar-brd:              #EEE;
$c-sidebar-txt:              #888;

$c-progressBar-brd:          #DDD;

$c-repo-url-brd:             #DDD;
$c-repo-url-bg:              #FFF;
$c-repo-url-type-brd:        mix($c-olive, #FFF, 33%);
$c-repo-url-type-bg:         mix($c-olive, #FFF, 20%);
$c-repo-url-type-txt:        $c-olive;
$c-repo-url-type-h-bg:       mix($c-olive, #FFF, 30%);

$c-news-bar-txt:             #AAA;


// File: _specialpage.scss

$c-sp-bg:                    #534B38;
$c-sp-brd:                   #EEE;
$c-sp-title:                 #FFF;
$c-sp-txt:                   #595644;
$c-sp-sep:                   #DDD;
$c-sp-meta:                  #999;

$c-sp-warning-bg:            $c-purple;
$c-sp-warning-txt:           $c-purple-medium;

$c-sp-rss-url-brd:           #DDD;
$c-sp-rss-url-bg:            #EEE;
$c-sp-rss-url-txt:           #000;

$c-sp-form-brd:              #EEE;
$c-sp-form-txt:              $c-text;
$c-sp-form-title:            $c-dark;
$c-sp-form-meta:             #999;
$c-sp-form-blank:            #AAA;

$c-sp-form-btn-bg:           mix($c-content-bg, $c-link, 88%);
$c-sp-form-btn-txt:          $c-link;

$c-sp-notice-bg:             #EEF;
$c-sp-notice-txt:            $c-purple-darkest;
$c-sp-notice-l:              #666;
$c-sp-notice-h:              #333;

$c-select-rev-brd:           #DDD;
$c-select-rev-txt:           #333;
$c-select-rev-blank:         #AAA;
$c-select-rev-h-bg:          #F6F6F6;

$c-data-import-bg:           #DDD;
$c-data-import-error-bg:     $c-pink-light;


// File: _error-page.scss

$c-ep-content-brd:           #696250;
$c-ep-content-bg:            #4D4432;
$c-ep-content-txt:           #B3AA98;
$c-ep-content-l:             #FFF;

$c-ep-contact-brd:           #E5E2DA;
$c-ep-contact-bg:            $c-ep-contact-brd;
$c-ep-contact-txt:           #000;
$c-ep-contact-title:         #332A19;

$c-suspended-content-bg:     #E5E2DA;
$c-suspended-content-txt:    #000;
$c-suspended-content-l:      $c-red-dark;

$c-suspended-contact-brd:    #332A19;
$c-suspended-contact-bg:     #4D4432;
$c-suspended-contact-txt:    #B3AA98;
$c-suspended-contact-title:  #FFF;
$c-suspended-contact-l:      #D9D2C3;
$c-suspended-contact-h:      #FFF;


// File: _wizards.scss

$c-wizard-brd:               #DBEEF9;
$c-wizard-brd-alt:           #92C9E2;
$c-wizard-bg:                #F0F9FF;
$c-wizard-txt:               #9ACCE6;
$c-wizard-step-bg:           #D5EFFF;
$c-wizard-cur-txt:           #39C;
$c-wizard-cur-step-bg:       #ACE0FF;


// File: _help.scss

$c-help-brd:                 #333;
$c-help-bg:                  mix($c-content-bg, #000, 5%);
$c-help-txt:                 #999;
$c-help-meta:                #DDD;
$c-help-bright:              #FFF;
$c-help-title:               #FFF;
$c-help-subtitle:            #A6FAA0;
$c-help-l:                   #CCC;
$c-help-l-alt:               #D5FF57;
$c-help-v:                   $c-help-txt;
$c-help-h:                   #FFF;

$c-help-code-bg:             #D5FF57;
$c-help-code-bg-alt:         #292929;
$c-help-mark:                #FFF;
$c-help-hl:                  #CCC;
$c-help-error-txt:           #FBB;

$c-help-twitter-bg:          #FFF;
$c-help-twitter-txt:         $c-text;
$c-help-twitter-l:           $c-base;


// File: _chat.scss

$c-chat-brd:                 #DDD;
$c-chat-bg:                  $c-content-bg;
$c-chat-bg-alt:              #FFFEDE;
$c-chat-txt:                 #000;
$c-chat-l:                   #999;
$c-chat-h:                   #000;
$c-chat-status:              #AAA;

$c-chat-offline-title:       $c-red-dark;
$c-chat-offline-txt:         #888;
$c-chat-offline-l:           #666;
$c-chat-offline-h:           #000;

$c-chat-form-brd:            #AAA;
$c-chat-form-bg:             #EEE;


// File: _deployments.overview.scss

$c-dpl-page-bg:              $c-olive-lightest;

$c-env-brd:                  #C4CCBA;
$c-env-bg:                   #FFF;
$c-env-h-bg:                 #FCFDFB;
$c-env-title:                #000;
$c-env-status-txt:           #FFF;
$c-env-meta:                 #898C87;
$c-env-rev-bg:               #CCD3C3;
$c-env-rev-txt:              #FFF;

$c-env-shortcut-bg:          mix(#000, $c-olive-lightest, 7%);
$c-env-shortcut-txt:         #737472;
$c-env-shortcut-h-bg:        mix(#000, $c-olive-lightest, 10%);
$c-env-shortcut-dsb-h-bg:    mix(#000, $c-olive-lightest, 7%);

// Deploying & Waiting

$c-env-waiting-brd:          #CDC3D4;
$c-env-waiting-bg:           #FDFAFF;
$c-env-waiting-h-bg:         #FBF5FF;
$c-env-waiting-meta:         #9C93A3;
$c-env-waiting-status-bg:    #A293AD;
$c-env-waiting-rev-bg:       #D4CADA;
$c-env-waiting-badge-bg:     #BCB1C4;

// Failed

$c-env-failed-brd:           #E1B0A6;
$c-env-failed-bg:            #FFF9F7;
$c-env-failed-txt:           #993926;
$c-env-failed-h-bg:          #FFF3F0;
$c-env-failed-meta:          #A68585;
$c-env-failed-title:         #993926;
$c-env-failed-status-bg:     #D47474;
$c-env-failed-rev-bg:        #EBB8B6;
$c-env-failed-badge-bg:      #D47474;

// Cancelled

$c-env-cancel-brd:           #CCC;
$c-env-cancel-bg:            #F5F5F5;
$c-env-cancel-txt:           #888;
$c-env-cancel-rev-bg:        #CCC;
$c-env-cancel-badge-bg:      #B5B5B5;

// Bypassed

$c-env-bypassed-badge-bg:    #B5B5B5;


// File: _deployments.servers.scss

$c-srv-label-txt:            #000;
$c-srv-label-ftp-bg:         #D9D9D9;
$c-srv-label-sftp-bg:        #C2CEF2;
$c-srv-label-s3-bg:          #FAD5A8;
$c-srv-label-heroku-bg:      #D4C3E5;
$c-srv-label-shopify-bg:     #7CB460;
$c-srv-label-shopify-txt:    #FFF;
$c-srv-label-ssh-bg:         #333;
$c-srv-label-ssh-txt:        #FFF;
$c-srv-label-cloudfiles-bg:  #C5E5B1;
$c-srv-label-dreamobj-bg:    #99DFF9;

$c-srv-btn-brd:              #D0D0D0;
$c-srv-btn-bg:               #FFF;
$c-srv-btn-txt:              #7F7F7F;
$c-srv-btn-h-brd:            #A0A0A0;
$c-srv-btn-h-txt:            #000;

$c-srv-type-h-bg:            #F6F6F6;
$c-srv-type-txt:             $c-dark;
$c-srv-type-meta:            $c-mute;


// File: _deployments.manual.scss

$c-dpl-review-thead-txt:     $c-mute;
$c-dpl-review-brd:           #E0E0E0;
$c-dpl-review-bg:            #F6F6F6;
$c-dpl-review-txt:           $c-dark;
$c-dpl-review-l:             $c-dark;
$c-dpl-review-l-brd:         $c-mute;


// File: _deployments.transfer-log.scss

$c-tl-brd:                   #EEE;
$c-tl-bg:                    #FFF;
$c-tl-txt:                   #000;
$c-tl-meta:                  $c-mute;

$c-tl-error-bg:              $c-pink-lightest;
$c-tl-error-txt:             $c-red-dark;
$c-tl-error-meta:            #B3867D;

$c-tl-title-brd:             #DDD;
$c-tl-title-bg:              #EEE;
$c-tl-title-txt:             #000;
$c-tl-close-txt:             $c-red;

$c-tl-tab-bg:                #EEE;
$c-tl-tab-txt:               #777;
$c-tl-tab-meta:              #AAA;
$c-tl-tab-failed-bg:         #FFDBD4;
$c-tl-tab-failed-txt:        #B34A36;
$c-tl-tab-failed-meta:       #D99F93;
$c-tl-tab-current-bg:        #DDE9FF;
$c-tl-tab-current-txt:       $c-link;
$c-tl-tab-current-meta:      #97AFE0;

$c-tl-actions-brd:           #AAA;

$c-tl-activity-brd:          #EEE;
$c-tl-activity-bg:           $c-tl-bg;
$c-tl-activity-txt:          #AAA;
$c-tl-activity-sep:          #DDD;

$c-tl-status-brd:            #EEE;
$c-tl-status-success:        $c-success;
$c-tl-status-failed:         $c-red;
$c-tl-status-bypassed:       #888;


// File: _deployments.details.scss

$c-release-title-l:          #000;
$c-release-title-meta:       #999;
$c-release-title-blank:      #BBB;
$c-release-title-branch:     #303042;

$c-release-comment-brd:      #E9ECF3;
$c-release-comment-bg:       #FAFAFF;
$c-release-comment-txt:      $c-purple-darkest;

$c-release-more-bg:          mix($c-content-bg, $c-link, 88%);
$c-release-more-h-bg:        mix($c-content-bg, $c-link, 82%);

$c-release-tbl-l:            $c-mute;
$c-release-tbl-l-brd:        mix($c-content-bg, $c-release-tbl-l, 67%);
$c-release-tbl-h-brd:        $c-mute;
$c-release-tbl-alt-l-brd:    mix($c-content-bg, $c-link, 67%);
$c-release-tbl-alt-h-brd:    $c-link;


// File: _welcome.scss

$c-welcome-bg:               $c-green-light;
$c-welcome-bg-alt:           $c-green-medium;
$c-welcome-txt:              mix($c-green-light, #000, 45%);
$c-welcome-title:            $c-base;
$c-welcome-l:                $c-base;

// Steps

$c-welcome-step-brd:         #59B377;
$c-welcome-step-bg:          #D2FFB2;
$c-welcome-step-txt:         #1D7348;
$c-welcome-step-meta:        mix($c-green-light, #000, 60%);
$c-welcome-step-h-brd:       mix($c-green-light, $c-welcome-step-brd, 25%);
$c-welcome-step-h-txt:       #000;

$c-welcome-step-norm-brd:    mix($c-green-light, $c-welcome-step-brd, 25%);
$c-welcome-step-norm-txt:    #444;

$c-welcome-step-curnt-bg:    rgba($c-welcome-step-bg, .25);
$c-welcome-step-curnt-h-brd: darken($c-welcome-step-brd, 10%);
$c-welcome-step-curnt-h-bg:  rgba($c-welcome-step-bg, .4);
$c-welcome-step-curnt-h-txt: darken($c-welcome-step-txt, 5%);

$c-welcome-step-compl-brd:   mix($c-green-light, $c-welcome-step-brd, 50%);
$c-welcome-step-compl-txt:   mix($c-green-light, #000, 60%);
$c-welcome-step-compl-h-txt: mix($c-green-light, #000, 45%);

$c-welcome-step-unavl-txt:   mix($c-green-light, #000, 60%);

$c-reading-bg:               rgba(#FFF, .2);
$c-reading-l:                $c-dark;

$c-welcome-help-bg:          #FFF399;
$c-welcome-help-txt:         mix($c-welcome-help-bg, #000, 35%);
$c-welcome-help-l-bg:        mix($c-green-light, #FFF, 25%);
$c-welcome-help-l-txt:       #333;

$c-welcome-team-txt:         mix($c-green-light, #000, 45%);
$c-welcome-team-role:        mix($c-green-light, #000, 65%);
$c-welcome-team-h-txt:       mix($c-green-light, #000, 25%);
$c-welcome-team-h-role:      mix($c-green-light, #000, 45%);


// File: _preview.scss

$c-prvw-bg:                  #FAFAFA;
$c-prvw-divider:             #000;

$c-prvw-mode-bg:             #EEE;
$c-prvw-mode-txt:            #000;
$c-prvw-mode-sep:            #999;
$c-prvw-mode-sep-alt:        #FFF;

$c-prvw-button-back-brd:     #BBB;
$c-prvw-button-back-h-brd:   #999;
$c-prvw-button-back-h-txt:   #222;

$c-prvw-refs-txt:            #999;
$c-prvw-refs-num-bg:         #777;
$c-prvw-refs-num-txt:        #333;
$c-prvw-refs-l-txt:          $c-prvw-refs-txt;
$c-prvw-refs-l-brd:          #666;

$c-prvw-refs-cur-num-bg:     #666;
$c-prvw-refs-cur-num-txt:    #FFF;
$c-prvw-refs-cur-l-brd:      #AAA;
$c-prvw-refs-cur-l-txt:      #666;

$c-prvw-refs-dsb-txt:        #666;
$c-prvw-refs-dsb-num-bg:     #666;
$c-prvw-refs-dsb-num-txt:    #222;
$c-prvw-refs-dsb-l-txt:      $c-prvw-refs-dsb-txt;
$c-prvw-refs-dsb-l-brd:      $c-prvw-refs-dsb-l-txt;


// File: _editor.scss

$c-editor-bg:                #272822;
$c-editor-txt:               #F8F8F2;

$c-editor-mob-bg:            #F9F9F9;
$c-editor-mob-txt:           #222;

$c-editor-tb-bg:             #EEE;

$c-editor-notify-brd:        #FFFABF;
$c-editor-notify-bg:         $c-yellow-bright;
$c-editor-notify-txt:        #4B4D41;
$c-editor-notify-sep:        #A39F67;


// File: _users.scss

$c-selector-brd:             #D9D9D9;
$c-selector-bg:              #FFF;
$c-selector-txt:             #666;
$c-selector-h-brd:           #C5C5C5;
$c-selector-dsb-brd:         #DDD;
$c-selector-dsb-txt:         #CCC;

$c-permission-brd:           #E6E6E6;
$c-permission-h-bsh:         rgba(#000, .15);
$c-permission-noaccess-txt:  #999;
$c-permission-partial:       #E6F0F5;
$c-permission-partial-txt:   #069;
$c-permission-full:          #F0F5E6;
$c-permission-full-txt:      #690;
$c-permission-apply-brd:     #DDD;
$c-permission-apply-bg:      #FFF;
$c-permission-apply-txt:     #666;
$c-permission-inactive:      #F5F5F5;
$c-permission-inactive-txt:  #CCC;

$c-constant-noaccess-txt:    mix(#FFF, $c-permission-noaccess-txt, 33%);
$c-constant-partial-txt:     mix(#FFF, $c-permission-partial-txt, 33%);
$c-constant-full-txt:        mix(#FFF, $c-permission-full-txt, 33%);

$c-user-brd:                 #EEE;
$c-user-bg:                  #F6F6F6;
$c-user-txt:                 #333;
$c-user-sep:                 #AAA;
$c-user-l-brd:               #DDD;
$c-user-l-bg:                #EEE;
$c-user-l-txt:               #000;

$c-users-list-item-brd:      #F3F3F3;
$c-users-list-item-bg:       #FAFAFA;

$c-list-bg:                  #FAFAFA;

$c-thead-no-action-brd:      #F3F3F3;


// File: _teams.scss

$c-team-brd:                 #CFCFCF;
$c-team-bg:                  #FDFDFD;

$c-team-users-number-text:   #999;
$c-team-users-brd:           #CFCFCF;

$c-team-empty-text:          #999;

$c-avatar-ellipsis-bg:       #FDFDFD;
$c-avatar-ellipsis-text:     #B7B7B7;


// File: _code-reviews.scss

$c-approval-brd:             mix($c-olive-lightest, $c-olive-light, 70%);
$c-approval-branch-bg:       mix($c-content-bg, $c-green-light, 33%);
$c-approval-commit-bg:       mix($c-content-bg, $c-green-light, 75%);
$c-approval-commit-txt:      mix(#909090, $c-green-darkish, 85%);
