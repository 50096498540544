@font-face {
    font-style: normal;
    font-weight: 400;
    font-family: 'Fira Mono';
    src: local('Fira Mono'),
         url('../fonts/FiraMono-Regular.woff2') format('woff2'),
         url('../fonts/FiraMono-Regular.woff') format('woff'),
         url('../fonts/FiraMono-Regular.ttf') format('truetype');
}

/* Global Defaults
------------------------------ */

html,
body {
  position: relative;
  min-height: 800px; // 2 x hudHeight
}
body {
  background-color: #FFF;
  font: normal small/1.5 $f-sansserif;
  text-size-adjust: 100%;

  @media print {
    font-size: 11pt;
  }
}
html.noscroll::-webkit-scrollbar {
  display: none;
}

a {
  color: $c-link;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

h2 {
  margin: 1em 0 .6em;
  overflow: hidden;
  color: $c-h2;
  font-size: 1.1em;
}
  h2 a.button {
    float: right;
    margin: -.5em 0 0 .5em !important;
    padding: 0 !important;
    font-size: 12px;
  }

h3 {
  margin: .6em 0 .2em;
  color: $c-dark;
  font-weight: bold;
  font-size: 1em;
}
h4 {
  margin: 1em 0 .25em;
  color: $c-h4;
  font-weight: bold;
  font-size: 1.1em;
}

p {
  margin: .5em 0 1em;
}

ul li {
  list-style: none;
}

hr {
  height: 1px;
  margin: 20px 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: $c-sp-brd;

  &.dashed {
    border-style: dashed;
    border-color: $c-form-boxed-brd;
  }
}


ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
q {
  font-style: italic;
  font-weight: normal;
}

b,
strong {
  // Some browsers set it to 'bolder' by default
  font-weight: bold;
}

code,
code pre,
kbd,
var {
  font: normal normal .92em $f-monospace-fb;

  .fonts-loaded & {
    font-family: $f-monospace;
  }
}
code.source {
  display: block;
  margin: 1em 0 1.5em;
  border-radius: 3px;
  padding: 1em 1.4em;
  background-color: $c-source-bg;
  color: $c-source-txt;
}

table {
  width: 100%;
}
  table th,
  table td {
    font-weight: normal;
    text-align: left;
  }
  table td {
    border-bottom: 1px solid $c-tbl-brd;
    vertical-align: top;
  }

.tb-generic {
  margin: .5em 0 .75em;
  color: $c-text;

  &.detached {
    margin: 1em 0 2em;
  }
  th,
  td {
    border-bottom: 1px solid $c-tbl-brd;
    padding: .8em .5em;
    vertical-align: middle;
    line-height: 1.45;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  thead th,
  thead td {
    border-bottom: 2px solid $c-tbl-head-brd;
    padding-top: .55em;
    padding-bottom: .55em;
    color: $c-tbl-head-txt;
    font-size: .9em;
    white-space: nowrap;
  }
  .tb-actions {
    text-align: right;
  }
    .color-badge {
      margin-right: .15em;
    }
    .userpic.size-small {
      margin-right: .3em;
      margin-left:  .15em;
      vertical-align: text-bottom;
    }
}
.tb-generic--headless {
  tbody tr:first-child {
    th,
    td {
      border-top: 1px solid $c-tbl-brd;
    }
  }
}
.tb-generic--indented {
  th,
  td {
    &:first-child {
      padding-left: .8em;
    }
    &:last-child {
      padding-right: .8em;
    }
  }
}


/* Animation
------------------------------ */

@keyframes grow {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}


/* Extend
------------------------------ */

.icon-text-expand,
.icon-text-collapse {
  display: inline-block;
  width: 15px;
  height: 5px;
  border: 0 !important;
  border-radius: 2px;
  padding: 4px 3px;
  vertical-align: -2px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #FFF;
  text-indent: -999em;

  &:hover {
    background-color: #333;
  }
}
.icon-text-expand {
  margin-left: .25em;
  background-color: $c-link;
  background-image: url('../images/i-expand.svg');
}
.icon-text-collapse {
  background-color: $c-mute;
  background-image: url('../images/i-text-collapse.svg');
}

.icon-collapse {
  &::before {
    content: '';

    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: .5em;
    border-radius: 1px;
    background: $c-collapse-bg url('../images/i-collapse.png') no-repeat 0 0;

    @include retina {
      background-image: url('../images/i-collapse@2x.png');
      background-size: 9px 18px;
    }
  }
}
.icon-collapse--align-to-checkbox {
  &::before {
    margin-left: 4px;
  }
}

.icon-collapse-selected {
  &::before {
    background-color: $c-collapse-h-bg !important;
    background-position: 0 -9px !important;
  }
}

.checkmark {
  display: inline-block;
  width:  11px;
  height: 11px;
  overflow: hidden;
  background: url('../images/i-plans.svg') no-repeat;
  text-indent: -9999em;
}

.icon-green-arrow {
  &::before {
    content: '';

    display: inline-block;
    width:  14px;
    height: 14px;
    margin-right: .5em;
    vertical-align: -.25em;
    background: url('../images/i-arrow-green.svg') no-repeat;
    background-size: 14px 14px;
  }
}

.icon-cancel {
  padding-left: 15px;
  background: url('../images/i-cancel.svg') no-repeat 0 55%;
  background-size: 11px 11px;
}

.icon-warning {
  &::before {
    content: '';

    display: inline-block;
    width: 15px;
    height: 13px;
    margin-right: .5em;
    vertical-align: -.1em;
    background: url('../images/i-error-box.svg') no-repeat;
    background-size: 15px 13px;
  }
}
.icon-warning--lightgrey {
  &::before {
    background-image: url('../images/i-error-box-lightgrey.svg');
  }
}
.icon-warning--red {
  &::before {
    background-image: url('../images/i-error-box-red.svg');
  }
}
.icon-warning--light {
  &::before {
    background-image: url('../images/i-error-light.svg');
  }
}

.i-comments-toggle {
  &::before {
    content: '';

    display: inline-block;
    width: 16px;
    height: 17px;
    background: url('../images/i-comments-toggle.svg') no-repeat;
    background-size: 42px 34px;
  }
  &.is-collapsed::before {
    background-position: 0 -17px;
  }
}
.i-comments-toggle-sml {
  @extend .i-comments-toggle;

  &::before {
    width: 12px;
    height: 13px;
    background-position: -16px 0;
  }
  &.is-collapsed::before {
    background-position: -16px -17px;
  }
}
.i-comments-counter,
.i-issues-counter {
  &::before {
    content: '';

    display: inline-block;
    background: url('../images/i-bubble.svg') no-repeat;
    background-size: 11px 160px;
  }
}
.i-comments-counter {
  &::before {
    width: 9px;
    height: 9px;
    margin-right: .33em;
    vertical-align: -.1em;
    background-position: 0 0;
  }
  &:link:hover::before {
    background-position: 0 -50px;
  }
  &.is-unread {
    color: #64A8F2 !important;
  }
  &.is-unread::before,
  &.is-unread:link:hover::before {
    background-position: 0 -100px;
  }
}
.i-issues-counter {
  &::before {
    width: 11px;
    height: 10px;
    margin-right: .25em;
    background-position: 0 -150px;
  }
}

.icon-attention {
  &::before {
    content: '';

    display: inline-block;
    width:  15px;
    height: 15px;
    margin-right: .5em;
    vertical-align: -.2em;
    background: url('../images/i-comment.svg') no-repeat;
    background-size: 15px 15px;
  }
}

.icon-add {
  &::before {
    content: '';

    display: inline-block;
    width:  14px;
    height: 14px;
    vertical-align: -.2em;
    background-image: url('../images/i-add.svg');
    background-size: 14px 14px;
  }
}

.icon-history {
  &::before {
    content: '';

    display: inline-block;
    width:  14px;
    height: 14px;
    margin-right: .4em;
    vertical-align: text-bottom;
    background: url('../images/i-history.svg') no-repeat;
    background-size: 14px 56px;
  }
}

.icon-pending {
  &::before {
    content: '';

    display: inline-block;
    width:  11px;
    height: 11px;
    margin-right: .35em;
    vertical-align: -.1em;
    background: url('../images/i-pending.svg') no-repeat;
    background-size: 11px 11px;
  }
}

.icon-pending-sml {
  &::before {
    content: '';

    display: inline-block;
    width:  9px;
    height: 9px;
    margin-right: .35em;
    background: url('../images/i-pending-sml.svg') no-repeat;
    background-size: 9px 9px;
  }
}



// Arrows placed before or after

.icon-arrow-bt::before,
.icon-arrow-at::after,
.icon-arrow-br::before,
.icon-arrow-ar::after,
.icon-arrow-bb::before,
.icon-arrow-ab::after,
.icon-arrow-bl::before,
.icon-arrow-al::after {
  display: inline-block;
  vertical-align: .15em;
  font-size: .66em;
}
.icon-arrow-bt::before,
.icon-arrow-br::before,
.icon-arrow-bb::before,
.icon-arrow-bl::before {
  margin-right: .85em;
}
.icon-arrow-at::after,
.icon-arrow-ar::after,
.icon-arrow-ab::after,
.icon-arrow-al::after {
  margin-left: .85em;
}
.icon-arrow-bt::before,
.icon-arrow-at::after {
  content: '▲';
}
.icon-arrow-br::before,
.icon-arrow-ar::after {
  content: '▶';
}
.icon-arrow-bb::before,
.icon-arrow-ab::after {
  content: '▼';
}
.icon-arrow-bl::before,
.icon-arrow-al::after {
  content: '◀';
}


// Status

.status-label {
  &::before {
    content: '';

    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: .3em;
    vertical-align: middle;
    background: url('../images/i-status.svg') no-repeat;
    background-size: 11px 200px;
  }
  a,
  strong {
    color: inherit !important;
  }
  &.status-label--ok {
    color: $c-form-status-ok-txt;

    &::before {
      background-position: 0 -2px;
    }
  }
  &.status-label--error {
    color: $c-form-status-error-txt;

    &::before {
      background-position: 0 -50px;
    }
  }
  &.status-label--disabled-ok {
    color: $c-mute;

    &::before {
      background-position: 0 -152px;
    }
  }
  &.status-label--disabled-error {
    color: $c-mute;

    &::before {
      background-position: 0 -100px;
    }
  }
}


// Lock

.icon-locked {
  &::before {
    content: '';

    display: inline-block;
    width:  8px;
    height: 11px;
    background: url('../images/i-lock-m.svg') no-repeat;
    background-size: 8px 48px;
  }
}
.icon-locked--link {
  &::before {
    background-position: 0 -12px;
  }
}


// File status icons

.vcs-modified,
.vcs-added,
.vcs-deleted,
.vcs-moved,
.vcs-copied {
  &::before {
    content: '';

    display: inline-block;
    width:  13px;
    height: 13px;
    margin-right: .5em;
    vertical-align: -.4em;
    background: url('../images/i-act-sml.svg') no-repeat;
    background-size: 13px 150px;
  }
}
.vcs-copied {
  &::before {
    background-position: 0 0;
  }
}
.vcs-moved {
  &::before {
    background-position: 0 -30px;
  }
}
.vcs-modified {
  &::before {
    background-position: 0 -60px;
  }
}
.vcs-deleted {
  &::before {
    background-position: 0 -90px;
  }
}
.vcs-added {
  &::before {
    background-position: 0 -120px;
  }
}


/* Loading
------------------------------ */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

// Default: Small

%icon-loading {
  display: inline-block;
  box-sizing: border-box;
  width:  9px;
  height: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #BBB #BBB #FFF #FFF;
  border-radius: 100%;
  background: transparent none;
  animation: spin 600ms infinite linear;
}
.icon-loading {
  @extend %icon-loading;
}

// Medium

%icon-loading--medium {
  width:  14px;
  height: 14px;
}
%icon-loading--medium-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
}
.icon-loading--medium {
  @extend %icon-loading--medium;
}

// Large

%icon-loading--large {
  width:  30px;
  height: 30px;
}
%icon-loading--large-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
}
.icon-loading--large {
  @extend %icon-loading--large;
}

// Huge

%icon-loading--huge {
  width:  40px;
  height: 40px;
}
%icon-loading--huge-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}
.icon-loading--huge {
  @extend %icon-loading--huge;
}


/* Blocks */

// Default: Huge

%block-loading {
  position: relative;
  min-height: 60px;

  &::before {
    @extend %icon-loading;
    @extend %icon-loading--huge;
    @extend %icon-loading--huge-centered;

    content: '';

    z-index: 90;
    border-width: 2px;
    border-color: $c-olive-light $c-olive-light #FFF #FFF;
  }
  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
    background-color: rgba(#FFF, .75);
  }
}
.block-loading {
  @extend %block-loading;
}



/* Misc
------------------------------ */

.clear {
  clear: both;
}
.clearfix::before,
.clearfix::after {
  content: ' ';

  display: table;
}
.clearfix::after {
  clear: both;
}
.to-left {
  float: left;
}
.to-right {
  float: right;
}
.hide {
  display: none !important;
}
.plain {
  font-style: normal;
  font-weight: normal;
}
.small {
  font-size: .9em;
}
.mute {
  color: $c-mute;
}
.dark {
  color: $c-dark;
}

p.last {
  margin-bottom: 0 !important;
}
p.hint {
  margin: .5em 0;
  padding: 0;
  color: $c-mute;
  font: italic .9em/1.5 $f-serif;
}
  p.hint img {
    vertical-align: middle;
  }
p.blank {
  margin: 2em 0 1.25em !important;
  color: $c-light;
  font: italic 1em/1.5 $f-serif;
}
  p.blank a {
    color: inherit;
  }
  p.blank a:hover {
    color: $c-dark;
    text-decoration: underline;
  }
p.high-load {
  margin: -15px 0 1.5em;
  border-bottom: 1px solid $c-highload-brd;
  padding: 1em 1.5em;
  background-color: $c-highload-bg;
  color: $c-highload-txt;
  font-weight: bold;
}
.sep {
  margin: 0 .5em;
  color: $c-lightest;
}
iframe#hidden-iframe {
  position: absolute;
  top: -3000px;
  left: 0;
  width: 0;
  height: 0;
}
iframe.preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  border: 0;
}
strong.notice {
  @extend .icon-warning;
  @extend .icon-warning--light;

  color: $c-notice-txt;
  font-size: .9em;
  text-transform: uppercase;
}
strong.exclusive {
  border-radius: 3px;
  padding: .2em .4em;
  background-color: $c-exclusive-bg;
  color: $c-exclusive-txt;
}
strong.mark {
  display: inline-block;
  margin: 0 .2em;
  border-radius: 2px;
  padding: .1em .4em;
  background-color: $c-mark-bg;
  color: $c-mark-txt;
  text-shadow: 0 1px 0 #FFF;
}
  strong.mark--purple {
    background-color: $c-mark-purple-bg;
    color: $c-mark-purple-txt;
    text-shadow: 0 1px 0 rgba(#000, .2);
  }
  strong.mark--green {
    background-color: #5BB86B;
    color: #FFF;
    text-shadow: none;
  }
  strong.mark--grey {
    background-color: $c-mark-grey-bg;
    color: $c-mark-grey-txt;
    text-shadow: 0 1px 0 rgba(#FFF, .25);
  }



/* Helpers */

.indent {
  margin-left: 25px !important; // 1.95em
}


.link-local {
  border-bottom: 1px dashed mix(#FFF, $c-link, 50%);
  color: $c-link;
  text-decoration: none !important;

  &:hover,
  &:active {
    border-bottom: 1px solid;
    text-decoration: none;
  }
}
a.link-external {
  text-decoration: underline;

  &:link,
  &:visited {
    color: $c-link;
  }
  &:hover,
  &:active {
    color: $c-base;
  }
  &.dark:link,
  &.dark:visited {
    color: $c-dark;
  }
}
a.link-action {
  &:link,
  &:visited {
    border-bottom: 1px dashed;
    color: $c-mute;
    text-decoration: none !important;
  }
  &:hover,
  &:active {
    border-bottom-style: solid;
    text-decoration: none;
  }
}
a.link-system {
  white-space: nowrap;
}
  a.link-system:link,
  a.link-system:visited,
  a.link-system:active {
    color: $c-link-system-txt;
    font-weight: bold;
    text-decoration: underline;
  }
  a.link-system:hover {
    color: $c-link-system-h-txt;
    text-decoration: none;
  }

a.link-delete,
a.link-cancel {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}
a.link-cancel:hover {
  color: $c-link;
}

a.link-option {
  border-bottom: 1px dashed $c-link-option-brd;
  color: $c-link-option-txt !important;
  font: italic 12px $f-serif;
  text-decoration: none !important;
}
a.link-option:hover {
  border-style: solid;
}

.link-preview {
  @extend .ct_button_icon;

  margin: 4px !important;
  vertical-align: baseline !important;
  background-position: -32px 0;
  text-indent: -999em;

  &:hover {
    background-position: -32px -16px;
  }
}


/* Cancel */

.a-remove {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../images/i-status.svg') no-repeat 50% -96px;
  background-size: 11px 200px;
  text-indent: -9999em;
  text-align: left;

  &.loading {
    position: relative;
    background-image: none;

    &::before {
      @extend %icon-loading;

      content: '';

      position: absolute;
      top: 4px;
      left: 3px;
    }
  }
}


/* Delete */

.a-delete {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../images/i-delete.svg') no-repeat 2px 1px;
  background-size: 11px 100px;
  text-indent: -9999em;
  text-align: left;

  &:hover {
    background-position: 2px -49px;
  }
  &.loading {
    position: relative;
    background-image: none;

    &::before {
      @extend %icon-loading;

      content: '';

      position: absolute;
      top: 4px;
      left: 3px;
    }
  }
  &.disabled,
  &.disabled:hover {
    background-position: 2px 1px;
    opacity: .5;
    cursor: default;
  }
}
.a-create {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../images/i-action-create.svg') no-repeat 2px 1px;
  text-indent: -9999em;
  text-align: left;

  &:hover {
    background-position: 2px -49px;
  }
  &.disabled {
    opacity: .5;
    cursor: default;

    &:hover {
      background-position: 2px 1px;
    }
  }
}


/* Configure / Settings icon */

.icon-settings {
  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;
    width:  13px;
    height: 13px;
    margin-top: -6px;
    background: url('../images/i-gear.svg') no-repeat;
    background-size: 13px 26px;
  }
  &:hover::before {
    background-position: 0 -13px;
  }
}
.icon-preferences {
  &::before {
    content: '';

    display: inline-block;
    width:  13px;
    height: 13px;
    margin-right: .4em;
    vertical-align: -.175em;
    background: url('../images/i-gear.svg') no-repeat;
    background-size: 13px 26px;
  }
  &:link,
  &:visited {
    &:hover::before {
      background-position: 0 -13px;
    }
  }
}
.a-settings {
  @extend .icon-settings;

  display: inline-block;
  position: relative;
  width:  13px;
  height: 14px;
  overflow: hidden;
  text-indent: -9999em;
  text-align: left;
}


/* User types */

.user-type-owner,
.user-type-admin,
.user-type-invited {
  margin-left: .2em;
  border-radius: 2px;
  padding: 1px 3px;
  vertical-align: 1px;
  color: #FFF;
  font-weight: normal !important;
  font-size: 9px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
}
.user-type-owner {
  background-color: $c-user-owner-bg;
}
.user-type-admin {
  background-color: $c-user-admin-bg;
}
.user-type-invited {
  background-color: $c-user-invited-bg;
}



/* Tags */

sup.tagged,
a.tagged {
  margin-left: .5em;
  border: 0 !important;
  border-radius: 2px;
  padding: .25em .5em;
  background-color: $c-tag-bg;
  color: $c-tag-txt !important;
  font-weight: normal !important;
  font-size: .7em;
  text-decoration: none !important;
  white-space: nowrap;
}
a.tagged-more {
  margin-left: .5em;
  border: 0 !important;
  color: $c-tag-more-txt !important;
  font-size: .7em;
  white-space: nowrap;
}



/* Comments (meta) */

.comment {
  color: $c-comment-txt;
  font: italic .9em/1.5 $f-serif;

  a {
    color: inherit;
  }
}
  .comment em {
    font-style: normal;
  }
  p.comment {
    @extend .icon-attention;

    margin: .6em .5em 1.2em;
    padding: .6em 0;
    font-size: .9em;
  }
  code.comment {
    margin: .6em .5em 1.2em;
    padding: .6em 0 .6em 2.2em;
    font: normal 1em $f-monospace-fb;

    .fonts-loaded & {
      font-family: $f-monospace;
    }
  }


/* Text boxes */
// TODO: Refactor .warning @ special pages

.text-box {
  border-radius: 3px;
  padding: .75em;
}
.text-box--grey {
  background-color: $c-textbox-grey-bg;
  color: $c-textbox-grey-txt;
}
.text-box--purple {
  background-color: $c-textbox-purple-bg;
  color: $c-textbox-purple-txt;
}


/* Replace <select> with nicer label */

.select-cover {
  display: inline-block;
  position: relative;
  border: 1px solid $c-selector-brd;
  border-radius: 2px;
  padding: .3em 27px .3em 10px;
  overflow: hidden;
  background-color: $c-selector-bg;
  color: $c-selector-txt;
  line-height: normal;
  text-align: left;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 10px;
    width: 7px;
    height: 4px;
    margin: -2px 0 0;
    background: url('../images/arrow-dropdown.svg') no-repeat 96% 50%;
  }
  &:hover {
    border-color: $c-selector-h-brd;
  }
  select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zi-select-cover;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &.disabled,
  &.disabled:hover {
    border-color: $c-selector-dsb-brd;
    background-image: none;
    color: $c-selector-dsb-txt;

    select {
      cursor: default;
    }
  }
}
.select-cover--permission {
  box-sizing: border-box;
  width: 100%;
  border-color: $c-permission-brd;
  padding-left: 5px;
  vertical-align: middle;
  font-size: .85em;

  &:hover {
    border-color: $c-permission-brd;
    box-shadow: 0 1px 1px $c-permission-h-bsh;
  }

  // Permission levels

  &.no-access {
    color: $c-permission-noaccess-txt;
  }
  &.partial-access {
    border-color: $c-permission-partial;
    background-color: $c-permission-partial;
    color: $c-permission-partial-txt;
  }
  &.full-access {
    border-color: $c-permission-full;
    background-color: $c-permission-full;
    color: $c-permission-full-txt;
  }

  // Apply to all

  &.apply {
    border-color: $c-permission-apply-brd;
    background-color: $c-permission-apply-bg;
    color: $c-permission-apply-txt;
  }

  // Disabled selector

  &.is-repo-inactive {
    border-color: $c-permission-inactive !important;
    background-color: $c-permission-inactive !important;
    color: $c-permission-inactive-txt !important;

    &:hover {
      box-shadow: none !important;
    }
    select {
      cursor: default;
    }
  }
}


/* Color badges for repositories, environments and teams */

.color-badge {
  display: inline-block;
  box-sizing: border-box;
  margin-right: .3em;
  border-width: 1px;
  border-style: solid;
  vertical-align: -.2em;

  &.color-badge--repo {
    width:  14px;
    height: 14px;
    border-color: $c-label-brd;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px #FFF;

    &.color-badge--invert {
      border-color: transparent;
      box-shadow: inset 0 0 0 1px rgba(#000, .66);
    }
    .status-disabled &,
    .status-importing & {
      width:  16px;
      height: 16px;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
    }
    .status-disabled & {
      @extend .icon-warning;
      @extend .icon-warning--light;

      &::before {
        margin-right: 0;
        vertical-align: .15em;
      }
    }
    .status-importing & {
      &::before {
        @extend %icon-loading;
        @extend %icon-loading--medium;

        content: '';
      }
    }
  }
  &.color-badge--env {
    width:  14px;
    height: 14px;
    border-color: $c-env-label-brd;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #FFF;
  }
  &.color-badge--team {
    width:  11px;
    height: 11px;
    margin-left: 1px;
    border-width: 3px;
    border-radius: 50%;
    vertical-align: -.1em;
    background-color: transparent;
  }
  &.color-badge--text {
    border-radius: 2px;
    padding: .1em .4em;
    overflow: hidden;
    color: $c-label-txt !important;
    text-align: left;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.color-badge--with-counter {
    margin-right: 0;
    border-radius: 2px 0 0 2px;
    vertical-align: middle;
  }
  &.color-badge--with-select {
    margin-left: .3em;
    vertical-align: middle;
  }
}
  .color-badge_counter {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #DDD;
    border-radius: 0 2px 2px 0;
    border-left: 0;
    padding: .1em .66em;
    vertical-align: middle;
    color: #333;
    font-weight: bold;
  }

$c-colors-map: (
  'pink':         #FDA7CC,
  'red':          #FF5454,
  'red-orange':   #F73,
  'orange':       #FFA93A,
  'yellow':       #F7D737,
  'yellow-green': #57D200,
  'aqua-green':   #73E5AC,
  'green':        #8ABF86,
  'green-blue':   #7FBFBF,
  'sky-blue':     #66E6FF,
  'light-blue':   #99D5FF,
  'blue':         #85AAF2,
  'orchid':       #E493FD,
  'violet':       #BF87BE,
  'brown':        #BF9985,
  'black':        #222,
  'grey':         #AAA,
  'white':        #FFF
);

@each $color-label, $color-value in $c-colors-map {
  .u-color-txt--#{$color-label} {
    color: darken($color-value, 12.5%) !important;
  }
  .u-color-brd--#{$color-label} {
    border-color: $color-value !important;
  }
  .u-color-bg--#{$color-label} {
    background-color: $color-value !important;
  }
  .color-badge--#{$color-label} {
    border-color: $color-value;
    background-color: $color-value;
  }
}
  .color-badge--white {
    &.color-badge--team {
      box-shadow: 0 0 2px rgba(#000, .33), inset 0 0 2px rgba(#000, .33);
    }
    &.color-badge--text {
      color: #444 !important;
      box-shadow: 0 0 2px rgba(#000, .33);
      text-shadow: none;
    }
    &.color-badge--with-counter {
      border: 1px solid #DDD;
      box-shadow: none;
    }
  }


/* Curtain */

#curtain {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zi-curtain;
  background-color: $c-page-bg;
  opacity: .95;
}



/* Mask */

.mask {
  @extend %block-loading;

  position: absolute;
  z-index: $zi-mask;
}



/* Userpics */

.userpic {
  display: inline-block;
  position: relative;

  img {
    margin: 0;
    border: 0;
    border-radius: 1px;
    padding: 0;
  }
  b {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(#000, .15);
    border-radius: 1px;

    @media print {
      display: none !important;
    }
  }
  &.size-large,
  &.size-large img {
    width: 32px;
    height: 32px;
  }
  &.size-medium,
  &.size-medium img {
    width: 24px;
    height: 24px;
  }
  &.size-small,
  &.size-small img {
    width: 18px;
    height: 18px;
  }

  // Automatic deployments

  &.autodeployed {
    img {
      border-radius: 50%;
    }
    b {
      border: 0 !important;
      border-radius: 0;
      background-image: url('../images/userpic-autodeployed.svg');
      background-repeat: no-repeat;
      background-size: 30px 50px;
    }
    &.size-small {
      width: 30px;
      height: 30px;
      margin: 1px;

      img {
        margin: 6px;
      }
      b {
        background-position: 0 0;
      }
    }
    &.size-tiny {
      width: 20px;
      height: 20px;

      img {
        width: 12px;
        height: 12px;
        margin: 4px;
      }
      b {
        background-position: 0 -30px;
      }
    }
  }
}


/* $.autocomplete */

.autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zi-autocomplete;
  box-sizing: border-box;
  border: 1px solid #EEE;
  background-color: #FFF;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .15);

  ul {
    list-style: none;
  }
    ul li {
      border-bottom: 1px solid #EEE;

      &:last-child {
        border-bottom: 0;
      }
    }
      ul li a {
        display: block;
        padding: .25em .5em;
        color: #666;

        &:hover,
        &.selected {
          background-color: #F9F9F9;
          color: #000;
          text-decoration: none;
          text-shadow: 0 1px 0 #FFF;
        }
      }

  // Nothing found

  p {
    margin: 0;
    padding: .3em .5em;
    color: #999;
    font-size: .9em;
  }
}
input.querying {
  background: url('../images/spinner.gif') no-repeat 98% center;
}



/* Tooltips */

[rel='tooltip'][data-tooltip] {
  &:link,
  &:visited {
    cursor: pointer;
  }
}

.tooltip {
  position: absolute;
  z-index: $zi-tooltip;
  max-width: 180px;
  border-radius: 3px;
  padding: .5em .75em;
  background-color: rgba(#000, .85);
  color: #FFF;
  box-shadow: 0 1px 3px rgba(#000, .3);
  font-size: .85em;
  line-height: 1.4;

  span {
    color: #999;
  }
}



/* jqstooltip (jquery.sparkline.js) */

.jqstooltip {
  border: 0 !important;
  border-radius: 3px !important;
  padding: .5em .75em !important;
  background-color: rgba(#000, .85) !important;
  box-shadow: 0 1px 3px rgba(#000, .3) !important;
  font: 11px/1.4 $f-sansserif !important;
}
  .jqstooltip_meta {
    color: #C5C5C5;
  }


/* Image Zoom */

.image-zoom {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: $zi-image-zoom;
  border-radius: 2px;
  padding: 5px;
  overflow: hidden;
  background-color: #FFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
}
  .image-zoom a,
  .image-zoom a img {
    display: block;
    text-decoration: none;
  }
  .image-zoom a img {
    border: 1px solid #EAEAEA;
  }
  .image-zoom a i {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url('../images/i-zoom-close.png') no-repeat;
    background-size: 30px 30px;
  }

.image-zoom.loading {
  width:  80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  padding: 0;

  &::before {
    @extend %icon-loading;
    @extend %icon-loading--medium;
    @extend %icon-loading--medium-centered;

    content: '';
  }
}
  .image-zoom.loading img {
    position: absolute;
    left: -9999em;
  }
  .image-zoom.loading i {
    display: none;
  }

.image-zoom.grow {
  animation-name: grow;
  animation-duration: .4s;
}



/* Contact Us */

ul#contactus {
  margin: .5em 0 1.25em;
  padding: 0 !important;
}
  ul#contactus li {
    margin: 0 0 .75em;
    padding: 0 0 0 22px !important;
    background-image: url('../images/i-support@2x.png');
    background-repeat: no-repeat;
    background-size: 13px 162px;
    list-style: none;
  }
  ul#contactus li.chat {
    background-position: 1px 3px;
  }
  ul#contactus li.support {
    background-position: 1px -47px;
  }
  ul#contactus li.twitter {
    background-position: 1px -97px;
  }
  ul#contactus li.guides {
    background-position: 1px -147px;
  }


/* Fancy numberical list */

.fancy-ol {
  margin: 1.5em 0;
  padding-left: 0 !important;

  li {
    position: relative;
    padding: 0 0 1em 1.8em !important;
    list-style: none;
  }
}
    .fancy-num {
      position: absolute;
      top: .15em;
      left: 0;
      box-sizing: border-box;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      padding-top: .2em;
      background-color: $c-olive;
      color: #FFF;
      font-weight: bold;
      font-size: 11px;
      line-height: 1;
      text-align: center;
    }
    .barcode {
      display: block;
      margin: .75em 0;

      img {
        border: 1px solid #E9E9E9;
        padding: 10px;
        vertical-align: middle;
      }
    }
    .barcode-num {
      margin-left: .25em;
      font-size: 1.1em;
      text-transform: uppercase;
      letter-spacing: .075em;
    }



/* App Store icons */

.icon-appstore::before {
  content: '';

  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 .33em;
  vertical-align: text-bottom;
  background: url('../images/i-app-stores.png') no-repeat;
  background-size: 16px 33px;
}
.icon-appstore--apple::before {
  background-position: 0 0;
}
.icon-appstore--google::before {
  background-position: 0 -17px;
}


/* Inner tabs */

.inner-tabs {
  @extend .clearfix;

  margin: 0 -30px;
  border-bottom: 1px solid $c-innertabs-a-brd;
  padding: 0 30px;
  background-color: $c-olive-lightest;
}
  .inner-tabs_list {
    float: left;
    margin-bottom: -1px;

    > li {
      float: left;
      margin-right: 10px;
      list-style: none;
    }
  }
    .inner-tabs_item {
      float: left;
      border: 1px solid $c-innertabs-brd;
      border-bottom-color: $c-innertabs-a-brd;
      padding: .75em 1.25em;
      background-color: $c-innertabs-bg;
      color: $c-innertabs-txt;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;

      &:hover,
      &.is-current {
        border-color: $c-innertabs-a-brd;
        color: $c-innertabs-a-txt;
        text-decoration: none;
      }
      &.is-current {
        border-bottom-color: $c-innertabs-a-bg;
        background-color: $c-innertabs-a-bg;
        cursor: default;
      }
    }
      .inner-tabs_counter {
        margin-left: .2em;
        opacity: .5;
        font-weight: normal;
        font-size: .85em;
      }

  .inner-tabs_aside {
    float: right;
    padding-top: .3em;
  }
    .inner-tabs_action {
      color: $c-innertabs-txt;
      text-decoration: none !important;

      u {
        border-bottom: 1px dashed rgba($c-innertabs-txt, .66);
        text-decoration: none;
      }
      &:link:hover,
      &:visited:hover {
        color: #000;

        u {
          border-bottom: 1px solid #888;
        }
      }
    }
