/* IP restrictions
------------------------------ */

form.add-ip {
  margin-top: 1em;
}
  .forms.add-ip fieldset {
    border-width: 2px;
    padding-top: 10px;
    background-color: $c-form-boxed-bg-alt;
  }
  .forms.add-ip p.error-msg,
  .forms.add-ip .form-error .form-label label {
    color: $c-form-boxed-error-txt;
  }
  .forms.add-ip .form-row.align,
  .forms.add-ip .form-submit.align {
    width: auto;
    margin-right: .75em;
  }
    .forms.add-ip .form-row.align .form-label {
      margin: 0 0 0 .25em;
    }
    .forms.add-ip .form-row.align .form-field {
      padding-top: .3em;
    }
      .forms.add-ip .form-row.align .form-field select,
      .forms.add-ip .form-row.align .form-field input {
        width: auto;
        margin: 0 .25em;
      }
      .forms.add-ip .form-row.align .form-field select {
        vertical-align: middle;
      }
      .forms.add-ip .form-row.align .form-field input {
        padding: .3em;
        line-height: 1em;
      }
      .forms.add-ip .form-row.align .form-field input.ip {
        width: 11em;
        padding: .3529em;
        font: normal normal .85em/1.1764em $f-monospace-fb;

        .fonts-loaded & {
          font-family: $f-monospace;
        }
      }
      .forms.add-ip #restrictions-ip-address,
      .forms.add-ip #restrictions-ip-range {
        display: inline;
        color: $c-form-boxed-meta;
      }

  .forms.add-ip .form-submit.align {
    margin-top: 2.7615em;
    padding-top: .175em;
  }
    .forms.add-ip .form-submit.align .sep {
      margin: 0 .25em;
    }
    .forms.add-ip .form-submit.align input {
      vertical-align: middle;
    }

  .forms.add-ip .form-submit.loading {
    &::before {
      @extend .icon-loading;

      content: '';
    }

    /* stylelint-disable selector-max-universal */
    > * {
      visibility: hidden;
    }
    /* stylelint-enable */
  }
