/* Forms
------------------------------ */

.optional {
  margin-left: .25em;
  color: $c-form-meta;
  font-style: italic;
  font-weight: normal;
}
.required {
  margin-left: .25em;
  color: $c-dark !important;
  font-weight: bold;
}


  fieldset h3 {
    margin: .75em .5em;
  }
  fieldset input {
    font-size: 1em;
  }


  /* Form Row */

  .form-row {
    display: block;
    margin: .3em 0 1.2em;
    overflow: hidden;
  }
  .form-row--chosen {
    overflow: visible;
  }
  .form-row.detached {
    margin-top: 1.2em;
  }
  .form-row.last {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
    .form-text {
      margin: 0 .5em;

      h3 {
        margin: .75em 0 .33em;
        color: $c-dark;
      }
    }
    .form-label {
      float: left;
      width: 140px;
      padding: .5em 0 .25em;
    }
    .form-field {
      margin-left: 150px;
      padding: .25em 0;

      .icon-loading {
        margin-left: .33em;
        vertical-align: middle;
      }
    }
      .form-field.aligned {
        padding-top: .5em;
      }
      .form-field .form-comment {
        margin-left: 0;
      }

    .field-pre {
      padding-right: .25em;
      color: $c-form-meta;
      font-size: 1em;
    }

    .form-row p.comment {
      margin-bottom: 0;
    }
    .form-row .hint {
      font-size: .9em !important;
    }
    .form-row .field-desc {
      margin-top: .5em !important;
      color: $c-form-desc-txt;
      font-size: .9em;
      line-height: 1.7;
    }
      .form-row .field-desc code {
        white-space: nowrap;
      }
      .form-row .field-desc strong {
        color: darken($c-form-desc-txt, 20%);
      }


    /* Comment */

    .form-comment {
      margin: .5em 0 .5em 150px;
      padding: 0;
      color: $c-form-meta;
      font: italic .9em/1.4em $f-serif;

      strong {
        font-style: normal;
        font-weight: normal;
      }
    }
    .form-comment-inline {
      display: inline-block;
      margin: 0 0 0 1em !important;
      color: $c-form-meta;
      font-size: .9em;
    }


    /* Comment in plain text */

    .form-comment.plain,
    .form-comment.plain p {
      color: $c-text !important;
      font: normal 1em/1.4 $f-sansserif;
    }
      .form-comment.plain h3 {
        margin: 1em 0 0;
        color: $c-dark;
      }
      .form-comment.plain h3:first-child {
        margin-top: .5em;
      }


  /* Vertical form */

  .form-row.form-row--vertical {
    margin-bottom: .6em;

    .form-label {
      float: none;
      width: auto;
      padding: 0;

      #specialPage #page & {
        text-align: left;
      }
    }
    .form-field {
      margin-left: 0;
    }
    p.error-msg {
      padding-left: 0 !important;
    }
  }


  /* Address */

  .form-group--location {
    display: flex;
  }
  .form-row--city,
  .form-row--state,
  .form-row--region {
    &,
    #specialPage #page .form-group--location & {
      margin-right: 10px !important;
    }
  }
  .form-row--city {
    flex-grow: 1;
  }
  .form-row--state,
  .form-row--region {
    width: 30%;
  }
  .form-row--zip {
    width: 20%;
  }


  /* Compact form */

  .form-row.form-row--compact {
    margin-bottom: .3em;
  }

  /* Short form */

  .form-row.form-row--short {
    .form-label {
      width: 90px;
      padding-left: 0;
    }
    .form-field,
    .form-text {
      margin-right: 0;
      margin-left: 100px;
    }
  }

  /* Form row with label */

  .form-row.form-row--labeless {
    .form-label {
      display: none;
    }
    .form-field,
    .form-comment {
      margin-bottom: .75em !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }


  /* Disabled form */

  .form-row.form-disabled {
    color: $c-form-disabled-txt;
  }

  /* Form Buttons */

  .form-buttons {
    overflow: hidden;
  }
    .form-buttons input,
    .form-buttons .button-alt {
      vertical-align: middle;
    }
      .button-alt {
        margin-left: .5em;
        color: $c-mute;

        a {
          margin-left: .5em;
          color: $c-dark;
          font-weight: bold;
          text-decoration: underline;

          &:hover,
          &:active {
            color: $c-form-link-action;
          }
        }
        a.link-local {
          font-weight: normal;
        }
      }


  /* Loading State */

  .form-buttons.loading {
    position: relative;
    text-indent: -9999px;

    &::before {
      @extend %icon-loading;

      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      width:  18px;
      height: 18px;
      margin: -9px 0 0 -9px;
      border-width: 2px;
    }
  }
  .form-buttons.loading input,
  .form-buttons.loading button,
  .form-buttons.loading a,
  .form-buttons.loading span {
    visibility: hidden;
  }


  /* Errors Handling */

  .form-error {
    padding: .4em 0;
    background-color: $c-form-error-bg;
  }
    .form-error .form-label {
      width: 135px;
      padding-left: 5px;
    }
    p.error-msg {
      clear: both;
      margin: 0;
      padding: .4em 0;
      color: $c-form-error-txt;
      font-weight: bold;
      font-size: .9em;
    }
    p.error-msg a.link-action {
      color: $c-form-error-link-action;
    }


  // New universal validation classes

  .validation {
    margin: .75em 0 0 150px;

    a {
      color: inherit !important;
    }
    .form-text & {
      margin-left: 0;
    }
  }
  .validation--error {
    @extend .icon-warning;
    @extend .icon-warning--red;

    color: $c-validation-error-txt;
  }
  .validation--ok {
    color: $c-validation-ok-txt;
  }

