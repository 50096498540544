/* Reviews section
------------------------------ */

// Layout

$filters-width: 20%;

.maincol--cr {
  display: flex;
  flex-direction: column;

  .toolbar {
    flex: 0 1 auto;
  }
}

.cr_container {
  flex: 1 1 auto;

  margin: -30px -30px -60px;
  background-image: linear-gradient(to right, #F8FAF5 0%, #F8FAF5 98%, #F2F5ED 99.5%, #EBF0E5);
  background-repeat: repeat-y;
  background-position: 0 0;
  background-size: $filters-width 4px;
}
  .cr_reviews {
    float: right;
    box-sizing: border-box;
    width: (100% - $filters-width);
    padding: 10px 30px;
  }
  .cr_reviews--blank {
    float: none;
    width: auto;
    padding-top: 0;
    background-color: #FFF;

    .blank-slate .message {
      margin: -15px 0 45px;
    }
  }
  .cr_filters {
    box-sizing: border-box;
    width: $filters-width;
    padding: 20px 10px;
  }
    .cr_filters_create {
      margin: 0 0 30px;
    }



/* Sidebar
------------------------------ */

.stats-container {
  &.block-loading {
    min-height: 100px;
  }
}

// Coverage stats

.cr-stats-title {
  @extend .clearfix;

  padding: 1px 0;
}
.cr-stats-filter {
  float: right;
  margin-top: -1px;
  color: #666;
  font-weight: normal;
  font-size: .9em;
}


// Stats

.coverage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
  .coverage_stats {
    flex: 0 0 auto;

    width: 90px;
    height: 90px;
  }
  .coverage_data {
    flex: 1 0 auto;

    margin-left: 25px;
    color: $c-text;

    h3 {
      margin: 0 0 .2em;
      color: $c-base;
      font-size: .85em;
      text-transform: uppercase;
    }
  }
    .chart-color {
      display: inline-block;
      width:  5px;
      height: 5px;
      margin-right: .2em;
      border-radius: 50%;
      vertical-align: .1em;
    }
    .chart-color--not-ready {
      background-color: #F7E37C;
    }
    .chart-color--approved {
      background-color: #66CC6F;
    }
    .chart-color--pending {
      background-color: #B7E7EB;
    }
    .chart-color--canceled {
      background-color: #C9C9C9;
    }



/* Faceted navigation
------------------------------ */

.faceted-nav_hr {
  height: 0;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid rgba($c-olive-light, .33);
}

.faceted-nav_toggler {
  @extend .icon-collapse;

  margin: 0 5px;
  font-size: .92em;
  text-decoration: none;

  &:link,
  &:visited {
    u {
      background-image: linear-gradient(to right, rgba($c-link, .5) 0, rgba($c-link, .5) 50%, rgba($c-link, 0) 50%, rgba($c-link, 0) 100%);
      background-repeat: repeat-x;
      background-position: 0 97.5%;
      background-size: 5px 1px;
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
    &:hover u {
      background-image: linear-gradient(to right, rgba($c-link, .66), rgba($c-link, .66));
      background-size: 5px 1px;
    }
  }
}

.faceted-nav {
  margin: 0 -10px 2em 0;
  white-space: nowrap;

  dd {
    position: relative;
    margin: 0 0 .25em;
  }
}
  .faceted-nav_title {
    margin: 0 0 .66em;
    padding: 0 6px;
    color: #AAA;
    font-size: .85em;
  }
  .faceted-nav_item {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    padding: .1em 6px;
    overflow: hidden;
    color: $c-mainnav-txt;
    font-size: .92em;
    text-decoration: underline;
    text-overflow: ellipsis;

    &:hover,
    &:active {
      color: $c-mainnav-h-txt;
    }
    &.i-branch {
      &::before {
        margin-right: .25em;
      }
      &:hover::before {
        background-position: 0 0;
      }
    }
    &.is-selected {
      display: block;
      border-radius: 2px 0 0 2px;
      border-bottom: 1px solid #EBF0E5;
      padding-top: .5em;
      padding-bottom: .5em;
      background-color: #FFF;
      color: $c-base;
      text-decoration: none;
      cursor: text;

      &.is-bookmarked {
        &::before {
          vertical-align: -1px;
        }
      }
    }
    &.is-loading {
      &::before {
        @extend %icon-loading;

        content: '';

        margin-right: .5em;
        vertical-align: baseline;
      }
    }
    &.is-bookmarked {
      padding-right: 24px;  // for .faceted-nav_delete

      &::before {
        content: '';

        display: inline-block;
        width: 8px;
        height: 10px;
        margin-right: .5em;
        vertical-align: -2px;
        background: url('../images/i-filter.svg') no-repeat 1px 0;
      }
    }
    &.is-save {
      &::before {
        content: '';

        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: .5em;
        vertical-align: 1px;
        background: url('../images/i-filter.svg') no-repeat -8px 0;
      }
    }
  }
  .faceted-nav_delete {
    position: absolute;
    top: 50%;
    right: 6px;
    width:  14px;
    height: 14px;
    margin-top: -8px;
    overflow: hidden;
    background: url('../images/i-filter.svg') no-repeat 0 -11px;
    text-indent: -999em;

    &:hover {
      background-position: -16px -11px;
    }
  }

  .faceted-nav_item--form {
    display: block;
    margin: 0;
    padding-right: 10px;
    overflow: visible;

    .chosen-container {
      font-size: inherit;
    }
    input[type='text'] {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      border: 1px solid #BBB;
      border-radius: 2px;
      padding: .5em;
      font-family: inherit;

      &:focus {
        border-color: $c-hud-search-f-brd;
        box-shadow: 0 0 1px 1px $c-hud-search-f-bsh;
        outline: none;
      }
    }
  }
  .faceted-nav_item--user {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .userpic {
      margin-right: .6em;
      vertical-align: -.45em;
    }
  }
  .faceted-nav_item--local {
    text-decoration: none !important;

    &:link,
    &:visited {
      u {
        background-image: linear-gradient(to right, rgba($c-mainnav-txt, .5) 0, rgba($c-mainnav-txt, .5) 50%, rgba($c-mainnav-txt, 0) 50%, rgba($c-mainnav-txt, 0) 100%);
        background-repeat: repeat-x;
        background-position: 0 97.5%;
        background-size: 5px 1px;
        text-decoration: none;
      }
      &:hover u {
        background-image: linear-gradient(to right, rgba($c-mainnav-h-txt, .66), rgba($c-mainnav-h-txt, .66));
      }
    }
  }


/* List of code reviews
------------------------------ */

.cr-item {
  margin: 20px 0;
}
  .cr-item_time {
    float: right;
    box-sizing: border-box;
    width: 4.5em;
    margin-left: .75em;
    border: 1px solid #EEE;
    border-radius: 2px;
    padding: .7em .25em .65em;
    color: #AAA;
    font-size: .92em;
    line-height: normal;
    text-align: center;
    white-space: nowrap;

    &.is-stale {
      border-color: #F5DFDF;
      color: #D2556A;
    }
  }
  .cr-item_obj {
    margin: 0;
    padding: .5em .75em .5em .5em;
    overflow: hidden;
    background-color: mix($c-content-bg, $c-loc-cmt-bg, 40%);
    color: $c-base;
    line-height: 1.4;

    .userpic {
      float: left;
      margin-right: .75em;
    }
  }
    .cr-item_indicators {
      float: right;
      margin-top: .15em;
      margin-left: 1em;
      color: #AAA;
      font-size: .92em;
      text-shadow: 0 1px 0 #FFF;
    }
      .cr-item_comments,
      .cr-item_issues {
        display: inline-block;
        margin-left: .75em;
      }

    .cr-item_link {
      display: block;
      overflow: hidden;
      color: inherit;
      text-decoration: none !important;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.i-branch::before {
        margin-right: .3em;
      }
      u {
        background-image: linear-gradient(to bottom, rgba($c-base, .2), rgba($c-base, .2));
        background-repeat: repeat-x;
        background-position: 0 97.5%;
        background-size: 1px 1px;
        text-decoration: none;
      }
      &:hover u {
        background-image: linear-gradient(to bottom, rgba($c-link, .66), rgba($c-link, .66));
        color: $c-link;
      }
    }

  .cr-item_meta {
    margin: .5em 0 1em;
    color: $c-mute;
    font-size: .92em;
  }

// Statuses

.cr-item--approved {
  .cr-item_obj {
    background-color: #F5FCEE;
  }
    .cr-item_link {
      @extend .status-label;
      @extend .status-label--ok;

      &::before {
        margin-right: .4em;
        margin-left: .2em;
      }
      u {
        background-image: linear-gradient(to bottom, rgba($c-success, .2), rgba($c-success, .2));
      }
      &:hover u {
        background-image: linear-gradient(to bottom, rgba($c-success, .66), rgba($c-success, .66));
        color: $c-success;
      }
    }
}
.cr-item--cancelled {
  .cr-item_obj {
    background-color: #F7F7F7;
  }
    .cr-item_link {
      @extend .status-label;
      @extend .status-label--disabled-error;

      &::before {
        margin-right: .4em;
        margin-left: .2em;
      }
      u {
        background-image: linear-gradient(to bottom, rgba($c-mute, .33), rgba($c-mute, .33));
      }
      &:hover u {
        background-image: linear-gradient(to bottom, rgba($c-mute, .66), rgba($c-mute, .66));
        color: $c-mute;
      }
    }
}

// Compact view (used in sidebar)

.cr-item--compact {
  margin: 20px 0;

  .cr-item_waiting {
    float: left;
    box-sizing: border-box;
    width: 5.5em;
    margin: .15em 1em 0 0;
    border: 2px solid mix(#FFF, $c-form-status-error-txt, 87.5%);
    border-radius: 3px;
    padding: .75em 0;
    color: mix(#FFF, $c-error, 33%);
    font-size: .92em;
    line-height: 1.33;
    text-align: center;
  }
  .cr-item_obj {
    padding: 0;
    background-color: transparent;
  }
  .cr-item_meta {
    margin: .25em 0 0;
  }
}

