/* Boxed
------------------------------ */

.boxed {
  background-color: #FFF;

  &.separated {
    margin-top: 30px;
  }
  &.single {
    margin-bottom: 30px;
  }
}
  a.action-back {
    position: absolute;
    z-index: $zi-boxed-action-back;
    width:  22px;
    height: 22px;
    margin: 20px 0 0 -22px;
    border: 2px solid $c-form-boxed-brd;
    border-radius: 50% 0 0 50%;
    border-right: 0;
    overflow: hidden;
    background-color: $c-form-boxed-bg;
    text-indent: -9999em;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      margin: -5px 0 0 -2px;
      border: 5px solid transparent;
      border-right-color: #A5A5A5;
      border-left-width: 0;
    }
    &:hover::before {
      border-right-color: #333;
    }
    .boxed.grouped & {
      margin: 17px 0 0 -23px;
    }
  }

  .box {
    @extend .clearfix;

    position: relative;
    margin: 0 0 30px;
    border: 2px solid $c-form-boxed-brd;
    padding: 20px;
    background: none;
    color: $c-form-boxed-txt;

    &:last-child {
      margin: 0;
    }
    &.borderless {
      border: 0;
      padding: 22px;
    }
    &.cancellation {
      border-color: $c-form-boxed-warn-brd;
      background-color: $c-form-boxed-warn-bg;
      color: $c-form-boxed-warn-txt;
    }
    h3 {
      margin: -.25em 0 .75em;
      color: $c-form-boxed-title;
      font-size: 1.2em;

      .meta {
        padding: 0 .25em;
        color: $c-form-boxed-meta;
        font-weight: normal;
      }
    }
    .h-centered {
      margin: -31px 0 20px !important;
      line-height: normal;
      text-align: center;
    }
      .h-centered-wrap {
        padding: 0 1em;
        background-color: $c-form-boxed-bg;
      }
    p {
      strong {
        color: $c-form-boxed-dark;
      }
      strong.mark {
        margin: 0;
        background-color: $c-form-boxed-mark-bg;
        color: $c-form-boxed-mark-txt;
      }
      a {
        text-decoration: underline;
      }
      a:hover {
        color: $c-form-boxed-h;
        text-decoration: none;
      }
      a.mute {
        color: $c-form-boxed-mute;
      }
      &.warning {
        padding-left: 22px;
        background: url('../images/i-error-box.svg') no-repeat 0 center;
        background-size: 15px 13px;
      }
      &.blank {
        margin: 0 !important;
        color: $c-form-boxed-blank;
      }
    }
  }


/* Grouped boxes */

.boxed.grouped {
  border: 2px solid $c-form-boxed-brd;

  .box {
    margin: 0;
    border-width: 0 0 1px;
  }
  .box:last-child {
    border: 0;
  }
}


/* Compare view */

.boxed.boxed--compare {
  min-height: 200px;
  border-color: $c-compare-box-brd;

  .box {
    border-color: $c-compare-box-brd;
    padding: 30px;
  }
  .box--summary {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $c-compare-head-bg;

    .tabs {
      float: left;
      margin-top: .2em;
      margin-left: -.6em;
    }
    .box-aside {
      float: right;
      margin-top: .3em;

      a {
        text-decoration: underline;
      }
      .button {
        margin-top: -.3em;
      }
      .link-external.icon-pending {
        color: $c-dark;
      }
    }
  }
}
