/* Browse
============================== */

.badge-locked {
  display: inline-block;
  margin-left: .5em;
  border-radius: 2px;
  padding: .1em .5em;
  vertical-align: .1em;
  background-color: $c-bdg-locked-bg;
  color: $c-bdg-locked-txt;
  font-size: .75em;

  &::before {
    content: '';

    display: inline-block;
    width: 7px;
    height: 9px;
    margin: 0 .4em 0 .1em;
    background: url('../images/i-lock.svg') no-repeat;
  }
}


/* Icons */

.icon-file,
.icon-dir {
  &::before {
    content: '';

    display: inline-block;
    width:  16px;
    height: 16px;
    margin-right: .4em;
    vertical-align: -.15em;
    background: url('../images/i-browse.png') no-repeat;
    background-size: 14px 33px;

    @include retina {
      background-image: url('../images/i-browse@2x.png');
    }
  }
  &.toolbar-title {
    &::before {
      margin-right: .2em;
      vertical-align: middle;
    }
  }
}
.icon-file {
  &::before {
    background-position: 1px 1px;
  }
}
.icon-dir {
  &::before {
    background-position: 1px -19px;
  }
}


/* Browser: directory listing */

.browser {
  margin: 1.5em 0 3em;
  table-layout: fixed;
}
.browser:last-of-type {
  margin-bottom: 0;
}
  .browser th,
  .browser td {
    padding: .5em 1em;
    vertical-align: baseline;
    font-size: .85em;
    white-space: nowrap;
  }
  .browser th:first-of-type,
  .browser td:first-of-type {
    padding-left: 0;
  }
  .browser th:last-of-type,
  .browser td:last-of-type {
    padding-right: 0;
  }

  .browser th {
    border-bottom: 2px solid $c-tbl-head-brd;
    padding-top: 0;
    padding-bottom: .75em;
    color: $c-tbl-head-txt;
  }
    .browser .sort {
      border-bottom: 1px dashed $c-browse-sort-brd;
      color: inherit;
      text-decoration: none;
    }
    .browser .sort:hover {
      border-bottom: 1px solid $c-browse-sort-h-brd;
      color: $c-browse-sort-h-txt;
    }
    .browser .sort::after {
      display: inline-block;
      padding: 0 0 0 2px;
      font-size: 9px;
      line-height: 9px;
    }
      .browser .s-ascending::after {
        content: '▼';

        vertical-align: baseline;
      }
      .browser .s-descending::after {
        content: '▲';

        vertical-align: 1px;
      }

  .browser td {
    border-color: $c-tbl-brd;
    color: $c-mute;
    line-height: 2.2058;
  }

  /* Name */

  .browser .br-name {
    width: 35%;
  }
  .browser td.br-name {
    overflow: hidden;
    color: $c-base;
    font-size: 1.25em;
    line-height: 1.5;
    text-overflow: ellipsis;
  }
    .browser .br-file .br-name {
      @extend .icon-file;
    }
    .browser .br-dir .br-name {
      @extend .icon-dir;

      font-weight: bold;
    }
    .browser .br-property .br-name {
      width: 50%;
      padding-left: 0;
    }

  /* Revision */

  .browser .br-rev {
    width: 8.5%;
  }
    .browser .br-rev a {
      font-weight: bold;
    }

  /* Author */

  .browser .br-author {
    width: 13%;
  }

  /* Date */

  .browser .br-date {
    width: 10.5%;
  }

  /* Actions */

  .browser .br-action {
    width: 3%;
    text-align: right;
  }

  /* Comment */

  .browser td.br-cmt {
    overflow: hidden;
    text-overflow: ellipsis;

    &.loading {
      &::before {
        @extend %icon-loading;

        content: '';
      }
    }
  }
    .browser .br-cmt ul {
      margin: 0 0 .75em;
      color: $c-dark;
      font-family: $f-monospace-fb;
      line-height: 1.8;
      list-style: none;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
