/* Servers
------------------------------ */

/* Server types */

.label-ftp,
.label-sftp,
.label-s3,
.label-heroku,
.label-shopify,
.label-ssh,
.label-cloud-files,
.label-dreamobjects {
  border-radius: 1px;
  padding: .15em .5em .25em !important;
  color: $c-srv-label-txt;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
  white-space: nowrap;
}
.label-ftp {
  background-color: $c-srv-label-ftp-bg;
}
.label-sftp {
  background-color: $c-srv-label-sftp-bg;
}
.label-s3 {
  background-color: $c-srv-label-s3-bg;
}
.label-heroku {
  background-color: $c-srv-label-heroku-bg;
}
.label-shopify {
  background-color: $c-srv-label-shopify-bg;
  color: $c-srv-label-shopify-txt;
  text-shadow: 0 1px 0 rgba(#000, .4);
}
.label-ssh {
  background-color: $c-srv-label-ssh-bg;
  color: $c-srv-label-ssh-txt;
  text-shadow: 0 1px 0 #000;
}
.label-cloud-files {
  background-color: $c-srv-label-cloudfiles-bg;
}
.label-dreamobjects {
  background-color: $c-srv-label-dreamobj-bg;
}

/* Add a new server */

.server-add {
  position: relative;
  margin: 1.5em 0 1em;
  overflow: hidden;
}
  .server-add p {
    margin: 0;
  }


/* List of servers */

.servers-list {
  margin: 10px -10px 0;
  overflow: hidden;
}
  .servers-list .server {
    position: relative;
    float: left;
    width: 33.3%;

    @media only screen and (max-width: 1020px) {
      width: 50%;
    }
  }

    .servers-list .server .container {
      position: relative;
      min-height: 6.8em;
      margin: 10px;
      border: 1px solid $c-form-boxed-brd;
      border-radius: 3px;
      padding: 1.15em 20px 1.5em;
      background-color: $c-form-boxed-bg-alt;
    }
      .servers-list .server h4 {
        margin: 0 0 .5em;
        overflow: hidden;
        color: $c-form-boxed-blank;
        font-size: .923em;  /* 12px */
        line-height: 1.5em; /* 18px */
        text-overflow: ellipsis;
        white-space: nowrap;
      }
        .servers-list .server h4 .label-ftp,
        .servers-list .server h4 .label-sftp,
        .servers-list .server h4 .label-s3,
        .servers-list .server h4 .label-heroku,
        .servers-list .server h4 .label-ssh,
        .servers-list .server h4 .label-cloud-files {
          display: inline-block;
          margin-right: .33em;
          vertical-align: .15em;
          font-size: 11px;
          line-height: 1.4;
        }
        .servers-list .server h4 .server-name {
          display: inline-block;
          max-width: 65%;
          vertical-align: bottom;
          overflow: hidden;
          color: $c-form-boxed-title;
          font-size: 1.1667em;
          line-height: 1.45em;
          text-overflow: ellipsis;
        }
        .servers-list .server h4 .sep {
          margin: 0 .25em 0 .5em;
          color: rgba($c-form-boxed-blank, .5);
        }
        .servers-list .server h4 .meta {
          color: $c-form-boxed-blank;
          font: .92em $f-monospace-fb;

          .fonts-loaded & {
            font-family: $f-monospace;
          }
        }
        .servers-list .server span[rel='tooltip'] {
          cursor: default;
        }

      .servers-list .server kbd {
        display: block;
        margin: .95em 0 1.3em;
        overflow: hidden;
        color: $c-form-boxed-meta;
        font-size: .85em;
        text-overflow: ellipsis;
        white-space: nowrap;

        .icon-arrow-ar::after {
          margin-left: .4em;
          font-size: 7px;
        }
      }

    .servers-list .server .server-buttons {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 100%;
    }
      .servers-list .server .server-buttons a {
        display: inline-block;
        position: relative;
        margin-right: 4.25%;
        border: 1px solid $c-srv-btn-brd;
        border-radius: 3px;
        padding: .4em 10px .4em 26px;
        background-color: $c-srv-btn-bg;
        color: $c-srv-btn-txt;
        font-weight: normal;
        font-size: .9em;
        text-decoration: none;

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          border-color: $c-srv-btn-h-brd;
          color: $c-srv-btn-h-txt;
        }
        &.link-settings {
          @extend .icon-settings;

          &::before {
            left: 8px;
          }
        }
        &.link-delete {
          &::before {
            content: '';

            position: absolute;
            top: 50%;
            left: 7px;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            background: url('../images/i-delete.svg') no-repeat 2px 1px;
            background-size: 11px 100px;
          }
          &:hover::before {
            background-position: 2px -49px;
          }
        }
      }


/* Server creation: select type */

.type-selector {
  margin-bottom: .75em;
}
  .type-selector p {
    position: relative;
    margin: 0 !important;
    padding: 1em;
    cursor: pointer;
  }
  .type-selector p.selected {
    border-radius: 3px;
    background-color: $c-srv-type-h-bg;
  }
  .type-selector label {
    display: inline-block;
    color: $c-srv-type-txt;
    font-weight: bold;
  }
    .type-selector label input {
      margin: 0 .5em 0 0;
    }
  .type-selector span {
    position: absolute;
    top: 1em;
    right: 1em;
    margin-top: .5em;
    color: $c-srv-type-meta;
    font: italic .9em $f-serif;
  }
  .type-selector strong {
    border-radius: 3px;
    padding-top: .2em;
    padding-bottom: .2em;
  }
