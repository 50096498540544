/* Repositories List
------------------------------ */

.repos-list .color-badge--repo,
.repos-table .color-badge--repo {
  float: left;
  margin-top: .2em;
  margin-right: .5em;
}


/* @ Sidebar */

.repos-list ul {
  margin: 0 0 1em;
  padding: 0 !important;
}
  .repos-list ul li {
    border-bottom: 1px solid $c-repos-list-brd;
    padding: 0 0 .65em !important;
    overflow: hidden;
    list-style: none;
  }
    .repos-list ul li a {
      display: block;
      padding: .65em .5em 26px 0;
      overflow: hidden;
      color: $c-repos-list-l;
      font-weight: bold;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .repos-list ul li p {
      margin: -25px 0 0;
      padding: .25em 0 0 21px;
      color: $c-repos-list-txt;
      font: .85em $f-sansserif;
    }


  /* Status: Disabled */

  .repos-list ul li.status-disabled {
  }
    .repos-list ul li.status-disabled a {
      color: $c-repos-list-dsb-l;
    }
    .repos-list ul li.status-disabled p {
      color: $c-repos-list-dsb-txt;
    }


/* @ Main Column */

.repos-table {
  color: $c-text;

  th,
  td {
    border-bottom: 1px solid $c-tbl-brd;
    padding: .8em .5em;
    vertical-align: middle;
    line-height: 1.45;
  }
  td:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
  thead th,
  thead td {
    border-bottom: 2px solid $c-tbl-head-brd;
    padding: .55em;
    color: $c-tbl-head-txt;
    font-size: .9em;
    white-space: nowrap;
  }

  // Name

  .repo-name {
    a {
      color: $c-dark;
      font-weight: bold;
      text-decoration: underline;
    }
    a:hover {
      color: $c-base;
    }
    .meta {
      color: $c-light;
    }
  }

  // Repository type

  .repo-type {
    width: 5%;
  }
  tbody .repo-type {
    color: $c-tbl-meta-alt;
    font-size: .85em;
  }

  // Repository usage bar

  .repo-usage {
    text-align: right;
  }
  .repo-usage-absolute {
    width: 7.5%;
    text-align: right;
    white-space: nowrap;
  }
  .repo-usage-relative {
    width: 4%;
    color: $c-tbl-meta-alt;
    font-size: .85em;
    line-height: 1.7;
  }

  // Latest activity

  .repo-lasci {
    width: 10%;
    color: $c-tbl-meta;
    text-align: right;
    white-space: nowrap;
  }

  // Actions

  .repo-actions {
    width: 5%;
    color: $c-tbl-meta;
    text-align: right;

    .a-settings {
      vertical-align: middle;
    }
  }
}
