/* Activity Timeline
------------------------------ */

/* Timeline */

.activity-timeline {
  position: relative;
  margin: -30px 0;
  overflow: hidden;

  .toolbar + &,
  .search-form + & {
    margin-top: -15px;
  }
}
#deployments-activity.loading {
  opacity: .5;
}

  .activity-timeline h2 {
    position: relative;
    margin: 0;
    padding: 1.9em 0 2em;
    overflow: hidden;
    color: $c-activity-title;
    font-weight: bold;
    font-size: 1.15em;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: 120px;
      width: 1px;
      background-color: $c-activity-brd;
    }
    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 118px;
      width: 5px;
      height: 5px;
      margin-top: -2px;
      border-radius: 50%;
      background-color: #DDD;
    }

    .weekday {
      float: left;
      width: 110px;
      margin-right: 20px;
      text-align: right;
    }
    .date {
      float: left;
    }
  }

  .rev-item,
  .release-item,
  .no-activity {
    position: relative;
    clear: both;
    overflow: hidden;
  }
    .rev-item h3,
    .rev-item .rev-user,
    .rev-item .rev-repo,
    .release-item .rel-user {
      clear: both;
      float: left;
      width: 115px;
      margin: 0;
      padding: 0;
      text-align: right;
    }
    .release-item .rel-user {
      width: 110px;
      margin-top: .3em;
    }

      /* Item title */

      .rev-item h3 { // TODO: not used (?), remove
        margin: .75em 0 .25em;
        color: $c-activity-txt;
        font-weight: normal;
        font-size: 1em;
      }
        .rev-item h3 a {
          color: $c-activity-txt;
          text-decoration: none;
        }
        .rev-item h3 a:hover {
          text-decoration: underline;
        }


      /* Repository label */

      .rev-repo {
        margin: .7em 0 .4em !important;
        background-color: transparent !important;
        font-weight: bold;
        font-size: .925em;

        .color-badge {
          max-width: 115px;
          margin-right: 0;

          // Compensation for box-shadow
          &.color-badge--white {
            margin-left: 1px;
          }
        }
      }


      /* Revision Author or Deployer */

      .rev-item .rev-user,
      .release-item .rel-user {
        position: relative;
        color: $c-activity-mute;
        font: italic .9em $f-serif;
      }
        .rev-item .rev-user {
          margin-top: .5em;
        }
        .rev-item .rev-user a,
        .release-item .rel-user a {
          color: $c-activity-mute;
        }
        .rev-item .rev-user strong {
          font-style: normal;
          font-weight: normal;
        }


      /* Release timestamp */

      .timestamp {
        position: absolute;
        top: .33em;
        left: 0;
        width: 68px;
        padding: .75em 0;
        color: $c-activity-time;
        font-weight: bold;
        font-size: .85em;
        -webkit-font-smoothing: antialiased;
        text-align: right;
        white-space: nowrap;
      }



    /* No Activity */

    .no-activity p {
      margin: 0 0 0 120px;
      border-left: 1px solid $c-activity-brd;
      padding: .5em;
      color: $c-activity-blank;
      font-style: italic;
    }


    /* Comments */

    .rev-item .rev-comment,
    .release-item .rel-comment {
      margin: 0 0 0 120px;
      border-left: 1px solid $c-activity-brd;
      padding: .25em 0 3em;
    }
    .release-item .rel-comment {
      padding-bottom: 2em;
    }
      .rev-item .rev-comment p,
      .release-item .rel-comment p {
        margin: 0 0 .2em;
        padding: .5em;
        font-size: 1em;
        line-height: 1.5;
      }

      .rev-item .rev-comment p {
        padding-right: 60px;
        background-color: $c-activity-rev-bg;

        a:link,
        a:visited {
          border-bottom: 1px solid $c-activity-rev-l-brd;
          color: $c-activity-l;
          text-decoration: none;
        }
        a:hover {
          border-bottom-color: $c-activity-rev-h-brd !important;
        }
      }


      .release-item .rel-comment p:first-child {
        min-height: 20px; // min-height should match the height of the userpic. Can't use border-sizing because of a bug in FF.
        padding-top: 6px;
        padding-bottom: 6px;
        overflow: hidden;
        background-color: $c-activity-env-bg;

        a:link,
        a:visited {
          border-bottom: 1px solid $c-activity-env-l-brd;
          color: $c-activity-l;
          text-decoration: none;
        }
        a:hover {
          border-bottom-color: $c-activity-env-h-brd;
        }

        b {
          margin: 0 .2em;
        }
        .rel-meta-size {
          float: right;
          margin-bottom: .25em;
          margin-left: 1.5em;
          color: $c-activity-env-meta;
          font-size: .9em;
          line-height: 1.7;
        }
      }


      /* Revision number */

      .rev-item .rev-comment p span.rev-number {
        border: 0;
        padding: .2em;
        font-weight: bold;
      }


      /* Comments count */

      .rev-item .rev-comment p.rev-comments-count {
        position: absolute;
        top: .7em;
        right: .7em;
        width: 60px;
        margin: 0;
        padding: 0;
        text-align: right;
      }
        .rev-item .rev-comment p.rev-comments-count a {
          @extend .i-comments-counter;

          border: 0;
          color: $c-activity-comment-txt;
          text-shadow: 0 1px 0 #FFF;
        }
        .rev-item .rev-comment p.rev-comments-count a:hover {
          background-position: 0 -47px;
          color: $c-activity-comment-h-txt !important;
        }


      /* Meta info about commit */

      .rev-item .rev-comment .rev-meta-info,
      .release-item .rel-comment .rel-action {
        background-color: $c-content-bg !important;
        color: $c-activity-meta-txt;
        font-size: .9em;
      }
      .rev-item .rev-comment .rev-meta-info {
        padding-top: .33em;

        a:link,
        a:visited {
          display: inline-block;
          height: 1.3em;
          border-color: mix($c-content-bg, $c-activity-meta-l, 70%);
          color: $c-activity-meta-l;
        }
      }


      /* Release action */

      .release-item .rel-comment .rel-action {
        margin: -3px 0 0;
        overflow: hidden;

        a.button {
          margin-right: .75em;
        }
      }
      .release-item .rel-comment .rel-action.loading {
        &::before {
          @extend %icon-loading;
          @extend %icon-loading--medium;

          content: '';
        }
        a {
          visibility: hidden;
        }
      }


      /* Revision changeset short block */

      .rev-changeset-short {
        border-bottom: 1px solid $c-content-bg;
        border-left: 0;

        a {
          color: $c-link;
        }
        p {
          background-color: transparent !important;

          a:link,
          a:visited {
            font-weight: bold;
            font-size: .9em;
          }
        }
      }
        ul.changeset {
          margin: .25em .5em;
          padding: 0;
          font-size: .9em;

          li {
            padding: .33em 0 0 3px;
            color: #666;
            white-space: nowrap;
            list-style: none;

            &::before {
              margin-right: .4em;
              vertical-align: -.3em;
            }
          }
          .link-action {
            margin-left: .5em;
            color: #BBB;
            font-weight: normal;
            font-size: .8em;
          }
          .meta {
            color: #BBB;
            font-weight: normal;
            font-size: .9em;
          }
        }


  // Expandable commit messages

  a.rev-comment-extended-show {
    @extend .icon-text-expand;
  }

  a.rev-comment-extended {
    display: block;
    border: 0 !important;
    padding: .4em 0 .25em;
    color: mix(#000, $c-blue-lightest, 75%);
    font-size: .9231em;
    line-height: 1.4;

    &:hover {
      color: #000;
    }
  }


  /* Service links */

  a.link-lighthouse,
  a.link-harvest,
  a.link-fogbugz,
  a.link-zendesk,
  a.link-sifter,
  a.link-jira,
  a.link-done-done,
  a.link-asana,
  a.link-groove,
  a.link-pivotal {
    margin-left: .5em;
    border: 0 !important;
    border-radius: 2px;
    padding: .25em .5em;
    font-weight: bold;
    font-size: .7em;
    text-decoration: none !important;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #FFF;
    white-space: nowrap;
  }
  a.link-lighthouse {
    background-color: $c-label-int-lh-bg !important;
    color: $c-label-int-lh-txt !important;
  }
  a.link-harvest {
    background-color: $c-label-int-hv-bg !important;
    color: $c-label-int-hv-txt !important;
  }
  a.link-fogbugz {
    background-color: $c-label-int-fb-bg !important;
    color: $c-label-int-fb-txt !important;
  }
  a.link-zendesk {
    background-color: $c-label-int-zd-bg !important;
    color: $c-label-int-zd-txt !important;
  }
  a.link-sifter {
    background-color: $c-label-int-sf-bg !important;
    color: $c-label-int-sf-txt !important;
  }
  a.link-jira {
    background-color: $c-label-int-jr-bg !important;
    color: $c-label-int-jr-txt !important;
  }
  a.link-done-done {
    background-color: $c-label-int-dd-bg !important;
    color: $c-label-int-dd-txt !important;
  }
  a.link-asana {
    background-color: $c-label-int-as-bg !important;
    color: $c-label-int-as-txt !important;
    text-shadow: 0 1px 0 rgba(#000, .2);
  }
  a.link-groove {
    background-color: $c-label-int-gr-bg !important;
    color: $c-label-int-gr-txt !important;
    text-shadow: 0 1px 0 rgba(#000, .2);
  }
  a.link-pivotal {
    background-color: $c-label-int-pt-bg !important;
    color: $c-label-int-pt-txt !important;
    text-shadow: 0 1px 0 rgba(#000, .2);
  }


  /* Release States */

  .release-item .status {
    margin-left: .5em;
    border: 0 !important;
    border-radius: 2px;
    padding: .15em .5em .25em;
    font-weight: bold;
    font-size: .85em;
    text-transform: uppercase;
    white-space: pre;
  }

    /* Status: Pending */

    .release-item.status-pending .rel-comment p:first-child {
      padding-left: 30px;
      background-image: url('../images/anim-deploying.gif');
      background-repeat: no-repeat;
      background-position: 6px .75em;
      background-size: 17px 11px;
      color: $c-activity-pending-txt;
    }
    .release-item.status-pending .rel-comment p .rel-meta-size {
      color: $c-activity-pending-meta;
    }
    .release-item.status-pending span.status {
      background-color: $c-activity-pending-bdg-bg;
      color: $c-activity-pending-bdg-txt;
    }
    .release-item.status-pending a.link-progress {
      margin-left: .5em;
      font-size: .85em;
      text-transform: uppercase;
    }

    /* Status: Waiting */

    .release-item.status-waiting .rel-comment p:first-child {
      color: $c-activity-pending-txt;
    }
    .release-item.status-waiting .rel-comment p .rel-meta-size {
      color: $c-activity-pending-meta;
    }
    .release-item.status-waiting span.status {
      background-color: $c-activity-pending-bdg-bg;
      color: $c-activity-pending-bdg-txt;
    }

    /* Status: Failed */

    .release-item.status-failed .rel-comment p:first-child {
      @extend .icon-warning;
      @extend .icon-warning--red;

      color: $c-activity-failed-txt;
    }
    .release-item.status-failed span.status {
      background-color: $c-activity-failed-bdg-bg;
      color: $c-activity-failed-bdg-txt;
    }
    .release-item.status-failed h3 {
      color: $c-activity-failed-title;
    }
    .release-item.status-failed .rel-comment p .rel-meta-size {
      color: $c-activity-failed-meta;
    }

    /* Status: Bypassed & Canceled */

    .release-item.status-skipped .rel-comment p:first-child,
    .release-item.status-canceled .rel-comment p:first-child {
      background-color: $c-activity-cancel-bg;
      color: $c-activity-cancel-txt;
    }
    .release-item.status-skipped span.status,
    .release-item.status-canceled span.status {
      background-color: $c-activity-cancel-bdg-bg;
      color: $c-activity-cancel-bdg-txt;
    }
    .release-item.status-skipped .rel-comment p:first-child a[rel='help'],
    .release-item.status-canceled .rel-comment p:first-child a[rel='help'] {
      color: $c-activity-cancel-txt;
      font-size: .9em;
    }
    .release-item.status-skipped .rel-comment p .rel-meta-size,
    .release-item.status-canceled .rel-comment p .rel-meta-size {
      color: $c-activity-cancel-meta;
    }


/* Compact activity */

.activity-timeline--compact {
  h2:not(:first-child) {
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    span.date {
      top: 1.5em;
    }
  }
  .activity-date {
    left: -110px;
    width: 110px;
    margin: 0 0 -2em;
    font-size: 1.1em;
    text-align: right;

    sup {
      position: relative;
      top: -.33em;
      margin-left: .2em;
      vertical-align: baseline;
      font-weight: normal;
      font-size: .75em;
      -webkit-font-smoothing: antialiased;
      line-height: 0;
    }
  }
  .rev-item {
    .rev-comment {
      padding-bottom: 1.5em;

      p {
        padding-right: .5em;
      }
        a.empty-msg {
          color: $c-activity-rev-empty;
          font-style: italic;

          &:hover {
            border-bottom-color: $c-activity-rev-empty !important;
          }
        }

        .userpic {
          margin-right: .25em;
          vertical-align: -.4em;
        }
    }
    &:last-child {
      .rev-comment {
        padding-bottom: 3em;
      }
    }
    .rev-user {
      text-align: left;
    }
  }
  .link-file-rev {
    float: right;
    margin: .5em .5em 0 2em;
    border: 1px solid rgba($c-link, .4);
    border-radius: 2px;
    padding: 0 .5em;
    color: rgba($c-link, .6);
    font-size: .923em;

    &:hover {
      border-color: $c-link;
      background-color: $c-link;
      color: #FFF;
      text-decoration: none;
    }
  }
}


/* Activity Options */

.activity-options {
  position: absolute;
  top: 2.25em;
  right: 0;
  z-index: $zi-activity-options;
}
  .activity-options span {
    display: inline-block;
  }
  .activity-options a.rss-feed {
    padding-left: 17px;
    background: url('../images/i-rss.svg') no-repeat 0 center;
    background-size: 12px 12px;
    color: $c-rss-txt;
    font-weight: bold;
  }
