// Access Tokens

.tokens {
  margin: 1.5em 0;
}
  .token-item {
    margin-bottom: 1.5em;
    list-style: none;

    form {
      display: inline;
    }
    .a-delete {
      margin-right: 20px;
      margin-left: -30px;
    }
  }
    .token-code {
      display: inline-block;
      width: 32em;
      border: 1px solid #E6E6E6;
      border-radius: 2px;
      padding: .66em 1em .6em;
      padding-right: 37px;
      color: #333;
      box-shadow: 0 1px 1px rgba(#000, .05);
      font-family: $f-monospace-fb;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
      &:hover {
        background-color: mix(#FFF, $c-yellow-lightest, 33%);
      }
    }
    .token-label {
      margin: 0 -.33em;
      padding: .2em .33em;
      color: #666;

      &:hover {
        background-color: $c-yellow-light;
      }
    }
    .token-rename {
      display: inline-block;

      input[type='text'] {
        padding: .25em .3em;
        color: #666;
        font-family: $f-sansserif;
      }
    }
