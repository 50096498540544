/* Preview
------------------------------ */

%btn-preview {
  background-image: url('../images/btn-preview.svg');
  background-repeat: no-repeat;
  background-size: 80px 64px;
}

body#preview {
  height: 100%;
  min-height: 0;
  background: transparent;
}
body#preview.main-window {
  @extend %bg-checkboard;

  background-color: $c-prvw-bg;
}
#preview table {
  width: 100%;
  height: 100%;
}
  #preview table td {
    border: 0;
    vertical-align: middle;
    text-align: center;
  }
    #preview table td img {
      display: block;
      margin: 0 auto;
    }

#preview b.divider {
  position: absolute;
  top: 0;
  z-index: $zi-preview-divider;
  width: 2px;
  height: 2px;
  background-color: $c-prvw-divider;
}


/* Bar */

#bar {
  height: 48px;
  overflow: hidden;
}
  #bar a.link-beanstalk {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $zi-preview-beanstalk;
    width: 132px;
    height: 48px;
    border-right: 1px solid rgba(#000, .15);
    background: $c-green-light linear-gradient(to bottom, $c-green-light, $c-green-medium);
    text-indent: -9999em;

    &::before {
      content: '';

      position: absolute;
      top: 6px;
      left: 13px;
      width: 100px;
      height: 31px;
      background: url('../images/bs-logo@2x.png') no-repeat;
      background-size: 100px 62px;
    }
  }
  #bar .line {
    position: fixed;
    bottom: 47px;
    left: 0;
    z-index: $zi-preview-line;
    width: 100%;
    height: 1px;
    background-color: rgba(#000, .15);
  }

  /* Add Revision Button */

  #revision-add {
    position: fixed;
    right: 246px;
    bottom: 0;
    z-index: $zi-preview-add;
    width: 45px;
    height: 48px;
    background: #333 linear-gradient(to bottom, #1D1D1D, #333);
  }
    #revision-add a {
      display: block;
      position: relative;
      width: 45px;
      height: 48px;
      border-left: 1px solid rgba(#FFF, .1);
      text-indent: -9999em;

      &::before {
        @extend %btn-preview;

        content: '';

        position: absolute;
        top:  16px;
        left: 14px;
        width:  16px;
        height: 16px;
        background-position: 0 0;
      }
    }
    #revision-add a:hover::before {
      background-position: 0 -16px;
    }
    #revision-add a:active::before {
      background-position: 0 -32px;
    }
    #revision-add a.limit-reached::before {
      background-position: 0 -48px;
      cursor: default;
    }


  /* Toolbar Adjustements */

  #bar .page-toolbar {
    display: inline-block;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: $zi-preview-toolbar;
    height: 36px;
    border: 0;
    padding: 5px 10px 6px;
    overflow: hidden;
    background-color: $c-prvw-mode-bg;

    a {
      margin-right: 1em;
      color: $c-prvw-mode-txt !important;
    }
    a:last-child {
      margin-right: 0;
    }
    .separator {
      float: left;
      height: 30px;
      margin: 3px .75em 3px 0;
      border-right: 1px solid $c-prvw-mode-sep-alt;
      border-left:  1px solid $c-prvw-mode-sep;
      text-indent: -10000px;

      &.aligned {
        margin-right: 0;
        margin-left: .85em;
      }
    }

    /* Buttons */

    .button {
      float: left;
      margin: 7px 5px;

      &.activated {
        border-color: #06C;
        color: #06C !important;
        box-shadow: 0 0 1px 1px rgba(#06C, .33);
      }
    }
  }

    /* View Button */

    #bar .page-toolbar a.view-default,
    #bar .page-toolbar a.view-split,
    #bar .page-toolbar a.view-overlay {
      position: relative;
      float: left;
      width: 31px;
      height: 20px;
      margin: 7px 0 0;
      border: 1px solid #999;
      background: #DDD linear-gradient(to bottom, #FFF, #DDD 95%, #CCC);
      text-indent: -9999em;
      cursor: pointer !important;

      &.active {
        background: #CFCFCF linear-gradient(to bottom, rgba(#808080, .8), #C0C0C0 20%, #CFCFCF);
      }
      &::before {
        @extend %btn-preview;

        content: '';

        position: absolute;
        top:  (20px - 10px) / 2;
        left: (31px - 11px) / 2;
        width:  11px;
        height: 10px;
      }
    }
    #bar .page-toolbar a.view-default {
      border-radius: 2px 0 0 2px;
      border-right: 0;

      &::before,
      &.active:hover::before {
        background-position: -32px 0;
      }
      &:hover::before {
        background-position: -32px -16px;
      }
    }
    #bar .page-toolbar a.view-split {
      &::before,
      &.active:hover::before {
        background-position: -48px 0;
      }
      &:hover::before {
        background-position: -48px -16px;
      }
    }
    #bar .page-toolbar a.view-overlay {
      margin-right: 5px;
      border-radius: 0 2px 2px 0;
      border-left: 0;

      &::before,
      &.active:hover::before {
        background-position: -64px 0;
      }
      &:hover::before {
        background-position: -64px -16px;
      }
    }

  /* Revision List */

  #revision-list {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $zi-preview-list;
    height: 48px;
    overflow: hidden;
    background: #333 linear-gradient(to bottom, #1D1D1D, #333);
  }
    #revision-list .item {
      position: relative;
      float: left;
      width: 200px;
      height: 48px;
      padding: 16px 0 0;
      background: #444 linear-gradient(to bottom, #3B3B3B, #444 10%, #333);
      color: $c-prvw-refs-txt;
      font-size: .85em;
      text-decoration: none;
      cursor: pointer;
    }
      #revision-list .item strong {
        display: inline-block;
        margin: 0 .5em 0 15px;
        border-radius: 2px;
        padding: 0 .3em;
        background-color: $c-prvw-refs-num-bg;
        color: $c-prvw-refs-num-txt;
      }
      #revision-list .item a.link-update {
        position: absolute;
        top: 18px;
        left: 40px;
        max-width: calc(100% - 40px - 40px);
        overflow: hidden;
        color: $c-prvw-refs-l-txt;
        line-height: 1.1;
        text-decoration: underline;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      #revision-list .item a.link-update:hover {
        border: 0;
      }
      #revision-list .item a.link-update.disabled {
        border: 0 !important;
      }
      #revision-list .item .meta {
        color: #777;
      }
      #revision-list .item a.link-close {
        @extend %btn-preview;

        display: none;
        position: absolute;
        top: 16px;
        right: 12px;
        z-index: $zi-preview-list-close;
        width:  16px;
        height: 16px;
        background-position: -16px 0;
        text-indent: -9999em;
      }
      #revision-list .item:hover a.link-close {
        display: block;
      }
      #revision-list .item b.shadow-l,
      #revision-list .item b.shadow-r {
        position: absolute;
        top: 0;
        z-index: $zi-preview-list-shadow;
        width: 5px;
        height: 48px;
      }
      #revision-list .item b.shadow-l {
        display: none;
        left: -5px;
        background-image: linear-gradient(to right, transparent, rgba(#000, .2));
      }
      #revision-list .item b.shadow-r {
        right: -5px;
        z-index: $zi-preview-list-shadow-r;
        background-image: linear-gradient(to left, transparent, rgba(#000, .2));
      }

    /* Selected */

    #revision-list .item.selected {
      background: #DDD linear-gradient(to bottom, #FFF, #EEE 33%, #CCC);
      color: $c-prvw-refs-txt;
    }
      #revision-list .item.selected strong {
        background-color: $c-prvw-refs-cur-num-bg;
        color: $c-prvw-refs-cur-num-txt;
      }
      #revision-list .item.selected a.link-update {
        border-color: $c-prvw-refs-cur-l-brd;
        color: $c-prvw-refs-cur-l-txt;
      }
      #revision-list .item.selected .meta {
        color: #888;
      }
      #revision-list .item.selected b.shadow-l {
        display: block;
      }

    /* Disabled */

    #revision-list .item.disabled {
      background: transparent none;
      color: $c-prvw-refs-dsb-txt;
      cursor: default;
    }
      #revision-list .item.disabled strong {
        background-color: $c-prvw-refs-dsb-num-bg;
        color: $c-prvw-refs-dsb-num-txt;
      }
      #revision-list .item.disabled a.link-update {
        border-color: $c-prvw-refs-dsb-l-brd;
        color: $c-prvw-refs-dsb-l-txt;
      }
      #revision-list .item.disabled a.link-close {
        background-position: -16px -16px;
      }
      #revision-list .item.disabled b.shadow-l {
        display: none;
      }
