/* Deployment Details and Release Notes
---------------------------------------- */

/* Summary */

.rel-summary {
  margin: 30px 0;
  overflow: hidden;
}
  .rel-summary p {
    margin: 0;
  }
    .rel-summary p a {
      text-decoration: underline;
    }

  .rel-summary p.rel-info {
    padding-left: 35px;
    font-size: 1.4em;
  }
    .rel-summary p.rel-info strong a {
      color: $c-release-title-l;
      font-weight: bold;
    }
    .rel-summary p.rel-info strong .color-badge--env {
      margin-right: .35em;
      margin-left: .25em;
      vertical-align: -.05em;
    }

    .rel-summary p.rel-info .meta {
      margin-left: .75em;
      color: $c-release-title-blank;
      font-size: .75em;
      text-transform: uppercase;
    }
    .rel-summary p .userpic {
      margin-right: .3em;
      margin-left: -35px;
      vertical-align: -.3em;
    }


  .rel-summary p.rel-commits {
    margin: .6em 0 .5em 35px;
    color: $c-release-title-meta;
    font-size: .95em;
  }
    .rel-summary p.rel-commits span.branch {
      padding-left: 13px;
      background: url('../images/i-branch.svg') no-repeat 0 3px;
      color: $c-release-title-branch;
      font-weight: bold;
    }
    .rel-summary p.rel-commits strong {
      margin: 0 .15em;
      color: $c-text;
    }
    .rel-summary p.rel-commits .sep {
      margin: 0 .75em;
      font-size: .85em;
    }
    .rel-summary p.rel-commits .range {
      margin: 0 .25em;
    }
    .rel-summary p.rel-commits a.commits-range {
      color: #333;

      &:hover {
        color: #000;
      }
    }
    .rel-summary p.rel-commits .meta {
      margin-left: .5em;
    }

    .rel-summary a.link-lighthouse,
    .rel-summary a.link-harvest,
    .rel-summary a.link-fogbugz,
    .rel-summary a.link-zendesk,
    .rel-summary a.link-sifter,
    .rel-summary a.link-jira,
    .rel-summary a.link-done-done,
    .rel-summary a.link-asana,
    .rel-summary a.link-groove,
    .rel-summary a.link-pivotal {
      margin-right: .5em;
      margin-left: 0;
      font-size: .75em;
    }

  .rel-summary p.rel-comment {
    position: relative;
    margin: 1.4em 0 .5em;
    border: 1px solid $c-release-comment-brd;
    border-radius: 5px;
    padding: 1.75em 1.45em 1.75em 2.9em;
    background-color: $c-release-comment-bg;
    color: $c-release-comment-txt;
    line-height: 1.6;

    &::before,
    &::after {
      content: '';

      position: absolute;
      left: 30px;
      width: 0;
      height: 0;
      margin-left: -7px;
      border: 7px solid transparent;
      border-top-width: 0;
    }
    &::before {
      top: -7px;
      border-bottom-color: $c-release-comment-brd;
    }
    &::after {
      top: -6px;
      border-bottom-color: $c-release-comment-bg;
    }
  }


/* Deployment Details */

.deployment-details {
}
  .deployment-details .options,
  .content--compare .options {
    margin: 1.25em 0 .25em;
  }
  a.link-more {
    display: inline-block;
    border-radius: 3px;
    padding: .5em 1.2em .6em;
    background-color: $c-release-more-bg;
    font-weight: bold;
    font-size: .9em;
    text-decoration: underline;
  }
  a.link-more:hover {
    background-color: $c-release-more-h-bg;
  }


/* Deployed Revisions */

table.tbl-release {
  width: 100% !important;

  th,
  td {
    border-bottom: 0;
    padding: .6em 1.5em .6em 0;
    vertical-align: top;
    color: $c-mute;
    white-space: nowrap;
  }
  thead th,
  thead td {
    border-bottom: 2px solid $c-tbl-head-brd;
    padding: .4em 1.35em .4em 0;
    color: $c-tbl-head-txt;
    font-size: .85em;
  }
  tbody tr:last-child th,
  tbody tr:last-child td {
    border-bottom: 0;
  }
  tbody a {
    border-width: 0 0 1px;
    border-style: solid;
    text-decoration: none;
  }
  .tbl-rel-rev {
    width: 5em;

    a {
      border-color: $c-release-tbl-l-brd;
      color: $c-release-tbl-l;
    }
    a:hover {
      border-color: $c-release-tbl-h-brd;
    }
  }
  .tbl-rev-user {
    width: 10%;
    font-style: italic;
    font-family: $f-serif;
  }
  .tbl-rev-date {
    width: 5em;
  }
  .tbl-rel-cmt-num {
    width: 7.5em;

    a {
      border-width: 0;
      color: inherit;
    }
  }
  .tbl-rev-comment {
    padding-right: 0;
    white-space: normal;

    a {
      border-color: $c-release-tbl-alt-l-brd;
    }
    a:hover {
      border-color: $c-release-tbl-alt-h-brd;
    }
    .status-label--ok a {
      border-color: rgba($c-form-status-ok-txt, .33);
    }
    .status-label--ok a:hover {
      border-color: $c-form-status-ok-txt;
    }
      .meta {
        margin-left: 1em;
      }
      .rev-comment-extended {
        color: mix(#FFF, $c-link, 20%);
        font-size: .9em;
      }
      .rev-comment-extended:hover {
        color: $c-link;
      }
  }
}


/* Deployed Tickets */

table.tbl-tickets {
  td {
    border: 0;
    padding: .6em 1.5em .6em 0;
  }
  .tbl-tkt-num {
    a {
      margin-left: 0;
      font-size: .8em;
    }
  }
  .tbl-tkt-title {
    width: 95%;

    a {
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $c-release-tbl-alt-l-brd;
      text-decoration: none;
    }
    a:hover {
      border-color: $c-release-tbl-alt-h-brd;
    }
  }
}


/* Deployed Files & Directories */

.release-files {
  margin: 0 -20px;
  overflow: hidden;

  p {
    display: block;
    margin: 0 20px 1em;
    border-bottom: 2px solid $c-tbl-head-brd;
    padding: .4em 0;
    color: $c-tbl-head-txt !important;
    font-size: .85em;

    .sep {
      margin: 0 1em;
      color: mix($c-content-bg, $c-tbl-head-txt, 75%);
    }
  }
  ul {
    float: left;
    width: 50%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: #999;

    li {
      margin: 0 20px;
      padding: 0;
      overflow: hidden;
      font: normal .85em/2.3 $f-monospace-fb;
      text-overflow: ellipsis;
      white-space: nowrap;
      list-style: none;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
      &.vcs-deleted::before {
        vertical-align: -.3em;
      }
      a {
        color: $c-dark;
      }
      a[href^='#'] {
        border-bottom: 1px dashed #C9C9C9;
        text-decoration: none;

        &:hover,
        &:active {
          border-bottom-style: solid;
        }
      }
    }
  }
}
