/* Integration congrats
------------------------------ */

.int-activated {
}
  #specialPage #page fieldset.int-activated legend {
    padding-left: 16px;
    background: url('../images/i-status.svg') no-repeat 0 5px;
    background-size: 11px 200px;
    color: $c-form-status-ok-txt;
  }

  .int-activated .repo-name {
    margin: 0 .2em;
    color: $c-dark;

    .color-badge {
      margin-right: .1em;
      margin-left:  .25em;
      vertical-align: -.15em;
    }
  }

  .int-activated .int-card {
    height: 170px;
    margin: 20px 0 30px;
  }
    .int-activated .int-card .logo {
      background-color: $c-int-page-bg;
      transform-style: flat;
    }

  .int-activated p.int-help {
    position: absolute;
    bottom: 0;
    z-index: $zi-int-help;
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
    .int-activated p.int-help a {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

  .int-activated .form-row {
  }
    .int-activated .form-row input[type='checkbox'] {
      float: left;
      margin: .33em 0 0;
    }
    .int-activated .form-row label {
      display: block;
      margin-left: 1.6em;
      line-height: 1.6;
    }
      .int-activated .form-row label .meta {
        display: block;
        color: $c-mute;
      }
      .int-activated .form-row label .meta a {
        color: $c-text;
        text-decoration: underline;
      }


/* Help blocks */

.integr-field {
  overflow: hidden;
}

.integr-help {
  padding: 0 0 1em;
  overflow: hidden;
}
  .integr-help h3 {
    margin: 0 0 .75em;
    color: $c-dark;
    font-size: 1.1em;
  }
  .integr-help p {
    margin: 0 0 1em !important;
    color: $c-text;
  }
  .integr-help code {
    color: $c-text;
  }
  .integr-help img {
    float: right;
    margin: .25em 0 .6em 1em;
    border: 1px solid $c-int-help-img-brd;
    padding: 1px;
  }


/* OAuth Signin */

.oauth-signin {
  padding: 40px 0 80px;
  text-align: center;
}
  .oauth-signin .meta {
    display: block;
    margin: -.5em 0 1.5em;
    color: $c-int-oauth-meta;
    font: italic 1em $f-serif;
  }
  .oauth-signin a {
    color: $c-int-oauth-l;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.2;
    text-decoration: underline;
  }
  .oauth-signin a:hover {
    color: $c-int-oauth-h;
  }


/* Assign Users */

table.assign-users {
  margin: 20px 0;
  border: 0;
  padding: 0;

  .empty {
    color: $c-mute;
  }
  th,
  td {
    border: 0;
    padding: .75em 0 .75em 10px;
  }
  tr > :first-child {
    padding-left: 20px;
  }
  tr > :last-child {
    padding-right: 20px;
  }
  .user-bs,
  .user-assigned,
  .user-token {
    width: 45%;
  }
  .user-action {
    width: 100px;
    text-align: right;
  }
  .userpic {
    margin-right: .25em;
    vertical-align: -.25em;
  }
  input[type='text'] {
    @extend .fullwidth;

    margin: 0;
    padding: .2em;
    font-family: $f-sansserif;
  }
  select {
    @extend .fullwidth;

    margin: .25em 0;
  }
  thead th,
  thead td {
    border-bottom: 2px solid $c-tbl-brd;
    color: $c-text;
    font-weight: bold;
  }
  tbody th,
  tbody td {
    border-bottom: 1px solid $c-tbl-brd;
    vertical-align: middle;
    background-color: $c-tbl-bg;
  }
  tfoot th,
  tfoot td {
    border-top: 2px solid $c-tbl-brd;
    padding-top: 1em;
  }
}
