/* HUD
------------------------------ */

$hud-space: 18px;

.hud {
  position: absolute;
  z-index: $zi-hud;
  height: auto;
  border: 1px solid $c-hud-brd;
  border-radius: 5px;
  background: rgba($c-hud-bg, .98);
  color: $c-hud-txt;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .2);

  &::before,
  &::after {
    content: '';

    position: absolute;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
  }
}

// Arrow

.hud.below-element {
  &::before,
  &::after {
    border-top-width: 0;
  }
  &::before {
    top: -5px;
    border-bottom-color: $c-hud-brd;
  }
  &::after {
    top: -4px;
    border-bottom-color: $c-hud-bg;
  }
}
.hud.above-element {
  &::before,
  &::after {
    border-bottom-width: 0;
  }
  &::before {
    bottom: -5px;
    border-top-color: $c-hud-brd;
  }
  &::after {
    bottom: -4px;
    border-top-color: $c-hud-bg;
  }
}
.hud.align-left {
  &::before,
  &::after {
    left: 16px;
  }
}
.hud.align-middle {
  &::before,
  &::after {
    left: 50%;
  }
}
.hud.align-right {
  &::before,
  &::after {
    right: 16px;
  }
}

// Warning HUD

.hud--warning {
  border-color: $c-hud-warning-brd;
  background-color: $c-hud-warning-bg;
  color: $c-text;

  &.below-element {
    &::before {
      border-bottom-color: $c-hud-warning-brd;
    }
    &::after {
      border-bottom-color: $c-hud-warning-bg;
    }
  }
  &.above-element {
    &::before {
      border-top-color: $c-hud-warning-brd;
    }
    &::after {
      border-top-color: $c-hud-warning-bg;
    }
  }
}

// Size

.hud.size-small {
  width: 220px;
}
.hud.size-large {
  width: 410px;
}

  .hud .wrapper {
    margin: $hud-space;
  }

  .hud .wrapper.loading {
    position: relative;
    min-height: 30px;
    text-indent: -9999em;

    &::before {
      @extend %icon-loading;
      @extend %icon-loading--medium;
      @extend %icon-loading--medium-centered;

      content: '';
    }
  }
    .hud .wrapper.loading div,
    .hud .wrapper.loading ul,
    .hud .wrapper.loading p {
      display: none;
    }


  // Content

  .hud p {
    margin: 0 0 1em;
    padding: 0;
    color: $c-hud-mute;
    font-size: .85em;
  }
    .hud p strong {
      color: $c-hud-dark;
    }

  // We still specify styles for fieldset on specialpage, so reset is needed
  #specialPage .hud fieldset {
    margin: 0;
    border: 0;
    padding: 0;
  }

  .hud .form-row,
  .hud .form-row.form-row--compact {
    margin-bottom: 1em;

    &.l-vertical {
      .form-label {
        color: #999;
        font-size: .9em;
      }
      input[type='text'] {
        margin: 0;
        border: 1px solid #CCC;
        padding: .5em;
        font: 1em inherit;

        &:focus {
          border-color: $c-hud-search-f-brd;
          box-shadow: 0 0 1px 1px $c-hud-search-f-bsh;
          outline: none;
        }
      }
      .error-msg {
        padding-top: .75em;
        font-weight: normal;
      }
    }
  }


  /* Share Preview */

  #share-form {
    label {
      display: block;
      border-radius: 3px;
      padding: .5em;
      background-color: $c-hud-alt-bg;
      color: $c-hud-txt;
      font-size: .85em;
      text-align: center;
      cursor: pointer;

      &.disabled {
        color: $c-hud-dsb-txt;
      }
      input[type='checkbox'] {
        vertical-align: middle;
      }
    }
    .form-buttons {
      margin-top: $hud-space;
      border-top: 1px solid $c-hud-inside-brd;
      padding-top: $hud-space/2;
      text-align: center;
    }
  }
    #share-url {
      display: block;
      width: 100%;
      margin: 0 0 1em;
      border: 0;
      border-radius: 3px;
      padding: .6em 0;
      background-color: $c-hud-url-bg;
      color: $c-hud-url-txt;
      font: normal .92em/1.5 $f-monospace-fb;
      text-align: center;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
      &:focus {
        background-color: $c-hud-url-f-bg;
        color: $c-hud-url-f-txt;
        outline: none;
      }
    }


  // Revision Search

  .ref-search {
    margin: 0 2px ($hud-space/2);

    .ref-search-wrap {
      position: relative;
    }
      .ref-search-field {
        @extend .fullwidth;

        border: 1px solid $c-hud-search-brd;
        border-radius: 2px;
        padding: .5em 45px .6em 10px;
        color: $c-hud-txt;
        box-shadow: 0 1px 2px rgba(#000, .05) inset;
        font-family: inherit;

        &:hover {
          border-color: $c-hud-search-h-brd;
        }
        &:focus {
          border-color: $c-hud-search-f-brd;
          box-shadow: 0 0 1px 1px $c-hud-search-f-bsh;
          outline: none;
        }
      }
      .action-search,
      .action-clear {
        position: absolute;
        top: 50%;
        z-index: $zi-hud-action;
        width: 16px;
        height: 16px;
        margin: -8px 0 0;
        border: 0;
        padding: 0;
        background: url('../images/bg-hud-search.svg') no-repeat;
        text-indent: -999em;
        cursor: pointer;
      }
      .action-search {
        right: 8px;
        background-position: 0 0;
      }
      .action-clear {
        right: 24px;
        background-position: -16px 0;
      }
    .ref-search-filter {
      margin: .5em 0 $hud-space !important;

      .halfwidth {
        width: 48%;

        &:first-child {
          margin-right: 3%;
        }
      }
    }
    .ref-search-filter--with-mode {
      position: relative;
      padding-right: 45px;
    }
      .ref-filter-mode {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -8px;
        line-height: 1;
      }
  }

  // Reference Tabs

  .ref-tabs {
    @extend .clearfix;

    position: relative;
    margin: (-$hud-space) (-$hud-space) $hud-space;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid $c-hud-brd;
    padding: ($hud-space/2) $hud-space 0;
    list-style: none;
  }
    .ref-tab {
      float: left;
      margin: 0 5px -1px 0;
      border: 1px solid $c-hud-tab-brd;
      border-radius: 3px 3px 0 0;
      border-bottom-color: $c-hud-tab-cur-brd;
      color: $c-hud-tab-txt;
      font-size: .9em;
      white-space: nowrap;

      a {
        display: inline-block;
        padding: .3em 1em;
        color: inherit;
        text-decoration: none;
      }
        .count {
          margin-left: .25em;
          color: $c-hud-tab-meta;
          font-size: .9em;
        }
        .count:empty {
          display: none;
        }
      a:hover {
        color: $c-hud-tab-h-txt;

        .count {
          color: $c-hud-tab-h-meta;
        }
      }
      &.current {
        border-color: $c-hud-tab-cur-brd;
        border-bottom-color: $c-hud-bg;
        color: $c-hud-tab-cur-txt;

        a,
        a:hover {
          color: inherit;
          cursor: default;

          .count {
            color: $c-hud-tab-cur-meta;
          }
        }
      }
      &.disabled {
        a {
          color: $c-hud-tab-dsb-txt;
          cursor: default;
        }
      }
    }
    .ref-mode {
      float: right;
      margin-right: -5px;
      padding-top: 3px;
    }
      .ref-mode_toggle {
        display: inline-block;
        width: 16px;
        height: 16px;
        overflow: hidden;
        background: url('../images/bg-hud-search.svg') no-repeat;
        opacity: .66;
        text-indent: -999em;

        &:hover {
          opacity: 1;
        }
        &.i-repository {
          background-position: -32px 0;
        }
        &.i-file {
          background-position: -48px 0;
        }
        &.i-directory {
          background-position: -64px 0;
        }
        &.disabled,
        &.disabled:hover {
          opacity: 1;
          cursor: default;

          &.i-repository {
            background-position: -32px -16px;
          }
          &.i-file {
            background-position: -48px -16px;
          }
          &.i-directory {
            background-position: -64px -16px;
          }
        }
      }



  // Reference List

  .ref-wrap {
    position: relative;
    max-height: 300px;
    overflow: hidden;
    color: $c-hud-ref-txt;
    font-size: .923em;

    &.loading {
      .ref-list {
        position: relative;
        min-height: 30px;

        &::before {
          @extend %icon-loading;
          @extend %icon-loading--medium;
          @extend %icon-loading--medium-centered;

          content: '';
        }
      }
      .ref-item {
        display: none;
      }
    }
    &.ps-container {
      padding-right: (18px + 6px);
    }
  }
    .ref-container {
    }
      .ref-list {
        list-style: none;
      }
        .ref-item {
          border-bottom: 1px dotted $c-hud-ref-brd;
          white-space: pre;

          &:last-child {
            border-bottom: 0;
          }
          a {
            display: block;
            padding: .4em 0;
            overflow: hidden;
            color: inherit;
            text-decoration: none;
            text-overflow: ellipsis;

            .hash,
            .time {
              display: inline-block;
              width: 6em !important;
              margin-right: .66em;
              color: $c-hud-ref-meta;
              font-size: .916em;
              text-align: right;
            }
            .hash {
              font-family: $f-monospace-fb;

              .fonts-loaded & {
                font-family: $f-monospace;
              }
            }
          }
          a:hover {
            color: $c-hud-ref-h-txt;

            .hash,
            .time {
              color: $c-hud-ref-h-meta;
            }
          }
        }
          .i-branch,
          .i-tag,
          .i-user {
            &::before {
              content: '';

              display: inline-block;
              width:  15px;
              height: 15px;
              margin-right: .66em;
              vertical-align: -4px;
              background: url('../images/i-refs.svg') no-repeat;

              .select-cover & {
                margin-right: .33em;
              }
              .disabled & {
                opacity: .5;
              }
            }
            &.stick::before {
              margin-right: .15em;
            }
          }
          .i-branch {
            &::before,
            .disabled &:hover::before {
              background-position: 0 0;
            }
            &.dark::before {
              background-position: 0 -30px;
            }
            &:link,
            &:visited {
              &:hover::before {
                background-position: 0 -15px;
              }
            }
          }
          .i-tag {
            &::before,
            .disabled &:hover::before {
              background-position: -15px 0;
            }
            &:link,
            &:visited {
              &:hover::before {
                background-position: -15px -15px;
              }
            }
          }
          .i-user {
            &::before,
            .disabled &:hover::before {
              background-position: -30px 0;
            }
            &:link,
            &:visited {
              &:hover::before {
                background-position: -30px -15px;
              }
            }
          }

        .ref-item.disabled {
          a {
            color: $c-hud-ref-dsb-txt;
            cursor: default;

            .hash,
            .time {
              color: inherit;
            }
          }
          .i-branch,
          .i-tag {
            &::before {
              opacity: .5;
            }
          }
          .i-branch {
            &:hover::before {
              background-position: 0 0;
            }
          }
          .i-tag {
            &:hover::before {
              background-position: -15px 0;
            }
          }
        }
        .ref-item.more {
          margin-top: .75em;
          border-radius: 3px;
          border-bottom: 0;
          padding: 1em;
          background-color: $c-hud-ref-more-bg;
          text-align: center;
          text-shadow: 0 1px 0 rgba(#FFF, .5);
          white-space: normal;

          a {
            display: inline;
            padding: 0;
            text-decoration: underline;
          }
        }
        .ref-item--empty {
          margin: 1.5em 0;
          border-bottom: 0;
          text-align: center;
        }


  /* Editor settings */

  .hud .settings {
    label {
      @extend .clearfix;

      display: block;
      margin: 0 0 1em;
      color: $c-mute;
      font-size: .85em;

      input,
      select {
        float: right;
      }
      select {
        margin-top: -1px;
      }
    }
    hr {
      height: 0;
      margin: $hud-space (-$hud-space);
      border: 0;
      border-top: 1px solid $c-hud-inside-brd;
      padding: 0;
    }
  }


  /* File and diff viewer settings */

  .viewer-settings {
    font-size: .92em;

    .form-row.l-vertical {
      margin: 0 0 1.25em;

      &:last-child {
        margin-bottom: 0;
      }
      .form-label {
        margin-bottom: .33em;
        font-size: .92em;
      }
      .form-field {
        padding: 0;
      }
      input[type='checkbox'] {
        vertical-align: baseline;
      }
    }
    .setting-lang {
    }
  }


  /* Delete file or directory */

  .delete-confirm {
    font-size: .923em;

    p {
      margin: 0 0 .5em;
      color: inherit;
      font-size: 1em;
    }
      strong {
        color: $c-dark;
        font-weight: bold;
      }
    .form-row {
      margin: 1.25em 0;
    }
      textarea {
        padding: .5em;
        font: 1em $f-sansserif;
      }
    .actions {
      margin-top: 1.5em;
    }
  }


