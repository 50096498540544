// Branches page

.base-branch {
  color: $c-text;
  font-weight: bold;
}

.badge_base-branch {
  margin-left: .33em;
  border-radius: 2px;
  padding: .2em .6em .3em;
  background-color: #A5BDEB;
  color: #FFF;
  font-weight: bold;
  font-size: .9em;
  text-shadow: 0 1px 1px rgba(#000, .1);
}

.branches {
  margin-bottom: 45px;

  th,
  td {
    padding-top: 1.1em;
    padding-bottom: 1.1em;
    white-space: nowrap;

    &:first-child {
      padding-left: .75em;
    }
  }
  .button {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  // Changes comparing to default branch

  .branches_changes {
    text-align: center;
  }
    .branches_changes_behind,
    .branches_changes_ahead {
      width: 10%;
      vertical-align: middle;
    }
    .branches_changes_behind {
      padding: 0 .33em 0 1.5em;
      text-align: right;
    }
    .branches_changes_ahead {
      padding: 0 1.5em 0 .33em;
    }

  // Code reviews

  .branches_review {
    width: 15%;

    .status-label {
      text-decoration: underline;
    }
    .link-external {
      color: #333;
      text-decoration: underline;
    }
  }

  // Merging with default branch

  .branches_merge {
    width: 15%;

    .button {
      margin-right: .5em;
    }
    .select-cover {
      vertical-align: middle;
    }
    .sep,
    .link-help {
      font-size: .9em;
    }
  }

  // Actions (Compare, Merge or delete)

  .branches_action {
    width: 2.5%;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    text-align: right;

    .button,
    .a-delete {
      margin-left: .5em;
    }
    .sep {
      margin: 0 .25em;
    }
  }
}

  // Name

  .branches_name {
    max-width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .sep {
      margin: 0 .25em;
      color: #AAA;
    }
    .meta {
      color: #AAA;
    }
  }

  // Last updated (date and committer)

  .branches_activity {
    text-align: center;
  }
    .branches_activity_time,
    .branches_activity_user {
      width: 10%;

      a {
        color: inherit;
      }
      a:hover {
        text-decoration: none;
      }
    }
    .branches_activity_time {
      padding-right: .75em;
      text-align: right;
    }
    .branches_activity_user {
      padding-left: .75em;

      .userpic {
        margin-right: .3em;
        vertical-align: -.4em !important;
      }
    }
    .branches_activity_time a,
    .branches_activity_user u {
      display: inline-block;
      height: 1.25em;
      border-bottom: 1px solid mix($c-content-bg, $c-text, 66%);
      text-decoration: none;

      .branch--merged & {
        border-bottom-color: mix($c-content-bg, $c-branch-merged-txt, 66%);
      }
      &:hover {
        border-bottom-color: initial;
      }
    }


// Loading branch data

.branch--loading {
  opacity: .5;

  .branch-name.i-branch::before {
    @extend %icon-loading;

    margin-right: .6em;
    vertical-align: baseline;
  }
}


// Default branch row

.branch--base {
  th,
  td {
    border-color: $c-branch-base-brd;
    background-color: $c-branch-base-bg;
  }
    .branch-name {
      margin-right: .5em;
    }
  td.branches_action {
    padding-right: 1em;
  }
}


// Merged branch row

.branch--merged {
  th,
  td {
    color: $c-branch-merged-txt;
  }
    .branch-name,
    .branch-merge {
      color: inherit;
      font-weight: normal;
    }
    .userpic {
      opacity: .5;
    }
}


// Branch name

.branch-name {
  color: $c-branch-txt;
  font-weight: bold;

  &:link,
  &:visited {
    text-decoration: underline;

    &:hover {
      color: $c-link;
    }
  }
  &.i-branch::before {
    margin-right: .15em;
    margin-left:  .15em;
  }
  &.loading::before {
    @extend %icon-loading;

    margin-right: .6em;
    vertical-align: baseline;
  }
  &.is-locked {
    @extend .icon-locked;

    color: $c-branch-locked-txt;

    &::before {
      margin-right: 7px;
      margin-left: 4px;
      vertical-align: -2px;
    }
    &:link:hover {
      @extend .icon-locked--link;
    }
  }
  .repo-team & {
    margin: 0 .2em;
    font-weight: normal;

    &.i-branch::before {
      margin-right: .15em;
    }
    &.is-locked::before {
      margin-right: 5px;
      margin-left: 3px;
      vertical-align: -1px;
    }
  }
}


// Changes graph and label

.branch-changes {
  display: inline-block;
  height: 3px;
  transition: width .5s ease;

  .branches_changes_behind & {
    background-color: $c-branch-behind-bg;
  }
  .branches_changes_ahead & {
    background-color: $c-branch-ahead-bg;
  }
}
.branch-changes-label {
  display: block;
  margin-bottom: .5em;
  font-size: .85em;

  .branches_changes_behind & {
    color: $c-branch-behind-txt;
  }
  .branches_changes_ahead & {
    color: $c-branch-ahead-txt;
  }
}


// Merge labels and icons

.branch-merge.status-label--ok,
.branch-merge.status-label--error {
  font-weight: bold;
}
