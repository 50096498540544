/* Search
------------------------------ */

.search-form {
  margin-bottom: 15px;
  border-bottom: 1px solid $c-activity-brd;
  padding-bottom: 30px;

  .form-row {
    margin: 0;
  }
    input,
    select {
      margin-right: .2em;
      vertical-align: middle;
      font-size: medium;
    }
    select {
      max-width: 17.5%;
    }
    input[type='search'] {
      width: 50%;
      margin-left: .2em;
    }
}
.search-results .highlight {
  background-color: $c-serp-hl-bg;
}
.search-results .changeset-comment .highlight {
  background-color: $c-serp-comment-hl-bg;
}

.search-results .changeset-comment .rev-comment p {
  @extend .i-comments-counter;

  padding-top: .4em;
  background-color: transparent;
}
.search-results .changeset-comment .rev-comment p.rev-meta-info {
  &::before {
    content: none;
  }
}
  .search-results .changeset-comment .rev-comment p,
  .search-results .changeset-comment .rev-comment p a,
  .search-results .changeset-comment .rev-comment p .comment,
  .search-results .changeset-comment .rev-comment p.rev-meta-info {
    color: $c-serp-comment-txt !important;
  }
  .search-results .changeset-comment .rev-comment p a:hover {
    border-color: $c-serp-comment-h-brd !important;
  }
  .search-results .changeset-comment .rev-comment p strong {
    display: none;
  }
