/* Page Layout
------------------------------ */

#container {
  min-width: 960px;
  margin: 0 auto;
}

  .beanstalk-logo {
    display: inline-block;
    width: 100px;
    height: 31px;
    margin-left: -8px;
    vertical-align: top;
    overflow: hidden;
    background: url('../images/bs-logo@2x.png') no-repeat;
    background-size: 100px 62px;
    color: transparent;
    font-size: 0;
    line-height: 0;
  }
  .conveyor-heart-logo {
    display: inline-block;
    width: 150px;
    height: 20px;
    margin-right: 15px;
    margin-bottom: -4px;
    vertical-align: bottom;
    overflow: hidden;
    background: url('../images/conveyor-heart.svg') no-repeat;
    background-size: 150px 20px;
    color: transparent;
    font-size: 0;
    line-height: 0;
  }


  /* Page */

  #page {
    background-color: $c-content-bg;
  }
    .content {
      @extend .clearfix;

      box-sizing: border-box;
      margin: 0;
      padding: 30px 30px 60px;
    }
    .content--compare {
      padding-bottom: 30px;
      background-color: $c-olive-lightest;
    }
    .content--cr {
      position: relative;
      padding-bottom: 0;
    }

      .divided {
        @extend .clearfix;

        display: flex;
        margin: -30px -30px -60px;

        .maincol {
          box-sizing: border-box;
          width: 70%;
          padding: 30px 30px 60px;
        }
        #sidebar {
          box-sizing: border-box;
          width: 30%;
          padding: 30px 30px 60px;
          background-image: linear-gradient(to right, #DDD, #F3F3F3 25%, #FFF);
          background-repeat: repeat-y;
          background-position: 0 0;
          background-size: 4px 4px;
        }
      }
      .divided--equals {
        .maincol,
        #sidebar {
          width: 50%;
        }
        #sidebar {
          border-left: 1px solid $c-sidebar-brd;
          padding-left: 29px;
          background-image: none;
        }
      }

      @media print {
        .divided,
        .divided .maincol,
        .divided #sidebar {
          float: none;
          width: auto;
          margin-right: 0;
          margin-left:  0;
        }
      }


  /* Footer */

  #footer {
    border-top: 1px solid #EEE;
    padding: 20px 30px 40px;
    color: $c-footer-txt;
    opacity: 0;
    box-shadow: inset 0 2px 2px rgba(#808080, .05);
    font-size: .92em;
    line-height: .9;

    @media print {
      display: none !important;
    }

    &.is-visible {
      opacity: 1;
    }
  }
      #footer .beanstalk-logo {
        margin-right: 30px;
        border: 0;
        vertical-align: bottom;
        background-position: 0 -31px;
        opacity: .5;

        &:hover {
          background-position: 0 0;
          opacity: 1;
        }
      }

      #footer p {
        display: inline-block;
        margin: 0 30px 0 0;
        vertical-align: bottom;
      }
        #footer .sep {
          margin: 0 .5em;
          color: $c-footer-meta;
        }
        #footer a {
          border-width: 0 0 1px;
          border-style: solid;
          border-color: mix($c-footer-l, #EEF7E4, 50%);
          color: $c-footer-l;
          text-decoration: none;
        }
        #footer a:hover {
          border-color: $c-footer-h;
          color: $c-footer-h;
        }
        #footer a:active {
          border: 0;
          color: $c-footer-h;
        }


/* Columns */

$column-gutter: 40px;

.columns {
  position: relative;
  overflow: hidden;
}
  .column {
    float: left;
    margin-left: $column-gutter;
  }

.columns--2 {
  padding-left: $column-gutter;

  .column {
    width: 50%;

    &:first-child {
      margin-left: -($column-gutter);
    }
  }
}
.columns--3 {
  padding-left: $column-gutter * 2;

  .column {
    width: 33.3%;

    &:first-child {
      margin-left: -($column-gutter * 2);
    }
  }
}
.columns--4 {
  padding-left: $column-gutter * 3;

  .column {
    width: 25%;

    &:first-child {
      margin-left: -($column-gutter * 3);
    }
  }
}
