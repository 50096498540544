/* Dashboard page
======================================== */

.dashboard-title {
  margin: -.166em 0 1.4em;
  font-size: 1.385em;
  line-height: 1.333;
}
  .dashboard-title_incidents {
    margin-left: 1.25em;
    vertical-align: .1em;
    color: $c-red;
    font-size: .777em;
    text-decoration: underline;

    &::before {
      margin-right: .4em;
      vertical-align: -2px;
    }
  }


/* Active repositories
---------------------------------------- */

.dashboard-activity {
  position: relative;
  margin: -15px 0 30px;
}


// Extra actions in the end

.dashboard-extras {
  margin: 15px 0 0;

  .maincol & {
    font-size: 1.077em;
  }
  > li {
    display: inline-block;
    margin-right: 1.5em;
    list-style: none;
  }
    a {
      text-decoration: none !important;
    }
    a.icon-arrow-ar::after {
      font-size: 7px;
    }
    .button {
      margin-left: 0;
    }
}


// Repository

.repostate {
  position: relative;
  margin: 0;
  border-bottom: 1px dashed #F3F3F3;
  padding: 15px 0;
}
  .repostate_badge {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 0;
    width: 4px;
    background-color: #F5F5F5;

    &.u-color-bg--white {
      box-shadow: 0 0 1px rgba(#000, .3);
    }
  }

  .repostate_activity {
    padding-left: 20px;
    overflow: hidden;
  }
    .repostate_name {
      margin: 0;
      font-weight: bold;
      font-size: 1.23em;

      &.u-color-txt--white {
        color: #666 !important;
      }
    }
      .repostate_name-link {
        color: inherit;
        text-decoration: underline;
      }

    .repostate_last-commit {
      float: right;
      margin-top: -(1.23em * 1.2);
      color: #AAA;
      font-size: .923em;
    }

    .repostate_commits {
      margin-top: .5em;
    }
      .repostate_commits-activity {
        display: inline-block;
        vertical-align: middle;
        color: transparent;
      }
      .repostate_committers {
        @extend .icon-arrow-bl;

        display: inline-block;
        margin: 0 0 0 .75em;
        vertical-align: middle;
        line-height: 1;

        &::before {
          margin-right: .2em;
          color: #CCC;
          font-size: 7px;
          line-height: 24px;
        }
        > li {
          display: inline-block;
          margin-right: 1px;
          vertical-align: middle;
          list-style: none;
        }
          .userpic {
            vertical-align: middle;
          }
      }
        .repostate_committers-more {
          margin-left: .2em;
          color: #AAA;
          font-size: .923em;
        }

    .repostate_commits--no-activity {
      color: #AAA;

      p {
        margin: 0;
        font-size: .923em;
        line-height: 24px;
      }
    }


/* Sidebar activity & stats
---------------------------------------- */

.db-activity-period {
  margin-left: .33em;
  color: $c-mute;
  font-weight: normal;
}

.db-repo-subtitle {
  margin: 1.5em 0 .75em !important;
  line-height: 1.4;

  .color-badge--text {
    border-radius: 1px;
    padding: 0 .3em .077em;
  }
}


// Team commits

.leaderboard {
  margin: 0 0 1.5em;
}
  .leaderboard_item {
    margin-bottom: 1.23em;
    padding-right: 2em; // For a number of commits
    padding-left: 42px;
  }
    .leaderboard_userpic {
      float: left;
      margin-left: -42px;
    }
    .leaderboard_name {
      display: inline-block;
      margin-top: .2em;
      overflow: hidden;
      color: inherit;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .leaderboard_bar {
      margin-top: -.2em;
      color: #AAA;
      font-size: 10px;
      line-height: 1;
      white-space: nowrap;
    }

      .bar-commits {
        display: inline-block;
        margin-right: .33em;
      }
        .bar-commits_repo {
          display: inline-block;
          min-width: 2px;
          height: 6px;
          background-color: #EEE;
          box-shadow: inset 0 0 0 1px #FFF;
          font-size: 0;

          &.u-color-bg--white {
            box-shadow: inset 0 0 0 1px #FFF, inset 0 0 0 2px rgba(#000, .15);
          }
        }


// Pending reviews

.db-reviews-list {
  margin: 0;
}
  .db-reviews-list_item {
    margin-bottom: .6em;
    overflow: hidden;
    color: $c-mute;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: inherit;
      text-decoration: none;

      &.i-branch::before {
        margin-right: 3px;
      }
    }
      strong {
        color: $c-dark;
        font-weight: normal;
        text-decoration: underline;
      }
    a:hover {
      strong {
        color: $c-link;
      }
    }
  }
  .db-reviews-list_item--more {
    margin-left: 18px;
    font-size: .923em;

    a {
      color: $c-link;
      text-decoration: underline;
    }
  }


// Manually deployed

.db-envs-list {
  margin: -.2em 0 0;
}
  .db-envs-list_item {
    display: inline-block;
    margin-right: .5em;
    font-size: .923em;

    a {
      color: $c-dark;
      text-decoration: underline;
    }
    .color-badge--env {
      width:  6px;
      height: 6px;
      margin-right: .5em;
      border: 0;
      vertical-align: 1px;
      box-shadow: none;

      &.color-badge--white {
        box-shadow: 0 0 1px rgba(#000, .75);
      }
    }
  }
  .db-envs-list_item--more {
    color: $c-mute;

    a {
      color: inherit;
    }
  }
