/* Changeset
============================== */

/* Helper for copying diffs to clipboard */

#clipboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
}


/* Commit message
------------------------------ */

.cs-message {
  margin: 0 0 1em;
  color: $c-cs-msg-txt;
  font-size: 1.385em; // 18px
  line-height: normal;

  .tagged,
  .link-lighthouse,
  .link-harvest,
  .link-fogbugz,
  .link-zendesk,
  .link-sifter,
  .link-jira,
  .link-done-done,
  .link-asana,
  .link-groove,
  .link-pivotal {
    font-size: .6em;
  }
}
  // Empty commit message
  .cs-message_empty {
    color: $c-cs-msg-e-txt;
    font-weight: 200;
  }

  // Multi-line commit message
  .cs-message_ext {
    font-weight: normal;
    font-size: .85em; // 15px
  }

  // Editable commit message
  .cs-message_editable {
    &:hover {
      background-color: $c-cs-msg-h-bg;
      cursor: pointer;
    }
  }

// Commit message editing form

.cs-message_form {
  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 7.75em;
    margin: 0;
    border: 1px solid $c-cmt-form-brd;
    padding: .3em .6em;
    font: medium/1.4 $f-sansserif;
  }
  .form-buttons {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $c-cmt-form-brd;
    padding: 1em .75em;
    background-color: $c-cmt-form-bg;
  }
}



/* Changeset Meta
------------------------------ */

.cs-meta {
  position: relative;
  margin: -30px -30px 30px;
  border-bottom: 1px solid $c-cs-meta-brd;
  padding-bottom: 15px;
  overflow: hidden;
  color: $c-cs-meta-txt;
  font-size: .923em;
}
  .cs-meta_block {
    float: left;
    box-sizing: border-box;
    margin-bottom: -999em;
    border-left: 1px solid $c-cs-meta-brd;
    padding: 15px 15px 0 30px;
    padding-bottom: 999em;

    &:first-child {
      border-left: 0;
    }
    .cs-meta--3-col & {
      width: 33%;
    }
    .cs-meta--4-col & {
      width: 25%;
    }
    .cs-meta--5-col & {
      width: 20%;
    }
  }


  // Commit meta (author & time)

  .cs-meta_commit {
    margin: 0;
    line-height: 1.4;

    .userpic {
      float: left;
      margin-top: .1em;
      margin-right: 1em;
    }
  }
    .cs-commit_author {
      display: block;
      font-size: 1.1em;
    }
    .cs-commit_timestamp {
      display: block;
      color: $c-mute;
    }


  // Comments

  .cs-meta_comments {
    margin: 1.1em 0 0;

    a {
      text-decoration: none;
      white-space: nowrap;
    }
    .sep {
      margin: 0 .33em;
    }
    .link-local {
      display: inline-block;
      height: 1.3em;
    }
  }
    .cs-meta_comments_link {
      strong {
        display: inline-block;
        min-width: 1em;
        height: 1.4em;
        margin-right: .2em;
        border-radius: 2px;
        padding: 0 .2em;
        background: #67A9EF;  // Matches comments toggle icon
        color: #FFF;
        font-size: .9em;
        line-height: 1.35;
        text-align: center;
      }
      &:hover {
        strong {
          background: $c-link;
        }
      }
    }
    .cs-comments_toggle {
      &.i-comments-toggle-sml::before {
        margin-right: .5em;
        vertical-align: -.3em;
      }
      .link-local {
        border-bottom-color: mix(#FFF, $c-mute, 50%);
        color: $c-mute;
      }
      &.is-collapsed .link-local {
        border-bottom-color:  mix(#FFF, $c-link, 50%);
        color: $c-link;
      }
      &:hover .link-local {
        border-bottom: 1px solid;
        text-decoration: none;
      }
    }


  // Properties

  .cs-meta_props {
    $labels-w: 45px;

    @extend .clearfix;

    margin-top: .1em;

    dt.cs-meta_title {
      clear: both;
      float: left;
      width: $labels-w;
      margin: 0 0 .25em;
      padding-top: .1em;
    }
    dd {
      margin: 0 0 .25em $labels-w;
      padding-left: 15px;
    }
      .link-lighthouse,
      .link-harvest,
      .link-fogbugz,
      .link-zendesk,
      .link-sifter,
      .link-jira,
      .link-done-done,
      .link-pivotal {
        display: inline-block;
        margin: 0 .1em .33em 0;
        vertical-align: baseline;
        font-size: .75em;
      }
  }
    // Commit hash

    .cs-meta_rev {
      margin-right: .25em;
      font-family: $f-monospace-fb;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
      &:link {
        text-decoration: underline;
      }
    }

    // Navigate revisions in SVN

    .cs-meta_nav {
      color: $c-olive;
      text-decoration: none;

      &.icon-arrow-bl::before,
      &.icon-arrow-ar::after {
        padding: .25em .66em;
      }
      &.icon-arrow-bl::before {
        margin-right: .4em;
      }
      &.icon-arrow-ar::after {
        margin-left: .4em;
      }
      &.is-disabled {
        color: mix($c-content-bg, $c-olive, 60%);
      }
      &:link:hover,
      &:visited:hover {
        color: $c-olive-dark;
      }
    }

    // Type icons

    .path_type {
      &::before {
        margin-right: -.1em;
        margin-left: .8em;
        vertical-align: -.25em;
      }
      &:first-child::before {
        margin-left: 0;
      }
    }


  // Lists branches or tags

  .cs-meta_title {
    margin: .25em 0 .55em;
    color: $c-cs-meta-title;
    font-weight: normal;
    font-size: .916em;  // 11px
  }
  .cs-meta_list {
    margin: 0 0 .25em;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      list-style: none;

      &.i-branch::before {
        margin-right: .2em;
        vertical-align: -.4em;
      }
      &.i-tag::before {
        margin-right: .45em;
        vertical-align: -.25em;
      }
    }
  }
  a.cs-meta_list_expand {
    text-decoration: none;

    &.icon-collapse::before {
      margin-right: .6em;
      vertical-align: -.1em;
    }
    .link-local {
      display: inline-block;
      height: 1.3em;
    }
  }


  // List of actions

  .cs-meta_actions {
    .hud & {
      font-size: .92em;
    }
    li {
      margin: 0 0 .45em;
      list-style: none;
    }
  }
    .pref-toggle {
      color: $c-cs-pref-loading;
      cursor: pointer;

      &::before {
        content: '';

        display: inline-block;
        width:  9px;
        height: 9px;
        margin-right: .2em;
      }
      &.is-loading {
        &::before {
          @extend %icon-loading;
        }
      }
      &.is-enabled,
      &.is-disabled {
        &::before {
          background: url('../images/i-pref-toggle.svg') no-repeat;
        }
      }
      &.is-enabled {
        color: $c-cs-pref-enabled;

        &::before {
          background-position: 0 -10px;
        }
      }
      &.is-disabled {
        color: $c-cs-pref-disabled;

        &::before {
          background-position: 0 0;
        }
      }
      input[type='checkbox'] {
        display: none;
      }
    }



/* Changeset Files
------------------------------ */

.cs-files {
  margin-bottom: 30px;
}
  .cs-files_more {
    margin: 1.25em 0 30px;
  }



/* Comments
------------------------------ */

.cs-comments {
  @extend .clearfix;

  margin: 45px 0 -30px;
}
.cs-comments--code-review {
  margin-top: -20px;
}
  .cs-comments_title {
    margin: 0 0 1em;
    padding: 0;
    color: $c-base;
    font-size: 1.385em; // 18px
  }

  .cs-comments_subscribtion {
    float: right;
    margin-top: -3.3em;
  }
    .link-subscribe,
    .link-unsubscribe {
      margin: 0;
      border: 0;
      padding: 0;
      background-color: transparent;
      font: 1em $f-sansserif;
      cursor: pointer;
      appearance: none;

      u {
        display: inline-block;
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        text-decoration: none;

        &:hover {
          border-bottom-style: solid;
        }
      }
      &::before {
        content: '';

        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: .4em;
        background: url('../images/i-pref-toggle.svg') no-repeat;
        background-size: 9px 29px;
      }
      &.loading {
        u {
          border-bottom-color: rgba($c-mute, .5);
          color: $c-mute;
        }
        &::before {
          @extend %icon-loading;

          margin-right: .5em;
        }
      }
    }
    .link-subscribe {
      u {
        border-bottom-color: rgba($c-cmt-subscribe, .5);
        color: $c-cmt-subscribe;
      }
      &::before {
        background-position: 0 -10px;
      }
    }
    .link-unsubscribe {
      u {
        border-bottom-color: rgba($c-cmt-unsubscribe, .5);
        color: $c-cmt-unsubscribe;
      }
      &::before {
        background-position: 0 -20px;
      }
    }


  .cs-comment {
    @extend .clearfix;

    position: relative;
    margin: 0 -30px 5px;
    border-top: 1px solid $c-cmt-brd;
    padding: 15px 30px 30px;
    background-color: $c-cmt-bg;
  }
    .cs-comment_meta {
      float: left;
      width: ($cr-timeline - 30px);
      padding-top: .75em;  // Match margin-top of '.text-markdown p'
      line-height: normal;
      text-align: right;
    }
      .cs-comment_author {
        display: block;

        .userpic {
          float: right;
          margin-left: 1em;
        }
        strong {
          display: block;
          margin-bottom: .2em;
        }
      }
      .cs-comment_anchor {
        border-bottom: 1px solid rgba($c-cmt-meta, .33);
        color: $c-cmt-meta;
        text-decoration: none;

        &:hover {
          border-color: rgba($c-cmt-meta, .75);
          text-decoration: none;
        }
      }
      .cs-comment_time {
        font-size: .92em;
      }


    .cs-comment_entry {
      width: 600px;
      margin-left: $cr-timeline;
      overflow-wrap: break-word;
      word-wrap: break-word;

      @media (min-width: 1100px) {
        width: 750px;
      }

      strong {
        color: inherit !important;
      }
    }
      .cs-comment_preview {
        padding: .5em 1em;
        background-color: $c-cmt-preview-bg;
      }
      .cs-comment_code {
        margin: 0 0 2em;

        .cv {
          margin-top: .75em;
        }
      }
        .cs-comment_ref {
          overflow: hidden;
          font-size: .846em;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
          .cs-comment_ref_file {
            border-color: $c-cmt-ref;
            color: $c-cmt-ref;
            font-family: $f-monospace-fb;

            .fonts-loaded & {
              font-family: $f-monospace;
            }
            &:hover {
              border-color: $c-cmt-ref-h;
              color: $c-cmt-ref-h;
            }
          }

      .cs_comment_edit {
        color: $c-cmt-meta;
        font-size: .92em;

        a {
          color: inherit;
        }
      }


  // Commenting form
  .cs-comment--form {
    background-color: transparent;

    .message + & {
      border-top: 0;
    }
    .cs-comment_meta {
      padding-top: 0;
    }
    .loc_comment_form {
      margin-top: 0;
    }
  }


  // Compact items
  .cs-comment--outdated {
    border-top-color: mix($c-content-bg, $c-cmt-brd, 50%);
    padding-bottom: 10px;
    background-color: transparent;
    color: $c-mute;

    p {
      margin: .5em 0;
    }
  }
