$beacon-color: #5CAD69; // Should match color set in HelpScout configurator

.fake-hs-beacon {
  $height: 55px;

  position: fixed;
  top: auto;
  right: 40px;
  bottom: 40px;
  z-index: 1049;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 60px;
  height: $height;
  line-height: $height;

  margin: 0;
  border-width: 0;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: $height;
  padding: 0;
  background-color: $beacon-color;
  color: #FFF;
  box-shadow: rgba(#000, 0.1) 0 4px 7px;
  transition: background 200ms linear;

  -webkit-tap-highlight-color: transparent;
  appearance: none;
  user-select: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: darken($beacon-color, 5%);
  }

  @media (max-height: 740px) {
    right: 20px;
    bottom: 10px;
  }

  &_icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background: url('../images/i-beacon.svg') no-repeat 50% 50%;
    color: #FFF;
    cursor: pointer;
    pointer-events: none;
  }
  &.is-loading &_icon {
    background-image: url('../images/loader.svg');
    background-size: 30px 30px;
  }

  &_text {
    display: block;
    padding: 0 20px 0 54px;
    color: #FFF;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
  }
}
