/* Reset
------------------------------ */

/* stylelint-disable */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  background-color: transparent;
  font-size: 100%;
}
/* stylelint-enable */
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* stylelint-disable selector-max-universal */
@media print {
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
/* stylelint-enable */
