/* Integration Overview
------------------------------ */


/* Help button */

p.int-help {
  margin: 45px 0 35px;
  font-size: 1.1em;
  text-align: center;

  a {
    color: $c-int-help-l;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: $c-int-help-h !important;
      text-decoration: underline;
    }
  }

  a.link-zapier {
    color: #FF4A00;
    text-decoration: none;

    > u {
      text-decoration: underline;
    }
  }
  a.link-zapier:hover {
    #zapier {
      fill: #222;
    }
  }
}
  .logo-zapier {
    display: inline-block;
    vertical-align: bottom;
  }


/* Grid */

.box.int-overview {
  border-color: $c-int-page-brd;
  background-color: $c-int-page-bg;

  p {
    color: $c-int-page-txt;
  }
  a.button-guide {
    display: inline-block;
    border-radius: 3px;
    padding: .7em 1.2em .8em;
    background-color: $c-button-guide-bg;
    color: $c-button-guide-txt !important;
    box-shadow: 0 0 4px #FFF;
    font-size: 1.1em;
    text-decoration: none !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);

    &:hover {
      background-color: darken($c-button-guide-bg, 5%);
    }
  }
}

.int-overview ul {
  margin: 20px -10px 0;
  overflow: hidden;
}
  .int-overview ul li {
    position: relative;
    float: left;
    width: 20%;
    min-width: 200px;
    list-style: none;
  }

  @media (max-width: 1113px) {
    .int-overview ul li {
      width: 25%;
    }
  }

    /* Cards */

    .int-card {
      display: block;
      position: relative;
      margin: 10px;
      -webkit-font-smoothing: subpixel-antialiased;
      text-decoration: none !important;
    }

      // This wrapper is needed only for Firefox 3.6
      .int-card .int-wrap {
        display: block;
      }

      .int-card img {
        display: block;
        width: 100%;
        min-height: 180px;
        visibility: hidden;
      }
      .int-card .logo,
      .int-card .details {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid $c-int-card-brd;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(0, 0, 0, .15);

        /* Flip card animation */
        transition: all .4s ease-in-out;
        transform-style: preserve-3d;
        backface-visibility: hidden;
      }


      /* Front (Logo) */

      .int-card .logo {
        z-index: $zi-int-logo;
        background-color: $c-int-card-bg;
        transform: rotateY(0);
      }
        .int-card h4 {
          position: absolute;
          bottom: 7.5%;
          width: 100%;
          margin: 0;
          padding: 0;
          font-size: 1.1em;
          text-align: center;
          white-space: nowrap;
        }

        $s-int: 150px;

        .int-card .logo-container {
          position: absolute;
          top: 45%;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('../images/integration-sprite.png') no-repeat 50% 0;
          background-size: $s-int ($s-int * 19);

          @include retina {
            background-image: url('../images/integration-sprite@2x.png');
          }
        }
          #int-basecamp-next .logo-container {
            height: 87px;
            margin-top: -44px;
            background-position: 50% ($s-int * 0);
          }
          #int-basecamp .logo-container {
            height: 86px;
            margin-top: -43px;
            background-position: 50% (-$s-int * 1);
          }
          #int-fogbugz .logo-container {
            height: 76px;
            margin-top: -38px;
            background-position: 50% (-$s-int * 3);
          }
          #int-lighthouse .logo-container {
            height: 84px;
            margin-top: -42px;
            background-position: 50% (-$s-int * 4);
          }
          #int-freckle .logo-container {
            height: 31px;
            margin-top: -15px;
            background-position: 50% (-$s-int * 5);
          }
          #int-harvest .logo-container {
            height: 43px;
            margin-top: -21px;
            background-position: 50% (-$s-int * 6);
          }
          #int-hipchat .logo-container {
            height: 80px;
            margin-top: -40px;
            background-position: 50% (-$s-int * 7);
          }
          #int-zendesk .logo-container {
            height: 92px;
            margin-top: -46px;
            background-position: 50% (-$s-int * 8);
          }
          #int-sifter .logo-container {
            height: 46px;
            margin-top: -23px;
            background-position: 50% (-$s-int * 9);
          }
          #int-modular-web-hooks .logo-container {
            height: 72px;
            margin-top: -36px;
            background-position: 50% (-$s-int * 10);
          }
          #int-web-hooks .logo-container {
            height: 72px;
            margin-top: -36px;
            background-position: 50% (-$s-int * 11);
          }
          #int-jira .logo-container {
            height: 43px;
            margin-top: -26px;
            background-position: 50% (-$s-int * 12);
          }
          #int-pivotal-tracker .logo-container {
            height: 82px;
            margin-top: -41px;
            background-position: 50% (-$s-int * 13);
          }
          #int-done-done .logo-container {
            height: 76px;
            margin-top: -38px;
            background-position: 50% (-$s-int * 14);
          }
          #int-slack .logo-container {
            height: 84px;
            margin-top: -42px;
            background-position: 50% (-$s-int * 15);
          }
          #int-asana .logo-container {
            height: 22px;
            margin-top: -11px;
            background-position: 50% (-$s-int * 16);
          }
          #int-groove .logo-container {
            height: 80px;
            margin-top: -40px;
            background-position: 50% (-$s-int * 17);
          }
          #int-bugsnag .logo-container {
            height: 26px;
            margin-top: -13px;
            background-position: 50% (-$s-int * 18);
          }


      /* Back (Features) */

      .int-card .details {
        z-index: $zi-int-features;
        border-color: $c-int-card-alt-brd;
        background-color: $c-int-card-alt-bg;
        color: $c-int-card-alt-txt;
        opacity: 0;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .33);
        transform: rotateY(-180deg);
      }
        .int-card .details-wrap {
          display: table;
          width: 100%;
          height: 92.5%;
        }
          .int-card .details h4 {
            color: $c-int-card-alt-title !important;
            font-weight: normal;
          }
          .int-card .details ul {
            display: table-cell;
            padding: 0;
            vertical-align: middle;
          }
            .int-card .details ul li {
              position: static;
              float: none;
              width: auto;
              min-width: 0;
              margin: .25em 2.5% .75em 5%;
              padding-left: 14px;
              background: url('../images/i-checkmark.svg') no-repeat 0 50%;
              background-size: 9px 9px;
              font-size: .9em;
              line-height: 1.2;
            }


    /* Toggle */

    .int-overview ul li a.toggle {
      position: absolute;
      top: 17px;
      right: 17px;
      z-index: $zi-int-toggle;
      width: 18px;
      height: 18px;
      overflow: hidden;
      background: url('../images/i-integration-card.png') no-repeat;
      background-size: 54px 36px;
      text-indent: -9999em;
    }
    .int-overview ul li a.toggle:hover {
      background-position: 0 -18px;
    }
    .int-overview ul li a.toggle.back {
      background-position: -36px 0 !important;
    }
    .int-overview ul li a.toggle.back:hover {
      background-position: -36px -18px !important;
    }


  /* Hover */

  .int-overview ul li:hover {
  }
    .int-overview ul li:hover .int-card .logo {
      background-color: $c-int-card-h-bg;
    }
    .int-overview ul li:hover .int-card .details {
      border-color: $c-int-card-alt-h-brd;
      background-color: $c-int-card-alt-h-bg;
    }


  /* Flip card */

  .int-overview ul li.flip {
  }
    .int-overview ul li.flip .int-card .logo {
      transform: rotateY(180deg);
    }
    .int-overview ul li.flip .int-card .details {
      z-index: $zi-int-features-flip;
      opacity: 1;
      transform: rotateY(0);
    }


  /* Activated */

  .active {
  }
    .active .int-card .logo {
      border-color: $c-int-card-act-brd !important;
      background-color: $c-int-card-act-bg;
      background-image: linear-gradient(to bottom, $c-int-card-act-bg, $c-int-card-act-bg-alt);
    }

    /* Hover state only for overview */
    .active:hover a.int-card .logo {
      background-color: $c-int-card-act-h-bg !important;
      background-image: linear-gradient(to bottom, $c-int-card-act-h-bg, $c-int-card-act-h-bg-alt);
    }

    .active .int-card h4 {
      color: $c-int-card-act-title;
    }
      .active .int-card h4 i {
        margin-left: .25em;
        border-radius: 2px;
        padding: .15em .5em .2em;
        vertical-align: .15em;
        background-color: $c-int-card-act-bdg-bg;
        color: $c-int-card-act-bdg-txt;
        font-style: normal;
        font-weight: bold;
        font-size: .7em;
        text-transform: uppercase;
      }
      .active .int-card .details h4 i {
        background-color: $c-int-card-act-alt-bdg-bg;
        color: $c-int-card-act-alt-bdg-txt;
        text-shadow: 0 1px 1px rgba(255, 255, 255, .15);
      }
    .int-overview ul li.active a.toggle {
      background-position: -18px 0;
    }
    .int-overview ul li.active a.toggle:hover {
      background-position: -18px -18px;
    }
