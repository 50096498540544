// Variables

$f-sansserif:    'Helvetica Neue', Arial, Helvetica, sans-serif;
$f-serif:         Georgia, 'Times New Roman', Times, serif;
$f-monospace-fb:  Monaco, Consolas, 'Lucida Console', 'Courier New', monospace, sans-serif;
$f-monospace:    'Fira Mono', $f-monospace-fb;


// Global sizing

$cr-timeline: 230px;


// Z-index order

$zi-activity-options:         200;
$zi-select-cover:             100;
$zi-curtain:                67000;
$zi-mask:                   67000;
$zi-autocomplete:           71000;
$zi-tooltip:                69001;
$zi-image-zoom:             68000;
$zi-blame-tooltip:           9999;
$zi-env-block:                100;
$zi-env-mode:                 101;
$zi-env-status:               101;
$zi-env-settings:              99;
$zi-transfer-log:           68000;
$zi-editor-notification:      100;
$zi-editor-toolbar:           100;
$zi-boxed-action-back:          1;
$zi-notification:             500;
$zi-floating-message:        2000;
$zi-dropdown:               69500;
$zi-dropdown-mainnav:       69499;
$zi-help:                   70000;
$zi-hud:                    69000;
$zi-hud-action:               200;
$zi-int-logo:                 900;
$zi-int-features:             800;
$zi-int-toggle:              9999;
$zi-int-features-flip:       1000;
$zi-int-pointer:               99;
$zi-int-desc:                 100;
$zi-int-help:                9999;
$zi-scrollbar-rail:          1000;
$zi-scrollbar-bar:           1001;
$zi-preview-divider:        68000;
$zi-preview-beanstalk:      65500;
$zi-preview-line:           68500;
$zi-preview-add:            66000;
$zi-preview-toolbar:        65000;
$zi-preview-list:           65000;
$zi-preview-list-close:       200;
$zi-preview-list-shadow:      100;
$zi-preview-list-shadow-r:    200;
$zi-teams-avatar:             500;
$zi-teams-edit-user-intro:     20;
$zi-teams-form-subsection:      0;
$zi-editable-name-error:    10000;
$zi-chmod-scrollable:           1;
$zi-chmod-scrollable-after:     2;
$zi-wizard-progress-li:       100;
$zi-wizard-progress-num:      200;
$zi-tour-bubble:           999999;
