/* Overview
------------------------------ */

#deployments-overview {
  min-height: 460px;
  background-color: $c-dpl-page-bg;
}

.block-environment {
  position: relative;
  min-width: 620px;
  height: 130px;
  margin: 30px 0;
}
  .block-environment a.env-wrap {
    position: absolute;
    top: 0;
    right: 100px;
    bottom: 0;
    left: 100px;
    z-index: $zi-env-block;
    border: 1px solid $c-env-brd;
    border-radius: 5px;
    padding: 1px;
    background-color: $c-env-bg;
    color: inherit;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    text-decoration: none;
  }
  .block-environment a.env-wrap:hover {
    background-color: $c-env-h-bg;
    box-shadow: 0 0 9px $c-env-brd;
  }

    .block-environment p {
      margin: .85em 20px 0 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    /* Environment Name */

    .block-environment h2 {
      position: relative;
      margin: 1.15em 0 0 15px;
      padding: 0 0 0 25px;
      overflow: hidden;
      color: $c-env-title;
      font-size: 1.25em;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
      .block-environment h2 .status {
        margin-left: .5em;
        border-radius: 2px;
        padding: .15em .5em .25em;
        vertical-align: .1em;
        color: $c-env-status-txt;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        white-space: nowrap;
      }
      .block-environment h2 .color-badge {
        margin-right: .15em;
        vertical-align: -.1em;
      }


    /* Environment Status */

    .env-status {
      padding-top: 1px;
    }
      .env-status .userpic {
        margin-top: -4px;
        margin-right: .2em;
        vertical-align: middle;
      }
      .env-status .userpic.autodeployed {
        margin-top: -3px;
      }
      .env-status .fn {
        font-weight: bold;
      }
      .env-status strong.branch {
        margin: 0 .25em;
        padding-left: 13px;
        background: url('../images/i-branch.svg') no-repeat 0 -146px;
      }



    /* Current Revision */

    p.env-current {
      margin-top: .95em;
      color: $c-env-meta;
    }
      .env-current var {
        margin-right: .5em;
        border-radius: 2px;
        padding: .15em .4em;
        background-color: $c-env-rev-bg;
        color: $c-env-rev-txt;
        font-size: .92em;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      }


    /* Mode */

    .block-environment .env-mode {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: $zi-env-mode;
    }
      i.mode-manual,
      i.mode-auto {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: -.25em;
        background-image: url('../images/i-env-mode.png');
        background-repeat: no-repeat;
        background-size: 15px 65px;
      }
      i.mode-manual {
        background-position: 0 0;
      }
      i.mode-auto {
        background-position: 0 -50px;
      }

    .block-environment i.mode-manual,
    .block-environment i.mode-auto {
      display: block;
    }


    /* Status Icon */

    .block-environment .status-icon {
      position: absolute;
      top: 50px;
      left: 70px;
      z-index: $zi-env-status;
      width: 30px;
      height: 30px;
      border-radius: 3px 0 0 3px;
    }


    /* Settings */

    .env-shortcuts {
      display: table;
      position: absolute;
      top: 0;
      right: 0;
      z-index: $zi-env-settings;
      width: 100px;
      height: 130px;
    }
      .env-shortcuts ul {
        display: table-cell;
        vertical-align: middle;
      }
      .env-shortcuts li {
        display: block;
        margin: 8px 0;
        text-decoration: none;
        list-style: none;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
        .env-shortcuts a {
          display: block;
          position: relative;
          border-radius: 0 3px 3px 0;
          padding: .6em .75em .6em 35px;
          background-color: $c-env-shortcut-bg;
          color: $c-env-shortcut-txt;
          text-decoration: none;
          text-shadow: 0 1px 0 rgba(#FFF, .5);

          &::before {
            content: '';

            position: absolute;
            top: 50%;
            left: 14px;
            width: 13px;
            margin-top: -6px;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
          &:hover {
            background-color: $c-env-shortcut-h-bg;
          }
          &.disabled {
            opacity: .5;
            cursor: default;
          }
          &.disabled:hover {
            background-color: $c-env-shortcut-dsb-h-bg;
          }
        }

        .env-shortcuts .link-deploy a {
          &::before {
            height: 14px;
            background-image: url('../images/i-deploy-sml.png');
            background-size: 13px 28px;
          }
          &.disabled::before {
            background-position: 0 -14px;
          }
        }

        .env-shortcuts .link-settings a {
          @extend .icon-settings;

          &::before {
            left: 14px;
          }
          &:hover::before {
            background-position: 0 0;
          }
        }



/* Status: Empty */

.block-environment.status-empty {
}
  .block-environment.status-empty h2 {
    margin-top: 2em;
  }


/* Status: Deploying & Waiting */

.block-environment.status-deploying a.env-wrap,
.block-environment.status-waiting a.env-wrap {
  border-width: 2px;
  border-color: $c-env-waiting-brd;
  padding: 0;
  background-color: $c-env-waiting-bg;
}
.block-environment.status-deploying a.env-wrap:hover,
.block-environment.status-waiting a.env-wrap:hover {
  background-color: $c-env-waiting-h-bg;
}
  .block-environment.status-deploying p.env-current,
  .block-environment.status-waiting p.env-current {
    color: $c-env-waiting-meta;
  }
  .block-environment.status-deploying h2 .status,
  .block-environment.status-waiting h2 .status {
    background-color: $c-env-waiting-status-bg;
  }
  .block-environment.status-deploying .env-current var,
  .block-environment.status-waiting .env-current var {
    background-color: $c-env-waiting-rev-bg;
  }
  .block-environment.status-deploying .status-icon {
    background: $c-env-waiting-badge-bg url('../images/i-env-deploying.gif') no-repeat 50% 50%;
    background-size: 19px 13px;
  }
  .block-environment.status-waiting .status-icon {
    background: $c-env-waiting-badge-bg url('../images/i-env-waiting.gif') no-repeat 50% 50%;
    background-size: 16px 16px;
  }


/* Status: Failed */

.block-environment.status-failed a.env-wrap {
  border-width: 2px;
  border-color: $c-env-failed-brd;
  padding: 0;
  background-color: $c-env-failed-bg;
  color: $c-env-failed-txt;
}
.block-environment.status-failed a.env-wrap:hover {
  background-color: $c-env-failed-h-bg;
}
  .block-environment.status-failed p.env-current {
    color: $c-env-failed-meta;
  }
  .block-environment.status-failed h2 {
    color: $c-env-failed-title;
  }
  .block-environment.status-failed h2 .status {
    background-color: $c-env-failed-status-bg;
  }
  .block-environment.status-failed p strong.branch {
    background-position: 0 -96px;
  }
  .block-environment.status-failed .env-current var {
    background-color: $c-env-failed-rev-bg;
  }
  .block-environment.status-failed .status-icon {
    background: $c-env-failed-badge-bg url('../images/i-env-failed.gif') no-repeat 50% 50%;
    background-size: 18px 14px;
  }


/* Status: Canceled */

.block-environment.status-canceled a.env-wrap {
  border-color: $c-env-cancel-brd;
  background-color: $c-env-cancel-bg;
  color: $c-env-cancel-txt;
}
  .block-environment.status-canceled h2,
  .block-environment.status-canceled p.env-current {
    color: $c-env-cancel-txt;
  }
  .block-environment.status-canceled p strong.branch {
    background-position: 0 -196px;
  }
  .block-environment.status-canceled .env-current var {
    background-color: $c-env-cancel-rev-bg;
  }
  .block-environment.status-canceled h2 .status {
    background-color: $c-env-cancel-badge-bg;
  }


/* Status: Bypassed */

.block-environment.status-skipped a.env-wrap {
  h2 .status {
    background-color: $c-env-bypassed-badge-bg;
  }
}


/* Button
------------------------------ */

#deployments-overview .forms .form-buttons {
  margin: 45px 0 60px;
  border: 0;
  padding: 0;
  background: transparent none;
  text-align: center;
}
