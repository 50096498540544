/* Notifications
------------------------------ */

.notification {
  position: relative;
  z-index: $zi-notification;
  margin: 0 0 1em;
  border: 1px solid $c-notify-brd;
  padding: .75em 1em .5em;
  overflow: hidden;
  background-color: $c-notify-bg;
}
.notification.blank {
  margin: 1em 0 0;
}
.notification.detached {
  margin: 2.3em 0;
}

  .notification a:link {
    color: $c-notify-l;
  }
  .notification a:visited {
    color: $c-notify-v;
  }
  .notification a:hover {
    color: $c-notify-h;
  }

  .notification h2 {
    margin: 0;
    padding: .25em 0;
    color: $c-notify-title;
    font-size: 1.2em;
  }
  .notification p {
    margin: .5em 0 .75em !important;
    padding: 0;
  }
  .notification ul,
  .notification ol {
    margin: 0 0 1em 2.1em;
  }
  .notification hr {
    height: 1px;
    margin: 1.25em -1em 1.5em;
    border: 0;
    border-top: 1px solid $c-notify-hr;
    padding: 0;
    background-color: transparent;
  }

  .notification .repo-url {
    margin: .25em 0 .5em;
  }
    .notification .repo-url input {
      font-size: 1.2em;
    }

  .notification .form-submit {
    margin: .75em 0 1.5em;
    overflow: hidden;
  }

  .notification #contactus {
    margin: 1em 0 1.25em;
  }
    .notification #contactus li {
      margin-bottom: .5em;
    }


/* Email Bounce */

.email-bounce {
  position: relative;
  padding: .9em 1em .9em 50px;
  background: $c-bounce-bg url('../images/i-email-bounce@2x.png') no-repeat 13px 1.5em;
  background-size: 29px 16px;
}
  .email-bounce h2 strong {
    color: $c-bounce-title;
  }
  .email-bounce p {
    margin: .25em 0 .75em !important;
  }
    .email-bounce q {
      font-style: italic;
    }
    .email-bounce a.link-system {
      color: $c-bounce-ls;
    }

  .email-bounce table {
    margin: .5em 0 2em;
    border-top: 1px solid $c-bounce-brd;
  }
    .email-bounce table th,
    .email-bounce table td {
      border-bottom: 1px solid $c-bounce-brd;
      padding: .6em 1em .6em 0;
    }
    .email-bounce table a {
      color: $c-bounce-l;
      text-decoration: underline;
    }

  .email-bounce p.action-hide {
    margin: 2em 0 0 !important;
    font-size: .9em;
  }
    .email-bounce p.action-hide a.link-action {
      border-color: $c-bounce-hide-brd;
      color: $c-bounce-hide-txt;
    }
  .email-bounce p.meta {
    position: absolute;
    right: 1em;
    bottom: 0;
    margin: 0 !important;
    border-radius: 3px 3px 0 0;
    padding: 1em 1.5em .9em;
    background-color: $c-bounce-meta-bg;
    color: $c-bounce-meta-txt;
    font-size: .9em;
    line-height: 1;
  }
    .email-bounce p.meta a {
      color: $c-bounce-meta-txt;
      text-decoration: none;
    }
    .email-bounce p.meta a:hover {
      color: $c-bounce-meta-h;
    }
    .email-bounce p.meta img {
      width: 69px;
      height: 11px;
      margin-left: .4em;
      vertical-align: -.15em;
    }


/* Notifiers
------------------------------ */

.notifier {
  display: block;
  position: relative;
  margin: 0 0 30px;
  border-radius: 5px;
  padding: 20px;
  background-color: $c-notifier-bg;
  color: $c-notifier-txt;
}
.notifier:hover {
  text-decoration: none;
}
.notifier.attached {
  margin-top: 0;
}
.notifier.detached {
  margin-top: 30px;
}
  .notifier a.link-hide {
    position: absolute;
    top: .75em;
    right: .75em;
    width: 1.75em;
    color: $c-notifier-hide-txt !important;
    line-height: 1.75;
    text-align: center;
    text-decoration: none;
  }
  .notifier a {
    color: $c-notifier-l;
    text-decoration: underline;
  }
  .notifier h2 {
    margin: 0 !important;
    padding: 0;
    font-size: 1.1em;

    &.icon-attention::before {
      vertical-align: -.15em;
    }
  }
  .notifier p {
    margin: .75em 0 0 !important;
  }
  .notifier p:first-child {
    margin: 0 !important;
  }
  .notifier p.action,
  #sidebar .notifier p.action { // TODO: overspecificity
    margin-top: .75em !important;
  }


/* Minimal layout */

.notifier.minimal {
  margin-right: -15px;
  margin-left: -15px;
  border-radius: 3px;
  padding: 1em 15px;
  font-size: .9em;
}
.notifier.minimal:first-child {
  margin-top: -15px;
}
  .notifier.minimal p {
    margin: .4em 0 .2em;
  }
  .notifier.minimal a {
    color: inherit;
  }

/* Floating Notifications */

.floating-message {
  position: fixed;
  top: 30px;
  left: 50%;
  z-index: $zi-floating-message;
  box-sizing: border-box;
  width: 550px;
  margin-left: -275px !important;
  border: none !important;
  padding-right: 50px !important;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);

  &.w-animation {
    transition: opacity 150ms, visibility 0 150ms;
  }
  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 150ms;
  }
}


/* Theme: Upgrade */

.notifier.t-upgrade {
  background-color: $c-notifier-upgrade-bg;
  color: $c-notifier-upgrade-txt;
}
  .notifier.t-upgrade a.link-hide {
    color: $c-notifier-upgrade-hide;
  }
  .notifier.t-upgrade strong {
    color: $c-notifier-upgrade-str;
  }
  .notifier.t-upgrade strong.mark {
    margin: 0;
    background-color: $c-notifier-upgrade-mark-bg;
  }


/* Theme: What's New */

.notifier.t-news {
  background-color: $c-notifier-news-bg;
  color: $c-notifier-news-txt;
}
  .notifier.t-news h2 {
    color: $c-notifier-news-title !important;
  }


/* Theme: Message */

.notifier.t-message {
  border: 2px solid $c-notifier-message-brd;
  background-color: $c-notifier-message-bg;

  h2 {
    color: $c-notifier-message-title;
  }
}
