/* Users & Permissions
------------------------------ */

/* Permissions */

table.options {
  margin: 1.5em 0 0;
}
  table.options th,
  table.options td {
    border-bottom: 1px solid $c-tbl-brd;
    padding: .25em 0;
    vertical-align: middle;
  }
  table.options thead.no-action {
    td,
    th {
      border-bottom: 1px solid $c-thead-no-action-brd;
    }
  }
  table.options thead th,
  table.options thead td {
    border-bottom: 2px solid $c-tbl-head-brd;
    color: $c-tbl-head-txt;
    font-size: .85em;
    white-space: nowrap;
  }
    table.options thead td .sep {
      margin: 0 .15em;
    }
    table.options thead td a.link-help {
      border-bottom: 0;
    }

  table.options tbody th,
  table.options tbody td {
    padding-top: .6em;
    padding-bottom: .6em;
  }
  table.options tbody th,
  table.options tbody th a {
    color: $c-tbl-txt;
  }
  table.options tbody tr.action {
    th,
    td {
      border-color: $c-tbl-action-brd;
      padding-top: .8em;
      padding-bottom: .8em;
      background-color: $c-tbl-action-bg;
      transition: background 200ms;
    }
    &.is-filtered th,
    &.is-filtered td {
      background-color: $c-tbl-action-f-bg;
    }
  }
  table.options tbody td {
    width: 195px;
    padding-right: 10px;
    text-align: right;

    #specialPage & {
      width: 145px;
    }
  }
  table.options.two-columns-table td {
    #specialPage & {
      width: 50%;
    }
  }
  table.options .search-field {
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #CCC;
    border-radius: 2px;
    padding: .3em .3em .461em;
    vertical-align: middle;
    color: $c-dark;
    box-shadow: inset 0 1px 2px rgba(#000, .1);
    font-family: $f-sansserif;
    appearance: textfield;

    // Remove in-field cancel button
    &::-webkit-search-cancel-button {
      appearance: none;
    }

    // Placeholder

    &::placeholder {
      color: #999;
    }

    // Focus

    &:focus {
      outline: none;
    }
    #specialPage #page & {
      width: 70%;
    }
  }
  .w-filter {
    th {
      padding-left: .8em !important;
    }
    th.email {
      padding-left: 0 !important;
    }
  }
  table.options tbody td.email {
    width: 30%;
    color: $c-mute;
    text-align: left;
  }
    .subscription {
      position: relative;
      border: 1px solid $c-permission-brd;
      border-radius: 3px;
      padding: 4px 5px;
      background: none;
      text-align: left;
      text-shadow: 0 1px 0 rgba(255, 255, 255, .5);

      &:hover {
        border-color: $c-permission-h-bsh !important;
        box-shadow: 0 1px 1px $c-permission-h-bsh;
        text-shadow: 0 1px 0 #FFF;
      }
      &.subscribed {
        border-color: $c-permission-full;
        background-color: $c-permission-full;
      }
      tr.action &,
      tr.action &:hover {
        border-color: $c-permission-apply-brd !important;
        background-color: $c-permission-apply-bg !important;
      }
      input[type='checkbox'] {
        margin: 0;
      }
    }

    /* Admin and Owner Permissions */

    table.options span.constant {
      display: block;
      padding: 1px 5px;
      font-weight: normal;
      font-size: .85em;
      text-align: left;

      &.no-access {
        color: $c-constant-noaccess-txt;
      }
      &.partial-access {
        color: $c-constant-partial-txt;
      }
      &.full-access {
        color: $c-constant-full-txt;
      }
    }


  table.options tbody span.userpic {
    margin-right: .3em;
    vertical-align: -.35em;
  }

  table.options .empty {
    display: block;
    border-bottom: 1px solid $c-tbl-brd;
    padding: 8px 10px;
    color: $c-tbl-empty-text;
  }
  table.options tr.empty {
    display: table-row;
    border-bottom: 1px solid $c-tbl-brd;
    padding: 0;
    color: #AAA;

    td {
      padding: 8px 10px;
      text-align: left;
    }
  }
  table.options .total {
    display: block;
    margin-top: 0;
    padding: 10px 26px;
    text-align: left;
  }

  table.options .search-field-container {
    .search-reset {
      display: none;

      &.show {
        display: inline;
        margin-left: .25em;
        white-space: nowrap;
      }
    }
  }

  .tbl-name {
    display: inline-block;
    max-width: 360px;
    padding-right: 2px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    #specialPage #page & {
      width: auto;
      min-width: 0;
      max-width: 166px;
    }
    .color-badge--repo {
      vertical-align: -.15em;
    }
  }


/* Permissions */
table.options.permissions thead td {
  padding-left: 5px;
}


/* Email Notifications */

table.options.notifications thead td a.link-help {
  border-bottom: 1px dashed mix($c-content-bg, $c-text, 50%);
  color: $c-text !important;
}
table.options.notifications thead td a.link-help:hover {
  border-bottom: 1px solid mix($c-content-bg, $c-dark, 50%);
  color: $c-dark !important;
}
table.options.notifications tbody th {
  width: 60%;
  min-width: 300px;
}
table.options.notifications tbody td {
  min-width: 110px;
  text-align: left;
}
table.options.notifications tbody tr:nth-child(odd) th,
table.options.notifications tbody tr:nth-child(odd) td {
  background-color: $c-tbl-bg;
}

table.options.notifications.w-scroll {
  margin-right: 11px;
}


/* Branches access */

table.permissions--branch {
  margin-bottom: 10px;

  tbody th,
  tbody td {
    padding-top: 1.1em;
    padding-bottom: 1.1em;
    vertical-align: top;
  }
  .tb-actions {
    padding-top: 1.33em;
  }
}

  .branch-merge-users {
    $spacing: .75em;

    margin-bottom: -$spacing;

    li {
      float: left;
      margin: 0 $spacing $spacing 0;
      list-style: none;
    }
      .userpic {
        vertical-align: middle;
      }
  }


// Scrollable container for permissions

.chmod {
  margin: 1em 0;

  &.is-scrollable {
    position: relative;
    z-index: $zi-chmod-scrollable;
    overflow: hidden;

    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zi-chmod-scrollable-after;
      height: 0;
      box-shadow: 0 0 3em 3em rgba(#FFF, 0);
      transition: box-shadow 250ms;
    }
  }
  &.is-faded {
    &::after {
      box-shadow: 0 0 3em 3em rgba(#FFF, .75);
    }
  }
  table.options {
    margin-top: 0;
  }
}
  .chmod-wrap {
    position: relative;
    max-height: 300px;
    overflow: hidden;
  }


// Filtering users

.users-filter {
  padding-top: .15em;
}

#users-list {
  margin: -.15em -5px -5px;
  overflow: hidden;
}
  #users-list li {
    float: left;
    width: 25%;
    list-style: none;
  }
    #users-list a,
    #users-list .disabled,
    .users-list_item {
      display: block;
      position: relative;
      margin: 5px;
      border: 1px solid $c-user-brd;
      border-radius: 3px;
      padding: 10px 20px 10px 57px;
      background-color: $c-user-bg;
      color: $c-user-txt;
      font-weight: bold;
      text-decoration: none;
      text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
      transition: all .2s ease;
    }
    .users-list_item {
      border-color: $c-users-list-item-brd;
      background-color: $c-users-list-item-bg;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    #users-list .fade {
      opacity: .2;
    }
    #users-list a:hover {
      border-color: $c-user-l-brd !important;
      background-color: $c-user-l-bg !important;
      color: $c-user-l-txt !important;
    }
      #users-list img {
        position: absolute;
        top: 1em;
        left: 1em;
        width: 32px;
        height: 32px;
        border-radius: 2px;
      }
      #users-list span {
        display: block;
        margin-top: .25em;
        color: $c-user-sep;
        font-weight: normal;
        font-size: .85em;
        white-space: pre;
      }
      #users-list sup.role {
        position: absolute;
        top: -1px;
        right: -1px;
        border-radius: 0 3px 0 0;
        padding: .4em .5em;
      }

    // Admin

    #users-list .disabled {
    }

    // Invited

    #users-list li.invited a {
      background: none !important;
    }

/* Fixed Header module */

.fixed-header {
  margin-top: 1.5em;

  .chmod {
    margin-top: 0;
  }
  table.options thead td {
    width: 205px;
    padding-left: 0;

    #specialPage & {
      width: 145px;
      padding-right: 10px;
    }
  }
}
.fixed-header-table {
  margin-top: 0 !important;

  //margin-bottom: -14px !important;
  &.w-scroll {
    td:last-child,
    th:last-child {
      //padding-right: 5px;
    }
    &.two-columns-table {
      #specialPage #page & {
        th:last-child,
        td:last-child {
          width: 270px;
        }
      }
    }
  }
  #specialPage #page & {
    margin-bottom: 0 !important;
  }
}
