/* Code Review page
------------------------------ */

$cr-resolution-h: 130px;

.content--cr {
  padding-bottom: ($cr-resolution-h + 4px);
}

.cr-container {
  box-sizing: border-box;
  margin: 0 -30px;
  background-color: $c-content-bg;

  &.block-loading {
    min-height: 120px;
  }
  .box {
    margin: 0;
    border: 0;
    padding: 30px;
  }
  .message {
    box-sizing: border-box;
    width: 600px;
    margin: 20px 0 15px $cr-timeline;

    @media (min-width: 1100px) {
      width: 750px;
    }
  }
}

/* Request review HUD
------------------------------ */

.cr-request {
  .form-row {
    margin-bottom: 1em !important;
  }
    .form-label {
      color: $c-mute;
      font-size: .92em;
    }
    input[type='text'] {
      margin: 0;
      border: 1px solid #CCC;
      padding: .5em;
      font: 1em $f-sansserif;

      &:focus {
        border-color: $c-hud-search-f-brd;
        box-shadow: 0 0 1px 1px $c-hud-search-f-bsh;
        outline: none;
      }
    }
    .i-branch {
      &::before {
        margin-right: .1em;
        margin-left: .3em;
        vertical-align: -3px;
      }
    }
}
  .cr-request_source {
    @extend .clearfix;

    margin: (-$hud-space) (-$hud-space) $hud-space;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #EEE;
    padding: 1em $hud-space .25em;
    background-color: #F9F9F9;

    .form-row {
      float: left;
      width: (374px - $hud-space) / 2;

      &:first-child {
        margin-right: $hud-space;
      }
      .form-text {
        margin-top: .5em;
        margin-left: -.85em !important;
        overflow: hidden;
        font-size: .92em;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .cr-request_exists {
    padding: 1.25em 0;
    text-align: center;
  }
  .cr-request_create {
  }
    .fix-overflow {
      width: 374px;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }


/* Header
------------------------------ */

.cr-head {
  @extend .clearfix;

  margin: -30px -30px 0;
  padding: 30px;
  background-color: $c-olive-lightest;
  color: mix(#F8FAF5, $c-cs-msg-txt, 33%);

  p {
    font-size: 1.077em; // 14px
  }
  strong {
    color: mix(#F8FAF5, $c-cs-msg-txt, 25%);
  }
  .status-label {
    margin-right: 0;
    margin-left: .15em;
  }
}
  .cr-head_wrap {
    float: left;
    box-sizing: border-box;
    width: 60%;
    padding-right: 30px;

    &.is-approved,
    &.is-cancelled {
      position: relative;
      padding-left: 50px;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        width:  36px;
        height: 36px;
        background: url('../images/i-checkmark-big.svg') no-repeat;
        background-size: 36px 72px;
      }
    }
    &.is-approved {
      &::before {
        background-position: 0 0;
      }
    }
    &.is-cancelled {
      &::before {
        background-position: 0 -36px;
      }
    }
  }
  .cr-head_aside {
    margin-left: 60%;
    border-left: 1px solid mix(#FFF, $c-olive-light, 66%);
    padding: .3em 0 .3em 30px;
  }
    .cr-branch {
      margin: 0 0 .3em;
      color: inherit;
      font-weight: 200;
      font-size: 1.385em; // 18px
      line-height: normal;

      strong {
        color: $c-cs-msg-txt;
        font-weight: bold;
      }
      .i-branch::before {
        margin-right: .25em;
        margin-left: .25em;
        vertical-align: -.1em;
      }
    }
    .cr-meta {
      margin: 0;

      .i-branch::before {
        margin-right: .15em;
        margin-left: .25em;
        vertical-align: -3px;
      }
    }
    .cr-objectives {
      margin: .45em 0 0;
      font-style: italic;
    }
      .cr-objectives_editable {
        &:hover {
          background-color: $c-cs-msg-h-bg;
          cursor: pointer;
        }
      }
        .cr-objectives_empty {
          color: $c-mute;
        }

    // Description editing form

    .cr-objectives_form {
      margin-top: .5em;

      textarea {
        box-sizing: border-box;
        width: 100%;
        height: 4em;
        margin: 0;
        border: 1px solid $c-cmt-form-brd;
        padding: .3em .6em;
        font: 1.077em $f-sansserif;
      }
      .form-buttons {
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: $c-cmt-form-brd;
        padding: 1em .75em;
        background-color: $c-cmt-form-bg;
      }
    }

    // Summary

    .cr-params {
      dt {
        float: left;
        width: 70px;
        padding-top: .1em;
        color: mix(#F8FAF5, $c-cs-msg-txt, 66%);
        font-size: .923em;
        text-align: right;
        white-space: nowrap;
      }
      dd {
        margin-bottom: .5em;
        margin-left: 85px;

        &:last-child {
          margin-bottom: 0;
        }
        &.cr-params_assignees {
          margin-bottom: 0;
        }
      }
        .cr-assignee {
          display: inline-block;
          margin-right: .75em;
          margin-bottom: .5em;
          white-space: nowrap;
        }
        .cr-unassigned {
          display: inline-block;
          margin-right: .75em;
          margin-bottom: .5em;
          font-style: italic;
        }
        .userpic {
          margin-right: .15em;
          margin-left: 0;
          vertical-align: -4px;

          &.is-approved {
            position: relative;
            margin-top: -4px;
            border: 2px solid #50B369;
            border-radius: 2px;
            padding: 2px;

            &::before {
              content: '';

              position: absolute;
              right: -1px;
              bottom: -1px;
              width:  11px;
              height: 11px;
              background: url('../images/i-userpic-approved.svg') no-repeat;
              background-size: 11px 11px;
            }
            b {
              display: none;
            }
          }
        }
        .i-branch {
          &::before {
            margin-right: .15em;
            margin-left: 0;
            vertical-align: -.2em;
          }
        }
        .icon-pending {
          &::before {
            margin-right: .4em;
            margin-left: 0;
            vertical-align: -.1em;
          }
        }
    }



// Subscribe to CR

.cr-subscribtion {
  display: inline-block;
  vertical-align: bottom;
}


/* Watchers & Assignees HUD
------------------------------ */

// Add a new user

.hud_add-users {
  margin: $hud-space (-$hud-space) (-$hud-space);
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #EEE;
  padding: $hud-space;
  background-color: #FAFAFA;
}

// Manageable list of users

.hud_users-external {
  width: 196px;
  margin: 0 -6px;

  &.is-scrollable {
    position: relative;
    z-index: $zi-chmod-scrollable;
    overflow: hidden;

    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zi-chmod-scrollable-after;
      height: 0;
      box-shadow: 0 0 1.5em 1.5em rgba(#FFF, 0);
      transition: box-shadow 250ms;
    }
  }
  &.is-faded {
    &::after {
      box-shadow: 0 0 1.5em 1.5em rgba(#FFF, .75);
    }
  }
}
  .hud_users-fail {
    padding: .5em 0;

    p {
      margin: .25em 0;
      color: inherit;
      font-size: .92em;
      text-align: center;
    }
  }

  .hud_users-container {
    max-height: 265px;
    overflow: auto;

    .is-scrollable & {
      position: relative;
      overflow: hidden;
    }
  }
    .hud_users-list {
      box-sizing: border-box;

      .is-scrollable & {
        padding-right: 12px;
      }
    }
      .hud_users-list_indv {
        position: relative;
        padding: 6px 28px 6px 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.is-empty {
          padding: 12px 6px;
          color: $c-mute;
          font-size: .9em;
          text-align: center;
          white-space: normal;
        }
        .userpic {
          margin-right: 2px;
          vertical-align: -.33em;
        }
        .color-badge {
          margin-right: 5px;
          margin-left: 4px;
          vertical-align: -.05em;
        }
        .a-remove {
          position: absolute;
          top: 50%;
          right: 6px;
          margin-top: -(16px/2);
          opacity: .33;

          &.loading {
            opacity: 1;
          }
        }
      }
      .hud_users-list_indv:hover {
        border-radius: 3px;
        background-color: #F9F9F9;

        .a-remove {
          opacity: 1;
        }
      }



/* Review resolution
------------------------------ */

// Commit

.commit-resolution {
  margin: 30px 0 0;
  border-radius: 0 0 3px 3px;
  border-top: 2px solid $c-approval-brd;
  padding: 30px;
  background: $c-approval-commit-bg;
  color: $c-approval-commit-txt;
  text-align: center;

  form {
    display: inline;
  }
    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: $c-base;
      }
    }
    .button {
      margin-right: .5em;
    }
    .icon-loading {
      margin-right: .25em;
    }
    .status-label {
      &::before {
        vertical-align: -.25em;
      }
    }
}
  .commit-resolution_action {
  }
  .commit-resolution_loading {
    color: mix($c-approval-branch-bg, $c-base, 50%);
  }
  .commit-resolution_error {
  }


// Branch

.cr-resolution {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 2px 0;
  border-style: solid;
  border-color: $c-approval-brd;
  background: $c-approval-branch-bg;
  font-size: 1.1em;
  text-align: center;

  &.is-conflicted {
    border-color: #EEE;
    background: #F9F9F9;
  }
  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: $c-base;
    }
  }
  .inline-button {
    color: $c-dark;
    font-weight: bold;
  }
}
  .cr-resolution_wrap {
    display: table;
    width: 100%;
    height: $cr-resolution-h;
  }
    .cr-resolution_form {
      display: table-cell;
      vertical-align: middle;
    }

  .cr-resolution_merge {
    margin: -.33em 0 1.25em -.5em;
    color: mix($c-approval-branch-bg, #000, 25%);

    .i-branch {
      &::before {
        margin-right: .15em;
        margin-left: .3em;
      }
    }
  }
  .cr-resolution_loading {
    color: mix($c-approval-branch-bg, $c-base, 50%);

    .icon-loading {
      margin-right: .25em;
    }
  }
  .cr-resolution_loading-failed {
  }
  .cr-resolution_action {
  }
  .cr-resolution_conflict {
  }
  .cr-resolution_issues {
  }
  .cr-resolution_merging {
    @extend .cr-resolution_loading;
  }
  .cr-resolution_merged {
  }
  .cr-resolution_merge-failed {
  }
