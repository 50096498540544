/* Special Page Layout
------------------------------ */

#specialPage {
  background-color: #EEF7E4;
  background-image: linear-gradient(to bottom, #E7F2DA, $c-green-medium);
  background-attachment: fixed;
  background-size: 100% 100%;
  color: $c-sp-txt;
  -webkit-font-smoothing: subpixel-antialiased;

  @media print {
    background: transparent none;
  }
}

  // Header

  .header-minimal {
    position: relative;
    width: 640px;
    margin: 0 auto 25px;
    padding-top: 40px;
    color: $c-sp-title;
  }
    .header-logo {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 31px;
    }
    .header-minimal-title {
      color: #24723C;
      font-weight: 200;
      font-size: 1.6em;
      line-height: .95;
      text-shadow: 0 1px 0 rgba(#FFF, .75);
    }


  // Page content


    #specialPage #page {
      position: relative;
      width: 720px;
      margin: 0 auto;
      border-radius: 2px;
      padding: 0 0 40px;
      overflow: visible !important;
      background: none;
    }
      #specialPage #page .content {
        border-radius: 2px;
        padding: 40px 40px 20px;
        background-color: $c-content-bg;
        box-shadow: 0 0 0 3px rgba($c-green-dark, .05);
      }
      #specialPage #page h2 {
        margin: 0 0 1em;
        padding: 0;
        font-size: 1.25em;
      }
        #specialPage #page h2 span {
          color: $c-light;
          font-weight: normal;
        }
        #specialPage #page h2 span.sep {
          color: $c-sp-sep;
        }
      #specialPage #page ul,
      #specialPage #page ol {
        padding-left: 1.8em;
      }
        #specialPage #page ul li,
        #specialPage #page ol li {
          padding: 0 0 .5em;
        }
      #specialPage #page p {
        margin: 0 0 1em;
      }
      #specialPage #page p.last {
        margin-bottom: 2em !important;
      }
      #specialPage #page p.comment {
        margin: 0 0 1em;
        padding: 0 0 0 25px;
        background-position: 0 1px;
        color: $c-sp-meta;
      }

    #specialPage a.a-abort {
      margin-left: .5em;
      color: $c-sp-meta;
    }

    #specialPage #page .loading-indicator {
      padding: 2em 0 4em;
    }
      #specialPage #page .loading-indicator p {
        padding: 0 6em;
        color: $c-sp-meta;
        font: italic 1em/1.5 $f-serif;
      }

    /* Warning */

    #specialPage .warning {
      margin: 0 0 2em !important;
      border-radius: 5px;
      padding: .75em 1em;
      background-color: $c-sp-warning-bg;
      color: $c-sp-warning-txt;
    }
    #specialPage .form-field .warning {
      margin: .75em 0 !important;
    }

      #specialPage .warning strong {
        margin-right: .25em;
        color: $c-base;
      }


    /* URL Preview */

    #repository-url-preview {
      position: relative;
      margin: 0 0 1.5em;
      border: 1px solid $c-url-brd;
      border-radius: 5px;
      padding: 1em;
      text-align: center;
    }
      #repository-url-preview kbd {
        color: $c-text;
        font-size: 11px;
      }


    /* RSS Feed URL */

    #specialPage .rss-url {
      position: relative;
      width: 640px;
      margin: 1.5em -39px 2em;
      padding: 1.5em 39px;
      overflow: hidden;
      background-color: $c-green-darkish;
      color: #FFF;
      box-shadow: 0 1px 1px rgba($c-green-dark, .25);
      font-family: $f-monospace-fb;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
      #specialPage .rss-url a {
        color: inherit;
      }


/* Forms for Special Page
------------------------------ */

#specialPage fieldset {
  margin: 0 0 40px;
  border-top: 1px solid $c-sp-form-brd;
  padding-top: 1em;
  color: $c-sp-form-txt;

  &.no-title,
  &.no-border {
    border-top: 0;
    padding-top: 0;
  }
}
  #specialPage legend {
    padding-right: .75em;
    background-color: $c-content-bg;
    color: $c-sp-form-title;
    font-weight: bold;
    font-size: 1.0769em; // 14px

    .color-badge {
      margin-right: .15em;
      vertical-align: -.15em;
    }
  }
    .optional-section {
      margin-left: .5em;
      border-radius: 2px;
      padding: .2em .4em;
      background-color: #CFE58A;
      color: #454D2E;
      font-weight: bold;
      font-size: .8em;
      text-transform: uppercase;
      text-shadow: 0 1px 0 rgba(#FFF, .4);
    }

    .legend-or {
      display: inline-block;
      margin-right: .33em;
      border-radius: 2px;
      padding: .2em .5em;
      vertical-align: .05em;
      background-color: $c-green-darkish;
      color: #FFF;
      font-size: .8em;
      text-align: center;
      text-transform: uppercase;
    }
  #specialPage #page fieldset .message {
    margin: 2em 0 0;
  }


/* Form Rows */

#specialPage #page .form-row {
  margin: 0 !important;
  padding: .75em 0;
}
#specialPage #page .form-row.detached {
  padding-top: 1.5em;
}
#specialPage #page .form-row.attached {
  padding-top: 0;
}
  #specialPage #page .form-row p.hint {
    margin: .75em 0 0 !important;
  }
  #specialPage #page .form-row p.detached {
    margin-top: 1.25em !important;
  }
    #specialPage #page .form-row .form-label,
    #specialPage #page .form-row .form-label label {
      color: $c-sp-form-txt;
      text-align: right;
    }
    #specialPage #page .form-row .form-label label span {
      color: $c-sp-form-blank;
    }

  #specialPage #page .form-row.aligned .form-label {
    padding-top: .25em;
  }


  #specialPage #page .form-row .form-field {
    margin-right: 0;
  }
  #specialPage #page .form-row .form-text {
    margin-right: 0;
    margin-left: 150px;
  }
    #specialPage #page .form-row .form-field input,
    #specialPage #page .form-row textarea,
    #specialPage #page .form-submit input {
      margin: 0 0 .3em;
      padding: .3em;
      font: normal 1em/1.4 $f-sansserif;
    }
    #specialPage #page .form-row input[type='radio'],
    #specialPage #page .form-row input[type='checkbox'] {
      margin-right: .2em;
      margin-left:  .2em;
      padding: 0;
      vertical-align: middle;
    }
    #specialPage #page .form-row input.security-code {
      width: 5em;
      margin: -.3em 0 0;
      padding: .1em .4em;
      vertical-align: middle;
      color: #444;
      font: 1.75em $f-monospace-fb;
      letter-spacing: .2em;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
    #specialPage #page .form-row input.recovery-code {
      width: 10.5em;
      margin: -.3em 0 0;
      padding: .1em .4em;
      vertical-align: middle;
      color: #444;
      font: 1.75em $f-monospace-fb;
      letter-spacing: .2em;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }

    #specialPage #page .form-row .form-field select {
      margin: .35em 0 0;
    }
    #specialPage #page .form-row .form-field img {
      display: block;
    }
    #specialPage #page .form-row .form-field span.sep {
      color: $c-sp-form-blank;
      font-size: 12px;
    }
    #specialPage #page .form-row .form-field.environments a {
      border-radius: 2px;
      padding: .2em .4em;
      background-color: $c-sp-form-btn-bg;
      color: $c-sp-form-btn-txt;
      text-decoration: none;
      text-shadow: 0 1px 0 #FFF;
      white-space: nowrap;
    }


  /* Form Comment */

  #specialPage #page .form-row .form-comment {
    margin: .4em 0 0 !important;
    padding-left: 160px;
  }
  #specialPage #page .form-row.form-row--short .form-comment {
    padding-left: 100px;
  }
    #specialPage #page .form-row .form-comment,
    #specialPage #page .form-row .form-comment p {
      margin: 0;
      color: $c-sp-form-meta;
      line-height: 1.5;
    }
    #specialPage #page .form-row .form-comment.notice p {
      margin: 0 0 1em;
      border-radius: 3px;
      padding: .5em .75em;
      background-color: $c-sp-notice-bg;
      color: $c-sp-notice-txt;
      font: normal 1em/1.6 $f-sansserif;
    }
    #specialPage #page .form-row .form-comment a {
      color: $c-sp-notice-l;
      text-decoration: underline;

      &:hover {
        color: $c-sp-notice-h;
      }
    }



  /* Form Toggle */

  .form-toggle {
    padding-left: 160px;
  }
    .form-toggle p {
      @extend .icon-collapse;
    }
      .form-toggle a {
        border-bottom: 1px dashed mix($c-content-bg, $c-link, 50%);
        text-decoration: none;

        &:hover {
          border-bottom-style: solid !important;
        }
      }

    .form-toggle.show p {
      @extend .icon-collapse-selected;
    }
    .form-toggle.disabled p,
    .form-toggle.disabled.show p {
      &::before {
        background-color: mix(#FFF, $c-mute, 70%) !important;
        background-position: 0 0 !important;
      }
    }
      .form-toggle.show a {
        border-bottom-color: mix($c-content-bg, $c-mute, 50%);
        color: $c-mute;
      }
      .form-toggle.disabled a {
        border-bottom-color: mix(#FFF, $c-mute, 70%) !important;
        color: mix(#FFF, $c-mute, 70%) !important;
      }


  /* Form Help */

  #specialPage #page p.form-help {
    padding-left: 160px;
    color: $c-mute;
    font: italic .9em/1.6 $f-serif;
  }


  /* Errors */

  #specialPage #page .form-error {
    background-color: transparent;
  }
  #specialPage #page .form-textarea.form-error,
  #specialPage #page .form-row.vertical.form-error {
    border-radius: 2px;
    padding: 10px !important;
    background-color: $c-form-boxed-error-bg;
  }
    #specialPage #page .form-error .form-label label {
      color: $c-form-boxed-error-txt !important;
      font-weight: bold;
    }
    #specialPage #page .form-error p.error-msg {
      margin: 0 !important;
      color: $c-form-boxed-error-txt;
      font-weight: normal;
    }
    #specialPage #page .form-error > p.error-msg {
      padding-left: 160px;
    }
    #specialPage #page .form-error.vertical > p.error-msg {
      padding-left: 0;
    }
    #specialPage #page .form-error .form-field.i-branch p.error-msg {
      margin-top: .33em !important;
      padding-left: 21px;
    }

  #specialPage #page .form-notice {
    margin: 1em -40px 0;
    background: url('../images/i-error-box.svg') no-repeat 175px .2em;
    background-size: 15px 13px;
  }
    #specialPage #page .form-notice p {
      margin: 0 !important;
      padding: 0 40px 0 200px;
      color: $c-text;
      font-weight: bold;
      text-shadow: 0 1px 0 #FFF;
    }


/* Vertical form */

#specialPage #page .form-row.vertical {
  &.form-url {
    padding-top: 0;
  }
  .form-label {
    float: none;
    width: auto;
    padding-top: 0;
    padding-left: 0;
  }
  .form-label,
  .form-label label {
    text-align: left;
  }
  .form-field,
  .form-text {
    margin-left: 0;
  }
  .form-text {
    margin-bottom: .75em;
  }
  .form-comment {
    margin-top: .2em !important;
    padding-left: 0;
  }
}


/* Form Submit */

#specialPage #page .form-buttons {
  margin: 0 -40px -20px !important;
  border-radius: 0 0 2px 2px;
  border-top: 1px solid $c-form-buttons-brd;
  padding: 30px;
  background-color: $c-form-buttons-bg;
  color: $c-text;
  text-align: center;

  @media print {
    display: none !important;
  }
}
#specialPage #page .form-buttons--red {
  border-top-color: #EBDBD8;
  background-color: #FAF4F2;
}
#specialPage #page .form-buttons.separate {
  margin-top: 2em !important;
}
#specialPage #page .form-buttons.actions {
  margin-top: 2em !important;
  padding-top: 2em;
}

  #specialPage #page .form-buttons .button-alt {
    color: $c-form-buttons-txt;
  }
  #specialPage #page .form-buttons input {
    // TODO: replace @extend with class names on elements
    @extend .button;
    @extend .button--big;

    margin-right: .25em !important;
  }


/* Form Submit Notice */

#specialPage #page .form-buttons-notice {
  margin: 0 -40px;
  border-top: 1px solid $c-sp-brd;
  padding: 30px 90px;
  color: $c-sp-meta;
  font: italic 1em/1.6 $f-serif;
  text-align: center;
}
  #specialPage #page .form-buttons-notice a {
    color: $c-text;
    text-decoration: underline;
  }


/* Dialog Buttons */

#specialPage .dialog-buttons {
  margin: 40px -40px -20px !important;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: $c-form-buttons-brd;
  padding: 40px;
  background-color: $c-form-buttons-bg;
  color: $c-text;
}
  #specialPage .dialog-buttons h4 {
    margin: -.5em 0 1.5em;
    color: $c-base;
    font-size: 1.1em;
  }
  #specialPage .dialog-buttons p {
    margin: 0 0 .75em !important;
  }
  #specialPage .dialog-buttons p:last-child {
    margin: 0 !important;
  }

  #specialPage .dialog-buttons a.button {
    min-width: 3em;
    margin-right: .75em;
    vertical-align: middle;
  }


/* Repository title */

.form-repo-title {
  position: relative;
  float: left;
  width: 70%;
}
.form-repo-color {
  margin-top: .2em;
  margin-left: 70%;
  padding-left: 20px;

  .color-badge {
    margin-left: .35em;
  }
  .color-badge--repo,
  .color-badge--env {
    vertical-align: -.3em;
  }
  .color-badge--team {
    vertical-align: -.2em;
  }
}


/* VCS Selection */

#repository-type-selector {
  label {
    display: inline-block;
    margin-right: .5em;
    border-radius: 3px;
    padding: .5em 1em;
    font-weight: bold;
    text-shadow: 0 1px 0 #FFF;
    cursor: pointer;

    &.subversion {
      background-color: #E1E1F2;
      color: #8787AD;
    }
    &.git {
      background-color: #DDF0ED;
      color: #779993;
    }
    input {
      margin-right: .25em !important;
    }
  }
  .link-help {
    margin-left: .75em;
    font-size: .923em;
  }
}


/* Shopify title */

.title-shopify {
  margin: .15em 0 .75em !important;
}
.logo-shopify {
  display: inline-block;
  width: 70px;
  height: 21px;
  margin-bottom: -1px;
  margin-left: .2em;
  vertical-align: text-bottom;
  overflow: hidden;
  background: url('../images/logo-shopify.png') no-repeat;
  text-indent: -999em;

  @include retina {
    background-image: url('../images/logo-shopify@2x.png');
    background-size: 70px 21px;
  }
}


/* Notices */

#specialPage p.notice {
  margin: 1em 0 !important;
  border-top: 1px solid $c-sp-brd;
  padding: 2em 4em 0;
  color: $c-sp-meta;
  font: italic 1em/1.5 $f-serif;

  @media print {
    padding-right: 0;
    padding-left:  0;
    text-align: center;

    a::after {
      content: ' (' attr(href) ') ';

      font-style: normal;
    }
  }
}


/* Select Revision */

.select-revision {
  display: inline-block;
  position: relative;
  max-width: 346px;  // 380px - 20px - 10px - (2px * 2)
  margin-right: .5em;
  border: 2px solid $c-select-rev-brd;
  border-radius: 3px;
  padding: .3em 20px .3em 10px;
  vertical-align: middle;
  overflow: hidden;
  color: #666;
  font-size: 1em !important;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 6px;
    width: 7px;
    height: 4px;
    margin-top: -2px;
    background: url('../images/arrow-dropdown.svg') no-repeat;
  }
}
  .select-revision_rev {
    color: $c-select-rev-txt;
    font-weight: bold;

    .select-revision.empty & {
      color: #AAA;
      font-weight: normal;
    }
  }
  .select-revision_msg {
    margin-left: .2em;
    font-weight: normal;
  }
a.select-revision:hover {
  background-color: $c-select-rev-h-bg;
  text-decoration: none;
  text-shadow: 0 1px 0 #FFF;
}


/* Export Repository
------------------------------ */

.export-in-progress {
  color: $c-mute;
  font: italic 1.1em $f-serif;
  text-align: center;

  .block-loading,
  .block-action-completed {
    min-height: 120px;
  }
  .block-action-completed {
    background: url('../images/i-yes-big.svg') no-repeat 50% 50%;
    background-size: 50px 50px;
  }
}


/* Data Import
------------------------------ */

#data-import {
  padding-bottom: 20px;
}
  #data-import .import-option {
    border-bottom: 1px solid $c-sp-brd;
    padding: 20px 0;
  }
  #data-import .import-option:first-of-type {
    padding-top: 0;
  }
  #data-import .import-option:last-of-type {
    border: 0;
    padding-bottom: 0;
  }

    #data-import .import-option p {
      margin: 0 !important;
    }
      #data-import .import-option p > label {
        color: $c-dark;
        font-weight: bold;
      }
    #data-import .import-option .details {
      margin: .5em 20px 0;
    }
      #data-import .import-option .details p {
        margin: 0 0 1em !important;
      }
      #data-import .import-option .details p.hint {
        margin: 1em 0 0 !important;
      }


/* Adjusted Forms */

#data-import .form-row {
  margin: 1em 0 1.5em !important;
  border-radius: 5px;
  padding: .4em 0 !important;
  background-color: $c-data-import-bg;
  text-align: center;
}
#data-import .form-row.form-error {
  @extend .icon-warning;
  @extend .icon-warning--light;

  position: relative;
  background-color: $c-data-import-error-bg !important;

  &::before {
    position: absolute;
    top: 50%;
    left: 1.5em;
    margin-top: -7px;
  }
}
  #data-import .form-field {
    margin: 0 !important;
    padding: 1em 20px .9em;
  }
    #data-import .form-field label {
      font-weight: bold;
    }


/* URL List */

#data-import ul.url-list {
  margin: 1em 0 20px;
  padding: 0 !important;
}
  #data-import ul.url-list li {
    margin: .5em 0 0 !important;
    padding: 0 !important;
    font: .81em/1.5 $f-monospace-fb;
    list-style: none;

    .fonts-loaded & {
      font-family: $f-monospace;
    }
  }

/* Two Factor Recovery Codes */
#specialPage ul.recovery-codes {
  text-align: center;
  padding-left: 0 !important;
  column-count: 2;
  color: #444;
  font: 1.2em $f-monospace-fb;
  letter-spacing: .1em;

  .fonts-loaded & {
    font-family: $f-monospace;
  }
}
