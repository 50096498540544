/* New Form Design
------------------------------ */

.forms {
  &.grouped {
    margin-bottom: 30px;
  }
  &.boxed,
  &.single {
    border-bottom: 2px solid $c-form-boxed-brd;
  }
  &.separated {
    margin-top: 15px;
  }
}
  .forms fieldset {
    border-width: 0 2px 1px;
    border-style: solid;
    border-color: $c-form-boxed-brd;
    padding: 20px;

    &:first-of-type {
      border-top-width: 2px;
    }
    &.first {
      border-top-width: 2px;
    }
    &:last-of-type {
      border-bottom-width: 0;
    }
    &.compact {
      padding-bottom: 0;
      overflow: hidden;

      h3 {
        margin: -.5em 0 .25em;
      }
    }
  }

  .forms h3 {
    margin: -.25em 0 .75em;
    color: $c-form-boxed-title;
    font-size: 1.2em;
  }

  .forms p {
    margin: 0;
    padding: 0;
    color: $c-form-boxed-txt;

    + p {
      margin-top: .5em;
    }
  }
  .forms .form-row + p {
    margin: -.5em 0 1em;
  }
    .forms p a {
      text-decoration: underline;
    }
    .forms p a:hover {
      color: $c-form-boxed-h;
      text-decoration: none;
    }

  .forms p.detached {
    margin: 1.5em 0 .75em;
  }
  .forms p.blank {
    color: $c-form-boxed-blank;
  }
  .forms p.warning {
    padding-left: 22px;
    background: url('../images/i-error-box.svg') no-repeat 0 center;
    background-size: 15px 13px;
    color: $c-form-boxed-txt;
  }

  .forms br {
    clear: both;
  }

  .forms strong.highlight {
    border-radius: 3px;
    padding: .25em .5em;
    background-color: $c-mark-bg;
    color: $c-mark-txt;
    font: .92em $f-monospace-fb;
    text-shadow: 0 1px 0 #FFF;

    .fonts-loaded & {
      font-family: $f-monospace;
    }
  }


  /* Forms Rows */

  .l-vertical {
    margin: 1em 0 1.5em;

    &:last-of-type {
      margin-bottom: 1em;
    }
    .form-label {
      float: none;
      width: auto;
      padding: 0;
      text-align: left !important;
    }
    .form-field {
      margin: 0 !important;
      padding: .5em 0;
    }
    .form-text {
      margin-left: 0 !important;
    }
  }

  .l-vertical.form-row--compact {
    margin: .5em 0 1em;

    .form-field {
      padding: .2em 0;
    }
  }


  .forms .form-row {
    @extend .l-vertical;

    .form-label {
      label {
        color: $c-form-boxed-label;
        font: italic .9em/1.5 $f-serif;
      }
    }
    .form-field {
      input[type='text'],
      input[type='password'] {
        padding: .3em;
        font: normal 1em/1.4 $f-sansserif;
      }
      select {
        margin: .65em 0 0;
        vertical-align: top;
      }
      code {
        display: inline-block;
        border-radius: 2px;
        padding: .25em .5em;
        background-color: $c-form-boxed-code-bg;
        color: $c-form-boxed-code-txt;
        text-shadow: 0 1px 0 #FFF;
      }
    }
  }

  .form-submit.detached {
    margin-top: 1em;
    padding: .5em 0;
  }

  .form-row.align,
  .form-submit.align {
    float: left;
    width: 240px;
    margin-right: 20px;
  }
  .form-submit.align {
    @extend .l-vertical;

    padding-top: 2.25em;

    .icon-loading {
      vertical-align: -.15em;
    }
  }
    .form-row.align .form-field input[type='text'],
    .form-row.align .form-field input[type='password'],
    .form-row.align .form-field select {
      box-sizing: border-box;
      width: 100%;
    }
    .form-row.align .color-badge {
      margin: 2em 1px 0;
    }


  /* Form Submit */

  .forms .form-buttons {
    border: 2px solid $c-form-buttons-brd;
    border-top-width: 1px;
    padding: 30px 20px;
    background-color: $c-form-buttons-bg;

    &.loading::before {
      left: 20px;
      margin-left: 0;
    }
  }
    .forms .form-buttons .button-alt {
      color: $c-form-buttons-txt;
    }
    .forms .form-buttons input {
      // TODO: replace @extend with class names on elements
      @extend .button;
      @extend .button--big;
    }


  /* Errors Handling */

  .forms .form-error {
    padding: 0;
    background: none;
  }
    .forms .form-error .form-field input {
      background-color: $c-form-boxed-error-bg;
    }
    .forms .form-error .form-label {
      width: auto;
      padding: 0;
    }
    .forms p.error-msg {
      clear: both;
      padding: .5em 0 0;
      color: $c-form-boxed-error-txt;
    }

/* Form Subsection module */
// as used in invite user screen

.form-subsection {
  border: 1px solid $c-subsection-brd;
  padding: 20px 25px;
  color: $c-mute;
}

  .form-subsection-title {
    /* obscure stuff */
    margin: 0 !important;
    border: 1px solid $c-subsection-brd;
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
    padding: 12px !important;
    padding-left: 20px !important;
    background-color: $c-subsection-bg;
    color: $c-subsection-text;
  }

  .form-subsection-toggle {
    color: $c-subsection-text;

    .form-subsection-title {
      border: 1px solid $c-subsection-brd;
      border-radius: 4px;
    }
    .icon-collapse {
      &::before {
        background-color: $c-subsection-icon-bg;
      }
    }

    /* state */
    &.expanded {
      color: $c-subsection-icon-bg;

      .form-subsection-title {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
        color: $c-subsection-icon-bg;
      }
      .icon-collapse::before {
        background-position: 0 -9px !important;
      }
    }
    &.disabled {
      cursor: default !important;

      .form-subsection-title {
        opacity: .5;
      }
      &:hover {
        text-decoration: none !important;
      }
    }
  }
  .form-subsection-tbl {
    margin: 0 -25px -20px;

    th:first-child,
    td:first-child {
      padding-left: 25px !important;
    }
    th:last-child,
    td:last-child {
      padding-right: 25px !important;
    }
    .fixed-header-table thead th,
    .fixed-header-table thead td {
      border-bottom: 1px solid #F0F0F0;
    }
    .fixed-header-table tbody tr.action th,
    .fixed-header-table tbody tr.action td {
      border-bottom-color: #F0F0F0;
      background-color: #FAFAFA;
    }
    .chmod {
      margin-bottom: 0;
    }
  }
  .form-subsection-tbl .tbl-name {
    #specialPage #page & {
      width: 166px;
    }
  }
