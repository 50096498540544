/* Teams
------------------------------ */

/* layout */

.l-button-create-team {
  margin: 0;
}

.l-teams-content {
  .section-title {
    margin: 0;
  }
  .button {
    margin-left: 0 !important;
  }
}

.l-teams-row {
  margin-top: 10px;
  margin-right: -1%;
  margin-left: -.8%;

  @include smallscreen {
    margin-right: -1.1%;
    margin-left: -1.1%;
  }
}

.l-team {
  float: left;
  box-sizing: border-box;
  width: 23.35%;
  margin-top: 20px;
  margin-right: .8%;
  margin-left: .8%;

  @include smallscreen {
    width: 31.2%;
    margin-right: 1.067%;
    margin-left: 1.067%;
  }
}
.l-team-content,
.l-team-empty-content {
  box-sizing: border-box;
  height: 82px;
  padding-top: .5em;
  padding-bottom: 1em;
}



/* Team module */

.team {
  border: solid 1px $c-team-brd;
  border-radius: 5px;
  background-color: $c-team-bg;
  box-shadow: 0 0 5px rgba(0, 0, 0, .05);

  header {
    position: relative;
    border-radius: 5px 5px 0 0;
    background-color: #FFF;
  }
}

.team-content {
  padding-right: 1.2em;
  padding-left: 1.2em;
}

.team-title {
  position: relative;
  max-width: 75%;
  padding-top: .8em;
  padding-bottom: .8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .color-badge--team {
    margin-right: .15em;
    margin-bottom: -1px;
    margin-left: 1px; // Needed for white badges
    vertical-align: baseline;
  }
}
.team-users-number {
  color: $c-team-users-number-text;
  font-weight: normal;
  font-size: .85em;
}
.team-edit {
  position: absolute;
  top: 9px;
  right: 9px;
  margin: 0;
}
.team-users-wrapper {
  position: relative;
  width: 100%;
  min-width: 200px;
  height: 62px;
  overflow: hidden;
}
.team-users {
  position: relative;
  border-top: 1px solid $c-team-users-brd;

  .userpic {
    float: left;
    margin-top: .5em;
    margin-right: 5px;
  }
}
.team-empty-wrapper {
  height: 64px;
  overflow: hidden;
}
.team-empty {
  display: table;
  width: 70%;
  height: 55px;
  margin: 0 auto;
  margin-top: .5em;
  overflow: hidden;
  color: $c-team-empty-text;
  text-align: center;

  p {
    display: table-cell;
    position: relative;
    bottom: .2em;
    vertical-align: middle;
  }
}

/* js states */
.team-flexible-pic {
  height: auto !important; //contain image!

  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}


/* avatar-ellipsis state */
.avatar-ellipsis::after {
  content: '…';

  position: absolute;
  top: 0;
  left: 0;
  z-index: $zi-teams-avatar;
  width: 28px;
  height: 28px;
  background: $c-avatar-ellipsis-bg;
  color: $c-avatar-ellipsis-text;
  font-size: 1.2em;
  text-align: center;
}

/* Create / Edit Group Screen
-------------------------------------------- */
/* modules */
.options-repo-checkbox {
  display: inline-block;
  margin: 0 .4em 0 0;
}
.users-in-group-create {
  margin-top: .8em;
}

/* states */
.is-selected th,
.is-selected td {
  background: $c-list-bg;
}
.in-create-group {
  #group-label-demo {
    display: none;

    &.show {
      display: inline-block;
    }
  }
  .group-label {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 10px !important;

    a {
      vertical-align: bottom;
    }
  }
  .toggle-link {
    display: inline-block;
    margin-left: .5em;
    font-weight: normal;
    font-size: .85em;
  }
}
.in-group-edit {
  .forms {
    margin-top: 0;
  }
  .form-field {
    [name='group-color'] {
      width: auto !important;
    }
    .rev-repo {
      top: 0;
      font-style: normal !important;
      font-weight: bold !important;
      font-family: $f-sansserif !important;

      a {
        max-width: none;
        overflow: visible;
      }
    }
  }
  .label-color-row {
    width: 500px;
  }
  .search-field-container {
    padding-right: 15px;

    .search-field {
      width: 230px;
    }
    .search-reset {
      left: 212px !important;
    }
  }
  .color-badge,
  .options-repo-checkbox {
    margin-top: -.2em;
    vertical-align: middle;
  }

  //align email cells with access cells from team permissions
  .email {
    width: 412px !important;
    padding-right: 10px !important;
  }
}


/* User Screen with teams
-------------------------- */

/* layout */
.l-customize-in-user {
  margin-top: 26px;
}

/* modules */
.form-toggle-permissions {
  padding-left: 0;
}
.group-checkboxes {
  .permission.is-repo-inactive {
    background-color: transparent !important;
  }
  .options-repo-checkbox {
    margin-right: 7px !important;
    margin-left: 0 !important;
  }
}
.group-checkboxes .rev-repo {
  display: inline-block;
  margin-right: 10px !important;
  margin-bottom: 7px !important;

  input[type='checkbox'] {
    margin: 0 !important;
    margin-right: .3em !important;
  }
  .label-itself {
    max-width: none;
    padding: .2em .6em;
    overflow: visible;
  }
}
.permissions-warning {
  margin-top: 1.5em !important;
  padding: 20px 2em !important;

  /* obscure stuff */
  #specialPage #page & {
    margin-bottom: 1.5em !important;
  }
}
.form-subsection-teams {
  margin: -.5em -20px 0 0;
  padding-left: 0 !important;
  list-style: none;

  li {
    float: left;
    width: ((488px - 20px) / 2);
    margin: .75em 20px 0 0;
    padding: 0 !important;
  }
  .tbl-name {
    max-width: 185px !important;
  }
  .options-repo-checkbox {
    position: relative;
    bottom: .25em;
    margin-right: .6em !important;
    vertical-align: bottom;
  }
}
//blank slate
.invite-user-no-teams {
  .teams-invite-user {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  p {
    text-align: center;
  }
  p:last-child {
    margin-bottom: 0 !important;
  }
}
//in edit users page
.teams-edit-user {
  .teams-edit-user-intro {
    position: relative;
    z-index: $zi-teams-edit-user-intro;
  }
  .form-subsection-teams {
    position: relative;
    z-index: $zi-teams-form-subsection;
    width: 890px;
    margin-top: 0;

    li {
      width: 25%;
      margin-top: 1em;
      color: $c-text;
    }
    .tbl-name {
      max-width: 78% !important;
    }
  }
}

/* Repo screen with teams
-------------------------- */

/* layout */
.l-customize-in-repo {
  margin-top: .5em;
}
.l-groups-in-repo {
  #specialPage #page & {
    margin-bottom: 1.5em;
    padding-bottom: 0;
  }
}

/* modules */
.permissions-wrapper-in-edit-repo {
  max-height: 18em;
}
.options-in-edit-repo {
  @media only screen and (max-width: 1224px) {
    .search-field {
      width: 158px !important;
    }
    .search-field-container .search-reset {
      left: 139px !important;
    }
    .tbl-name {
      max-width: 122px;
    }
    .email {
      width: 30% !important;
      padding-left: 0 !important;
    }
  }

  .tbl-name {
    max-width: 186px;
  }
}
