@import 'variables';
@import 'colors';

/* Header
------------------------------ */

.header {
  position: relative;
  line-height: 17px;

  .count {
    display: inline-block;
    min-width: 1em;
    margin-left: .3em;
    border-radius: 2px;
    padding: 2px 3px 1px;
    vertical-align: baseline;
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    text-align: center;
  }
}
  .nav-item {
    display: inline-block;
    position: relative;
    margin: 0 .15em;
    white-space: nowrap;
    list-style: none;

    &:first-child {
      margin-left: 0;

      .metanav-item {
        margin-left: -.8em;
      }
      .metanav-item--current {
        margin-left: 0;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }



/* 1st Level: Meta Navigation
------------------------------ */

.metanav {
  padding: 1em 30px;
  background-color: $c-brown;
  color: $c-metanav-txt;

  @media print {
    display: none !important;
  }
}
  .meta-global {
    float: left;
  }
  .meta-personal {
    float: right;
  }

  .metanav-item {
    border-radius: 2px;
    padding: 5px 10px 7px;
    color: inherit;
    text-decoration: none;

    a {
      color: inherit;
      text-decoration: none;
    }
    u {
      background-image: linear-gradient(to bottom, transparent, transparent 14px, $c-metanav-brd 14px, $c-metanav-brd 15px, transparent 15px, transparent);
      text-decoration: none;
    }
    .count {
      border: 1px solid $c-metanav-brd;
    }
    .userpic {
      margin-top: -2px;
      margin-right: .33em;
      vertical-align: middle;

      b {
        border-color: transparent;
      }
    }
  }

  a.metanav-item:hover,
  span.metanav-item:hover {
    color: $c-metanav-h-txt;
    text-decoration: none;

    u {
      background-image: linear-gradient(to bottom, transparent, transparent 14px, $c-metanav-h-brd 14px, $c-metanav-h-brd 15px, transparent 15px, transparent);
    }
    .count {
      border-color: $c-metanav-h-brd;
    }
  }
  a.metanav-item:active {
    color: $c-metanav-h-txt;
  }


  // Current, Help and Trial

  .metanav-item--current {
    background-color: $c-metanav-cur-bg;
    color: $c-metanav-cur-txt;
    font-weight: bold;

    u {
      background-image: none !important;
    }
    .count {
      min-width: 0;
      border: 0;
      padding-right: 0;
      padding-left:  0;
      color: $c-metanav-cur-count-txt;
    }
    .userpic b {
      border-color: $c-metanav-cur-userpic-brd;
    }
  }
    a.metanav-item--current:hover {
      background-color: $c-metanav-cur-h-bg;
      color: $c-metanav-cur-h-txt;
    }

  .metanav-item--disabled {
    color: $c-metanav-dsb-txt;
  }

  .metanav-item--help {
    background-color: $c-metanav-help-bg;
    color: $c-metanav-help-txt;
    font-weight: bold;
  }
    a.metanav-item--help:hover {
      background-color: $c-metanav-help-h-bg;
      color: $c-metanav-help-h-txt;
    }

  .metanav-item--trial {
    background-color: $c-metanav-trial-bg;
    color: $c-metanav-trial-txt;
    font-weight: bold;
    font-size: .85em;
  }
    span.metanav-item--trial:hover {
      color: $c-metanav-trial-txt;
    }
    a.metanav-item--trial:hover {
      background-color: $c-metanav-trial-h-bg;
      color: $c-metanav-trial-h-txt;
    }



/* Bar
------------------------------ */

.header-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $c-hbar-bg-alt;
  padding: .7em 30px;
  background-color: $c-hbar-bg;
}

/* Title */

.header-title {
  flex: 0 1 auto;

  margin: 0;
  overflow: hidden;
  color: $c-hbar-txt;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;

  .color-badge {
    position: relative;
    top: 1px;
    margin-right: .1em;
    border-color: $c-hbar-brd;
    vertical-align: baseline;
  }
  .userpic {
    margin-right: .2em;
    vertical-align: top;
  }
}
  .header-title-meta {
    margin-left: .25em;
    color: $c-hbar-meta;
    font-weight: 200;
  }


/* 2nd Level: Main Navigation */

.mainnav {
  flex: 1 0 auto;

  margin-left: 30px;
  white-space: nowrap;

  @media print {
    display: none !important;
  }
}

  .mainnav-item {
    display: inline-block;
    border-radius: 2px;
    padding: 6px 8px;
    color: $c-mainnav-txt;
    font-size: 1.1em; // 14px
    text-decoration: none;

    a {
      color: inherit;
      text-decoration: none;
    }
    u {
      background-image: linear-gradient(to bottom, transparent, transparent 15px, $c-mainnav-brd 15px, $c-mainnav-brd 16px, transparent 16px, transparent);
      text-decoration: none;
    }
    .count {
      border: 1px solid $c-mainnav-brd;
      color: $c-mainnav-count-txt;
    }
  }
  a.mainnav-item:hover,
  span.mainnav-item:hover {
    color: $c-mainnav-h-txt;
    text-decoration: none;

    u {
      background-image: linear-gradient(to bottom, transparent, transparent 15px, $c-mainnav-h-brd 15px, $c-mainnav-h-brd 16px, transparent 16px, transparent);
    }
  }

  .mainnav-item--current {
    padding: 6px 8px;
    background-color: $c-mainnav-cur-bg;
    color: $c-mainnav-cur-txt !important;
    box-shadow: 0 1px 1px rgba(#FFF, .3), inset 0 1px 1px rgba($c-mainnav-cur-sh, .15);
    font-weight: bold;

    u {
      background-image: none !important;
    }
    .count {
      min-width: 0;
      border: 0;
      padding-right: 0;
      padding-left:  0;
      color: $c-mainnav-cur-count-txt;
    }
  }
  a.mainnav-item--current:hover {
    background: $c-mainnav-cur-bg-h;
  }


/* Search Form */

.search {
  flex: 0 0 30%;

  position: relative;

  @media print {
    display: none !important;
  }
}
  .search-main {
    position: relative;
    margin-left: 60px;
    border: 0;
    padding: 0;
    overflow: visible;
    background-color: transparent;
  }

  .a-advanced-search {
    position: absolute;
    top: 0;
    left: -30px;
    width:  30px;
    height: 30px;
    border-radius: 2px 0 0 2px;
    overflow: hidden;
    background-color: rgba($c-search-filter-bg, .5);
    text-indent: -999em;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      width: 11px;
      height: 13px;
      margin-top: -6px;
      margin-left: -5px;
      background: url('../images/i-header-sprite.svg') no-repeat -87px 0;
      background-size: 98px 27px;
    }
    &:hover {
      background-color: rgba($c-search-filter-bg, .75);

      &::before {
        background-position: -87px -14px;
      }
    }
  }
  .a-advanced-filter {
    position: absolute;
    top: 0;
    left: -30px;
    border-radius: 2px 0 0 2px;
    padding: 3px;
    background-color: $c-search-filter-bg;

    b {
      display: none;
    }
  }

  .search-field-wrap {
    position: relative;
    margin: 0 30px 0 0;
  }

  .quick-search,
  .search-field-wrap {
    .search-field {
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      margin: 0;
      border: 1px solid $c-search-field-brd;
      border-radius: 0 2px 2px 0;
      padding: .5em 2em .5em .5em;
      vertical-align: middle;
      color: $c-dark;
      box-shadow: inset 0 1px 2px rgba(#000, .1);
      font-family: $f-sansserif;
      appearance: textfield;

      // Remove in-field cancel button
      &::-webkit-search-cancel-button {
        appearance: none;
      }

      // Placeholder

      &::placeholder {
        color: #999;
      }

      // Focus

      &:focus {
        outline: none;
      }
    }
    .search-reset {
      $size: 11px;

      position: absolute;
      top: 50%;
      right: 10px;
      width:  $size;
      height: $size;
      margin-top: -($size/2);
      overflow: hidden;
      background: url('../images/i-header-sprite.svg') no-repeat -39px 0;
      background-size: 98px 27px;
      text-indent: -999em;
    }
  }

  .search-submit {
    $size: 25px;

    position: absolute;
    top: 50%;
    right: 0;
    box-sizing: content-box;
    width:  $size;
    height: $size;
    margin: -($size/2) 0 0;
    border: 0;
    padding: 0;
    overflow: hidden;
    background: url('../images/i-header-sprite.svg') no-repeat 5px 5px;
    background-size: 98px 27px;
    opacity: .75;
    text-indent: -999em;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }



/* Dropdown
------------------------------ */

// Arrow

.dd-arrow {
  display: inline-block;
  position: relative;
  width:  18px;
  height: 18px;
  border-radius: 2px;
  vertical-align: middle;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 5px;
    margin: -2px 0 0 -4px;
    background: url('../images/i-header-sprite.svg') no-repeat;
    background-size: 98px 27px;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }
}
.dd-arrow--metanav {
  margin: -1px 0 0 -8px;

  &:hover {
    background-color: $c-dd-metanav-h-bg;
  }
  &::before {
    background-position: -51px 0;
  }
  &.is-open::before {
    background-position: -51px -6px;
  }
  .metanav-item--current & {
    margin: -1px -4px 0 3px;

    &::before {
      background-position: -78px 0;
    }
    &.is-open::before {
      background-position: -78px -6px;
    }
    &:hover::before {
      background-position: -51px 0;
    }
  }
}
.dd-arrow--mainnav {
  margin: -1px 0 0 -7px;

  &:hover {
    background-color: $c-dd-mainnav-h-bg;
  }
  &::before {
    background-position: -60px 0;
  }
  &.is-open::before {
    background-position: -60px -6px;
  }
  .mainnav-item--current & {
    margin: -.5em -.33em -.35em .2em;

    &::before {
      background-position: -69px 0;
    }
    &.is-open::before {
      background-position: -69px -6px;
    }
    &:hover::before {
      background-position: -60px 0;
    }
  }
}


// Popup

.dropdown {
  position: absolute;
  right: -#{floor(230px / 2 - 18px / 2)};  // .dropdown - 1/2 .dd-arrow
  z-index: $zi-dropdown;
  box-sizing: border-box;
  width: 230px;
  max-height: 500px;
  border-radius: 3px;
  padding: 15px;
  background-color: $c-dd-bg;
  color: $c-dd-txt;
  box-shadow: 0 2px 5px rgba(#000, .25);
  font-size: 12px;
  line-height: normal;

  &:focus {
    outline: none;
  }
  &::after {
    content: '';

    position: absolute;
    top: -5px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-width: 0;
    border-bottom-color: $c-dd-bg;
  }
  a {
    color: $c-dd-l;
  }
  a:hover {
    color: $c-dd-h;
  }
  .empty {
    margin: 2.5em 0 1.5em;
    color: $c-dd-meta;
    text-align: center;
  }
}
.dropdown--loading {
  min-height: 60px;

  &::before {
    @extend %icon-loading;

    content: '';

    position: absolute;
    top:  50%;
    left: 50%;
    z-index: 90;
    width:  14px;
    height: 14px;
    margin: -7px 0 0 -7px;
    border-width: 1px;
    border-color: $c-olive-light $c-olive-light $c-dd-bg $c-dd-bg;
  }
}
.dropdown--metanav {
  top: 2em;
}
.dropdown--mainnav {
  top: 2.3em;
  z-index: $zi-dropdown-mainnav;
}
.dropdown--search {
  top: 2.5em;
  left: -#{floor(230px / 2 - (30px + 30px / 2))}; // .dropdown center - (.search-mail margin-left + .a-advanced-search center)
}
  .quick-search {
    margin: -15px -15px 15px;
    border-bottom: 1px solid $c-qs-brd;
    padding: 1em 15px;

    &.is-focused {
      background-color: $c-dd-bg-h;
      background-image: linear-gradient(to bottom, $c-dd-bg, $c-dd-bg-h);
    }
  }
  .total {
    margin: 15px 0 .5em;
    color: $c-dd-meta;
    text-align: center;
  }
  .dropdown-list {
    margin: 0;
    list-style: none;

    li {
      margin: 0 -15px;
      padding: .5em 15px .4em;

      &.is-focused {
        background-color: $c-dd-bg-h;
      }
    }
    .color-badge {
      border-color: $c-dd-label-brd;
      vertical-align: -.25em;
    }
    a {
      display: inline-block;
      max-width: 100%;
      padding-bottom: .1em;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      u {
        border-bottom: 1px solid $c-dd-list-brd;
        text-decoration: none;
      }
    }
    a:hover {
      u {
        border-bottom-color: $c-dd-list-brd-h;
      }
    }
  }
  .dropdown-list--users {
    .userpic {
      margin-right: .5em;
      vertical-align: -.7em;
    }
    .username {
      display: none;
    }
  }
