/* Documents Viewer
------------------------------ */

.doc-viewer {
  max-width: 820px;
  margin: 30px 20% 30px 60px;
  color: $c-docs-txt;
  font: 1.075em/1.6 $f-sansserif;

  a:link {
    color: $c-docs-l;
  }
  a:visited {
    color: $c-docs-v;
  }
  a:hover {
    color: $c-docs-h;
  }
  a:active {
    color: $c-docs-a;
  }
  b,
  strong {
    color: darken($c-docs-txt, 7.5%);
  }
  code {
    font-size: .85em;
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    overflow: visible;
    font-weight: bold;
  }
  h1 {
    margin: .6em 0;
    color: $c-docs-h1;
    font-size: 1.75em;
  }
  h2 {
    margin: 1.5em 0 .75em;
    color: $c-docs-h2;
    font-size: 1.3em;
  }
  h3 {
    margin: 1.75em 0 .66em;
    color: $c-docs-h3;
    font-size: 1.1em;
  }
  h4,
  h5,
  h6 {
    margin: 1.5em 0 .75em;
    color: $c-docs-h4;
    font-size: 1em;
  }
  a.title-anchor {
    position: absolute;
    top: 50%;
    left: -1.2em;
    margin-top: -.7em;
    color: $c-docs-anchor-l;
    font-weight: 200;
    font-size: 18px;
    line-height: 1.4;
    text-decoration: none;

    &:hover {
      color: $c-docs-anchor-h;
    }
  }
  pre code,
  blockquote {
    border-color: $c-cv-brd;
    background-color: #FFF;
  }
  pre code {
    color: $c-base;
  }
  blockquote {
    color: mix(#FFF, $c-docs-txt, 25%);
  }
  table {
    width: auto;
    margin: 1.5em 0;

    th,
    td {
      border-bottom: 1px solid $c-cv-brd;
      padding: .6em 3em .6em .6em;
    }
    thead th,
    thead td {
      border-bottom-width: 2px;
      background-color: #FFF;
      color: $c-mute;
      font-weight: bold;
      font-size: .8em;
    }
    tbody {
      border: 0;
    }
  }
}
