/* Transfer Log
------------------------------ */

.transfer-log {
  position: fixed;
  top:    30px;
  right:  30px;
  bottom: 30px;
  left:   30px;
  z-index: $zi-transfer-log;
  border-radius: 5px;
  overflow: hidden;
  background-color: $c-tl-bg;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .4);
}
.transfer-log.loading {
  &::before {
    @extend %icon-loading;
    @extend %icon-loading--large;
    @extend %icon-loading--large-centered;

    content: '';
  }
}
  .transfer-log.loading div,
  .transfer-log.loading ul,
  .transfer-log.loading p {
    display: none;
  }

/* stylelint-disable no-unknown-animations */
.transfer-log.grow {
  animation-name: grow;
  animation-duration: .4s;
}
/* stylelint-enable */

  .transfer-log h2 {
    display: block;
    margin: 0;
    border: 0;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid $c-tl-title-brd;
    padding: .75em 20px;
    color: $c-tl-title-txt;
    font-size: 1.15em;
  }
  .transfer-log.updating h2 {
    &::before {
      @extend %icon-loading;

      content: '';

      margin-right: .6em;
      border-color: #808080 #808080 #FFF #FFF;
      vertical-align: baseline;
    }
  }

  .transfer-log .link-close {
    @extend .icon-cancel;

    position: absolute;
    top: 1em;
    right: 20px;
    border-radius: 3px;
    color: $c-tl-close-txt;
    font-weight: bold;
    text-decoration: underline;
  }


  /* Tabs */

  .transfer-log .tabs {
    margin: 0;
    padding: .5em 20px;
    font-weight: normal;
    font-size: 1em;

    li {
      display: inline-block;
      margin: .5em 0;
      list-style: none;
    }
    a {
      display: inline-block;
      margin-right: 1em;
      border-radius: 2px;
      padding: .35em .8em;
      background-color: $c-tl-tab-bg;
      color: $c-tl-tab-txt;
      text-decoration: none;

      span {
        display: inline-block;
        margin: 0 0 2px .25em;
        color: $c-tl-tab-meta;
        font-size: .85em;
        text-transform: uppercase;
      }
    }
    .failed a {
      background-color: $c-tl-tab-failed-bg;
      color: $c-tl-tab-failed-txt;

      span {
        color: $c-tl-tab-failed-meta;
      }
    }
    .selected a {
      @extend .icon-collapse;

       background-color: $c-tl-tab-current-bg !important;
       color: $c-tl-tab-current-txt !important;

      &::before {
        left: 6px;
      }
      span {
        color: $c-tl-tab-current-meta !important;
      }
    }
  }


  /* Actions */

  .transfer-log .actions {
    border-top: 1px solid $c-tl-actions-brd;
    overflow-y: scroll;
  }

    // Current Activity

    .transfer-activity {
      margin: 0;
      border-top: 1px solid $c-tl-activity-brd;
      padding: .5em 20px .6em;
      background-color: $c-tl-activity-bg;
      color: $c-tl-activity-txt;
      font-size: .85em;
    }
      .transfer-activity span.sep {
        color: $c-tl-activity-sep;
      }


    // Deployment Status

    .transfer-status {
      position: relative;
      margin: 0;
      border-top: 1px solid $c-tl-status-brd;
      padding: 1em 20px 1em 35px;
      font-weight: bold;
      font-size: .9em;
    }
     .transfer-status.status-success {
      color: $c-tl-status-success;
    }
    .transfer-status.status-failed {
      color: $c-tl-status-failed;
    }
    .transfer-status.status-bypassed {
      color: $c-tl-status-bypassed;
    }

      .transfer-status::before {
        content: '';

        position: absolute;
        left: 20px;
        width: 11px;
        height: 11px;
        background-image: url('../images/i-status.svg');
        background-repeat: no-repeat;
        background-size: 11px 200px;
      }
       .transfer-status.status-success::before {
        top: 1.2em;
         background-position: 0 0;
      }
      .transfer-status.status-failed::before {
        top: 1.4em;
        background-position: 0 -50px;
      }
      .transfer-status.status-bypassed::before {
        top: 1.4em;
        background-position: 0 -100px;
      }


    .transfer-log .actions ol {
      display: block;
      margin: 0;
      overflow: hidden;
      font: normal .85em $f-monospace-fb;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
      .transfer-log .actions ol li {
        display: block;
        margin: 0;
        border-top: 1px solid $c-tl-brd;
        padding: 5px 20px;
        overflow: hidden;
        color: $c-tl-txt;
      }
        .transfer-log .actions ol li span {
          margin-right: .5em;
          color: $c-tl-meta;
        }

      .transfer-log .actions ol li.progress-error {
        background-color: $c-tl-error-bg;
        color: $c-tl-error-txt;
      }
        .transfer-log .actions ol li.progress-error span {
          color: $c-tl-error-meta;
        }
