/* Repository Status
------------------------------ */

.repository-status .content {
  color: $c-base;
}
  .repository-status span.highlight {
    border-radius: 3px;
    padding: .1em .25em;
    background-color: $c-repo-status-hl-bg;
    color: $c-repo-status-hl-txt;
    font-weight: bold;
  }
  .repository-status .actions a.button {
    vertical-align: -1.3em;
  }
  #specialPage #page.repository-status .form-buttons.actions {
    border-color: $c-repo-status-actions-brd;
    background-color: $c-content-bg;
  }


/* Status: Disabled */

.repository-status.disabled {
  .page-heading {
    @extend .icon-warning;
    @extend .icon-warning--light;

    margin: -40px -40px 1.5em;
    border-bottom: 1px solid $c-repo-status-dsb-brd;
    padding: .65em 40px;
    overflow: hidden;
    background-color: $c-repo-status-dsb-bg;
    background-image: linear-gradient(to bottom, $c-repo-status-dsb-bg, $c-repo-status-dsb-bg-alt);
    color: $c-repo-status-dsb-txt;
    font-weight: bold;
    font-size: 1.5em;
    line-height: normal;
    text-shadow: 0 1px 1px #700;

    &::before {
      vertical-align: baseline;
    }
  }
}
