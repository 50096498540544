/* Welcome
------------------------------ */

#welcome {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: visible;
  background-color: $c-welcome-bg;
  background-image: linear-gradient(to bottom, $c-welcome-bg, $c-welcome-bg-alt);
  color: $c-welcome-txt;
}
  #welcome .wrapper {
    min-width: 940px;
    margin: 0 auto -30px;
    padding: 30px 0 90px;
    background: url('../images/bg-leaves-welcome@2x.png') no-repeat 40% 0;
    background-size: 828px 442px;
    font-size: 1.1em;
    text-align: center;
  }

  .b-logo {
    display: block;
    width:  120px;
    height: 181px;
    margin: 0 auto 30px;
    padding-right: 47px;  // Visual compensation for centering logo
    background: url('../images/logo-beanstalk-welcome.png') no-repeat;

    @include retina {
      background-image: url('../images/logo-beanstalk-welcome@2x.png');
      background-size: 120px 181px;
    }
  }

  #welcome a {
    color: $c-welcome-l;
    text-decoration: underline;
  }
  #welcome h1 {
    margin: 0 0 .75em;
    color: $c-welcome-title;
    font-size: 1.4em;
  }
  #welcome p {
    margin: .75em 0;
    color: $c-welcome-txt;

    &.enlarge {
      font-size: 1.15em;
    }
  }
    #welcome .link-wildbit {
      display: inline-block;
      width: 60px;
      height: 17px;
      margin: -3px .25em 0;
      vertical-align: middle;
      background: url('../images/logo-wildbit-sml.png') no-repeat;
      text-indent: -9999em;

      @include retina {
        background-image: url('../images/logo-wildbit-sml@2x.png');
        background-size: 60px 17px;
      }
    }

  #welcome .action-create {
    margin: 30px 0 60px;
  }


  // Boarding steps

  .boarding-steps {
    width: 27.5%;
    margin: 2.5em auto 4em;

    li {
      margin: 1.25em 0;
      list-style: none;
    }
  }
    .boarding-step {
      display: block;
      border: 1px dashed $c-welcome-step-norm-brd;
      border-radius: 7px;
      padding: 1.25em;
      font-size: 1.1em;
      text-decoration: none !important;

      u {
        color: $c-welcome-step-norm-txt;
        text-decoration: underline;
      }

      // Current Step

      &.current {
        border-width: 3px;
        border-style: solid;
        background-color: $c-welcome-step-curnt-bg;
        box-shadow: inset 0 0 5px #FFF;

        u {
          color: $c-welcome-step-txt;
          font-weight: bold;
        }
        &:hover {
          border-color: $c-welcome-step-curnt-h-brd;
          background-color: $c-welcome-step-curnt-h-bg;

          u {
            color: $c-welcome-step-curnt-h-txt;
          }
        }
      }

      // Complete

      &.complete {
        border-style: solid;
        border-color: $c-welcome-step-compl-brd;
        background: url('../images/i-checkmark-circle.svg') no-repeat 5% 50%;
        background-size: 16px 16px;
        color: $c-welcome-step-compl-txt;

        u {
          color: $c-welcome-step-compl-txt;
        }
        &:hover {
          u {
            color: $c-welcome-step-compl-h-txt;
          }
        }
      }

      // Disabled

      &.disabled {
        u {
          text-decoration: none;
        }
        &:hover {
          u {
            color: inherit;
          }
        }
      }

      // Unavailable

      &.unavailable {
        color: $c-welcome-step-unavl-txt;
        font-weight: normal;
      }
    }

    a.boarding-step:hover {
      border-style: solid;
      border-color: $c-welcome-step-h-brd;

      u {
        color: $c-welcome-step-h-txt;
      }
    }

      .boarding-step-num {
        margin-right: .25em;
        color: $c-welcome-step-meta;
        font-weight: normal;
      }


  // Reading list

  .reading-list {
    width: 40%;
    margin: 2.5em auto 4em;
    border-radius: 4px;
    padding: 1em 2.5em 1.75em;
    background-color: $c-reading-bg;
    box-shadow: 0 1px 3px rgba($c-welcome-txt, .6);
    text-align: left;

    ul {
      margin: 0;
    }
    li {
      margin: .5em 0;
      list-style: none;
    }
    a {
      color: $c-reading-l !important;
    }
  }


  // Help badge

  #welcome .help-link {
    margin-right: .33em;
    border-radius: 2px;
    padding: .3em .6em .4em;
    background-color: $c-welcome-help-bg;
    color: $c-welcome-help-txt;
    font-weight: bold;
    font-size: .9em;
    text-decoration: underline;

    &:hover {
      background-color: $c-welcome-help-l-bg;
      color: $c-welcome-help-l-txt;
    }
  }


  // Team photos

  #welcome .wildbit-team {
    margin-top: 3em;

    li {
      display: inline-block;
      width: 75px;
      vertical-align: top;
      list-style: none;
    }
      a {
        display: inline-block;
        color: $c-welcome-team-txt;
        text-decoration: none;
        transition: color .2s ease;
      }
        img {
          display: block;
          width:  48px;
          height: 48px;
          margin: 0 auto .66em;
          border-radius: 50%;
          box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
        }
        .role {
          display: block;
          margin-top: .4em;
          color: $c-welcome-team-role;
          font-size: .8em;
          line-height: 1.2;
        }
      a:hover {
        color: $c-welcome-team-h-txt;

        .role {
          color: $c-welcome-team-h-role;
        }
      }
  }
