/* Get Started (help pages)
------------------------------ */

#platform-select {
  position: relative;
  margin: -39px -39px 2em;
  border-radius: 1px 1px 0 0;
  overflow: hidden;
  background-image: url('../images/i-os@2x.png');
  background-repeat: no-repeat;
  background-size: 30px 175px;
}
  #platform-select p {
    margin: 0 !important;
    padding: 20px 20px 20px 75px;
  }
  #platform-select p span {
    font-weight: bold;
  }

  #platform-select a {
    position: absolute;
    top: 20px;
    right: 40px;
    border-radius: 2px;
    padding: .1em .4em .2em;
    font-size: .85em;
    text-decoration: none;
  }
  #platform-select a:hover {
    text-decoration: underline;
  }

  #platform-select select {
    position: absolute;
    top: 20px;
    right: 40px;
  }

#platform-select.os-mac {
  background-color: $c-platform-mac-bg;
  background-position: 40px (0 + 17px);
  color: $c-platform-mac-txt;
}
  #platform-select.os-mac a {
    background-color: $c-platform-mac-txt;
    color: $c-platform-mac-bg;
  }
#platform-select.os-win {
  background-color: $c-platform-win-bg;
  background-position: 40px (-(75px - 17px));
  color: $c-platform-win-txt;
}
  #platform-select.os-win a {
    background-color: $c-platform-win-txt;
    color: $c-platform-win-bg;
  }
#platform-select.os-nix {
  background-color: $c-platform-nix-bg;
  background-position: 40px (-(150px - 17px));
  color: $c-platform-nix-txt;
}
  #platform-select.os-nix a {
    background-color: $c-platform-nix-txt;
    color: $c-platform-nix-bg;
  }


/* Help Content */

#os-independent {
  padding-bottom: 1em;
}

.repo-help a {
  text-decoration: underline;
}
.repo-help hr {
  margin: 1.75em -40px !important;
}
.repo-help p {
  margin-bottom: 1.5em !important;
}
.repo-help ul,
.repo-help ol {
  margin: 0 0 1em;
}
  .repo-help ul li {
    list-style: circle;
  }

.repo-help pre code.source {
  margin: 0 0 2em !important;
  padding: 10px 15px;
  overflow-x: auto;
  background-color: $c-term-bg;
  color: $c-term-txt;
  line-height: 1.8;
  white-space: pre;
}
  .repo-help pre code.source span.note {
    display: inline-block;
    margin: .5em 0;
    color: $c-term-blank;
    font: 1.1em $f-sansserif;
  }


/* Subversion Clients */

.repo-help ul.clients {
  padding: 0 !important;
}
  .repo-help ul.clients li {
    margin-bottom: 1.5em;
    padding-left: 50px !important;
    background-image: url('../images/i-clients@2x.png');
    background-repeat: no-repeat;
    background-size: 34px 435px;
    list-style: none;

    &.client-tortoise {
      background-position: 0 0;
    }
    &.client-cornerstone {
      background-position: 0 -200px;
    }
    &.client-binary {
      background-position: 0 -400px;
    }
  }

/* Code Options */

ul.options {
  padding-left: 0 !important;

  li {
    @extend .icon-collapse;

    list-style: none;

    &::before {
      top: .5em;
      left: 5px;
      margin-top: 0;
    }
    a {
      border-bottom: 1px dashed mix($c-content-bg, $c-link, 50%);
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid mix($c-content-bg, $c-dark, 50%);
        color: $c-dark;
      }
    }
  }
  .show {
    @extend .icon-collapse-selected;

    a {
      border-bottom-color: mix($c-content-bg, $c-dark, 66%);
      color: $c-dark;
    }
  }
  pre code.source {
    margin: 1em 0 1em -20px !important;
  }
}



/* Coupons */

#coupon {
  position: relative;
  float: right;
  margin: 0 -40px 20px 20px;
  border-radius: 4px 0 0 4px;
  padding: 1.1em 40px 1.6em 15px;
  background-color: $c-coupon-bg;
  color: $c-coupon-txt;
  font-size: .9em;
  line-height: 1.4;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .75);
}
  #coupon b {
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  #coupon input {
    margin: .6em 0 0;
    border: 0;
    border-bottom: 1px dashed $c-coupon-l-brd;
    padding: 0;
    overflow: visible;
    background: transparent none;
    color: $c-coupon-l;
    font: inherit;
    font-weight: bold;
    font-size: 1.1em;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;
    user-select: text;
  }
  #coupon code {
    display: inline-block;
    margin: .6em 0 0;
    border-radius: 2px;
    padding: .2em .4em .1em;
    background-color: $c-coupon-code-bg;
    color: $c-coupon-code-txt;
    font-size: 1em;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    white-space: nowrap;
  }
  #coupon code:hover {
    background-color: darken($c-coupon-code-bg, 3%);
    color: darken($c-coupon-code-txt, 3%);
  }
    #coupon code a {
      display: block;
      margin: -.2em -.4em -.1em;
      padding: .2em .4em .1em;
      color: inherit;
      text-decoration: none;
    }

  #coupon code.loading {
    @extend .icon-loading;
  }

  #coupon.tower {
    width: 100px;
    padding-right: 45px;
  }
    #coupon.tower b {
      top: -6px;
      width: 40px;
      height: 102px;
      background-image: url('../images/i-tower.png');
      background-size: 40px 102px;
    }

  #coupon.cornerstone {
    width: 105px;
    padding-right: 80px;
  }
    #coupon.cornerstone b {
      top: -7px;
      width: 83px;
      height: 100px;
      background-image: url('../images/i-cornerstone.png');
      background-size: 83px 100px;
    }


/* Open in the app */

p.open-in-app {
  margin-top: 2em !important;
}
  p.open-in-app a {
    color: $c-text;
    text-decoration: underline;
  }
  p.open-in-app a:hover {
    color: $c-base;
  }
  p.open-in-app .meta {
    color: $c-mute;
    font-size: .9em;
  }
  p.open-in-app .sep {
    margin: 0 .25em;
    color: inherit;
  }
  p.open-in-app .button {
    margin: -2px 0 0;
    vertical-align: middle;
  }


/* Help block in Wizard */

.help-block {
  position: relative;
  margin: 40px -40px 1px;
  border-top: 1px solid $c-help-block-brd;
  padding: 20px 40px !important;
  background-color: $c-help-block-bg;
  text-align: center;

  p {
    margin: 0 !important;
    padding: 0;
    color: $c-base;
  }
    strong.mark {
      padding: .2em .5em .3em;
      background-color: mix($c-help-block-bg, $c-help-block-mark-bg, 33%);
      color: $c-help-block-mark-txt;
      text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    }
    a {
      margin-left: .25em;
      color: $c-help-block-l;
      text-decoration: underline;
    }
    a:hover {
      color: $c-help-block-h;
    }
}
