/* Messages
------------------------------ */

.message {
  position: relative;
  margin: 0 0 1.5em;
  border-width: 2px 0;
  border-style: solid;
  padding: 20px 2em;
  overflow: hidden;

  #specialPage & {
    margin-bottom: 30px;
  }
  &.detached {
    margin: 30px 0 !important;
  }
}
.forms h3 + .message {
  margin-top: 1.5em;
}
  .message h2 {
    margin: 0 0 .75em !important;
    font-size: 1.1em !important;
  }
  .message p {
    margin: 0 !important;
  }
  .message ul {
    padding: 0 0 0 22px !important;
  }
    .message ul li {
      margin: .5em 0 0 !important;
      padding: 0 0 0 3px !important;
      list-style: circle;
    }
  .message a {
    text-decoration: underline !important;
  }
  .message .form-row:last-child {
    margin-bottom: .5em;
  }
  .message .link-preview {
    margin: 0 .1em 0 .3em;
    vertical-align: -.2em;
  }
  .message a.link-hide {
    position: absolute;
    top: .5em;
    right: 1.25em;
    font-size: 1.1em;
    text-decoration: none !important;
  }


  /* Notice */

  .message.notice {
    border-color: $c-msg-notice-brd;
    background-color: $c-msg-notice-bg;

    h2 {
      color: $c-msg-notice-title;
    }
    p,
    ul li {
      color: $c-msg-notice-txt;
    }
    a {
      color: $c-msg-notice-l !important;
    }
    a.link-hide {
      color: mix($c-msg-notice-bg, $c-base, 50%) !important;
    }
    .sep {
      color: $c-msg-notice-meta;
    }
  }

  /* Error */

  .message.error {
    border-color: $c-msg-error-brd;
    background: $c-msg-error-bg none;

    h2 {
      @extend .icon-warning;
      @extend .icon-warning--red;

      color: $c-msg-error-title;

      &::before {
        content: '';

        vertical-align: baseline;
      }
    }
    p,
    ul li {
      color: $c-msg-error-txt;
    }
    a {
      border: 0;
      color: $c-msg-error-l !important;
    }
    a.link-hide {
      color: mix($c-msg-error-bg, $c-base, 50%) !important;
    }
  }

  /* Cancelation */

  .message.cancellation {
    border-color: $c-form-boxed-warn-brd;
    background-color: $c-form-boxed-warn-bg;

    h2 {
      color: $c-form-boxed-warn-title !important;
    }
    p {
      color: $c-form-boxed-warn-txt;
    }
    a {
      border: 0;
      color: $c-form-boxed-warn-l !important;
    }
    a.link-hide {
      color: mix($c-form-boxed-warn-bg, $c-base, 50%) !important;
    }
  }
