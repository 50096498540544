/* Help Slider
------------------------------ */

#help {
  position: fixed;
  top: 0;
  right: -340px;
  z-index: $zi-help;
  width: 300px;
  height: 100%;
  background-color: rgba(#000, .95);
  color: $c-help-txt;
  box-shadow: 0 0 10px 0 rgba(#000, .5);

  @media print {
    display: none !important;
  }

  &.loading {
    @extend %block-loading;

    position: fixed;

    &::before {
      border-color: $c-help-bg $c-help-bg #808080 #808080;
    }
    &::after {
      content: none;
    }
  }
  .help-wrap {
    position: relative;
    overflow: hidden;
  }
    .help-content {
      padding: 30px;
      overflow: hidden;
    }
}
  a.close-help {
    position: absolute;
    top: 20px;
    left: -40px;
    width: 40px;
    height: 40px;
    border-radius: 5px 0 0 5px;
    background: $c-help-bg url('../images/b-close-help.svg') no-repeat 50% 50%;
    color: $c-help-bright;
    opacity: .5;
    text-indent: -9999em;

    &:hover {
      opacity: 1;
    }
  }

  #help h1 {
    margin: -.15em 0 20px;
    color: $c-help-title;
    font-size: 1.2em;
    text-shadow: 0 1px 0 #000;

    code {
      color: inherit;
      font-size: 1em;
    }
  }
  #help h2 {
    margin: 1.75em 0 .85em;
    color: $c-help-subtitle;
    font-size: 1.1em;
    text-shadow: 0 1px 0 #000;
  }
  #help a:link {
    color: $c-help-l;
    text-decoration: underline;
  }
  #help a:visited {
    color: $c-help-v;
    text-decoration: underline;
  }
  #help a:hover {
    color: $c-help-h;
  }

  #help p {
    margin: 0 0 1em;
    font-size: 1em;
    text-shadow: 0 1px 0 #000;
  }
    #help p a {
      color: $c-help-h;
      text-decoration: underline;
    }

  #help code {
    color: $c-help-code-bg;
    font-size: .85em;
  }
  #help pre code {
    display: block;
    white-space: pre-wrap;

    &.sample {
      margin: .5em 0;
      border: 1px solid #333;
      border-radius: 3px;
      padding: 1em;
    }
  }

  #help ul {
    margin: 0 0 1.5em 1.4em;
  }
    #help ul li {
      margin-bottom: .5em;
      list-style-position: outside;
      list-style-type: disc;
    }

  #help .mark {
    padding: 0;
    background: none;
    color: $c-help-mark;
    text-shadow: 0 1px 0 #000;
  }
  #help .highlight {
    color: $c-help-hl;
  }


  /* Assistly */

    #help .form-row {
    }
      #help .form-row label {
        color: $c-help-bright;
      }
      #help .form-row input,
      #help .form-row textarea {
        box-sizing: border-box;
        width: 100%;
        margin: .3em 0;
        padding: .3em;
        font: normal 1em/1.4 $f-sansserif;
      }
      #help .form-row input {
        border: 0;
      }
      #help p.form-comment,
      #help p.form-error {
        font: normal 1em/1.4 $f-sansserif;
      }
        #help p.form-comment a {
          color: $c-help-meta;
        }
      #help p.form-error {
        margin: 0;
        background: none;
        color: $c-help-error-txt;
      }
      #help .form-submit {
        margin: 0;
      }
        #help .form-submit input {
          // TODO: replace @extend with class names on elements
          @extend .button;
          @extend .button--big;

          margin-right: .25em !important;
        }
        #help .form-submit span.sep {
          margin-right: 1em;
          color: $c-help-txt;
        }
        #help .form-submit a {
          color: $c-help-l-alt;
          text-decoration: underline;
        }



/* Global Help Link */

a.link-help {
  border-bottom: 1px solid mix($c-content-bg, $c-link, 75%);
  color: $c-link !important;
  font-weight: normal;
  text-decoration: none !important;

  &:hover {
    border: 0;
    color: $c-base !important;
  }
}


/* Support Options */

#help p.submit-case {
}

#help ul.support-options {
  margin: 0 0 1.5em;
  border-top: 1px solid $c-help-brd;
}
  #help ul.support-options li {
    margin: 0;
    list-style: none;
  }
  #help ul.support-options li:last-child {
    margin-bottom: 0;
  }

    #help ul.support-options li a {
      display: block;
      border-bottom: 1px solid $c-help-brd;
      padding: .75em 0;
      color: $c-help-l-alt;
      font-weight: bold;
      text-decoration: none;
      text-shadow: 0 1px 0 #000;
    }
    #help ul.support-options li a:hover {
      color: $c-help-h !important;
    }
    #help ul.support-options li.link a {
      color: $c-help-l;
      font-weight: normal;
    }


/* Follow Us */

#help p.follow-us {
  margin: 2em 0 0;
  border-radius: 3px;
  padding: .75em 1em;
  background-color: $c-help-twitter-bg;
  color: $c-help-twitter-txt;
  text-shadow: none;
}
  #help p.follow-us a {
    color: $c-help-twitter-l;
  }
