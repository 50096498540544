/* Code Viewer
============================== */

/* Markdown support */

.text-markdown {
  a {
    text-decoration: underline;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
  del {
    text-decoration: line-through;
  }
  code {
    font: .92em/1.33 $f-monospace-fb;

    .fonts-loaded & {
      font-family: $f-monospace;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: .66em;
    }
  }
  h1 {
    margin: 1em 0 .3em;
    color: $c-dark;
    font-size: 1.15em;
  }
  h2 {
    margin: 1.25em 0 .4em;
    color: $c-dark;
    font-size: 1.075em;
  }
  h3 {
    margin: 1.5em 0 .2em;
    color: $c-base;
    font-size: 1em;
  }
  h4 {
    margin: 1.75em 0 .75em;
    color: $c-base;
    font-size: .85em;
    text-transform: uppercase;
  }
  p {
    margin: .75em 0 !important;
  }
  ul,
  ol {
    margin: .75em 0 !important;
    padding-left: 2em;
  }
    li {
      margin: 0 0 .4em !important;
    }
    ul li {
      list-style: disc;
    }
  pre code,
  blockquote {
    display: block;
    margin: 1em 0;
    border: 1px solid $c-md-block-brd;
    background-color: $c-md-block-bg;
  }
  pre code {
    border-bottom: 2px solid $c-md-block-brd-alt;
    padding: .75em 1.25em;
    overflow: auto;
  }
  blockquote {
    border-left: 2px solid $c-md-block-brd-alt;
    padding: .25em 1.25em;
    overflow: hidden;
    color: $c-md-block-txt;
  }
  .cv--sample {
    border-color: $c-md-block-brd-alt;
    background-color: $c-md-block-bg;

    tbody {
      border-color: $c-md-block-bg;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border: 1px solid $c-md-block-brd;
    background-color: $c-md-block-bg;
  }
  img.emoji {
    width: 20px;
    height: 20px;
    border: 0;
    vertical-align: middle;
    background-color: transparent;
  }
  #help & {
    margin: .5em 0 .75em;

    a {
      color: $c-help-txt !important;
    }
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      color: $c-help-txt;
    }
    li {
      margin-bottom: .2em !important;
    }
    code {
      color: $c-help-txt;
    }
    pre code,
    blockquote {
      border: 0;
      border-radius: 3px;
      background-color: $c-help-code-bg-alt;
      color: $c-help-txt;
    }
    blockquote p {
      text-shadow: none;
    }
  }
}

.label-username,
.label-team {
  font-style: italic;
}


/* Syntax highlighting */
/* stylelint-disable */
.cv {
  .hll { background-color: #FFC; }
  .c   { color: #AAA; }                                // Comment
  .err { background-color: #F0A0A0; color: #F00000; }  // Error
  .k   { color: #00A; }                                // Keyword
  .cm  { color: #AAA; }                                // Comment.Multiline
  .cp  { color: #4C8317; }                             // Comment.Preproc
  .c1  { color: #AAA; }                                // Comment.Single
  .cs  { color: #00A; }                                // Comment.Special
  .ge  { }                                             // Generic.Emph
  .gr  { color: #A00; }                                // Generic.Error
  .gh  { color: #000080; }                             // Generic.Heading
  .go  { color: #888; }                                // Generic.Output
  .gp  { color: #555; }                                // Generic.Prompt
  .gs  { }                                             // Generic.Strong
  .gu  { color: #800080; }                             // Generic.Subheading
  .gt  { color: #A00; }                                // Generic.Traceback
  .kc  { color: #00A; }                                // Keyword.Constant
  .kd  { color: #00A; }                                // Keyword.Declaration
  .kn  { color: #00A; }                                // Keyword.Namespace
  .kp  { color: #00A; }                                // Keyword.Pseudo
  .kr  { color: #00A; }                                // Keyword.Reserved
  .kt  { color: #0AA; }                                // Keyword.Type
  .m   { color: #099; }                                // Literal.Number
  .s   { color: #A50; }                                // Literal.String
  .na  { color: #1E90FF; }                             // Name.Attribute
  .nb  { color: #0AA; }                                // Name.Builtin
  .nc  { color: #0A0; text-decoration: underline; }    // Name.Class
  .no  { color: #A00; }                                // Name.Constant
  .nd  { color: #888; }                                // Name.Decorator
  .ni  { color: #800000; }                             // Name.Entity
  .nf  { color: #0A0; }                                // Name.Function
  .nn  { color: #0AA; text-decoration: underline; }    // Name.Namespace
  .nt  { color: #1E90FF; }                             // Name.Tag
  .nv  { color: #A00; }                                // Name.Variable
  .ow  { color: #00A; }                                // Operator.Word
  .w   { color: #BBB; }                                // Text.Whitespace
  .mf  { color: #099; }                                // Literal.Number.Float
  .mh  { color: #099; }                                // Literal.Number.Hex
  .mi  { color: #099; }                                // Literal.Number.Integer
  .mo  { color: #099; }                                // Literal.Number.Oct
  .sb  { color: #A50; }                                // Literal.String.Backtick
  .sc  { color: #A50; }                                // Literal.String.Char
  .sd  { color: #A50; }                                // Literal.String.Doc
  .s2  { color: #A50; }                                // Literal.String.Double
  .se  { color: #A50; }                                // Literal.String.Escape
  .sh  { color: #A50; }                                // Literal.String.Heredoc
  .si  { color: #A50; }                                // Literal.String.Interpol
  .sx  { color: #A50; }                                // Literal.String.Other
  .sr  { color: #099; }                                // Literal.String.Regex
  .s1  { color: #A50; }                                // Literal.String.Single
  .ss  { color: #00A; }                                // Literal.String.Symbol
  .bp  { color: #0AA; }                                // Name.Builtin.Pseudo
  .vc  { color: #A00; }                                // Name.Variable.Class
  .vg  { color: #A00; }                                // Name.Variable.Global
  .vi  { color: #A00; }                                // Name.Variable.Instance
  .il  { color: #099; }                                // Literal.Number.Integer.Long
}
/* stylelint-enable */

// Diff

.gi {
  background-color: $c-cv-ins-bg;
  color: $c-cv-ins-txt;
}
.gd {
  background-color: $c-cv-del-bg;
  color: $c-cv-del-txt;
}
  .gi > ins,
  .gd > del {
    display: inline-block;

    // Margin and padding is equal to .loc pre
    margin-top: -1px;
    margin-bottom: -1px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-decoration: none;
  }
  .gi > ins {
    background-color: $c-cv-ins-bg-alt;
    color: $c-cv-ins-txt-alt;
  }
  .gd > del {
    background-color: $c-cv-del-bg-alt;
    color: $c-cv-del-txt-alt;
  }



/* Code viewer container
------------------------------ */

.cv-container {
  position: relative;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}



/* Toolbar
------------------------------ */

.ct {
  @extend .clearfix;

  position: relative;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid $c-ct-brd;
  background-color: $c-ct-bg;
  background-image: linear-gradient(to bottom, $c-ct-bg, $c-ct-bg 85%, $c-ct-bg-alt);
  font-size: .85em;  // 11px
}

  // Path

  .ct_path {
    padding: .73em 1.2em .6em;
    overflow: hidden;
    color: $c-ct-path-txt;
    font-family: $f-monospace-fb;
    text-shadow: 0 1px 0 rgba(#FFF, .85);
    text-overflow: ellipsis;
    white-space: nowrap;

    .fonts-loaded & {
      font-family: $f-monospace;
    }

    // Create a file
    .a-create {
      margin: -.1em 0 0 .35em;
      vertical-align: top;
    }
  }

    // Revision number
    .ct_path_rev {
      display: inline-block;
      margin: -3px 0 -3px -.75em;
      border: 1px solid $c-ct-rev-brd;
      border-radius: 2px;
      padding: 2px 5px 1px;
      background-color: $c-ct-rev-bg;
      color: $c-ct-rev-txt !important;
      text-decoration: none !important;
      text-shadow: none;

      &:link:hover {
        border-color: $c-ct-rev-h-bg;
        background-color: $c-ct-rev-h-bg;
        color: $c-ct-rev-h-txt !important;
      }
    }

    // Files and directories
    .path_item {
      color: $c-ct-path-txt;

      &:link,
      &:visited {
        background-image: linear-gradient(to bottom, rgba($c-ct-path-txt, .4), rgba($c-ct-path-txt, .4));
        background-repeat: repeat-x;
        background-position: 0 93.5%;
        background-size: 1px 1px;
        text-decoration: none;

        &:hover {
          background-image: linear-gradient(to bottom, rgba($c-ct-path-h, .4), rgba($c-ct-path-h, .4));
          color: $c-ct-path-h;
        }
      }
    }
    .path_item--current {
      color: $c-ct-path-current;

      &:link,
      &:visited {
        background-image: linear-gradient(to bottom, rgba($c-ct-path-current, .4), rgba($c-ct-path-current, .4));
      }
    }

    // Slashes
    .path_sep {
      margin: 0 .3em 0 .35em;
      color: $c-ct-path-sep;
    }

    // Diffs
    .path_diff {
      color: $c-ct-path-txt;

      &::before {
        content: '\2022';

        margin: 0 .75em 0 .33em;
        color: mix($c-ct-bg, $c-ct-path-sep, 50%);
      }
      a {
        color: inherit;
      }
    }


  // Comments toggle

  .ct_comments-toggle {
    position: absolute;
    top: 50%;
    left: -(((30px - 16px) / 2) + 16px);
    width: 16px;
    height: 17px;
    margin-top: -9px;

    &.is-loading {
      @extend %icon-loading;

      width: 12px;
      height: 12px;
      margin-top: -7px;
      margin-left: 2px;
      border-width: 1px;
    }
  }


  // Actions

  .ct_actions {
    float: right;
    overflow: hidden;
    white-space: nowrap;
  }
    .ct_actions_item {
      float: left;
      border-left: 1px solid $c-ct-brd;
      list-style: none;
    }
      .ct_button {
        display: inline-block;
        padding: .65em .9em;
        color: $c-ct-txt;
        text-shadow: 0 1px 0 #FFF;

        &:link:hover {
          color: $c-ct-h;
          text-decoration: none;
        }
        &.current {
          background-color: $c-ct-current-bg;
          background-image: linear-gradient(to top, $c-ct-current-bg-alt 50%, $c-ct-current-bg-alt 50%, $c-ct-current-bg);
          color: $c-ct-current-txt;
          box-shadow: inset 0 1px 1px rgba(#000, .06);

          &:link:hover {
            color: $c-ct-txt;
          }
        }
        &.disabled {
          color: $c-ct-dsb-txt;
          text-shadow: none;
          cursor: default;
        }
      }
        .ct_button_icon {
          display: inline-block;
          width: 15px;
          height: 16px;
          margin-right: .3em;
          vertical-align: -.4em;
          background-image: url('../images/i-toolbar.svg');
          background-repeat: no-repeat;
          background-size: 79px 48px;

          .ct_button.current & {
            opacity: .5;
          }
          .ct_button.current:hover & {
            opacity: .8;
          }
        }

      .ct_button--blame {
        .ct_button_icon {
          background-position: 0 0;
        }
        &:hover .ct_button_icon,
        &.current .ct_button_icon {
          background-position: 0 -16px;
        }
        &.disabled .ct_button_icon {
          background-position: 0 -32px;
        }
      }
      .ct_button--edit {
        .ct_button_icon {
          background-position: -16px 0;
        }
        &:hover .ct_button_icon,
        &.current .ct_button_icon {
          background-position: -16px -16px;
        }
        &.disabled .ct_button_icon {
          background-position: -16px -32px;
        }
      }
      .ct_button--preview {
        .ct_button_icon {
          background-position: -32px 0;
        }
        &:hover .ct_button_icon,
        &.current .ct_button_icon {
          background-position: -32px -16px;
        }
        &.disabled .ct_button_icon {
          background-position: -32px -32px;
        }
      }
      .ct_button--download {
        .ct_button_icon {
          background-position: -48px 0;
        }
        &:hover .ct_button_icon,
        &.current .ct_button_icon {
          background-position: -48px -16px;
        }
        &.disabled .ct_button_icon {
          background-position: -48px -32px;
        }
      }
      .ct_button--expand {
        .ct_button_icon {
          background-position: -64px 0;
        }
        &:hover .ct_button_icon,
        &.current .ct_button_icon {
          background-position: -64px -16px;
        }
        &.disabled .ct_button_icon {
          background-position: -64px -32px;
        }
      }

      .ct_button--settings {
        width: 13px;
        vertical-align: top;
        overflow: hidden;

        .ct_button_icon {
          @extend .a-settings;

          width: 13px;
          margin-right: .9em;
          vertical-align: -.25em;
          background: none;
        }
        &.disabled .ct_button_icon {
          opacity: .5;

          &:hover::before {
            background-position: 0 0;
          }
        }
      }



/* Code viewer
------------------------------ */

$cv-cmt-w1: 600px;
$cv-cmt-w2: 750px;
$cv-cmt-issue: 45px;
$cv-lh: 1.23em;  // 16px / 13px

/* stylelint-disable no-duplicate-selectors */
.cv {
  display: block;
  border: 1px solid $c-cv-brd;
  border-top-width: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $c-cv-bg;
  line-height: $cv-lh;

  table {
  }
    td {
      border-bottom: 0;
    }
    tbody {
      // Workaround to imitate padding in diff section
      border-width: 1em 0;
      border-style: solid;
      border-color: $c-cv-bg;
    }
    tbody.cv_sep {
      border-width: 0;

      td {
        height: 1px;
        padding: 0;
        background: $c-cv-brd;
      }
    }
}
/* stylelint-enable */
  .ln,
  .loc {
    padding: 0 1em;
    font-family: $f-monospace-fb;

    .fonts-loaded & {
      font-family: $f-monospace;
    }
  }

  // Line Number
  .ln {
    width: 1%;
    padding-top: .2em;
    color: $c-cv-ln-txt;
    font-size: .85em;  // 11px
    text-align: right;
    text-shadow: 0 1px 0 rgba(#FFF, .75);
    white-space: nowrap;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  // Line Of Code
  .loc {
    color: $c-cv-txt;
    font-size: .92em;  // 12px

    pre {
      display: inline-block;
      box-sizing: border-box;
      min-height: $cv-lh;  // Maintain line height even for empty lines
      padding: 1px 0;
      font-family: inherit;
      tab-size: 4;
    }
    pre:empty {
      &::before {
        content: ' ';  // Workaround to highlight empty lines properly
      }
    }
  }

  // Highlighting a line
  .cv tr.is-highlighted,
  .cv--commentable tr:hover,
  .cv--commentable tr.is-commented {
    .ln {
      color: $c-cv-ln-h;
    }
    .loc {
      pre {
        width: 100%;
        background-color: $c-cv-hover;
        cursor: pointer;
      }
      del,
      ins {
        background-color: transparent;
      }
    }
  }
  .cv--commentable tr.is-commented {
    .loc {
      pre {
        background-color: $c-cv-hl;
      }
    }
  }


// Unfolded changes

.cv_source {
  border-top: 2px solid darken($c-cv-brd, 1.5%);
  border-bottom: 1px solid $c-cv-brd;
  padding: 1.33em 1em;
  background-color: #F7F7F7;

  &:first-child {
    border-top: 0;
  }
  .userpic {
    float: left;
    margin-right: 1em;
  }
}
  .cv_source_link {
    text-decoration: none !important;

    strong {
      color: $c-dark;
    }
    u {
      background-image: linear-gradient(to bottom, rgba($c-link, .25), rgba($c-link, .25));
      background-repeat: repeat-x;
      background-position: 0 97.5%;
      background-size: 1px 1px;
      text-decoration: none;
    }
    &:hover u {
      background-image: linear-gradient(to bottom, rgba($c-dark, .4), rgba($c-dark, .4)) !important;
      color: $c-dark !important;
    }
  }
  .cv_source_meta {
    display: block;
    margin-top: .33em;
    color: #BBB;
    font-size: .92em;
  }

.cv_source--approved {
  background-color: #F5FCEE;

  .cv_source_link {
    @extend .status-label;
    @extend .status-label--ok;

    u {
      background-image: linear-gradient(to bottom, rgba(#0F7C14, .25), rgba(#0F7C14, .25));
      color: #0F7C14;
    }
  }
}



// Word wrap mode

.cv--word-wrap {
  .loc {
    pre {
      white-space: pre-wrap;
    }
  }
}


// Code Snippet (used in comments)

.cv--sample {
  border-width: 1px;
  text-decoration: none !important;

  .cv_context {
    opacity: .33;
    transition: opacity 200ms;
  }
  &:link:hover,
  &:visited:hover {
    border-color: $c-cv-brd-h;

    .cv_context {
      opacity: .75;
    }
  }
  &.disabled {
    cursor: default !important;

    &:hover {
      border-color: $c-cv-brd;

      .cv_context {
        opacity: .33;
        transition: none;
      }
    }
  }
}


// Image viewer

%bg-checkboard {
  background-color: $c-cv-bg;
  background-image: linear-gradient(45deg, #F2F2F2 25%, transparent 25%, transparent 75%, #F2F2F2 75%, #F2F2F2),
                    linear-gradient(45deg, #F2F2F2 25%, transparent 25%, transparent 75%, #F2F2F2 75%, #F2F2F2);
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
}

.cv--image {
  @extend %bg-checkboard;

  padding: 1em;
  text-align: center;

  img {
    vertical-align: middle;
  }
}


// Blame

$blame-cv: 70%;

.cv--blame {
  position: relative;
  overflow: visible;
}
  .blame-tooltip {
    position: absolute;
    top: -1.4em;
    left: $blame-cv;
    z-index: $zi-blame-tooltip;
    box-sizing: border-box;
    width: 13em;
    margin-left: -5.6em;
    border-radius: 2px;
    padding: .5em 0 .65em;
    background-color: $c-blame-tooltip-bg;
    color: $c-blame-tooltip-txt;
    box-shadow: 0 0 6px 1px #FFF;
    font-size: .85em;
    text-align: center;
    white-space: nowrap;

    &::before {
      content: '';

      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      margin: 0 0 -4px -2px;
      border: 4px solid transparent;
      border-top-color: $c-blame-tooltip-bg;
      border-bottom-width: 0;
    }
  }

  .blame-block {
    position: relative;
    border-bottom: 1px solid $c-cv-brd;
    overflow: hidden;

    &:last-child {
      border-bottom: 0;
    }

    // Fade long code lines
    &::after {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: $blame-cv;
      width: 30px;
      margin-left: -30px;
      background-image: linear-gradient(to left, $c-cv-bg, rgba($c-cv-bg, 0));
    }
    .is-enabled & {
      .blame_code,
      .blame_origin {
        opacity: .25;
      }
    }
    .is-enabled &.highlight {
      .blame_code,
      .blame_origin {
        opacity: 1;
      }
    }
  }
    .blame_code {
      float: left;
      width: $blame-cv;
      overflow: auto;

      tbody {
        border-width: .75em 0;
      }
    }
    .blame_origin {
      position: relative;
      padding: 1em 1em 1em 25px;
      overflow: hidden;
      font-size: .92em;
      line-height: 1.25;
      white-space: nowrap;

      .indicator {
        position: absolute;
        top: 1.6em;
        left: 5px;
        width:  15px;
        height: 15px;
        background: url('../images/i-blame-status.svg') no-repeat;
        background-size: 15px 90px;

        &.hover {
          background-position: 0 -30px;
        }
        &.hold {
          background-position: 0 -60px;
        }
      }
      a {
        color: $c-base;
        text-decoration: none;

        .userpic {
          float: left;
          margin-top: .25em;
          margin-right: .75em;
        }
        .commit {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;

          strong {
            margin-right: .25em;
          }
        }
        .meta {
          display: block;
          color: $c-blame-meta;
          font-size: .92em;
        }
        .sep {
          margin: 0 .25em;
          color: $c-blame-sep;
        }
        &:hover {
          .commit {
            text-decoration: underline;
          }
          .meta {
            color: $c-blame-meta-h;
          }
        }
      }
    }


// Message

.cv--message {
  border-width: 1px;
  padding: 1em 1.25em;
  color: $c-cv-meta;

  .ct + & {
    border-top-width: 0;
  }
  a {
    color: $c-cv-txt;
    text-decoration: underline;
  }
  var {
    margin: 0 .2em;
    color: $c-cv-txt;
    font-size: .92em;
  }
  p {
    margin: .5em 0;
  }
  ul {
    margin: 1em 0 .75em 1.25em;
    list-style: square;

    li {
      margin-bottom: .6em;
      font: .92em $f-monospace-fb;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
  }
  &.is-standalone {
    margin: 30px;
  }
  &.is-unavailable,
  &.is-loading {
    padding-top: 3em;
    padding-bottom: 3.5em;
    text-align: center;
  }
  &.is-unavailable {
    color: #777;
    font-weight: bold;

    p {
      @extend .icon-warning;

      &::before {
        margin-right: .6em;
        vertical-align: -.05em;
      }
    }
  }
  &.is-loading {
    color: $c-mute;

    p::before {
      @extend %icon-loading;

      content: '';

      margin-right: .6em;
      border-color: #AAA #AAA $c-cv-bg $c-cv-bg;
    }
  }
}



/* Inline comments
------------------------------ */

/* Comment */

.ic_items {
  padding: 0 .85em;
}
.loc_comment {
  box-sizing: border-box;
  margin: .5em 0;
  border-radius: 2px;
  overflow: hidden;
  background-color: $c-loc-cmt-bg;
  line-height: 1.5;
  cursor: cursor;

  &:last-child {
    margin-bottom: 1.5em;
  }
}
  .loc_comment_wrap {
    box-sizing: border-box;
    max-width: $cv-cmt-w1;
    padding: 0 1em;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .userpic {
      margin-right: .25em;
      vertical-align: -.35em;
    }
  }
    .loc_comment_author {
      margin-right: .5em;

      .userpic,
      .userpic img {
        width:  16px;
        height: 16px;
      }
      .userpic {
        margin-right: .15em;
        vertical-align: -.2em;
      }
    }
    .loc_comment_footer {
      margin-left: .5em;
      color: $c-loc-cmt-meta;
      font-size: .92em;

      &::before {
        content: '–';

        margin-right: .5em;
      }
      a {
        color: inherit;
      }
    }
      .loc_comment_edit {
        a {
          margin: 0 .2em;
        }
      }
      .loc_comment_meta {
        white-space: nowrap;
      }
        .loc_comment_time {
        }
        .loc_comment_rev {
        }

// Issue

.loc_comment--issue {
  position: relative;
  background-color: $c-loc-issue-bg;

  .loc_comment_wrap {
    max-width: ($cv-cmt-w1 - $cv-cmt-issue);
    margin-left: $cv-cmt-issue;
  }
  .loc_comment_footer {
    .sep {
      margin: 0 .3em 0 0;
      color: inherit;
    }
  }
  &.is-done .loc_comment_wrap {
    color: mix($c-loc-issue-bg, $c-text, 50%);

    a:link,
    a:visited,
    .loc_comment_footer {
      color: inherit;
    }
  }
}
  .loc_comment_action {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: $cv-cmt-issue;
    border-right: 1px solid rgba(#808080, .1);
    padding: .75em 0 0;
    background-color: rgba(#FFF, .4);
    text-align: center;
    cursor: pointer;

    input[type='checkbox'] {
      margin: 0;
      font-size: large;
    }
    &.is-loading {
      &::before {
        @extend %icon-loading;

        content: '';
      }
      input[type='checkbox'] {
        display: none;
      }
    }
  }


/* Comment button */

.loc_comment_button {
  @extend .i-comments-toggle-sml;

  display: inline-block;
  margin: .4em 1.1em 2em;
  color: $c-loc-cmt-btn;
  font-weight: bold;
  font-size: .92em;
  text-decoration: none;

  &::before {
    margin-right: .5em;
    vertical-align: -.25em;
    background-position: -16px -17px;
  }
  u {
    display: inline-block;
    height: 1.2em;
    border-bottom: 1px dashed rgba($c-loc-cmt-btn, .66);
    text-decoration: none;
  }
  &:hover {
    color: $c-loc-cmt-btn-h;

    &::before {
      background-position: -29px -17px;
    }
    u {
      border-bottom: 1px solid rgba($c-loc-cmt-btn-h, .66);
    }
  }
}


/* Comment indicator */

$indicatorSize: 14px;

.loc_comment_indicator {
  position: absolute;
  left: -($indicatorSize + ((30px - $indicatorSize) / 2));
  box-sizing: border-box;
  width:  $indicatorSize;
  height: $indicatorSize;
  cursor: pointer;

  .userpic,
  .userpic img {
    width:  $indicatorSize;
    height: $indicatorSize;
  }
}
.loc_comment_indicator--two {
  .userpic {
    position: absolute;
    top:  -1px;
    left: -1px;
    box-shadow: 0 0 1px 1px #FFF;
  }
  .userpic + .userpic {
    top: 1px;
    right: -1px;
    left: auto;
  }
}
.loc_comment_indicator--multiple {
  &::before {
    content: '';

    position: absolute;
    top: -1px;
    left: -2px;
    box-sizing: border-box;
    width:  $indicatorSize;
    height: $indicatorSize;
    border: 1px solid $c-loc-cmt-idc-brd;
    border-radius: 1px;
    background-color: $c-loc-cmt-idc-bg;
  }
  .userpic {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 1px 1px #FFF;
  }
  .userpic + .userpic {
    top: 1px;
    right: -2px;
    left: auto;
  }
}

.loc_comment_collapse {
  @extend .loc_comment_indicator;

  margin: 0;
  border: 1px solid $c-content-bg;
  border-radius: 2px;
  padding: 0;
  background-color: $c-loc-cmt-idc-brd;
  text-indent: -99em;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 2px;
    margin: -1px 0 0 -3px;
    background-color: #FFF;
  }
  &:hover,
  &:focus {
    background-color: $c-loc-cmt-idc-h-bg;
  }
}


/* Comment form */

// TODO: rename to .comment_form as it is used both for inline and page comments
.loc_comment_form {
  @extend .clearfix;

  position: relative;
  float: left;
  margin: .5em 0 2em;
  border: 1px solid $c-cmt-form-brd;
  background-color: $c-cmt-form-bg;
  box-shadow: 0 1px 2px rgba(#000, .04);

  .loc_comment + & {
    margin-top: 0;
  }
  .loc_comment.hide + & {
    margin-top: .5em;
  }
}
.loc_comment_form--issue {
  border-color: #E4E4E9;

  .loc_comment_textarea {
    border-color: #E4E4E9;
  }
  .loc_comment_actions {
    background-color: #F6F7FC;
  }
}

  // Autocomplete highlight and menu

  .textcomplete-wrapper {
    display: block !important;
  }
    .loc_comment_textarea {
      box-sizing: border-box;
      width: ($cv-cmt-w1 - 2px);
      margin: 0;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $c-cmt-form-brd;
      padding: .77em;
      font: 1em $f-sansserif;

      &:focus {
        outline: none;
      }
    }

    .dropdown-menu {
      margin: .25em 0 0;
      border: 1px solid $c-cmt-suggest-brd;
      padding: 0;
      background-color: $c-cmt-suggest-bg;

      .textcomplete-item {
        border-top: 1px solid $c-cmt-suggest-brd;
        padding: 0;
        list-style: none;

        &:first-child {
          border-top: 0;
        }
        &.active {
          background-color: $c-cmt-suggest-a-bg;
        }
      }
        a {
          display: block;
          padding: .5em .66em;
          color: $c-cmt-suggest-txt;
          text-decoration: none;
        }
        a:hover {
          background-color: $c-cmt-suggest-h-bg;
          cursor: pointer;
        }
          .username {
            margin-left: .25em;
            color: $c-cmt-suggest-meta;
          }
          .userpic {
            margin-right: 4px;
            vertical-align: -.33em;
          }
          .color-badge {
            margin-right: 5px;
            margin-left: 6px;
            vertical-align: -.15em;
          }
    }

  // Blame data

  .loc_comment_blame {
    min-height: 2.9em;
    margin: 0 0 .25em;
    overflow: hidden;
    background-color: $c-loc-blame-bg;
    color: $c-loc-blame-txt;
    font-size: .85em;

    &.is-loading {
      background-image: url('../images/spinner-horizontal.gif');
      background-repeat: no-repeat;
      background-position: 1em 50%;
      background-size: 35px 7px;
    }
    p {
      margin: .75em 1em;

      &::before {
        content: '';

        display: inline-block;
        width: 9px;
        height: 12px;
        margin-right: .5em;
        vertical-align: -.2em;
        background: url('../images/i-blame-sml.svg') no-repeat;
        background-size: 9px 12px;
      }
    }
    .loc_comment_blame_user,
    .loc_comment_blame_ref {
      color: $c-loc-blame-alt;
    }
    .loc_comment_blame_ref {
      font: .9em $f-monospace-fb;
      text-decoration: underline;

      .fonts-loaded & {
        font-family: $f-monospace;
      }
    }
  }

  // Error message

  .loc_comment_error {
    margin-top: -1px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $c-cmt-form-error-brd;
    padding: 1em .75em;
    background-color: $c-cmt-form-error-bg;
    color: $c-cmt-form-error-txt;

    p {
      margin: 0;
    }
  }


  // Comment vs Issue toggle

  .loc_comment_head {
    border-bottom: 1px solid mix(#FFF, $c-cmt-form-brd, 50%);
    padding: .75em;
    background-color: mix(#FFF, $c-cmt-form-bg, 50%);
  }
    .loc_comment_type {
      overflow: hidden;
    }
      .loc_comment_toggle {
        float: left;
        border: 1px solid $c-cmt-form-brd;
        padding: .4em 1em;
        color: #BBB;
        font-size: .92em;
        cursor: pointer;

        &:first-child {
          border-radius: 3px 0 0 3px;
          border-right: 0;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
          border-left-color: #CCC;
        }
        &.is-selected {
          border-color: #CCC;
          background-color: #FFF;
          color: $c-dark;
          cursor: auto;
        }
        input[type='radio'] {
          display: none;
        }
      }


  // Action buttons

  .loc_comment_actions {
    box-sizing: border-box;
    min-width: ($cv-cmt-w1 - 2px);
    padding: 1em .75em;
    transition: background .2s;

    .link-syntax {
      float: right;
      margin-top: .25em;
    }
  }


  // Close button

  .loc_comment_close {
    position: absolute;
    top: 10px;
    right: -30px;
    width:  30px;
    height: 30px;
    margin: 0;
    border: 1px solid #E3E3E3;
    border-radius: 0 3px 3px 0;
    padding: 0;
    background-color: #F9F9F9;
    color: #999;
    font-size: 1.1em;
    line-height: 2;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: #CCC;
      background-color: #CCC;
      color: #FFF;
      outline: none;
    }
  }
  .loc_comment_close--inside {
    top: .55em;
    right: .55em;
    border-radius: 3px;
  }

@media (min-width: 1000px) {
  .loc_comment_wrap {
    max-width: $cv-cmt-w2;
  }
  .loc_comment_textarea {
    width: ($cv-cmt-w2 - 2px);
  }
  .loc_comment_actions {
    min-width: ($cv-cmt-w2 - 2px);
  }
  .loc_comment--issue {
    .loc_comment_wrap {
      max-width: ($cv-cmt-w2 - $cv-cmt-issue);
    }
  }
}
