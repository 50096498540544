/* Incidents
------------------------------ */

/* Summary */

.incident-container .summary {
  margin: 0 0 2em;
}
  .incident-container .summary h3,
  .incident-container .summary h4 {
    margin: 0 0 1em;
    border-bottom: 1px solid $c-incident-brd;
    padding: .5em 0;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1.5;
  }
  .incident-container .summary h3 {
    color: $c-incident-title;
  }
  .incident-date {
    float: right;
    margin-top: .1em;
    color: $c-light;
    font-weight: normal;
    font-size: .9em;
    line-height: 1.666;
  }
  .incident-container .summary h4 {
    margin-top: 2em;
    border: 0;
    padding: 0;
  }
  .incident-container .summary p.notice,
  .incident-container .summary ul li {
    margin: 0;
    border-left-width: 2px;
    border-left-style: solid;
    padding: .5em .5em .5em 1em;
    line-height: 1.6;
  }
  .incident-container .summary p.notice {
    margin-bottom: 1.5em;
    border-left-color: $c-incident-notice-brd;
    background-color: $c-incident-notice-bg;
  }
  .incident-container .summary ul li {
    margin-bottom: .5em;
    border-left-color: $c-incident-resolutn-brd;
    background-color: $c-incident-resolutn-bg;
    list-style: none;
  }
    .incident-container .summary p a,
    .incident-container .summary ul li a {
      text-decoration: underline;
    }

  .incident-container .error-message code {
    display: block;
    height: 100px;
    margin: 0 0 1em;
    border: 1px solid $c-incident-brd;
    padding: .5em 1em;
    overflow-y: scroll;
    white-space: pre;
  }

  .incident-container .ssh-overview {
    margin: 0 0 1em;
  }
    .incident-container .ssh-overview pre {
      box-sizing: border-box;
      width: 100%;
    }


/* Single Action */

.incident-container .single-action {
  padding: 0 0 1em;
  overflow: hidden;
}
  .incident-container .single-action h4 {
    margin: 0;
  }
  .incident-container .single-action a {
    font-weight: bold;
    text-decoration: underline;
  }


/* Action Buttons */

.incident-container .actions {
  border-top: 1px solid $c-incident-brd;
  padding: 1em 0 0;
  overflow: hidden;
}
  .incident-container .actions a {
    margin-right: 1em;
  }
  .incident-container .log {
    margin: 0 0 1em;
  }
  .incident-container .log textarea {
    width: 100%;
  }


/* Incidents list in sidebar */

.repos-list.incidents ul li.selected a {
  color: $c-incident-current-txt;
}
.repos-list.incidents ul li p {
  padding-left: 0;
}
