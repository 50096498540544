/* Issues in code review
------------------------------ */

.cr-issues {
  margin: -20px -30px -30px;
  padding: 0 30px;

  .cs-comment_code {
    margin: .9em 0;
  }
}

  // Filter

  .cr-issues_filter {
    box-sizing: border-box;
    width: 600px;
    margin: 15px 0 25px $cr-timeline;
    font-size: .92em;

    @media (min-width: 1100px) {
      width: 750px;
    }
  }
    .issue-filter {
      display: inline-block;
      margin-right: 1.75em;
      color: $c-green-darkish;
      text-decoration: none !important;
      white-space: nowrap;

      u {
        text-decoration: underline;
      }
      &.is-selected {
        border-radius: 2px;
        padding: .1em .5em;
        background-color: $c-green-darkish;
        color: #FFF;

        u {
          text-decoration: none;
        }
        .issue-filter_count {
          color: rgba(#FFF, .66);
        }
      }
    }
      .issue-filter_count {
        margin-left: .2em;
        color: #BBB;
        font-size: .9em;
      }


  // Message

  .cr-issues_message {
    box-sizing: border-box;
    width: 600px;
    margin: 30px 0 30px $cr-timeline;
    color: #AAA;
    font-style: italic;

    @media (min-width: 1100px) {
      width: 750px;
    }
  }


  // Issue

  .cr-issue {
    @extend .clearfix;

    position: relative;
    margin: 0 -30px 5px;
    border-top: 1px solid mix(#F9FBFF, #E4E4E9, 50%) !important;
    padding: 15px 30px;
    background-color: #F9FBFF;
    transition: background .2s;

    &:hover {
      background-color: #F6F7FC;
    }
    .icon-text-expand,
    .icon-text-collapse {
      margin-left: .5em;
    }
  }
      .cr-issue_action {
        position: absolute;
        top: 1.6em;
        left: ($cr-timeline - 10px);
        box-sizing: border-box;
        width:  2em;
        height: 2em;
        border: 1px solid #E4E4E9;
        border-radius: 2px;
        padding: .15em 0 0;
        background-color: mix(#FFF, #F6F7FC, 50%);
        text-align: center;

        input[type='checkbox'] {
          margin: 0;
        }
        &.is-loading {
          &::before {
            @extend %icon-loading;

            content: '';

            position: absolute;
            top: 50%;
            left: 50%;
            margin: -4px 0 0 -4px;
          }
          input[type='checkbox'] {
            display: none;
          }
        }
      }

      .cr-issue .cs-comment_code,
      .cr-issue_excerpt,
      .cr-issue_more {
        width: 600px;
        margin-left: $cr-timeline;

        @media (min-width: 1100px) {
          width: 750px;
        }
      }
      /* stylelint-disable no-duplicate-selectors */
      .cr-issue {
        .cv--sample {
          border-color: rgba(#E4E4E9, .75);
          background-color: $c-md-block-bg;

          tbody {
            border-color: $c-md-block-bg;
          }
        }
      }
      /* stylelint-enable */

      .cr-issue_footer {
        position: absolute;
        top: 2.15em;
        left: 0;
        width: ($cr-timeline - 20px);
        height: 2em;
        margin: 0;
        overflow: hidden;
        color: #CCC;
        font-size: .92em;
        text-align: right;

        .sep {
          margin: 0 .25em;
          color: inherit;
        }
        a {
          color: inherit;
        }
        a:hover {
          color: $c-base;
          text-decoration: underline !important;
        }
      }


  // Completed
  .cr-issue.is-done {
    color: #CCC;

    .cr-issue_action {
      border-color: #EEE;
      background-color: #FFF;
    }
    .icon-text-expand {
      background-color: #BBB;
    }
  }

  // Preview
  .cr-issue--preview {
    margin-left: -($cr-timeline);
    background-color: $c-yellow-light;
  }
