/* Buttons
---------------------------------------- */

.button {
  display: inline-block;
  margin: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #C3C3C3 #BBB #B3B3B3;
  border-radius: 2px;
  padding: 6px 12px 5px;
  background-color: $c-button-bg;
  background-image: linear-gradient(to bottom, $c-button-bg-alt, $c-button-bg);
  color: $c-button-txt !important;
  box-shadow: inset 0 1px 1px 0 rgba(#FFF, .5), 0 1px 1px 0 rgba(#808080, .1);
  font: normal normal 12px/13px $f-sansserif;
  text-decoration: none !important;
  text-shadow: 0 1px 0 rgba(#FFF, .5);
  white-space: nowrap;
  cursor: pointer;

  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }

  &:hover,
  &:focus {
    border-color: $c-button-h-brd;
    color: $c-button-h-txt !important;
    box-shadow: 0 0 1px 1px $c-button-h-bsh;
    outline: none;
  }
  &:active {
    border-color: #B3B3B3 #BBB #BBB;
    background-color: $c-button-a-bg;
    background-image: linear-gradient(to bottom, $c-button-a-bg-alt, $c-button-a-bg);
    color: $c-button-a-txt !important;
    box-shadow: inset 0 1px 2px 0 $c-button-a-bsh !important;
  }

  // Disabled button

  &[disabled],
  &.disabled {
    border-color: $c-button-dsb-brd !important;
    background-color: $c-button-dsb-bg !important;
    background-image: none !important;
    color: $c-button-dsb-txt !important;
    box-shadow: none !important;
    cursor: default !important;

    .icon,
    .icon-arrow-bt::before,
    .icon-arrow-br::before,
    .icon-arrow-bb::before,
    .icon-arrow-bl::before,
    .icon-arrow-at::after,
    .icon-arrow-ar::after,
    .icon-arrow-ab::after,
    .icon-arrow-al::after {
      opacity: .5;
      filter: grayscale(100%);
    }
  }
}


// Big buttons

.button--big {
  border-radius: 3px;
  padding: 9px 18px;
  box-shadow: inset 0 1px 2px 0 rgba(#FFF, .5), 0 1px 2px 0 rgba(#808080, .1);
  font-size: 14px;
  line-height: 13px;
}


// Colored buttons

.button--warning {
  border-color: lighten(#E8AA9E, 2%) #E8AA9E saturate(darken(#E8AA9E, 3%), 5%);
  background-color: #FFE9E5;
  background-image: linear-gradient(to bottom, #FFE9E5, #FFCABF);
  color: #731300 !important;
  text-shadow: 0 1px 0 rgba(#FFF, .5);

  &:hover,
  &:focus,
  &:active {
    border-color: #E5A395;
    background-color: #FFDFD9;
    background-image: linear-gradient(to bottom, #FFF0EB, #F7B7A8);
  }
  &:hover,
  &:focus {
    color: #731300 !important;
    box-shadow: 0 0 1px 1px rgba(#E5A395, .25);
  }
  &:active {
    color: rgba(#731300, .8) !important;
    box-shadow: 0 0 1px 1px rgba(#E5A395, .25), inset 0 1px 2px 0 rgba(#000, .3);
  }
}

.button--success {
  border-color: lighten(#87BF7C, 2%) #87BF7C saturate(darken(#87BF7C, 3%), 5%);
  background-color: #E8FFD1;
  background-image: linear-gradient(to bottom, #E8FFD1, #AEE09D);
  color: #4D7339 !important;
  text-shadow: 0 1px 0 rgba(#FFF, .5);

  &:hover,
  &:focus,
  &:active {
    border-color: #69A85D;
    background-color: #E5FFCC;
    background-image: linear-gradient(to bottom, #E5FFCC, #A6D98D);
  }
  &:hover,
  &:focus {
    color: #2E5419 !important;
    box-shadow: 0 0 1px 1px rgba(#69A85D, .25);
  }
  &:active {
    color: rgba(#2E5419, .8) !important;
    box-shadow: 0 0 1px 1px rgba(#69A85D, .25), inset 0 1px 2px 0 rgba(#000, .3);
  }
}


// Compare button

.i-compare::before {
  content: '';

  display: inline-block;
  width:  13px;
  height: 12px;
  margin: 0 .33em 0 -.2em;
  vertical-align: text-bottom;
  background: url('../images/i-compare.svg') no-repeat;

  .button--big & {
    vertical-align: -.05em;
  }
}
.i-compare--black::before {
  background-image: url('../images/i-compare-black.svg');
}

.button--compare {
  @extend .button--success;
}



/* Icons
---------------------------------------- */

/* stylelint-disable no-duplicate-selectors */
.button {
  .icon-arrow-bt::before,
  .icon-arrow-br::before,
  .icon-arrow-bb::before,
  .icon-arrow-bl::before {
    margin-right: 7px;
    margin-left: -2px;
    color: #666;
  }
  .icon-arrow-at::after,
  .icon-arrow-ar::after,
  .icon-arrow-ab::after,
  .icon-arrow-al::after {
    margin-right: -2px;
    margin-left: 7px;
    color: #666;
  }

  .icon-loading {
    margin-right: 6px;
    margin-left: -3px;
    border-color: #999 #999 #F4F4F4 #F4F4F4;
  }

  .icon {
    $icon-size: 13px;

    float: left;
    width:  $icon-size;
    height: $icon-size;
    margin: 0 5px 0 -5px;
    background-image: url('../images/i-button.svg');
    background-repeat: no-repeat;
    background-position: -100% -100%;
    background-size: $icon-size ($icon-size * 9);

    &.config {
      background-position: 0 -#{$icon-size * 0};
    }
    &.checkmark {
      background-position: 0 -#{$icon-size * 1};
    }
    &.lock {
      background-position: 0 -#{$icon-size * 2};
    }
    &.merge {
      background-position: 0 -#{$icon-size * 3};
    }
    &.create {
      background-position: 0 -#{$icon-size * 4};
    }
    &.abort {
      background-position: 0 -#{$icon-size * 5};
    }
    &.next-step {
      background-position: 0 -#{$icon-size * 6};
    }
    &.deploy {
      background-position: 0 -#{$icon-size * 7};
    }
    &.retry {
      background-position: 0 -#{$icon-size * 8};
    }
    &.shopify {
      width: 16px;
      height: 16px;
      margin-top: -1px;
      margin-bottom: -2px;
      background: url('../images/i-shopify.png') no-repeat 0 0 / 16px 16px;
    }
    &.app-tower {
      height: 14px;
      margin-top: -1px;
      background: url('../images/i-tower-sml.png') no-repeat 1px 0 / 11px 14px;

      @include retina {
        background-image: url('../images/i-tower-sml@2x.png');
      }
    }
    &.app-cornerstone {
      width: 16px;
      height: 16px;
      margin-top: -2px;
      margin-bottom: -2px;
      background: url('../images/i-cornerstone-sml.png') no-repeat 0 0 / 16px 16px;

      @include retina {
        background-image: url('../images/i-cornerstone-sml@2x.png');
      }
    }
  }
}
/* stylelint-enable */

/* Help button */

a.button-help {
  position: relative;
  float: left;
  width:  18px;
  height: 18px;
  margin-top: .3em;
  margin-left: -39px;
  border-radius: 2px 0 0 2px;
  padding: 0;
  overflow: hidden;
  background: $c-button-help-bg;
  color: $c-button-help-txt !important;
  font-weight: bold;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  text-indent: -999em;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: $c-button-help-h-bg;
  }
  &::before {
    content: '?';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-indent: 0;
    text-align: center;
  }
}



/* Options */

div.key-add {
  margin: 1em 0 1em -2px;
  padding: 0;
  overflow: hidden;
}

div.options {
  margin: 1em 0 1em -2px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
  #specialPage & {
    margin-top: -.75em;
  }
  p {
    margin: .75em 0 0 !important;
  }
  .sep {
    display: inline-block;
    margin: .8em .75em 0;
    font-size: .9em;
  }
  a.link-action {
    margin-left: .5em;
    color: $c-options-action;
    font-weight: bold;
  }
}


/* Button as inline action */

.inline-button {
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0;
  background: none;
  color: $c-link;
  font: 1em $f-sansserif;
  text-decoration: underline;
  cursor: pointer;
  appearance: none;

  &:hover {
    color: $c-base;
  }
}
.inline-button--dark {
  color: $c-text;
}

