/* Account and Billing
------------------------------ */

.custom-plan-borderless {
  .custom-plan {
    margin: 0 20px 20px;
    border: 0;
    padding: 0;
  }
}

/* Plan showcase */

.plan-showcase {
  @extend .clearfix;

  border: 2px solid $c-plans-page-brd;
  background-color: transparent;
  transition: background 200ms ease;

  &.bloom {
    background-color: $c-plans-page-bg-alt;
  }
}
  .plan-showcase-wrap {
    @extend .clearfix;

    margin: 20px -12px 40px;
  }


    // Plan Description

    .plan-description {
      float: left;
      width: 60%;
    }
      .plan-description-wrap {
        margin: 0 10px 0 30px;

        a {
          color: $c-plan-l;
          text-decoration: underline;
        }
      }

        .plan-title {
          margin-bottom: 1em;

          h1,
          h2 {
            margin: 0;
            padding: 0;
            color: $c-plan-title;
          }
          h1 {
            margin-bottom: .4em;
            font-size: 1.6em;
            line-height: 1.2;
          }
          h2 {
            font-weight: normal;
            font-size: 1.1em;
            line-height: 1.3;
          }
        }

        .plan-includes {
          margin: 1em 0;
          overflow: hidden;
          font-size: 1.1em;
          list-style: none;

          li {
            float: left;
            margin: .4em .75em .4em 0;
            border-radius: 3px;
            padding: .5em .75em;
            background-color: $c-plan-incl-bg;
            color: $c-plan-incl-txt;
            white-space: nowrap;
          }
            b {
              color: $c-plan-incl-dark;
            }
          .plan-limit-exceeded {
            padding-left: 30px;
            background: $c-plan-exceed-bg url('../images/i-error-box-red.svg') no-repeat .75em 47.5%;
            background-size: 15px 13px;
            color: $c-plan-exceed-txt;

            b {
              color: $c-plan-exceed-dark;
            }
          }
        }

        .plan-business-title {
          margin: 1em 0 .6em;
          color: $c-plan-bus-title;
          font-size: 1.25em;

          &.icon-warning::before {
            vertical-align: baseline;
          }
        }
        .plan-business-title--cutoff {
          color: $c-plan-title-cutoff;
        }
        .plan-business-title--disable {
          color: $c-plan-dsb-txt;
        }

        .plan-business-list {
          margin: 0 0 1.25em;
          list-style: none;

          li {
            position: relative;
            margin-bottom: .6em;
            padding-left: 15px;
            line-height: 1.3;

            &::before {
              content: '';

              position: absolute;
              top: .3em;
              left: 0;
              width: 10px;
              height: 10px;
              background: url('../images/i-includes-grey.svg') no-repeat;
              background-size: 10px 10px;
            }
          }
          a {
            color: inherit;
          }
        }
        .plan-business-list--cutoff,
        .plan-business-list--disable {
          li {
            &::before {
              top: .35em;
              width: 11px;
              height: 11px;
              background-image: url('../images/i-status.svg');
              background-size: 11px 200px;
            }
          }
          del {
            text-decoration: none;
          }
        }
        .plan-business-list--cutoff {
          li {
            color: $c-plan-list-cutoff;

            &::before {
              background-position: 0 -100px;
            }
          }
        }
        .plan-business-list--disable {
          li {
            color: $c-plan-dsb-txt;

            &::before {
              background-position: 0 -50px;
            }
          }
        }

    // Upgrade form

    .upgrade-form {
      float: right;
      width: 40%;

      a {
        color: $c-plan-l;
        text-decoration: underline;
      }
      h2 {
        margin: 0 0 .66em;
        color: $c-plan-form-title;
        font-size: 1.1em;
      }
      p {
        margin: 0 0 .5em;
      }
    }
      .upgrade-form-wrap {
        position: relative;
        margin: 0 0 0 10px;
        border: 2px solid $c-plan-form-brd;
        padding: 20px;
        background-color: $c-plan-form-bg;

        &::after {
          content: '';

          position: absolute;
          right: -2px;
          bottom: -22px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: $c-plan-form-corner;
          border-left-width: 0;
        }
      }
        .StripeElement {
          box-sizing: border-box;
          margin: 0;
          border: 1px solid #BFBFAC;
          border-radius: 5px;
          padding: 1em;
          background-color: #FFF;

          &--invalid {
            border-color: $c-form-boxed-error-brd;
          }
          &--webkit-autofill {
            background-color: mix(#FFF, $c-plan-form-bg, 50%) !important;
          }
        }

        .stripe-card-errors {
          box-sizing: border-box;
          margin: 10px auto 20px;
          color: $c-form-boxed-error-txt;

          .error-msg {
            margin: 0;
            font-weight: normal;
            font-size: 100%;
          }
        }


        // TODO: Review and cleanup after Stripe migration

        .upgrade-form input[type='text'] {
          margin-top: 0;
          padding: .4em;
          font-family: $f-sansserif;
        }
        .upgrade-form select {
          margin: .4em .4em .4em 0;
        }
        .upgrade-form .validation {
          font-size: .9em;
          line-height: 1.4;
        }
        .upgrade-form .form-error {
          padding: 0;
          background-color: transparent;

          .form-label {
            color: $c-form-boxed-error-txt;
            font-weight: bold;
          }
          .error-msg {
            font-weight: normal;
          }
        }
        .upgrade-form .form-text {
          margin-bottom: .5em;
        }
        .upgrade-form .form-buttons {
          margin-top: 1.5em;
          text-align: center;
        }
        .upgrade-form .detached {
          margin: 1.5em 0 0;
        }
        .upgrade-form .attached {
          margin-top: .25em;
        }

        .upgrade-form-summary {
          margin-top: 1.66em;
          color: $c-plan-form-meta;
          font-size: .9em;

          p {
            margin: 0;
          }
        }
        .upgrade-roadblocks {
          margin: .75em 0 0 1.4em;

          > li {
            list-style: disc;
          }
        }
          .upgrade-roadblocks_repos {
            margin: .25em 0;

            .color-badge {
              float: left;
              margin-top: .2em;
              margin-right: .5em;
            }
          }


@media (max-width: 1050px) and (min-width: 800px) {
  .plan-description,
  .upgrade-form {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .plan-description,
  .upgrade-form {
    float: none;
    width: auto;
  }
    .plan-showcase-wrap {
      margin-right: 20px;
      margin-left:  20px;
    }
      .plan-description-wrap {
        margin: 0;
      }
      .upgrade-form-wrap {
        margin: 20px 0 0;

        &::after {
          content: none;
        }
      }
}



/* Plans lineup */

$plan-title-height: 80px;

.plans-lineup {
  @extend .clearfix;

  margin: 20px -10px 60px;
}
  .plan-item {
    float: left;
    width: 16.66%;
  }
    .plan-item-wrap {
      display: block;
      position: relative;
      margin: 0 10px;
      border: 2px solid $c-plan-item-brd;
      border-radius: 5px;
      padding: 0 20px 20px;
      background-color: $c-plan-item-bg;
      color: $c-plan-item-txt;
      text-decoration: none;
    }
      .plan-item-title {
        display: inline-block;
        margin: 0;
        border: 2px solid $c-plan-item-brd;
        border-radius: 0 0 2px 2px;
        border-top: 0;
        padding: .4em .75em .5em;
        vertical-align: middle;
        color: $c-plan-item-title;
        font-weight: bold;
        font-size: 11px;
        line-height: normal;
        text-transform: uppercase;
      }
      .plan-item-desc {
        position: relative;
        height: 170px;
        padding-top: 20px;
        transition: border-color .15s ease;
      }
        .plan-item-includes {
          margin: 0 0 1.25em;
          list-style: none;

          li {
            margin-right: -10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          del {
            color: $c-plan-item-del;
          }
          ins {
            font-weight: bold;
          }
        }
          .i-extra {
            display: inline-block;
            position: relative;
            width:  12px;
            height: 12px;
            border-radius: 50%;
            vertical-align: -1px;
            background-color: $c-plan-item-txt;

            &::before,
            &::after {
              content: '';

              position: absolute;
              background-color: $c-plan-item-bg;
            }
            &::before {
              top: 2px;
              left: 5px;
              width: 2px;
              height: 8px;
            }
            &::after {
              top: 5px;
              left: 2px;
              width: 8px;
              height: 2px;
            }
          }

        .plan-item-price {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          line-height: 18px;

          b {
            padding-right: .1em;
            font-size: 1.6em;
          }
        }

        .plan-item-view {
          position: absolute;
          right: -26px;
          bottom: 0;
          border-radius: 2px 0 0 2px;
          padding: .3em .75em;
          background-color: $c-plan-item-btn-bg;
          color: $c-plan-item-btn-txt;
          font-weight: bold;
          font-size: .9em;
          line-height: 18px;

          &::after {
            content: '';

            position: absolute;
            right: 0;
            bottom: -8px;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: $c-plan-item-btn-corner;
            border-left-width: 0;
          }
        }
        .plan-current {
          margin: 20px -22px -22px;
          border-radius: 0 0 5px 5px;
          padding: 1em .5em;
          background-color: $c-plan-item-current-bg;
          color: $c-plan-item-current-txt;
          font-weight: bold;
          font-size: .75em;
          line-height: normal;
          text-align: center;
          text-transform: uppercase;
          text-shadow: 0 1px 1px #FFF;
        }


    // Hover state

    .plan-item-wrap:hover {
      border-color: $c-plan-item-h-brd;
      text-decoration: none;
      transition: border-color 150ms;
      transform: translateY(-3px);

      .plan-item-title {
        border-color: $c-plan-item-h-brd;
        color: $c-plan-item-h-title;
      }
      .plan-current {
        background-color: $c-plan-item-h-current-bg;
        color: $c-plan-item-h-current-txt;
        transition: background 150ms;
      }
    }

    @media (max-width: 1200px) {
      .copy-repo-full {
        display: none;
      }
    }
    @media (max-width: 1200px) and (min-width: 1100px) {
      .plan-item-price b {
        font-size: 1.2em;
      }
    }
    @media (max-width: 1100px) {
      .plan-item-view {
        display: none;
      }
    }



  // Current plan

  .plan-item.plan-item--current {
    .plan-item-wrap {
      position: relative;
      border: 0;
      border-radius: 0 0 5px 5px;
      padding: 0 22px 22px;
      background-color: $c-plan-current-bg;
      color: $c-plan-current-txt;
      text-align: center;

      &:hover {
        cursor: default;
        transform: none;
      }
      &::before {
        content: '';

        position: absolute;
        top: -44px;
        right: 0;
        left: 0;
        height: 47px;
        background: url('../images/plan-current.svg') no-repeat;
        background-size: 100% 100%;
      }
    }
      .plan-item-title {
        position: relative;
        border: 2px solid rgba(#FFF, .25);
        border-radius: 2px;
        color: $c-plan-current-title;
      }
      .plan-item-desc {
        text-align: left;
      }
        .plan-item-includes {
          del {
            color: $c-plan-current-del;
          }
        }
        .i-extra {
          background-color: $c-plan-current-txt;

          &::before,
          &::after {
            background-color: $c-plan-current-bg;
          }
        }
        .plan-item-price {
          text-align: center;
        }
        .plan-item-view {
          display: none;
        }
        .plan-current {
          background-color: $c-plan-current-bdg-bg;
          color: $c-plan-current-bdg-txt;
          text-shadow: 0 1px 1px rgba(#000, .2);
          transition: none;
        }
  }


/* Custom plans */

.plans-lineup--custom {
  // Reset old queries
  @media (max-width: 1100px) and (min-width: 1000px) {
    .plan-item-price b {
      font-size: 1.6em;
    }
  }
  @media (max-width: 1000px) {
    .plan-item-view {
      display: block;
    }
  }

  // Setup new ones
  @media (max-width: 1175px) {
    .plan-item-view {
      display: none;
    }
  }

  .plan-item {
    width: 14.28%;
    font-size: .9em;
  }
    .plan-item-wrap {
      padding-right: 10px;
      padding-left:  10px;
    }
      .plan-item-view {
        right: -16px;
      }
      .plan-current {
        margin-right: -12px;
        margin-left:  -12px;
      }
  .plan-item--current {
    .plan-item-wrap {
      padding-right: 12px;
      padding-left:  12px;
    }
  }
}



/* Customers logos */

.customers-logos {
  margin: 60px 0;
  overflow: hidden;
  list-style: none;
}
  .customer-item {
    $items: 7;

    float: left;
    width: 100% / $items;
    height: 71px;
    margin: 0;
    text-align: center;

    span {
      display: inline-block;
      height: 100%;
      overflow: hidden;
      background-image: url('../images/customers-showcase-color.png');
      background-repeat: no-repeat;
      background-size: 633px 71px;
      text-indent: -999em;

      @include retina {
        background-image: url('../images/customers-showcase-color@2x.png');
      }
    }
  }
  .customer-item--title {
    display: table;
    color: $c-plan-title;
    font-weight: bold;
    font-size: 1.25em;
    line-height: normal;

    strong {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .customer-logo-wholefoods {
    width: 85px;
    background-position: 0 0;
  }
  .customer-logo-philips {
    width: 125px;
    background-position: -85px 0;
  }
  .customer-logo-intel {
    width: 90px;
    background-position: -(85px + 125px) 0;
  }
  .customer-logo-disney {
    width: 103px;
    background-position: -(85px + 125px + 90px) 0;
  }
  .customer-logo-citrix {
    width: 98px;
    background-position: -(85px + 125px + 90px + 103px) 0;
  }
  .customer-logo-happycog {
    width: 71px;
    background-position: -(85px + 125px + 90px + 103px + 98px) 0;
  }
  .customer-logo-realmac {
    width: 60px;
    background-position: -(85px + 125px + 90px + 103px + 98px + 71px) 0;
  }


/* FAQ */

.faq {
  h4 {
    margin-bottom: .3em;
    color: $c-dark;
    font-size: 1em;
  }
  p {
    margin-top: 0;
  }
}
